import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { storesContext } from '../../../stores';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Modal } from 'react-bootstrap';
import CreateClass from '../../Tabs/CreateClass/index.jsx';
import { ClassDetailsContext } from '../../../views/ClassDetails/store';

const EditClassModal = (props) => {
  const { classStore, userStore } = useContext(storesContext);
  const { t } = useTranslation();

  const classDetailsStore = useContext(ClassDetailsContext);
  useEffect(() => {}, [classDetailsStore.classDetail]);
  const handleCloseModal = () => {
    classDetailsStore.showEditClassModal = !classDetailsStore.showEditClassModal;
  };

  return (
    <React.Fragment>
      <Modal
        show={classDetailsStore.showEditClassModal}
        onHide={handleCloseModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Edit Class')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {classStore.ready &&
            (userStore.isInstructor || userStore.isAcademyAdmin) && (
              <CreateClass
                addClass={classStore.fetchClasses}
                classesLength={classStore.classes.length}
                existingClass={classDetailsStore.classDetail}
                existingFile={props.existingFile}
                toggleEditClassModalProp={props.toggleEditClassModalProp}
                onEditClassSubmit={props.onEditClassSubmit}
              />
            )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

EditClassModal.propTypes = {
  existingFile: PropTypes.string,
  toggleEditClassModalProp: PropTypes.func,
  onEditClassSubmit: PropTypes.func,
};

EditClassModal.defaultProps = {
  existingFile: '',
  toggleEditClassModalProp: () => {},
  onEditClassSubmit: () => {},
};

export default observer(EditClassModal);
