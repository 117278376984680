import { observable, action, computed } from 'mobx';
import CloseIcon from '../../../img/icons/close.svg';
import ArrowRightIcon from '../../../img/icons/arrow-right.svg';
import { ROLES } from '../../../config/constants';
import { logout } from '../../../services/AuthService';
class SideNavigationStore {
  constructor(rootStore) {
    this.userStore = rootStore.userStore;
    this.routerStore = rootStore.routerStore;
    this.catalogStore = rootStore.catalogStore;
    this.classStore = rootStore.classStore;
  }
  offeringRoute = [
    'videocourses:view',
    'exams:view',
    'courses:view',
    'courses:view.student',
    'courses:page',
    'courses:page.student',
  ];
  classRoute = [
    'classes:view',
    'classes:create:assignment',
    'classes:edit:assignment',
    'classes:view:assignment',
    'classes:view:student',
  ];
  consumptionRoute = [
    'academysummary:classes',
    'academysummary:class:details',
    'academysummary:instructors',
    'academysummary:students',
  ];

  @observable showSideBar = true;
  @observable offeringTitle = '';
  @observable offeringRouteName = '';

  @observable nestedRouteTitle = '';
  @observable nestedRouteName = '';
  @observable _navLinks = [
    {
      text: 'Logout',
      path: 'logout',
      roles: [
        ROLES.student.name,
        ROLES.global.name,
        ROLES.bdm.name,
        ROLES.instructor.name,
        ROLES.academy_admin.name,
      ],
      handler: () => this.logout(),
    },
  ];

  @action async logout() {
    //todo: - move to common place
    await logout(this.userStore);    
  }

  @action toggleSiderBar() {
    this.showSideBar = !this.showSideBar;
  }

  @computed get sidebarClassName() {
    return this.showSideBar ? '' : 'small-sidebar-menu';
  }

  @computed get closeButton() {
    return this.showSideBar ? CloseIcon : ArrowRightIcon;
  }

  getClassDetails() {
    let routeTitle = null;
    let classDetail = this.classStore.getClassDetailbyId(
      this.routerStore.route?.params.classid,
    );
    if (classDetail) {
      routeTitle = classDetail.class_name;
      routeTitle += this.routerStore.route?.params?.studentusername
        ? ` / ${this.routerStore.route?.params?.studentusername}`
        : '';
      routeTitle +=
        this.routerStore.route?.name === 'classes:create:assignment' ||
        this.routerStore.route?.name === 'classes:edit:assignment'
          ? ` / assignment`
          : '';
    }
    return routeTitle;
  }
  getOfferingDetails(langugage) {
    let offeringTitle = this.catalogStore.getCourseTitleBySlug(
      this.routerStore.route?.params.slug,
      langugage,
    );
    return offeringTitle;
  }
  @action setNestedRouteTitle(langugage) {
    if (this.isNestedRoute(this.routerStore.route?.name)) {
      const nestedRouteTitle = this.isClassRoute(this.routerStore.route?.name)
        ? this.getClassDetails()
        : this.isOfferingRoute(this.routerStore.route?.name)
        ? this.getOfferingDetails(langugage)
        : this.isConsumptionSummaryClassRoute(this.routerStore.route?.name)
        ? 'Classes' 
        : this.isConsumptionSummaryClassDetailsRoute(this.routerStore.route?.name)
        ? this.getClassDetails()
        : this.isConsumptionSummaryInstructorRoute(this.routerStore.route?.name)
        ? 'Instructors'
        : this.isConsumptionSummaryStudentRoute(this.routerStore.route?.name)
        ? 'Students'
        : this.isSubmitSupportRoute(this.routerStore.route?.name)
        ? 'Open a Support Case'
        : null;
      this.nestedRouteTitle = nestedRouteTitle;
      this.nestedRouteName = this.routerStore.route?.name;
    }
  }

  @action resetNestedRouteTitle() {
    this.nestedRouteTitle = '';
    this.nestedRouteName = '';
  }

  isClassRoute(routeToRender) {
    return (
      this.classRoute.includes(routeToRender) &&
      this.routerStore.route?.params.classid !== ''
    );
  }
  isOfferingRoute(routeToRender) {
    return this.offeringRoute.includes(routeToRender);
  }

  isSubmitSupportRoute(routeToRender) {
    return routeToRender === 'submit-support';
  }

  isConsumptionSummaryClassRoute(routeToRender) {
    return routeToRender === 'academysummary:classes' &&  this.consumptionRoute.includes(routeToRender);
  }
  isConsumptionSummaryClassDetailsRoute(routeToRender) {
    return routeToRender === 'academysummary:class:details' &&  this.consumptionRoute.includes(routeToRender);
  }
  isConsumptionSummaryInstructorRoute(routeToRender) {
    return routeToRender === 'academysummary:instructors' && this.consumptionRoute.includes(routeToRender);
  }
  isConsumptionSummaryStudentRoute(routeToRender) {
    return routeToRender === 'academysummary:students' && this.consumptionRoute.includes(routeToRender);
  }
  @action isNestedRoute(routeToRender) {
    return (
      this.classRoute.includes(routeToRender) ||
      this.offeringRoute.includes(routeToRender) ||
      this.consumptionRoute.includes(routeToRender) ||
      this.isSubmitSupportRoute(routeToRender)
    );
  }
  @computed get navLinks() {
    return this._navLinks.filter((l) =>
      l.roles.includes(this.userStore.role?.name),
    );
  }

  @computed get shouldRender() {
    return this.routerStore?.route?.name !== 'courses:lab';
  }

}

export default SideNavigationStore;
