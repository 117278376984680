import React, { useEffect, useContext, useState } from 'react';
import { storesContext } from '../../stores';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';
import { Modal, Button } from 'react-bootstrap';
import ExternalResourcesStore from './store';
import { CARD_COLORS } from '../../config/constants';
import CardList from '../CardList';
import IconCardContainer from '../Cards/IconCardContainer';
import UserInfoForm from '../Cards/MaterialCard/BuyLabCard/ModalLabOptions/UserInfoForm';
import ViewLoader from '../Loading/View';

const ExternalResource = () => {
    const { t } = useTranslation();
    const [showModal, setshowModal] = useState(false);
    const [redirectOnUserInfoUpdate, setredirectOnUserInfoUpdate] = useState(null);
    const { userStore } = useContext(storesContext);
    const store = useLocalStore(() => new ExternalResourcesStore(userStore));
    const getColor = (index) => {
        return CARD_COLORS[index % CARD_COLORS.length];
    };
    const onResourceClick = (item) => {
        if (!store.canConnectLTI1P3) {
            setshowModal(true);
            setredirectOnUserInfoUpdate(item.url);
        } else {
            window.open(item.url, '_blank').focus();
        }
    };

    const renderUserInfoModal = () => {
        return (
            <Modal
              show={showModal}
              onHide={() => setshowModal(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              dialogClassName="modal-labs"
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {t('Personal Information')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UserInfoForm onUserInfoUpdate={() => window.open(redirectOnUserInfoUpdate, '_blank').focus()} connectLTI={true} />
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setshowModal(false)}>{t('Close')}</Button>
              </Modal.Footer>
            </Modal>
        );
    };

    const renderCourseItems = (items) => {
        return (
          <React.Fragment>
            {items.length > 0 && (
              <CardList>
                {items?.map((i, index) => {
                  return (
                    <React.Fragment key={index}>
                      <IconCardContainer
                        title={i.title}
                        firstLineElement={i.firstLine}
                        secondLineElement={i.secondLine}
                        cardColor={getColor(index)}
                        iconName={i.icon || 'external-link-new'}
                        onCardClick={() => {
                          onResourceClick(i);
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </CardList>
            )}
          </React.Fragment>
        );
    };

    useEffect(() => {
        when(
            () => !store.loaded,
            async () => {
                await store.getLTI1P3ProviderInfo();
            },
        );
    }, [store]);

    return(
        <React.Fragment>
            {store.loaded ? (
              <React.Fragment>
                {store.providers?.length > 0 && store.providers.map((p, index) => {
                    return(
                        <React.Fragment key={index}>
                            <section className="m-3">
                                <b>{p.section_header}</b>
                                {renderCourseItems(p.courses)}                                
                            </section>
                        </React.Fragment>
                    );
                })}
                {renderUserInfoModal()}
              </React.Fragment>            
            ) : (
                <ViewLoader />
            )}
        </React.Fragment>
    );
};

export default observer(ExternalResource);