import { observable, action, computed } from 'mobx';
import {
  getCourseStructure,
  getCourseInfo,
  getCourseCollateral,
  getCoursePage,
  getStudentSelfPacedVideoAccessStatus,
} from '../services/CourseService';
import {
  getUserDataForCourse,
  createUserDataForCourse,
  modifyUserDataForDoc,
} from '../services/UserDataService';
import notifications from '../mocks/notifications.mock';
import moment from 'moment';
import { KALTURA_CONFIGS, ZDOC_COURSE_INFO } from '../config/constants';
import ZDocManagerStore from '../stores/ZdocManager';
import { NO_CLASS } from '../config/constants';
class CourseStore {
  @observable course = {};
  @observable error = {};
  @observable courseInfo = {};
  @observable collateral = {};
  @observable structure = [];
  @observable page = {};
  @observable available = true;
  @observable contentLoading = false;
  @observable errorLoadingContent = false;
  @observable _activeTab = 1;
  @observable classId = '';
  @observable courseSlug = '';
  @observable pageSlug = '';
  @observable language = 'en-US';
  @observable userData = {};
  @observable videosInCourse = {};
  @observable isStudentEligibleForSelfPaced = false;
  @observable course_lock_solution_status = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.zdocManagerStore = new ZDocManagerStore(ZDOC_COURSE_INFO);
  }

  @computed get firstPageSlug() {
    return this.structure? this.structure[0]?.page_tag : null;
  }

  @computed get entityValueToTrackUserActivityWith() {
    return this.rootStore.userStore.isStudent
      ? this.classId
      : this.rootStore.academyStore.academyId;
  }

  @computed get entityValueToTrackProgressWith() {
    return this.rootStore.userStore.isStudent
      ? this.classId
      : this.courseSlug;
  }
  @computed get entityKeyToTrackUserActivityWith() {
    return this.rootStore.userStore.isStudent ? 'class_id' : 'academy_id';
  }

  @computed get entityKeyToTrackProgressWith() {
    return this.rootStore.userStore.isStudent ? 'class_id' : 'slug';
  }
  @computed get coursePageRouteName() {
    const page_name = `courses:page${
      this.rootStore.userStore.isStudent ? '.student' : ''
    }`;
    return page_name;
  }

  @computed get coursePageRouteParamsByRole() {
    const params = this.rootStore.userStore.isStudent
      ? {
          classid:
            this.rootStore.routerStore.route.params.classid === undefined
              ? NO_CLASS
              : this.rootStore.routerStore.route.params.classid,
        }
      : {};

    return params;
  }

  @computed get courseRouteName() {
    const page_name = `courses:view${
      this.rootStore.userStore.isStudent ? '.student' : ''
    }`;
    return page_name;
  }

  @computed get structureLoaded() {
    return this.structure.length > 0;
  }

  @action contentNotAvailable() {
    this.available = false;
  }
  @action async getAssignedCourseInfo(courseSlug, classId) {
    try {
      const courseInfo = await this.rootStore.classStore.getAssignedCourseInfo(
        courseSlug,
        classId,
      );

      return courseInfo &&
        this.rootStore.classStore.isNowBetweenDates(
          courseInfo.startDate,
          courseInfo.endDate,
        )
        ? courseInfo
        : null;
    } catch (error) {
      return null;
    }
  }

  @action async getCourseInfoByCourseSlug(courseSlug) {
    let course = {};
    try {
      const courseSlugParts = courseSlug.split('-');
      const code = courseSlugParts?.length > 0 && courseSlugParts[0];
      const version = courseSlugParts?.length > 0 && courseSlugParts[1];
      const collateral = await this.getCourseCollateral(
        code,
        version,
        this.language,
      );
      const title = `${collateral.title} ${version}`;
      const academy_id = this.rootStore.academyStore.academyId;
      course = {
        title: title,
        slug: courseSlug,
        code: code,
        version: version,
        modality: 'course',
        collateral,
        academy_id,
      };
    } catch (error) {
      course = null;
    } finally {
      return course;
    }
  }

  @action async getCourseDetailsByRole(paramSlug, classId = null) {
    let courseInfo = {};
    try {
      if (this.rootStore.userStore.isStudent && classId !== '') {
        courseInfo = await this.getAssignedCourseInfo(paramSlug, classId);
      } else if (this.rootStore.userStore.isInstructor) {
        courseInfo = await this.getCourseInfoByCourseSlug(paramSlug);
      }
    } catch (error) {
      courseInfo = {};
    } 
    finally {
      return courseInfo;
    }
  }

  @action async init() {
    try {
      const paramSlug = this.rootStore.routerStore.route.params.course;
      const classId = this.rootStore.routerStore.route.params.classid;

      const courseInfo = await this.getCourseDetailsByRole(paramSlug, classId);
      if (courseInfo?.slug !== paramSlug) {
        this.contentNotAvailable();
        return;
      } else {
        this.courseInfo = courseInfo;
      }

      await this.getCourseInfo(paramSlug, classId);
      await this.getUserDataForCourse(paramSlug);
      if (this.usedTranslation) {
        this.language = this.usedTranslation;
      }
      this.courseSlug = paramSlug;
      await this.getCourseStructure(paramSlug);
      let selectedPage = this.rootStore.routerStore.route.params.page;

      if (!selectedPage) {
        selectedPage = this.getLastViewedPageSlug();
      }

      if(this.rootStore.userStore.isStudent){
        const classDetails = this.rootStore.classStore.getClassDetailbyId(this.classId);
        this.course_lock_solution_status = classDetails.course_lock_solution || false;
        const classAcademyId = classDetails.academy_id;
        await this.getStudentSelfPacedVideoAccessStatus(classAcademyId, this.courseSlug);
      }

      return selectedPage || this.firstPageSlug;
    } catch (error) {
      console.error('Error in init:', error);
      this.contentNotAvailable();
      return this.firstPageSlug;
    }
  }

  @action async getCourseStructure(slug, lang = this.language) {
    try {
      this.structure = await getCourseStructure(slug, lang);
      this.activeTab = 2;
      return this.structure;
    } catch (error) {
      console.error('Error getCourseStructure:', error);
      this.contentNotAvailable();
    }
  }

  @action async getCourseInfo(code, classId) {
    try {
      const info = await getCourseInfo(code, classId);
      this.course = info;
      return info;
    } catch (error) {
      this.error = error;
      console.error('Failed to get course info:', error);
    }
  }

  @action async getCourseCollateral(code, version, lang = this.language) {
    try {
      const collateral = await getCourseCollateral(code, version, lang);
      this.collateral = collateral;
      return collateral;
    } catch (error) {
      this.error = error;
    }
  }

  @action getCoursePage = async () => {
    try {
      this.errorLoadingContent = false;
      this.contentLoading = true;
      const page = await getCoursePage(
        this.courseSlug,
        this.pageSlug,
        this.language,
      );
      const courseInfo = await this.getCourseDetailsByRole(
        this.courseSlug,
        this.classId,
      );
      if (!page || !courseInfo.slug) {
        this.errorLoadingContent = true;
        this.contentLoading = false;
        return {};
      }
      this.contentLoading = false;
      this.page = page;
      await this.rootStore.progressStore.setCourseProgress(
        this.courseSlug,
        page.uuid,
        this.entityKeyToTrackUserActivityWith,
        this.entityValueToTrackUserActivityWith,
      );
      await this.saveLastViewedPageSlug();
      return page;
    } catch (error) {
      console.error('Error getCoursePage:', error);
      if (this.contentLoading) {
        this.errorLoadingContent = true;
        this.contentLoading = false;
      }
    }
  };

  @action createUserDataForCourse = async (data) => {
    try {
      data = {
        ...data,
        ...{ course_slug: this.courseSlug },
      };
      data[this.entityKeyToTrackUserActivityWith] =
        this.entityValueToTrackUserActivityWith;
      this.userData = await createUserDataForCourse(data);
      return this.userData;
    } catch (error) {
      console.error('createUserDataForCourse error', error);
      this.error = error;
    }
  };

  @action getUserDataForCourse = async (courseSlug) => {
    try {
      const userDataElements = await getUserDataForCourse(courseSlug);
      this.userData = {};

      if (userDataElements) {
        for (let element of userDataElements) {
          if (
            element[this.entityKeyToTrackUserActivityWith] ===
              this.entityValueToTrackUserActivityWith &&
            element.course_slug === courseSlug
          ) {
            this.userData = element;
            return this.userData;
          }
        }
      }
    } catch (error) {
      console.error('getUserDataForCourse error', error);
      this.error = error;
    }
  };

  @action getLastViewedPageSlug = () => {
    return this.userData?.last_view;
  };

  @action saveLastViewedPageSlug = async () => {
    const data = { last_view: this.pageSlug };
    if (this.userDataIsEmpty) {
      await this.createUserDataForCourse(data);
    } else if (
      !this.userDataIsEmpty &&
      this.getLastViewedPageSlug() !== this.pageSlug &&
      this.userData.doc_id
    ) {
      const docId = this.userData.doc_id;
      await modifyUserDataForDoc(docId, data);
    }
  };

  @action saveAutoStartSetting = (autoSart) => {
    const data = { show_autostart: autoSart };
    if (this.userDataIsEmpty) {
      this.createUserDataForCourse(data);
    } else if (!this.userDataIsEmpty && this.userData.doc_id) {
      const docId = this.userData.doc_id;
      modifyUserDataForDoc(docId, data);
    }
  };

  @computed get showAutostart() {
    // eslint-disable-next-line camelcase
    const preference = this.userData?.show_autostart;
    if (preference !== undefined && preference !== null) {
      return preference;
    }
    return true;
  }

  @action saveUsedTranslation = async (lang) => {
    this.language = lang;
    const data = { language: lang };
    if (this.userDataIsEmpty) {
      this.createUserDataForCourse(data);
    }

    const docId = this.userData.doc_id;

    if (docId) {
      modifyUserDataForDoc(docId, data);
    }
  };

  @computed get usedTranslation() {
    return this.userData?.language;
  }

  @computed get currentCourse() {
    const { dynamic_info: dynamicInfo } = this.course || {};

    const found = dynamicInfo?.summaries.find(
      (course) =>
        course.version === dynamicInfo.current &&
        course.language === this.language,
    );

    const defaultCourse = dynamicInfo?.summaries.find(
      (course) =>
        course.version === dynamicInfo.current && course.language === 'en-US',
    );

    return found || defaultCourse || {};
  }

  @computed get courseCode() {
    const { dynamic_info: dynamicInfo } = this.course || {};
    if (dynamicInfo) return dynamicInfo?.code ? dynamicInfo?.code : '';

    return this.courseInfo?.code ? this.courseInfo.code : '';
  }

  @computed get isVideoClassroom() {
    return this.currentCourse?.modality === 'video_classroom';
  }
  @computed get contentNotFound() {
    return this.courseInfoFetched && !this.course.dynamic_info?.code;
  }

  set activeTab(tab) {
    this._activeTab = tab;
  }

  get activeTab() {
    return this._activeTab;
  }

  @computed get content() {
    return this.page?.content?.html || '';
  }

  @computed get userDataIsEmpty() {
    return !('class_id' in this.userData || 'academy_id' in this.userData);
  }

  @computed get courseInfoFetched() {
    return 'code' in this.courseInfo;
  }

  @computed get translations() {
    const { dynamic_info: dynamicInfo } = this.course;
    return dynamicInfo?.variants[dynamicInfo?.current] || [];
  }

  @computed get labMaintenanceBanners() {
    const today = moment();
    const { courseSlug } = this;
    const labMaintenanceNotifications = notifications.filter(
      (n) => n.type === 'lab_maintenance',
    );

    return labMaintenanceNotifications.filter((n) => {
      const toDate = moment.unix(n.to_date);
      const fromDate = moment.unix(n.from_date);
      return (
        n.course_slugs.includes(courseSlug.toLowerCase()) &&
        today.isBetween(fromDate, toDate)
      );
    });
  }

  @action async getPageVideosForCourse(courseSlug) {
    try {
      this.zdocManagerStore.terms = { _id: courseSlug };
      const _courseInfoDocs = await this.zdocManagerStore.fetch();

      this.videosInCourse =
      _courseInfoDocs && _courseInfoDocs.length >= 1
          ? _courseInfoDocs[0]
          : {};
    } catch (e) {
      this.videosInCourse = {};
      console.error(
        'Error while getting list of videos for all pages for a course',
        e,
      );
    } finally {
      return this.videosInCourse;
    }
  }

  @computed get customVideoPlayerId() {
    return this.videosInCourse?.custom_player_id || KALTURA_CONFIGS.player.uiConfId;
  }

  @computed get pageWithVideo() {
    const videosForCurrentPage = this.videosInCourse?.page_videos?.find(
      (v) => v.page_slug === this.pageSlug,
    )?.entries;

    const _defaultLanguage = 'en-US';
    const _currentLanguage = this.language || _defaultLanguage;

    let videosForCurrentPageByLanguage =
      videosForCurrentPage?.length > 0 &&
      videosForCurrentPage?.filter((v) => v.language === _currentLanguage);
    videosForCurrentPageByLanguage =
      videosForCurrentPageByLanguage?.length > 0
        ? videosForCurrentPageByLanguage
        : videosForCurrentPage?.filter((v) => v.language === _defaultLanguage);
    return videosForCurrentPage?.length > 0
      ? (videosForCurrentPageByLanguage.length > 0 &&
          videosForCurrentPageByLanguage[0]) ||
          videosForCurrentPage[0]
      : null;
  }

  @action getStudentSelfPacedVideoAccessStatus = async (academyId, courseSlug) => {
    try {
      const resp = await getStudentSelfPacedVideoAccessStatus(academyId, courseSlug);
      if (resp) {
        this.isStudentEligibleForSelfPaced = resp.self_paced_video_access_status;
      }
    } catch (error) {
      console.error(error);
      this.isStudentEligibleForSelfPaced = false;
    }
  };

  @computed get enableSelfPacedVideo() {        
    return this.rootStore.userStore.isInstructor || (this.rootStore.userStore.isStudent && this.isStudentEligibleForSelfPaced);
  }
}

export default CourseStore;
