import { observable, action, computed } from 'mobx';
import moment from 'moment';
import { AUDIENCE_OPTIONS, REGION_OPTIONS } from '../../config/constants';
import getAnnouncements, {
  deleteAnnouncement,
} from '../../services/AnnouncementService';
class AnnouncementStore {
  constructor(userStore, vocabularyStore, academyStore) {
    this.userStore = userStore;
    this.vocabularyStore = vocabularyStore;
    this.academyStore = academyStore;
  }

  @observable loaded = false;
  @observable announcements = [];
  @observable _currentPage = 1;
  @observable announcementPerPage = 10;
  @observable isNewAnnoucmenetOpen = true;

  @observable selectedAnnouncement = null;
  @observable showRemoveAnnouncementModal = false;
  @observable apiInProgress = false;
  @observable errorMessage = '';
  @observable isSortAscending = false;
  @observable sortByField = 'start_date';

  @action getAnnouncements = async () => {
    try {
      const announcements = await getAnnouncements();

      this.announcements = this.sortAnnouncements(
        announcements.map((announcement) => {
          return {
            doc_id: announcement.doc_id,
            announcement_type: announcement.announcement_type,
            content: announcement.content,
            audience_region:
              announcement.audience_region?.length > 0 &&
              announcement.audience_region[0] === 'global'
                ? REGION_OPTIONS
                : REGION_OPTIONS.filter((region) =>
                    announcement.audience_region?.includes(region.value),
                  ),
            isGlobalAnnouncement:
              announcement.audience_region?.length === 1 &&
              announcement.audience_region[0] === 'global',
            audience: AUDIENCE_OPTIONS.filter((audience) =>
              announcement.audience?.includes(audience.value),
            ),
            countries:
              Object.values(this.vocabularyStore.countries)
                .filter((country) =>
                  announcement.countries?.includes(country.value),
                )
                ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
            academies:
              Object.values(this.academyStore.academies)
                .filter((academy) =>
                  announcement.academies?.includes(academy.value),
                )
                ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
            start_date: new Date(announcement.start_date),
            end_date: new Date(announcement.end_date),
            dateRange: {
              from: new Date(announcement.start_date),
              to: new Date(announcement.end_date),
            },
            message: announcement.message,
            author_name: announcement.author_name,
            status: moment().isBetween(
              `${announcement.start_date} 00:00`,
              `${announcement.end_date} 23:59`,
            )
              ? 'Active'
              : 'Inactive',
            url: announcement?.url || '',
            date_created: announcement['@timestamp'],
          };
        }) || [],
      );
    } catch (e) {
      console.log(e);
      this.announcements = [];
    } finally {
      this.loaded = true;
    }
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return (
      Math.ceil(this.announcements.length / this.announcementPerPage, 10) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedAnnouncements() {
    const startIndex = (this.currentPage - 1) * this.announcementPerPage;
    return this.announcements.slice(
      startIndex,
      startIndex + this.announcementPerPage,
    );
  }

  @action onSortBy = (sortBy) => {
    this.sortByField = sortBy;
    this.isSortAscending = !this.isSortAscending;

    this.announcements = this.sortAnnouncements(this.announcements);
  };

  @action sortAnnouncements = (announcements) => {
    if (!this.isSortAscending) {
      return announcements
        .slice()
        .sort((a, b) =>
          this.sortByField === 'start_date' || this.sortByField === 'end_date'
            ? moment(moment(b[this.sortByField]).toDate()).diff(
                moment(moment(a[this.sortByField]).toDate()),
              )
            : this.sortByField === 'content'
            ? b[this.sortByField].localeCompare(a[this.sortByField])
            : b[this.sortByField] - a[this.sortByField],
        );
    }

    return announcements
      .slice()
      .sort((a, b) =>
        this.sortByField === 'start_date' || this.sortByField === 'end_date'
          ? moment(moment(a[this.sortByField]).toDate()).diff(
              moment(moment(b[this.sortByField]).toDate()),
            )
          : this.sortByField === 'content'
          ? a[this.sortByField].localeCompare(b[this.sortByField])
          : a[this.sortByField] - b[this.sortByField],
      );
  };

  toggleAddAnnouncementForm = () => {
    this.isNewAnnoucmenetOpen = !this.isNewAnnoucmenetOpen;
  };

  @action onSubmitAnnouncement = async (announcement) => {
    await this.getAnnouncements();
  };

  @action onDeleteAnnouncement = async (announcement) => {
    this.selectedAnnouncement = { ...announcement };
    this.toggleRemoveAnnouncementModal();
  };

  @action onRemoveAnnouncement = async () => {
    try {
      this.apiInProgress = true;
      await deleteAnnouncement(this.selectedAnnouncement.doc_id);
      this.toggleRemoveAnnouncementModal();
      this.selectedAnnouncement = null;
      await this.getAnnouncements();
    } catch {
      this.errorMessage = 'Error while deleting an announcement';
    } finally {
      this.apiInProgress = false;
    }
  };

  @action onRepeatAnnouncement = async (announcement) => {};

  @action onSortAnnouncementBy = async (sortBy) => {
    this.onSortBy(sortBy);
  };

  @action toggleRemoveAnnouncementModal = () => {
    this.errorMessage = '';
    this.showRemoveAnnouncementModal = !this.showRemoveAnnouncementModal;
  };
}

export default AnnouncementStore;
