import React, { useContext, useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { storesContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import { Container, Row, Button } from 'react-bootstrap';
import CardList from '../../components/CardList';
import LinkContainer from '../../components/LinkContainer';
import PromoVideoCard from './PromoVideoCard';
import ImageGalleryCard from './ImageGalleryCard';
import CourseBrochuresCard from './CourseBrochuresCard';
import InstructorGuidesCard from './InstructorGuidesCard';
import { CARD_COLORS } from '../../config/constants';
import ResourcesStore from './store';
import LinkListView from './LinkListView';
import { RESOURCES_API_URL } from '../../config/constants';
import './styles.scss';
import LabImageCard from './LabImageCard';
import ViewLoader from '../../components/Loading/View';
import VideoWidget from '../../components/Video/Widget';
import { KALTURA_CONFIGS } from '../../config/constants';

const ResourcesView = () => {
  const { t } = useTranslation();
  const { routerStore, userStore, configStore } = useContext(storesContext);
  const store = useLocalStore(() => new ResourcesStore());
  const resourcesTitle = t('Resources');
  const [title, setTitle] = useState(resourcesTitle);
  const redColor = CARD_COLORS[4];
  const greenColor = CARD_COLORS[3];
  const blueColor = CARD_COLORS[1];
  const lightBlueColor = CARD_COLORS[2];
  const orangeColor = CARD_COLORS[5];
  const greyColor = CARD_COLORS[0];
  const allowedPaths = {
    guides: { title: 'Instructor Guides' },
    brochures: { title: 'Course Brochures' },
    'student-resources': { title: 'Student Resources' },
    'openshift': { title: 'Openshift' },
    'image-gallery': { title: 'Image Gallery' },
    banners: { title: 'Pull-Up Banner' },
    'press-release': { title: 'Press Release Template' },
    'download-lab-images': { title: 'Lab Images' },
    'dual-boot': { title: 'Dual Boot' },
    'installation-and-troubleshooting-guides': {
      title: 'Installation and Troubleshooting Guides',
    },
  };

  const getOnClickLinkList = (sectionName) => {
    return () =>
      routerStore.navigate('resources:link-list', {
        section: sectionName,
      });
  };

  const renderResourcesCards = () => {
    return (
      <React.Fragment>
        {store.shouldVideoShow && (
          <div className="resource-video-wrapper">
            <div className="overlay">
              <div className="overlay-content">
                <VideoWidget
                  isEmbeddedVideo={true}
                  playerId={KALTURA_CONFIGS.videoTutorial.uiConfId}
                  entryId={KALTURA_CONFIGS.videoTutorial.promoVideoEntryId}
                />
                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      store.togglePromoVideo();
                    }}
                  >
                    {t('Close video')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <section className="m-3">
          <b>{t('Promote on Social Media')}</b>
          <CardList>
            <PromoVideoCard
              cardColor={redColor}
              onClick={() => store.togglePromoVideo()}
            />
            <ImageGalleryCard
              cardColor={greenColor}
              onClick={getOnClickLinkList('image-gallery')}
            />
          </CardList>
        </section>
        <section className="m-3">
          <b>{t('Downloads & Print')}</b>
          <CardList>
            <CourseBrochuresCard
              title = {t('RHA Brochure')}
              firstLine = {t('Red Hat Academy')}
              secondLine = {t('courses brochure')}
              cardColor={blueColor}
              onClick={()=> window.open("https://www.redhat.com/en/resources/academy-brochure", "_blank")}
            />
            <InstructorGuidesCard
              cardColor={lightBlueColor}
              onClick={getOnClickLinkList('guides')}
            />
            <CourseBrochuresCard
              title = {t('Student Resources')}
              firstLine = {t('Links and downloads')}
              secondLine = {t('to share with your students')}
              cardColor={greyColor}
              onClick={getOnClickLinkList('student-resources')}
            />
            <CourseBrochuresCard
              title = {t('Openshift')}
              firstLine = {t('Explore OpenShift, Containers and Kubernetes')}
              secondLine = {''}
              cardColor={orangeColor}
              onClick={getOnClickLinkList('openshift')}
            />
          </CardList>
        </section>
        {userStore.isInstructor && renderLabImagesCard()}
        {renderResourcesBottom()}
      </React.Fragment>
    );
  };

  const renderResourcesBottom = () => {
    return (
      <div className="resources-bottom">
        <Container className="link-row-wrapper">
          <Row className="link-row">
            <LinkContainer
              title={t('Brand Guidelines')}
              description={`<p>${t(
                'Brand standards are the source code of our identity. They govern how we look and sound in all types of media. They are the codification of our rules, and important to follow.',
              )}</p>`}
              actionText={t('Meet our brand')}
              actionUrl='https://www.redhat.com/en/about/brand/standards'
              openActionInNewTab={true}
            />
            <LinkContainer
              title={t('Press Release Template')}
              description={`<p>${t(
                'Download press release templates with detailed guidelines. Create a press release and send to your public relations.',
              )}</p>`}
              actionText={t('Create a press release')}
              openActionInNewTab={true}
              onClick={getOnClickLinkList('press-release')}
            />
            <LinkContainer
              title={t('Pull-Up Banner')}
              description={`<p>${t(
                'See our tasteful designed pull-up banner',
              )}</p>`}
              actionText={t('See pull-up banner')}
              onClick={getOnClickLinkList('banners')}
            />
          </Row>
        </Container>
      </div>
    );
  };

  const renderLabImagesCard = () => {
    return (
      <React.Fragment>
        <section className="m-3">
          <b>{t('Lab Images')}</b>
          <CardList>
            {store.loading && <ViewLoader />}
            {!store.loading && store.labImagesByFolders?.length <= 0 && (
              <React.Fragment>
                <p>{t('No lab images found')}</p>{' '}
              </React.Fragment>
            )}
            {store.labImagesByFolders?.length > 0 && (
              <React.Fragment>
                <LabImageCard
                  key={1}
                  cardColor={redColor}
                  onClick={getOnClickLinkList('download-lab-images')}
                  title={'Download Lab Images'}
                  firstLine={t('Download lab files')}
                  secondLine={' '}
                />
                <LabImageCard
                  key={2}
                  cardColor={redColor}
                  onClick={getOnClickLinkList('dual-boot')}
                  title={'Dual Boot'}
                  firstLine={t('Download lab files')}
                  secondLine={' '}
                />
                <LabImageCard
                  key={3}
                  cardColor={redColor}
                  onClick={getOnClickLinkList(
                    'installation-and-troubleshooting-guides',
                  )}
                  title={'Installation and Troubleshooting Guides'}
                  firstLine={t('Download lab files')}
                  secondLine={' '}
                />
              </React.Fragment>
            )}
          </CardList>
        </section>
      </React.Fragment>
    );
  };

  useEffect(() => {
    // Setting the title for the page using the route
    let sectionPath = routerStore.route.params.section;

    if (sectionPath) {
      if (sectionPath in allowedPaths) {
        const sectionName = allowedPaths[sectionPath].title;
        setTitle(sectionName);
        document.title = sectionName;
        store.section = sectionPath;
        store.courseSlug = routerStore.route.params?.courseslug;
      } else {
        routerStore.navigate('default-redirect');
      }
    } else {
      setTitle(resourcesTitle);
      store.section = undefined;
    }
  }, [
    store,
    routerStore,
    allowedPaths,
    resourcesTitle,
    routerStore.route.params.section,
  ]);

  useEffect(() => {
    store.getFiles(userStore.isInstructor);
  }, [store, userStore]);

  return (
    <div className="resources">
      <h3 className="view-page-title">{title}</h3>
      {!store.section ? (
        renderResourcesCards()
      ) : store.isSectionForLabImages ? (
        <LinkListView
          baseFileURL={configStore.LabImagesEndpoint}
          loading={store.loading}
          files={store.selectedFiles}
          signedUrl={true}
        />
      ) : (
        <LinkListView
          baseFileURL={RESOURCES_API_URL}
          loading={store.loading}
          files={store.selectedFiles}
        />
      )}
    </div>
  );
};

export default observer(ResourcesView);
