import {  get } from 'axios';
import { COURSE_CERTS_API_URL } from '../config/constants';

export const getCourseAndCerts = async () => {
  try {
    const response = await get(`${COURSE_CERTS_API_URL}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default getCourseAndCerts;
