import PropTypes from 'prop-types';
import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import './styles.scss';

const ExpandToggle = ({ toggle, className }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <Accordion.Toggle
      as={Button}
      variant="link"
      eventKey="0"
      className={`expandable-toggle ${className}`}
      onClick={() => {
        setIsExpanded(!isExpanded);
        toggle();
      }}
    >
      <div
        className={`toggle ${isExpanded ? 'icon-arrow-up' : 'icon-arrow-down'}`}
      ></div>
    </Accordion.Toggle>
  );
};

ExpandToggle.propTypes = {
  className: PropTypes.string,
  toggle: PropTypes.func,
};

export default ExpandToggle;
