import { action, observable, computed } from 'mobx';
import { createContext } from 'react';
import { roundData } from '../../services/UtilityService';

import {
  requestPromoCodeForStudents,
  getClassDetailbyId,
} from '../../services/ClassService';
import moment from 'moment';

class ClassDetailsStore {
  @observable hideRemoveStudentsModal = true;
  @observable removeStudentsMessage = '';

  @observable _currentPage = 1;

  @observable entriesPerPage = 10;

  @observable isSortAscending = true;
  @observable sortByField = 'username';

  @observable hasClassData = false;
  @observable loaded = false;
  @observable studentsProgressLoading = false;

  @observable studentsDetails = [];

  @observable students = [];

  @observable classDetail = null;
  @observable assignmentDetails = null;

  @observable showEditClassModal = false;
  @observable gotFileName = false;
  @observable existingFile = '';

  @observable academyDetails = null;
  @observable hasAcademyData = false;
  @observable promoCodeRequestInProgress = false;
  @observable selectedStudents = [];
  @observable showPromoCodeModal = false;
  @observable promoCodeRequestMessage = '';
  @observable allStudentsSelected = false;
  @observable promoCodeHasError = false;

  constructor(classStore, classAssignmentStore, classStudentStore, userStore) {
    this.classStore = classStore;
    this.classAssignmentStore = classAssignmentStore;
    this.classStudentStore = classStudentStore;
    this.userStore = userStore;
  }

  @action toggleClassEditModal = () => {
    this.showEditClassModal = !this.showEditClassModal;
  };

  @action reloadData = async (classId) => {
    this.loaded = false;
    this.allStudentsSelected = false;
    this.selectedStudents = [];
    await this.classStore.fetchClasses();
    await this.getClassDetailbyId(classId);
    await this.populateStudents(true);
    await this.setAssignment(classId);
    this.loaded = true;
  };

  @action onSortBy = (sortBy) => {
    this.sortByField = sortBy;
    this.isSortAscending = !this.isSortAscending;

    this.students = this.sortStudents(this.students);
  };
  @action sortStudents = (students) => {
    if (!this.isSortAscending) {
      return students
        .slice()
        .sort((a, b) =>
          this.sortByField === 'lastAccessedDate'
            ? moment(b[this.sortByField]).toDate() -
              moment(a[this.sortByField]).toDate()
            : this.sortByField === 'username'
            ? b.username.localeCompare(a.username)
            : this.sortByField === 'name'
            ? b.name.localeCompare(a.name)
            : b[this.sortByField] - a[this.sortByField],
        );
    }

    return students
      .slice()
      .sort((a, b) =>
        this.sortByField === 'lastAccessedDate'
          ? moment(a[this.sortByField]).toDate() -
            moment(b[this.sortByField]).toDate()
          : this.sortByField === 'username'
          ? a.username.localeCompare(b.username)
          : this.sortByField === 'name'
          ? a.name.localeCompare(b.name)
          : a[this.sortByField] - b[this.sortByField],
      );
  };

  @computed get classId() {
    return this.classDetail?.doc_id;
  }
  @computed get assignmentId() {
    return this.assignmentDetails?.doc_id;
  }
  @action getClassDetailbyId = async (classId) => {
    try {
      const classDetail = await getClassDetailbyId(classId);
      this.classDetail = classDetail;
    } catch {
      this.classDetail = null;
    } finally {
      this.hasClassData = true;
    }
    return this.classDetail;
  };

  @action getStudentDetailsByClassId = async (force = false) => {
    if (this.studentsDetails.length && !force) {
      return this.studentsDetails;
    }
    if (this.studentsProgressLoading === true) return;
    try {
      const studentsDetails = await this.classStudentStore.getStudentDetailsByClassId(
        this.classId,
      );
      if (studentsDetails) {
        this.studentsDetails = studentsDetails;
      }
      this.studentsProgressLoading = false;
    } catch (e) {
      console.error(e);
      this.studentsDetails = [];
    } finally {
      this.studentsProgressLoading = false;
      return this.studentsDetails;
    }
  };

  getCourseProgressByClassId = (courseProgress) => {
    const classCourseProgress =
      courseProgress &&
      courseProgress.filter((p) => p.class_id === this.classId);
    return classCourseProgress && classCourseProgress.length > 0
      ? classCourseProgress[0]
      : 0;
  };

  getStudentDetailbyUsername = (username) => {
    const keyStudentUserName = Object.keys(this.studentsDetails).find(
      (u) =>
        this.studentsDetails[u]?.userinfo?.username.toLowerCase() === username,
    );

    return keyStudentUserName ? this.studentsDetails[keyStudentUserName] : null;
  };

  getGradesProgress = (studentDetail) => {
    const gradeScore = studentDetail?.grades?.total_score || 0;

    return roundData(gradeScore);
  };

  @action populateStudents = async (force = false) => {
    if (this.students.length && !force) {
      return this.students;
    }
    try {
      await this.getStudentDetailsByClassId();

      this.students = this.sortStudents(
        Object.keys(this.studentsDetails)
          .map((username, index) => {
            const studentDetial =
              username !== 'completed' &&
              this.getStudentDetailbyUsername(username.toLowerCase());
            if (studentDetial) {
              const studentClassProgress = this.getCourseProgressByClassId(
                studentDetial?.course_progress,
              );
              const studentGradeProgress = this.getGradesProgress(
                studentDetial,
              );

              let fullName =
                studentDetial?.userinfo?.first_name &&
                studentDetial?.userinfo?.first_name;
              fullName =
                studentDetial?.userinfo?.last_name &&
                fullName + ' ' + studentDetial?.userinfo?.last_name;
              fullName = fullName === undefined ? '' : fullName;
              const lastAccessedDate = studentClassProgress['@timestamp'];
              return {
                detail: studentDetial,
                username:
                  studentDetial.userinfo && studentDetial.userinfo.username
                    ? studentDetial.userinfo.username
                    : '',
                name: fullName,
                courseProgress: roundData(studentClassProgress?.total_progress),
                gradeProgress: studentGradeProgress,
                promoEligiblity: studentDetial.partner_promo_eligible || false,
                lastAccessedDate,
              };
            } else {
              return null;
            }
          })
          .filter((s) => s !== null),
      );
    } catch (e) {
      console.error('[populateStudents]', e);
    } finally {
      this.loaded = true;
    }
  };

  @action setAssignment = (classId) => {
    try {
      if (classId !== undefined) {
        this.assignmentDetails = this.classAssignmentStore.getAssignmentByClassId(
          classId,
        );
      }
    } catch (e) {
      this.assignmentDetails = null;
    } finally {
      return this.assignmentDetails;
    }
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return Math.ceil(this.students.length / this.entriesPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.students.slice(startIndex, startIndex + this.entriesPerPage);
  }

  // Get Academy Detials for a instructor.
  @action setAcademyDetails = async (details) => {
    try {
      
      if (details) {
        this.academyDetails = details;

        this.hasAcademyData = true;
      }
    } catch (error) {
      this.academyDetails = null;
    } finally {
      this.hasAcademyData = true;
    }
  };

  @action requestPromoCode = async () => {
    try {
      this.togglePromoCodeModal();
      this.promoCodeRequestInProgress = true;
      this.promoCodeHasError = false;
      this.promoCodeRequestMessage = 'Requesting promo code ...';
      const data = await requestPromoCodeForStudents(
        this.classId,
        this.selectedStudents,
      );
      if (data) {
        this.promoCodeRequestMessage = 'Promo Code requested successfuly.';
        setTimeout(() => {
          this.promoCodeRequestMessage = '';
        }, 1000);
      }
    } catch (error) {
      this.promoCodeHasError = true;
      this.promoCodeRequestMessage =
        'Sorry, Promo Code was not requested successfully.';
    } finally {
      this.allStudentsSelected = false;
      this.selectedStudents = [];
      this.promoCodeRequestInProgress = false;
    }
  };

  @action onSelectStudents = (students) => {
    this.selectedStudents = students;
  };
  @action togglePromoCodeModal = () => {
    this.showPromoCodeModal = !this.showPromoCodeModal;
  };
  @computed get isInstructorWithPartner() {
    const _hasInstructor = this.academyDetails?.instructors_info?.find(
      (i) =>
        i.username.toLowerCase() === this.userStore.username?.toLowerCase() &&
        i?.is_partner === true,
    );
    return _hasInstructor ? true : false;
  }
  @computed get isCustomClass() {
    return this.classDetail?.custom_class || false;
  }
  @computed get hasSelectedStudents() {
    return this.selectedStudents ? this.selectedStudents?.length > 0 : false;
  }
  @computed get isPromoCodeRequestAllowed() {
    return this.students?.filter(s => this.selectedStudents.includes(s.detail.userinfo.username) && !s.promoEligiblity)?.length <= 0;
  }

  @computed get promoCodeEligibiltyMessage() {
    return !this.isPromoCodeRequestAllowed ? 'Your selection contains student(s) not eligible for promo code.' :'';
  };

}

export const ClassDetailsContext = createContext(null);
export default ClassDetailsStore;
