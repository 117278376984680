import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Col, Row, Alert } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import './styles.scss';

const handleError = () => {
  // TODO: send error to backend analytics service
};

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert variant="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const DetailsCard = (props) => {
  const { t } = useTranslation();
  const details = props.details;
  const decorColor = details.decor;
  const cardDecor = {
    borderBottom: `4px solid ${decorColor}`,
  };
  const cardDecorCollapsed = {
    borderRight: `4px solid ${decorColor}`,
  };

  return (
    <React.Fragment>
      <Row className="secondary-title">
        <Col>
          <h5>{t('Class Details')}</h5>
        </Col>
      </Row>
      <div className="detail-cards-wrapper">
        <div className="card-detail-table card-detail-table--5cols card-detail-table--collapse">
          <div className="card-detail-table-row card-detail-table-row--head" style={cardDecor}>
            <div className="card-detail-table-cell classname-cell column-heading">
              {t(`Class name`)}
            </div>
            <div className="card-detail-table-cell course-title-cell column-heading">
              {t(`Course title`)}
            </div>
            <div className="card-detail-table-cell student-cell column-heading">
              {t(`Students`)}
            </div>
            <div className="card-detail-table-cell calendar-cell column-heading">
              {t(`Date Range`)}
            </div>
            <div className="card-detail-table-cell label-cell column-heading">
              {t(`Label`)}
            </div>
          </div>

          <div className="card-detail-table-row">

            <div className="card-detail-table-cell classname-cell">
               <div className="card-detail-table-cell--content classname-bold" >
                {t(`${details.class_name}`)}
              </div>
            </div>

            <div className="card-detail-table-cell course-title-cell">

               <div className="card-detail-table-cell--heading" style={cardDecorCollapsed}>
                {t(`Course title`)}
              </div>

               <div className="card-detail-table-cell--content">
                <span> {t(`${details.course_title}`)}</span>
              </div>
            </div>


            <div className="card-detail-table-cell student-cell">
              <div className="card-detail-table-cell--heading" style={cardDecorCollapsed}>
                {t(`Students`)}
              </div>
              <div className="card-detail-table-cell--content">
                {t(`${details.students?.length || 0}`)}
              </div>
            </div>


            <div className="card-detail-table-cell calendar-cell">
              <div className="card-detail-table-cell--heading" style={cardDecorCollapsed}>
                {t(`Calendar`)}
              </div>
              <div className="card-detail-table-cell--content">
                {moment(details.start_date).format('MMM DD YYYY')} -{' '}
                {moment(details.end_date).format('MMM DD YYYY')}
              </div>
            </div>
            <div className="card-detail-table-cell card-detail-table-cell--foot label-cell">
              <div className="card-detail-table-cell--heading" style={cardDecorCollapsed}>
                {t(`Label`)}
              </div>
              <div className="card-detail-table-cell--content">
                {details.label}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

DetailsCard.propTypes = {
  details: PropTypes.object,
};

DetailsCard.defaultProps = {
  details: {},
};

export default withErrorBoundary(
  observer(DetailsCard),
  ErrorMessage,
  handleError,
);
