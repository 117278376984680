import { observable, action } from 'mobx';
import { getQuestionsFromBank } from '../../../services/ClassAssignmentService';
class CreateAssignmenStore {
  @observable pageTitle = '---';
  @observable assignmentDetails = null;
  @observable classDetails = null;
  @observable predefinedQuestions = null;
  @observable ready = false;

  constructor(classAssignmentStore, routerStore) {
    this.classAssignmentStore = classAssignmentStore;
    this.routerStore = routerStore;
  }

  @action onAssignmentSubmit = async () => {
    await this.classAssignmentStore.fetchAssignments();
    await this.setPredefinedQuestions();
    this.navigateToClassDetails();
  };
  navigateToClassDetails() {
    this.routerStore.navigate('classes:view', {
      classid: this.routerStore.route?.params?.classid,
    });
  }
  @action onAssignmentCancel = async (redirect) => {
    this.navigateToClassDetails();
  };

  @action setAssignment = (assignmentId) => {
    try {
      if (assignmentId !== undefined) {
        this.assignmentDetails = this.classAssignmentStore.getAssignmentById(
          assignmentId,
        );
      }
    } catch (e) {
      this.assignmentDetails = null;
    } finally {
      return this.assignmentDetails;
    }
  };
  @action setPredefinedQuestions = async () => {
    const courseSlug = this.classDetails?.course_slug || null;
    try {
      if (courseSlug !== null) {
        const predefinedQuestions = await getQuestionsFromBank(courseSlug);
        this.predefinedQuestions = predefinedQuestions?.map((q) => {
          const questionToReturn = {
            question: q.question,
            uuid: q.uuid,
            timestamp: q['timestamp'],
            is_public: q.is_public,
            answers: q.choices.map((c) => {
              const isAnswer = q.answers.filter(
                (a) => a.toLowerCase() === c.toLowerCase(),
              );
              return { text: c, isAnswer: isAnswer.length > 0 };
            }),
          };
          return questionToReturn;
        });
      }
    } catch (e) {
      this.predefinedQuestions = [];
    } finally {
      return this.predefinedQuestions;
    }
  };
}

export default CreateAssignmenStore;
