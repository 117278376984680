import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import { when } from 'mobx';
import ViewLoader from '../../components/Loading/View';
import './styles.scss';
import AcademyStudentViewStore from './store';
import { storesContext } from '../../stores';
import AcademyStudentsList from '../../components/ConsumptionSummary/AcademyStudents';
import DownloadFile from '../../components/DownloadFile';
import AccessComponent from '../../components/AccessComponent';
import { ACADEMY_STUDENT_LIST_API_CSV } from '../../config/constants';

const AcademyStudentsView = () => {
  const { routerStore } = useContext(storesContext);
  const store = useLocalStore(() => new AcademyStudentViewStore());

  useEffect(() => {
    when(
      () => !store.loaded,
      async () => {
        await store.getAcademyDetails(routerStore.route.params.academyid || null);
        store.getAcademyStudents(routerStore.route.params.academyid || null);
      },
    );
  }, [store, routerStore.route.params.academyid]);

  return (
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
        {!store.loaded && <ViewLoader />}
        {store.loaded && (
          <div className="student-list-container">
            <Row className="header-student">
              <Col className="p-0">
                <div className="row view-page-title">
                  <div className="col-sm-11">
                    <h3>{`${store.academyDetails?.institution_name}/Students`}</h3>
                  </div>
                  <div className="col-sm">
                    <AccessComponent componentName="StudentsCsvDownload">
                      <div className="col-sm">
                        <DownloadFile 
                          url={ACADEMY_STUDENT_LIST_API_CSV.replace('<academy-id>', store.academyDetails.doc_id).replace('<report_type>', 'csv')}>
                        </DownloadFile>
                      </div>
                    </AccessComponent>
                  </div>
                </div>
                
                <div className="secondary-title">
                  <AcademyStudentsList 
                    students={store.paginatedStudents}
                    onSortBy={store.onSortBy}
                    sortByField={store.sortByField}
                    isSortAscending={store.isSortAscending}
                  ></AcademyStudentsList>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {store.loaded && store.paginatedStudents.length ? (
                  <div className="student-list-pagination">
                    <UltimatePagination
                      currentPage={store.currentPage}
                      totalPages={store.totalPages}
                      onChange={store.setCurrentPage}
                    />
                  </div>
              ) : null}
              </Col>
            </Row>
          </div>
        )}
      </React.Suspense>
    </Container>
  );
};

export default observer(AcademyStudentsView);
