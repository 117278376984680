import { observable, action, computed } from 'mobx';

class ViewAssignmentStore {
  @observable classDetail = null;

  @observable assignmentDetails = null;
  @observable classLoaded = null;
  @observable assignmentLoaded = null;

  constructor(classStore, classAssignmentStore) {
    this.classStore = classStore;
    this.classAssignmentStore = classAssignmentStore;
  }

  @action getClassDetailbyId = (classId) => {
    const classDetail = this.classStore.getClassDetailbyId(classId);
    this.classLoaded = true;
    return classDetail;
  };

  @computed get allQuestions() {
    return (
      this.assignmentDetails?.quiz?.map((q) => {
        const questionToReturn = {
          question: q.question,
          uuid: q.uuid,
          timestamp: q['timestamp'],
          is_public: q.is_public,
          answers: q.choices.map((c) => {
            const isAnswer = q.answers.filter(
              (a) => a.toLowerCase() === c.toLowerCase(),
            );
            return { text: c, isAnswer: isAnswer.length > 0 };
          }),
        };

        return questionToReturn;
      }) || []
    );
  }

  @computed get allChallenges() {
    return (
      this.assignmentDetails?.code_challenge?.map((c) => {
        const challengeToReturn = {
          description: c?.question,
          uuid: c?.uuid,
          example: c?.example,
          timestamp: c['timestamp'],
        };

        return challengeToReturn;
      }) || []
    );
  }
  @action setAssignment = (classId) => {
    try {
      if (classId !== undefined) {
        this.assignmentDetails = this.classAssignmentStore.getAssignmentByClassId(
          classId,
        );
      }
    } catch (e) {
      this.assignmentDetails = null;
    } finally {
      this.assignmentLoaded = true;
      return this.assignmentDetails;
    }
  };
}

export default ViewAssignmentStore;
