import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import ViewLoader from '../../../../Loading/View';
import { PropTypes } from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import './styles.scss';

const StudentClassList = (props) => {
  const { classes, onSortBy } = props;
  const { t } = useTranslation();

  const noResultMessage = () => {
    const noResultMsg = t(`No classes assigned to this student.`);
    return t(noResultMsg);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex flex-sm-row flex-column row-header row-student-class">
        
        <div
          onClick={() => {
            onSortBy('class_name');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Class Name')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('course_title');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Course Name')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('total_progress');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Course Progress')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('submission');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Submission')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('quiz_score');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Quiz Score')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('code_challenge_score');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Code Challenge Score')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('total_score');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Total Score')}</span>
        </div>

        <div
          onClick={() => {
            onSortBy('last_accessed');
          }}
          className="mr-auto p-2 column-student-class"
        >
          <span>{t('Last Accessed')}</span>
        </div>
      </div>
    );
  };

  const getStudentProgress = (student_class) => {
    const progress = student_class?.total_progress ? Math.round(student_class?.total_progress * 100) : 0;
    return <ProgressBar striped now={progress} label={`${progress}%`} />;
  };

  const renderClass = (student_class) => {
    return (
      <React.Fragment key={student_class.id}>
        <div
          key={student_class.id}
          className="d-flex flex-sm-row flex-column row-student-class"
        >
          <div className="mr-auto p-2 column-student-class">
            <div className="flex-sm-row">
              <span className="row-sm-header">{t('Class Name')}:&nbsp;&nbsp;</span>
              {student_class.class_name}
            </div>
          </div>

          <div className="mr-auto p-2 column-student-class">
            <div className="flex-sm-row">
              <span className="row-sm-header">{t('Course Name')}:&nbsp;&nbsp;</span>
              {student_class.course_title}
            </div>
          </div>
         
          <div className="mr-auto p-2 column-student-class">
            <div className="flex-sm-row">
              <span className="row-sm-header">
                {t('Progress')}:&nbsp;&nbsp;
              </span>
              {getStudentProgress(student_class)}
            </div>
          </div>

          <div className="mr-auto p-2 column-student-class">
            <div className="flex-sm-row">
              <span className="row-sm-header">{t('Submission')}:&nbsp;&nbsp;</span>
             {student_class.submission? moment(student_class.submission).format('MMM DD, YYYY') : ''}
            </div>
          </div>

          <div className="mr-auto p-2 column-student-class">
              <div className="flex-sm-row">
                <span className="row-sm-header">{t('Quiz Score')}:&nbsp;&nbsp;</span>
                {`${student_class.quiz_score ? Math.round(student_class?.quiz_score * 100) : 0}%`}
              </div>
          </div>

          <div className="mr-auto p-2 column-student-class">
              <div className="flex-sm-row">
                <span className="row-sm-header">{t('Code Challenge Score')}:&nbsp;&nbsp;</span>
                {`${student_class.code_challenge_score ? Math.round(student_class?.code_challenge_score * 100) : 0}%`}
              </div>
          </div>

          <div className="mr-auto p-2 column-student-class">
              <div className="flex-sm-row">
                <span className="row-sm-header">{t('Total Score')}:&nbsp;&nbsp;</span>
                {`${student_class.total_score ? Math.round(student_class?.total_score * 100) : 0}%`}
              </div>
          </div>

          <div className="mr-auto p-2 column-student-class">
            <div className="flex-sm-row">
              {student_class.last_accessed? moment(student_class.last_accessed).format('MMM DD, YYYY') : ''}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderClasses = () => {
    return (
      <React.Suspense fallback={<ViewLoader />}>
        <div className="class-list-container">
          {classes.length > 0 ? (
            <div className="class-list">
              
              {renderHeader()}

              {classes.map((student_class) =>
                renderClass(student_class)
              )}

            </div>
          ) : (
            <div className="no-class-msg">
              {noResultMessage()}
            </div>
          )}
        </div>
      </React.Suspense>
    );
  };
  return renderClasses();
};

StudentClassList.propTypes = {
  onSortBy: PropTypes.func,
  classes: PropTypes.array,
  sortByField: PropTypes.string,
  isSortAscending: PropTypes.bool,
};

StudentClassList.defaultProps = {
  onSortBy: () => {},
  classes: [],
  sortByField: 'name',
  isSortAscending: false,
};

export default observer(StudentClassList);
