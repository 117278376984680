import { delete as del, get, post, patch } from 'axios';
import {
  ACADEMY_API,
  ACADEMY_INSTRUCTOR_LIST_API,
  ACADEMY_PATCH_API,
  ADOPT_ACADEMY_API,
  REFER_AN_ACADEMY_API,
  RESOURCES_API_URL,
  LAB_IMAGES_API_URL,
  CLOUDFRONT_SIGNED_API,
  ACADEMY_STUDENT_LIST_API,
  ACADEMY_STUDENT_ACTIVITY_API,
  ACADEMY_STATUS_API,
  ACADEMIES,
} from '../config/constants';

export const getAcademyDetails = async (academyId = null) => {
  try {
    const url = academyId
      ? ACADEMY_PATCH_API.replace('<academy-id>', academyId)
      : ACADEMY_API;
    const academy = await get(url);
    return academy.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postInstructorsInfo = (academyId, data) => {
  return post(
    `${ACADEMY_INSTRUCTOR_LIST_API.replace('<academy-id>', academyId)}`,
    data,
  )
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const patchInstructorsInfo = (academyId, data) => {
  return patch(
    `${ACADEMY_INSTRUCTOR_LIST_API.replace('<academy-id>', academyId)}`,
    data,
  )
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const patchAcademyDetails = (academyId, data) => {
  return patch(`${ACADEMY_PATCH_API.replace('<academy-id>', academyId)}`, data)
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateAcademyStatus = (academyId, status) => {
  return post(`${ACADEMY_STATUS_API.replace('<academy-id>', academyId)}`, {
    status: status === 'Active' ? 'disable' : 'enable',
  })
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const referAnAcademy = (academyId, data) => {
  return post(
    `${REFER_AN_ACADEMY_API.replace('<academy-id>', academyId)}`,
    data,
  )
    .then((result) => result.data)
    .catch((err) => {
      console.error(err);
      return `Couldn't refer academy`;
    });
};

export const deleteInstructorsInfo = (academyId, dataToDelete) => {
  return del(
    `${ACADEMY_INSTRUCTOR_LIST_API.replace('<academy-id>', academyId)}`,
    { data: dataToDelete },
  )
    .then((result) => result.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getInstructors = async (academyId) => {
  try {
    const instructors = await get(
      ACADEMY_INSTRUCTOR_LIST_API.replace('<academy-id>', academyId),
    );
    return instructors.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getStudents = async (academyId) => {
  try {
    const students = await get(
      `${ACADEMY_STUDENT_LIST_API.replace('<academy-id>', academyId)}`,
    );
    return students.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getStudentActivities = async (academyId, username) => {
  try {
    const activities = await get(
      `${ACADEMY_STUDENT_ACTIVITY_API.replace(
        '<academy-id>',
        academyId,
      ).replace('<username>', username)}`,
    );
    return activities.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAdoptAcademyInfo = async (academyId) => {
  try {
    const response = await get(
      `${ADOPT_ACADEMY_API.replace('<academy-id>', academyId)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const sendInterestAcademyInfo = async (academyId) => {
  try {
    const response = await post(
      `${ADOPT_ACADEMY_API.replace('<academy-id>', academyId)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCommonAcademyResourcesFiles = async () => {
  try {
    const response = await get(RESOURCES_API_URL);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getLabImages = async () => {
  try {
    const response = await get(LAB_IMAGES_API_URL);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCloudFrontSignedUrl = async (data) => {
  try {
    const response = await post(CLOUDFRONT_SIGNED_API, data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAcademies = async () => {
  try {
    const response = await get(ACADEMIES);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAcademyDetails;
