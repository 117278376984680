import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { inject, PropTypes as MobXPropTypes, observer } from 'mobx-react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import {
  InputGroup,
  FormControl,
  DropdownButton,
  DropdownItem,
  Button,
} from 'react-bootstrap';
import { DotLoader } from 'react-spinners';

@withTranslation()
@observer
class VMRow extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    vmSummary: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.menuOpen = false;
  }

  handleClick(command) {
    const vm = this.props.vmSummary;
    this.props.action(command, { vm_id: vm.id });
  }

  render() {
    const vm = this.props.vmSummary;
    const isLoading = vm.state !== 'STOPPED' && vm.state !== 'STARTED';
    return (
      <tr key={vm.id} id={`vm-${vm.id}`}>
        <td className="lab-controls-vm-panel-name">{vm.name}</td>
        <td className="lab-controls-vm-panel-actions">
          <InputGroup>
            <FormControl
              type="text"
              className="autostop-display"
              value={vm.state}
              readOnly
            />
            <InputGroup.Addon>
              <DotLoader loading={isLoading} color="#999" size={15} />
            </InputGroup.Addon>
            <DropdownButton
              componentClass={InputGroup.Button}
              id="vm-action-dropdown"
              title="Action"
              className="btn-secondary"
            >
              <DropdownItem
                key="vm-action-start"
                disabled={vm.state !== 'STOPPED'}
                onClick={() => this.handleClick('vm_start')}
              >
                Start
              </DropdownItem>
              
              <DropdownItem
                key="vm-action-shutdown"
                disabled={vm.state !== 'STARTED'}
                onClick={() => this.handleClick('vm_stop')}
              >
                Shutdown
              </DropdownItem>
              <DropdownItem
                key="vm-action-poweroff"
                disabled={vm.state === 'STOPPED'}
                onClick={() => this.handleClick('vm_poweroff')}
              >
                Power Off
              </DropdownItem>
            </DropdownButton>
          </InputGroup>
        </td>
        <td className="lab-controls-vm-panel-console">
          <Button
            variant="success"
            disabled={vm.state !== 'STARTED'}
            onClick={() => this.handleClick('get_vnc_url')}
          >
            Open Console
          </Button>
        </td>
      </tr>
    );
  }
}

/* reset
              <MenuItem divider />
              <MenuItem
                key="vm-action-reset"
                disabled={vm.state === 'STARTING'}
                onClick={e => this.handleClick('vm_reset', e)}
              >
                Reset
              </MenuItem>
 */

export default VMRow;
