import { observable, computed, action, autorun, toJS } from 'mobx';
import { get, post, put } from 'axios';
import _ from 'lodash';
import { createContext } from 'react';
import {
  USER_LAB_API,
  INHIBIT_LAB_START_API,
  NO_CLASS,
} from '../../config/constants';
import rootStore from '../../stores';

class LabEnvironmentStore {
  @observable courseSlug = null;

  @observable labDefinition = {};

  @observable currentEnrollment = {};

  @observable targetEnrollment = {};

  @observable _currentTemplate = 0;

  @observable userLab = {};

  @observable lastRequestedCommand = null;

  @observable pollIntervalSecs = 0;

  @observable bypassSlugCheck = false;

  @observable lauchLabInProgress = false;

  @observable _modal = {
    opened: false,
    working: false,
    title: '',
    confirmText: null,
    dismissText: '',
    confirmFunc: () => {},
  };
  pollIntervalId = null;
  @observable _inhibitLabInfo = { allow_lab_start: true, reason: '' };
  @observable classId = '';

  constructor() {
    autorun(() => {
      if (this.bypassSlugCheck || this.courseSlug) {
        this.fetchUserLab();
      }
    });

    autorun(() => {
      const delay = this.pollIntervalSecs * 1000;
      this.stopFetchUserLabPolling();
      if (delay) {
        this.pollIntervalId = setInterval(this.fetchUserLab, delay);
      }
    });
  }

  @action stopFetchUserLabPolling() {
    if (this.pollIntervalId) {
      clearInterval(this.pollIntervalId);
    }
  }

  @computed get currentTemplate() {
    if (!this.availableTemplates.length) {
      return null;
    }

    return this.availableTemplates[this._currentTemplate];
  }

  @action setCurrentTemplate(index) {
    this._currentTemplate = index;
  }

  @computed get availableTemplates() {
    const components = this.labDefinition.components
      ? toJS(this.labDefinition.components)
      : [];
    /* eslint-disable camelcase */
    if (
      components.length &&
      components[0]?.osp_templates &&
      components[0].osp_templates.length
    ) {
      return components[0].osp_templates;
    }

    return [];
  }

  @computed get openStackComponent() {
    const { components } = this.userLab;
    return components && components.length
      ? components.find(c => c.driver === 'openstack')
      : {};
  }

  @computed get classroomWebapp() {
    return _.get(this.userLab, 'classroom_webapp', {});
  }

  @computed get userCredentialMap() {
    return _.get(this.userLab, 'ssh_enabled', false)
      ? _.get(this.openStackComponent, 'context.user_credential_map', {})
      : {};
  }

  @computed get webApplications() {
    return _.get(this.userLab, 'web_applications', []);
  }

  @computed get hasUserLabParameters() {
    return this.labDefinition.user_lab_parameters?.length > 0;
  }

  @computed get modal() {
    return this._modal;
  }

  @computed get cleanCourseSlug() {
    return this.courseSlug.replace('vc-', '-');
  }

  @action createNewModal(modal, autoOpen = false) {
    this._modal.opened = autoOpen;
    this._modal.working = false;
    this._modal.title = modal.title ? modal.title : '';
    this._modal.confirmText = modal.confirmText ? modal.confirmText : null;
    this._modal.dismissText = modal.dismissText ? modal.dismissText : 'Ok';
    this._modal.confirmFunc = modal.confirmFunc ? modal.confirmFunc : () => {};
  }

  @action setModalWorking(working) {
    this._modal.working = working;
  }

  @action hideModal() {
    this._modal.opened = false;
  }

  @action modalDoConfirm() {
    if (this._modal.confirmFunc) this._modal.confirmFunc();
  }

  @action setInhibitLabInfo = async () => {
    // Checking for inhibit lab info only when starting a lab is possible
    if ((this.labState === 'ready' || this.labState === 'deleted') &&
      !(
        !this._inhibitLabInfo.allow_lab_start && 
        this._inhibitLabInfo.reason === 'throttled'
      )
    ) {
      const url = `${INHIBIT_LAB_START_API}?offering_slug=${this.cleanCourseSlug}`;
      try {
        const result = await get(url);
        this._inhibitLabInfo = result.data;
      } catch (error) {
        console.warn('Error checking for labs availability');
      }
    }
  };

  @action fetchUserLab = async () => {
    const userLabId = this.userLab?.doc_id;
    const userLabOfferingSlug = this.userLab?.offering_slug;
    const currentEuuid = this.currentEnrollment.uuid;
    const targetEuuid = this.targetEnrollment.uuid;
    const classIdParam =
      rootStore.userStore.isStudent && this.classId && this.classId !== '' && this.classId !== NO_CLASS
        ? `class_id=${this.classId}`
        : '';

    if (!userLabId) {
      let url = `${USER_LAB_API}?offering=${this.cleanCourseSlug}`;
      if (currentEuuid) {
        url += `&current_euuid=${currentEuuid}`;
      }
      if (targetEuuid) {
        url += `&target_euuid=${targetEuuid}`;
      }

      if (classIdParam !== '') {
        url += `&class_id=${this.classId}`;
      }
      try {
        const result = await get(url);

        if (result.data.items && result.data.items.length) {
          [this.userLab] = result.data.items;
        } else {
          if (!this.lauchLabInProgress) {
            this.userLab = {};
          }
        }
      } catch (error) {
        if (error.response?.status === 403) {
          console.warn('disabling polling due to session timeout');
          this.pollIntervalSecs = 0;
        }
      }
    } else if (
      this.bypassSlugCheck ||
      this.cleanCourseSlug === userLabOfferingSlug
    ) {
      let url = `${USER_LAB_API}${userLabId}`;
      if (classIdParam !== '') {
        url += `?class_id=${this.classId}`;
      }
      try {
        const result = await get(url);

        this.userLab = result.data;
      } catch (error) {
        if (error.response?.status === 403) {
          console.warn('disabling polling due to session timeout');
          this.pollIntervalSecs = 0;
        }
      }
    }
  };

  @action doCommand = async (command, params) => {
    this.lastRequestedCommand = command;

    if (command === 'launch') {
      return this.doLaunch(params);
    }

    if (!this.userLab?.doc_id) {
      console.warn('no active lab.');
      return {};
    }

    const data = {
      command,
      ...(this.classId && this.classId !== ''
        ? { class_id: this.classId }
        : {}),
      params,
    };

    const url = `${USER_LAB_API}${this.userLab?.doc_id}`;
    const result = await put(url, data);

    if (result.data?.status === 'S200') {
      /* eslint-disable camelcase */
      if (result.data?.user_lab?.doc_id) {
        /* eslint-disable camelcase */
        this.userLab = result.data.user_lab;
      }
    } else if (result.data?.status === 'E529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'throttled';
      console.warn('Cannot start labs: throttled');
    } else if (result.data?.status === 'UE529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'user_throttled';
      console.warn('Cannot start labs: user_throttled');
    }

    return result;
  };

  @action doLaunch = async (params) => {
    if (this.userLab?.doc_id) {
      console.warn('lab already exists.');
      return {};
    }
    this.lauchLabInProgress = true;
    const url = USER_LAB_API;
    const data = {
      command: 'launch',
      offering_slug: this.courseSlug,
      current_euuid: this.currentEnrollment.uuid,
      target_euuid: this.targetEnrollment.uuid,
      ...(this.classId && this.classId !== ''
        ? { class_id: this.classId }
        : {}),
      ...(this.currentTemplate
        ? { child_offering_slug: this.currentTemplate.child_offering_slug }
        : {}),
      params,
    };
    const result = await post(url, data);
    

    // this logic is shared with doCommand above,
    // and should be combined...
    if (result.data?.status === 'S200') {
      /* eslint-disable camelcase */
      if (result.data?.user_lab?.doc_id) {
        /* eslint-disable camelcase */
        this.userLab = result.data.user_lab;
      }
    } else if (result.data?.status === 'E529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'throttled';
      console.warn('Cannot start labs: throttled');
    } else if (result.data?.status === 'UE529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'user_throttled';
      console.warn('Cannot start labs: user_throttled');
    } else if (result.data?.status === 'NA529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'not_allowed_launch';
      console.warn('Cannot start labs: Not_allowed_launch');
    }

    this.lauchLabInProgress = false;
    return result;
  };

  @computed get labState() {
    if (
      !this.canLabStart &&
      ['throttled', 'user_throttled', 'not_allowed_launch'].includes(
        this.reasonLabCantStart,
      ) &&
      this.lastRequestedCommand === 'app_start'
    ) {
      return this.userLab.state;
    }

    if (
      !this.canLabStart &&
      ['throttled', 'user_throttled', 'not_allowed_launch'].includes(
        this.reasonLabCantStart,
      )
    ) {
      return 'ready';
    }

    if (!this.userLab?.state) {
      if (this.lastRequestedCommand === 'launch') return 'requested';
      return 'ready';
    }

    if (
      this.userLab?.state === 'running' &&
      (this.lastRequestedCommand === 'app_stop' ||
        this.userLab?.desired_state === 'stopped')
    )
      return 'stopping';
    if (
      (this.userLab?.state === 'running' ||
        this.userLab?.state === 'stopped') &&
      (this.lastRequestedCommand === 'app_delete' ||
        this.userLab?.desired_state === 'deleted')
    )
      return 'deleting';
    if (
      this.userLab?.state === 'stopped' &&
      (this.lastRequestedCommand === 'app_start' ||
        this.userLab?.desired_state === 'running')
    )
      return 'starting';
    if (
      (this.userLab?.state === 'transitioning' ||
        this.userLab?.state === 'installing') &&
      (this.lastRequestedCommand === 'launch' ||
        this.userLab?.desired_state === 'running')
    )
      return 'creating';

    return this.userLab?.state;
  }

  @computed get labDeleteThreshold() {
    // after the threshold is reached, a button to extend autodelete will disappear
    if (
      this.courseSlug.startsWith('do280') ||
      this.courseSlug.startsWith('do417')
    ) {
      return 7;
    }
    return 14;
  }

  @computed get canLabStart() {
    return this._inhibitLabInfo.allow_lab_start;
  }

  @computed get reasonLabCantStart() {
    if (!this.canLabStart) {
      return this._inhibitLabInfo.reason;
    }

    return undefined;
  }
}

export default LabEnvironmentStore;
export const labEnvironmentContext = createContext(null);
