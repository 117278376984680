import { observable } from 'mobx';
import { CERTIFICATE_OF_ATTENDANCE_RHLC_URL } from '../../../config/constants';
import { generateCertificate } from '../../../services/CertificateService';

class CertificateofAttendanceModalStore {
  @observable user = {
    first_name: '',
    last_name: '',
    preferred_name: '',
  };

  @observable confirmModal = {
    opened: false,
  };

  @observable redirectModal = {
    opened: false,
  };

  @observable loading = false;

  async generateCertificate(courseSlug, timestamp,class_id, academy_id) {
    const params = {
      name: this.user.preferred_name,
      course: courseSlug,
      username: this.user?.username,
      ...(timestamp && { timestamp }),
      ...(class_id && { class_id }),
      ...(academy_id && { academy_id }),
    };

    try {
      const uuid = await generateCertificate(params);

      return window.open(
        `${CERTIFICATE_OF_ATTENDANCE_RHLC_URL}?course=${courseSlug}&uuid=${uuid}`,
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  updateUserProfile(user = this.user, userStore) {
    return new Promise((resolve, reject) => {
      const userData = {
        preferred_name: this.user.preferred_name,
      };
      
      userStore
        .partiallyUpdateUserRecord(userData)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }
}

export default CertificateofAttendanceModalStore;
