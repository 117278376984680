// TODO: This is not yet in place. Staging this for when we convert to a SPA

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { APP_BASE } from './constants';
import moment from 'moment';
const configureI18n = uiStore => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en-US',
      nsSeparator: false,
      keySeparator: false,
      debug: process.env.NODE_ENV === 'development',
      backend: {
        loadPath: `${APP_BASE}/locales/{{lng}}.json?dt=` + moment.now(),
      },
      react: {
        useSuspense: true,
      },
    });

   i18n.on('languageChanged', uiStore.onLanguageChange);

  return i18n;
};

export default configureI18n;
