import React from 'react';
import { useTranslation } from 'react-i18next';
import IconCardContainer from '../../../components/Cards/IconCardContainer';

const InstructorGuidesCard = ({ cardColor, onClick }) => {
  const { t } = useTranslation();

  const title = t('Instructor Guides');
  const firstLine = t('PDF downloads');
  const secondLine = t('for courses offered');

  return (
    <IconCardContainer
      title={title}
      firstLineElement={firstLine}
      secondLineElement={secondLine}
      cardColor={cardColor}
      iconName="download"
      onCardClick={onClick}
    />
  );
};

export default InstructorGuidesCard;
