import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DisplayAnnouncementsStore from './store';
import ViewLoader from '../../Loading/View';
import { when } from 'mobx';
import './styles.scss';
import infoIcon from '../../../img/icons/info_2.svg';
import alertIcon from '../../../img/icons/alert.svg';
import SVG from 'react-inlinesvg';
import moment from 'moment-timezone';
import { storesContext } from '../../../stores';
import { DISPLAY_ANNOUNCEMENT_INTERVAL } from '../../../config/constants';

const DisplayAnnouncements = () => {
  const { userStore } = useContext(storesContext);
  const store = useLocalStore(() => new DisplayAnnouncementsStore());
  const { t } = useTranslation();

  useEffect(() => {
    when(
      () => userStore.isLoggedIn && !store.ready,
      () => {
        store.getAnnouncements();
      },
    );
  }, [store, userStore]);

  const renderAnnouncements = () => {
    return (
      <React.Suspense fallback={<ViewLoader />}>
        {store.ready && store.announcement?.slides?.length > 0 && (
          <Carousel
            variant="dark"
            className="announcement-slider"
            interval={DISPLAY_ANNOUNCEMENT_INTERVAL}
          >
            {store.announcement.slides.map((slide) => {
              return (
                <Carousel.Item
                  key={slide.doc_id}
                  className={
                    slide.announcement_type === 'info'
                      ? 'border-blue'
                      : 'border-red'
                  }
                >
                  <div className="row ">
                    <div className="col announcement">
                      <div className="info-icon">
                        <SVG
                          src={
                            slide.announcement_type === 'info'
                              ? infoIcon
                              : alertIcon
                          }
                        ></SVG>
                      </div>
                      <div className="announcement-content">
                        <div className="title">
                          {' '}
                          {slide.content} -{' '}
                          {moment(slide['@timestamp']).format('MM/DD/YYYY')}
                        </div>
                        <div className="pr-2">{slide.message}</div>
                      </div>
                    </div>
                    {slide.url && (
                      <div className="col-md-2 announcement announcement-url">
                        <a
                          href={`${slide.url.indexOf && slide.url.indexOf("http") !== 0 ? '//':''}${slide.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Read More')}
                        </a>
                      </div>
                    )}
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
      </React.Suspense>
    );
  };
  return renderAnnouncements();
};

export default observer(DisplayAnnouncements);