import React from 'react';
import Help from '../../components/Help';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';

const HelpView = () => {
	return <Help />
};

export default withErrorBoundary(
  observer(HelpView),
  GenericViewError,
  handleError,
);