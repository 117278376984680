import { action, observable } from 'mobx';
import { validateEmail } from '../../../../../../services/UtilityService';

class UserInfoFormStore {
  @observable defaultEmail;
  @observable firstName;
  @observable lastName;
  @observable updating = false;
  @observable errorMessage;

  constructor(userStore, onUserInfoUpdate) {
    this.userStore = userStore;
    this.defaultEmail = this.userStore.email;
    this.firstName = this.userStore.firstName;
    this.lastName = this.userStore.lastName;
    this.onUserInfoUpdate = onUserInfoUpdate;
  }

  @action async updateInfo() {
    this.errorMessage = undefined;
    if (validateEmail(this.defaultEmail) && this.firstName && this.lastName) {
      this.updating = true;
      try {
        await this.userStore.partiallyUpdateUserRecord({
          default_email: this.defaultEmail,
          first_name: this.firstName,
          last_name: this.lastName,
        });
        await this.onUserInfoUpdate();
        this.updating = false;
      } catch (error) {
        console.error(error);
        this.errorMessage = 'There was an error updating the info';
        this.updating = false;
      }
    }
  }
}

export default UserInfoFormStore;
