import React, { useContext } from 'react';
import { storesContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import { Accordion, Card } from 'react-bootstrap';
import LabMaterialsStore from './store';
import BuyLabCard from '../Cards/MaterialCard/BuyLabCard';
import LoadingView from '../Loading/View';
import { when } from 'mobx';
import './styles.scss';

const LabMaterials = ({ course }) => {
  const { t } = useTranslation();
  const { classStore, userStore } = useContext(storesContext);
  const store = useLocalStore(
    () => new LabMaterialsStore(classStore, userStore),
  );

  React.useEffect(() => {
    when(
      () => !store.ready,
      async () => {
        await store.getLabProviderDetails();
        store.ready = true;
      },
    );
  }, [store, course]);

  React.useEffect(() => {
    when(
      () => store.ready,
      () => {
        if (course) store.getMaterials(course);
      },
    );
  }, [store, course, store.ready]);

  React.useEffect(() => {
    // Refresh lab providers every 30 seconds
    let interval;
    if (course) {
      interval = setInterval(() => {
        store.refreshLabProviders(course);
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [store, course]);

  return (
    store.ready && (
      course &&
        course.customClass === false &&
        store.isCourseAllowingLabs(course) ? (
        <React.Fragment>
          <Accordion>
            <Card className="expandable-lab-materials">
              <Card.Header className={store.openedView && 'opened'}>
                <p className="font-weight-bold mb-0">
                  {t(store.getCourseTitle(course))}
                </p>
              </Card.Header>
              <Card.Body>
                <p>{t(store.getCourseDescriptionPart2(course))}</p>

                {store.ready ? (
                  <BuyLabCard
                    course={course}
                    getLabProvidersForCourse={() => { store.getLabProvidersForCourse(course); }}
                    courseAllowsLabs={store.isCourseAllowingLabs(course)}
                    canBuyLabs={store.canBuyLabs}
                    providers={store.providersInfo}
                  />
                ) : (
                  <LoadingView />
                )}

              </Card.Body>
            </Card>
          </Accordion>

        </React.Fragment>
      ) : (
        <p className="m-3">{t('No Lab offered for this course.')}</p>
      )
    )

  );
};

export default observer(LabMaterials);
