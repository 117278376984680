import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  inject,
  PropTypes as MobXPropTypes,
  observer,
  Provider,
} from 'mobx-react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import LabControls from './LabControls';
import AutostopTimer from './LabControls/AutostopTimer';
import LabWebApps from './LabControls/LabWebAppPanel';
import DefaultLabComponent from './DefaultLabComponent';
import RavelloLabComponent from './RavelloLabComponent';
import OpenstackLabComponent from './OpenstackLabComponent';
import OCPSharedLabComponent from './OCPSharedLabComponent';
import TowerLabComponent from './TowerLabComponent';

import {
  LAB_DATA_POLLING_SECONDS,
  LAB_AUTOSHUTDOWN_POLLING_SECONDS,
} from '../../config/constants';

import LabEnvironmentStore from './store';

@inject('userStore')
@withTranslation()
@observer
class LabEnvironment extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    courseSlug: PropTypes.string.isRequired,
    labDefinition: MobXPropTypes.observableObject.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    currentEnrollment: PropTypes.object,
    targetEnrollment: PropTypes.object,
    classId: PropTypes.string,
  };

  static defaultProps = {
    currentEnrollment: {},
    targetEnrollment: {},
    classId: '',
  };

  componentMap = {
    ravello: RavelloLabComponent,
    shared_ocp: OCPSharedLabComponent,
    tower: TowerLabComponent,
    openstack: OpenstackLabComponent,
  };

  constructor(props) {
    super(props);
    this.store = new LabEnvironmentStore();
    this.store.currentEnrollment = this.props.currentEnrollment;
    this.store.targetEnrollment = this.props.targetEnrollment;
    this.store.labDefinition = this.props.labDefinition;
    // set courseSlug last, as it triggers the action.
    this.store.classId = this.props.classId;
    this.store.courseSlug = this.props.courseSlug;

    // force use :(
    console.warn(props.userStore);
  }

  async componentDidMount() {
    this.store.pollIntervalSecs = LAB_DATA_POLLING_SECONDS;
    await this.props.userStore.getQuotas(
      this.props.classId
    );
    this.checkLabQuota();
    this.store.setInhibitLabInfo();
    this.checkInhibitLabStart();
  }

  componentDidUpdate() {
    if (
      this.props.courseSlug !== this.store.courseSlug ||
      this.props.classId !== this.store.classId
    ) {
      this.updateStore();
    }
  }

  componentWillUnmount() {
    this.store.pollIntervalSecs = 0;
    if (this.labHoursWatchdog) clearTimeout(this.labHoursWatchdog);
    if (this.inhibitLabStartWatchdog)
      clearTimeout(this.inhibitLabStartWatchdog);
  }

  getComponents() {
    const ul = this.store.userLab;

    if (!ul?.doc_id || !this.props.userStore.hasLabHours) {
      return null;
    }

    const elems = ul?.components.map((comp) => {
      const elemComp = this.componentMap[comp.driver] || DefaultLabComponent;
      const props = {
        action: this.store.doCommand,
        labStore: this.store,
        labComponent: comp,
        key: comp.slug,
      };
      return React.createElement(elemComp, props);
    });
    return elems;
  }

  checkLabQuota() {
    const { t } = this.props;

    this.labHoursWatchdog = setTimeout(() => {
      this.props.userStore
        .getQuotas(this.props.classId)
        .then(() => {
          if (
            !this.props.userStore.hasLabHours &&
            this.store.userLab?.state === 'running' &&
            // eslint-disable-next-line
            this.store.userLab?.desired_state !== 'stopped'
          ) {
            this.store.doCommand('app_stop');

            this.store.createNewModal(
              {
                title: t(
                  'Your labs have shut down. You have used all of your available lab hours.',
                ),
                dismissText: t('Ok'),
              },
              true,
            );
          }
        })
        .finally(() => {
          this.checkLabQuota();
        });
    }, LAB_AUTOSHUTDOWN_POLLING_SECONDS * 1000);
  }

  checkInhibitLabStart() {
    this.inhibitLabStartWatchdog = setTimeout(() => {
      this.store.setInhibitLabInfo();
      this.checkInhibitLabStart();
    }, LAB_AUTOSHUTDOWN_POLLING_SECONDS * 1000);
  }

  updateStore() {
    this.store.currentEnrollment = this.props.currentEnrollment;
    this.store.targetEnrollment = this.props.targetEnrollment;
    this.store.labDefinition = this.props.labDefinition;
    this.store.courseSlug = this.props.courseSlug;
    this.store.classId = this.props.classId;
    this.store.lastRequestedCommand = null;
    this.store.userLab = {};
    this.store.fetchUserLab();
  }

  render() {
    const comps = this.getComponents();
    const { t } = this.props;

    return (
      <Provider labStore={this.store}>
        <div>
          {!this.props.userStore.hasLabHours && (
            <Alert variant="warning" id="lab-autoshutdown-banner-dialog">
              <h4>
                {/* <Glyphicon glyph="exclamation-sign" /> */}
                <b>{` ${t('Important!')}`}</b>
              </h4>
              {t(
                'Your labs have shut down. You have used all of your available lab hours.',
              )}
            </Alert>
          )}

          <div className="instruction-wrapper">
            {`${t('Lab Hours Used: ')} ${this.props.userStore.labHoursUsed}/${
              this.props.userStore.labHoursAllowed
            }`}
          </div>

          <LabControls />
          <br />

          {comps}
          {this.props.userStore.hasLabHours && (
            <AutostopTimer labStore={this.store} />
          )}
          {this.store?.userLab?.web_applications && (
            <LabWebApps labStore={this.store} />
          )}
          <Modal
            tabIndex="-1"
            show={this.store.modal.opened}
            onHide={() => {
              this.store.hideModal();
            }}
          >
            <Modal.Header tabIndex="-1" closeButton>
              <Modal.Title>{this.store.modal.title} </Modal.Title>
            </Modal.Header>
            <Modal.Footer tabIndex="-1">
              {this.store.modal.confirmText ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    this.store.modalDoConfirm();
                  }}
                  disabled={this.store.modal.working}
                >
                  {this.store.modal.confirmText}
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  this.store.hideModal();
                }}
                disabled={this.store.modal.working}
              >
                {this.store.modal.dismissText}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Provider>
    );
  }
}

/*
  debug elements
        <p>Lab Definition</p>
        <pre>{JSON.stringify(this.store.labDefinition, null, 2)}</pre>
        <p>User Lab</p>
        <pre>{JSON.stringify(this.store.userLab, null, 2)}</pre>
*/

export default LabEnvironment;
