import React, { Component } from 'react';
import { SyncLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import {
  ButtonToolbar,
  Button,
  DropdownButton,
  
  Dropdown,
} from 'react-bootstrap';
import shortid from 'shortid';
import LabInstructions from './LabInstructions';

import LabInfo from '../LabInfo';
import LabAlert from './LabAlert';
import { labEnvironmentContext } from '../store';
import './styles.scss';

@inject('labStore')
@inject('userStore')
@withTranslation()
@observer
class LabControls extends Component {
  static propTypes = {
    labStore: PropTypes.object.isRequired,
    userStore: PropTypes.object.isRequired,
  };

  canCreateOnStates = ['ready', 'deleted'];

  renderButtons() {
    const LabButton = (command, activeState, workingState, title) => {
      let state = null;
      if (activeState.when.includes(this.props.labStore.labState)) {
        state = Object.assign({ status: 'active' }, activeState);
      } else if (workingState.when.includes(this.props.labStore.labState)) {
        state = Object.assign({ status: 'working' }, workingState);
      }

      return state !== null ? (
        <Button
          key={command}
          className={[
            state.btnClass,
            'has-spinner',
            'mr-3',
            state.status === 'working' ? 'spinner-active' : '',
          ].join(' ')}
          title={title}
          disabled={
            !this.props.userStore.hasLabHours ||
            state.status !== 'active' ||
            (this.canCreateOnStates.includes(this.props.labStore.labState) &&
              !this.props.labStore.canLabStart)
          }
          onClick={() => {
            this.props.labStore.doCommand(command);
          }}
        >
          <div className="spinner-wrapper">
            <SyncLoader size={8} color="#6a6e73" />
          </div>
          {state.label}
        </Button>
      ) : null;
    };

    const LabTemplateSelector = () => {
      return this.props.labStore.availableTemplates.length &&
        this.props.labStore.labState === 'ready' ? (
        <DropdownButton
          bsStyle="success"
          title={this.props.labStore.currentTemplate.title}
          key={shortid.generate()}
          id={shortid.generate()}
        >
          {this.props.labStore.availableTemplates.map((template, i) => (
            <Dropdown.Item
              key={template.child_offering_slug}
              onClick={() => {
                this.props.labStore.setCurrentTemplate(i);
              }}
            >
              {template.title}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      ) : null;
    };

    return [
      LabTemplateSelector(),
      LabButton(
        'launch',
        {
          when: ['ready', 'deleted'],
          label: 'Create',
          btnClass: 'btn-secondary',
        },
        {
          when: ['requested', 'creating', 'installing', 'transitioning'],
          label: 'Creating',
          btnClass: 'btn-greyed',
        },
        'Create the lab',
      ),
      LabButton(
        'app_delete',
        {
          when: ['running', 'stopped', 'failed', 'starting', 'stopping'],
          label: 'Delete',
          btnClass: 'btn-danger',
        },
        { when: ['deleting'], label: 'Deleting', btnClass: 'btn-greyed' },
        'Delete the lab',
      ),
      LabButton(
        'app_start',
        { when: ['stopped'], label: 'Start', btnClass: 'btn-secondary' },
        {
          when: [
            'requested',
            'creating',
            'installing',
            'transitioning',
            'starting',
          ],
          label: 'Starting',
          btnClass: 'btn-greyed',
        },
        'Start the lab',
      ),
      LabButton(
        'app_stop',
        { when: ['running'], label: 'Stop', btnClass: 'btn-secondary' },
        { when: ['stopping'], label: 'Stopping', btnClass: 'btn-greyed' },
        'Stop the lab',
      ),
      this.props.labStore.labState !== 'ready' ? (
        <React.Fragment key={'lab-nfo'}>
        <labEnvironmentContext.Provider value={this.props.labStore}>
          {this.props.labStore.labState ? <LabInfo key='unique' /> : null}
        </labEnvironmentContext.Provider>
        </React.Fragment>
      ) : null,
    ];
  }

  render() {
    return (
      <React.Fragment>
        <labEnvironmentContext.Provider value={this.props.labStore}>
          <LabInstructions
            lab={this.props.labStore}
            user={this.props.userStore}
          />
        </labEnvironmentContext.Provider>
        {!this.props.labStore.canLabStart ? (
          <LabAlert alertType={this.props.labStore.reasonLabCantStart} />
        ) : null}
        <ButtonToolbar className='align-items-end'>{this.renderButtons()}</ButtonToolbar>
      </React.Fragment>
    );
  }
}

export default LabControls;
