import { action, observable } from 'mobx';

class ContainerCardStore {
  @observable opened = false;

  @observable show = false;

  @observable collateral = {};

  @action toggle = () => {
    this.opened = !this.opened;
  };

 
}

export default ContainerCardStore;
