import { action, observable } from 'mobx';

class AcademyStudentStore {

  @observable selectedStudentId = '';

  @action setSelectedStudentId = (selectedStudentId) => {
    this.selectedStudentId = this.selectedStudentId !== selectedStudentId ? selectedStudentId : '';
  }
}

export default AcademyStudentStore;