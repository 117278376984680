import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, ProgressBar, useAccordionToggle } from 'react-bootstrap';
import { observer, useLocalStore } from 'mobx-react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import StudentClassList from '../AcademyStudentDetail/StudentClasses';
import CollapseIcon from '../../../../img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from '../../../../img/icons/web-icon-caret-thin-down.svg';
import SVG from 'react-inlinesvg';
import AcademyStudentDetailStore from './store';
import './styles.scss';

const AcademyStudentDetail = (props) => {
    const { t } = useTranslation();
    const { student, selectedStudentId, onRowClick } = props;
    const studentId = student?.id;

    const store = useLocalStore(
      () => new AcademyStudentDetailStore(),
    );

    const rowClick = useAccordionToggle(studentId, async (selectedStudentId) => {
      studentId !== selectedStudentId && store.getStudentActivities(student);
      onRowClick(student.id);
    });

    const getStudentProgress = (student) => {
      const progress = student?.progress ? Math.round(student?.progress * 100) : 0;
      return <ProgressBar striped now={progress} label={`${progress}%`} />;
    };

    const renderStudentDetail = (expanded) => {
      return (
        <div className='caret-wrapper'>
          <div className='info-expand'>
            <SVG src={expanded ? CollapseIcon : ExpandIcon}></SVG>
          </div>
        </div>
      );
    };

    return (
        <div key={studentId} className="student-detail-wrapper">
          <div className={`d-flex flex-sm-row flex-column row-student ${ studentId === selectedStudentId && 'selected'}`}
          onClick={(e) => rowClick(selectedStudentId)}>
              
            <div className="mr-auto p-2 column-student">
                <div className="flex-sm-row">
                <span className="row-sm-header">{t('Red Hat Network (RHN) ID')}:&nbsp;&nbsp;</span>
                  {student?.username}
                </div>
            </div>
            
            <div className="mr-auto p-2 column-student">
                <div className="flex-sm-row">
                <span className="row-sm-header">{t('Name')}:&nbsp;&nbsp;</span>
                  {student?.name}
                </div>
            </div>

            <div className="mr-auto p-2 column-student">
                <div className="flex-sm-row">
                <span className="row-sm-header">{t('Total Classes')}:&nbsp;&nbsp;</span>
                  {student?.total_class}
                </div>
            </div>

            <div className="mr-auto p-2 column-student">
              <div className="flex-sm-row">
                <span className="row-sm-header">{t('Progress')}:&nbsp;&nbsp;</span>
                {getStudentProgress(student)}
              </div>
            </div>

            <div className="mr-auto p-2 column-student">
                <div className="flex-sm-row">
                  <span className="row-sm-header">{t('Submissions')}:&nbsp;&nbsp;</span>
                  {student?.submissions}
                </div>
            </div>

            <div className="mr-auto p-2 column-student">
                <div className="flex-sm-row">
                  <span className="row-sm-header">{t('Last Accessed')}:&nbsp;&nbsp;</span>
                  {student.last_accessed
                      ? moment(student.last_accessed).format('MMM DD, YYYY')
                      : ''}
                    {renderStudentDetail(studentId === selectedStudentId)}
                </div>
            </div>
          </div>
          <Accordion.Collapse eventKey={studentId}>
            <div className="row">
              <div className="col-sm">
                {store.loading ? <div className="loading-student_detail">{`${t('Loading Student Detail')} ...`}</div>
                :
                <StudentClassList classes={store.classes}></StudentClassList>
              }
              </div>
            </div>
          </Accordion.Collapse>
        </div>
    );
  };

  AcademyStudentDetail.propTypes = {
    student: PropTypes.object,
    selectedStudentId: PropTypes.string,
    onRowClick: PropTypes.func,
  };
  
  AcademyStudentDetail.defaultProps = {
    student: {},
    selectedStudentId: '',
    onRowClick: () => {},
  };

  export default observer(AcademyStudentDetail);