import { USER_DATA_API_URL } from '../config/constants';
import { get, post, patch } from 'axios';

export const getUserDataForCourse = async (courseSlug) => {
  try {
    const response = await get(
      `${USER_DATA_API_URL}?course_slug=${courseSlug}`,
    );
    const elements = response.data;
    return elements;
  } catch (error) {
    throw new Error(error);
  }
};

export const createUserDataForCourse = async (data) => {
  try {  
    if(!data.class_id && !data.academy_id) {throw new Error("Required param class_id or academy_id is missing")}  
    const response = await post(`${USER_DATA_API_URL}`, data);
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const modifyUserDataForDoc = async (docId, data) => {
  try {
    const response = await patch(`${USER_DATA_API_URL}${docId}`, data);
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
