import { observable, computed, action } from 'mobx';
import { getAssignmentByClassId } from '../../../services/ClassAssignmentService';
import { roundData } from '../../../services/UtilityService';
import { ASSIGNMENT_TYPES } from '../../../config/constants';
import moment from 'moment';
import { formatDate } from '../../../services/UtilityService';
class StudentAssignmentCardStore {
  @observable assignment;
  @observable loaded = false;
  constructor(uiStore) {
    this.uiStore = uiStore;
  }
  @action setAssignment = async (classId) => {
    try {
      const assignment = await getAssignmentByClassId(classId);
      this.assignment = assignment;
    } catch (e) {
    } finally {
      this.loaded = true;
      return this.assignment;
    }
  };
  @computed get hasQuiz() {
    return this.assignment?.quiz?.length > 0;
  }
  @computed get hasKC() {
    return this.assignment?.code_challenge?.length > 0;
  }
 

  
  @computed get quizResponse() {
    if (
      this.assignment?.grades?.student_response &&
      this.assignment?.grades?.student_response?.length
    ) {
      return this.assignment?.grades?.student_response?.filter(
        (g) => g.question_type === ASSIGNMENT_TYPES.quiz.question_type,
      );
    }
    return [];
  }
  @computed get kcLastSubmittedDate() {
    return formatDate(
      moment(
        this.knowledgeCheckResponse
          .slice()
          .sort(
            (a, b) =>
              moment(b.timestamp).toDate() - moment(a.timestamp).toDate(),
          )[0],
      ),
      this.uiStore.currentLanguage,
    );
  }
  @computed get quizLastSubmittedDate() {
    return formatDate(
      moment(
        this.quizResponse
          .slice()
          .sort(
            (a, b) =>
              moment(b.timestamp).toDate() - moment(a.timestamp).toDate(),
          )[0],
      ),
      this.uiStore.currentLanguage,
    );
  }
  @computed get quizGrade() {
    const totalQuizScore = this.assignment?.grades?.quiz_score || 0;
    return roundData(totalQuizScore);
  }
  @computed get kcGrade() {
    const totalChalengeScore =
      this.assignment?.grades?.code_challenge_score || 0;
    return roundData(totalChalengeScore);
  }
  @computed get knowledgeCheckResponse() {
    if (
      this.assignment?.grades?.student_response &&
      this.assignment?.grades?.student_response?.length
    ) {
      return this.assignment?.grades?.student_response?.filter(
        (g) =>
          g.question_type === ASSIGNMENT_TYPES.knowledgeCheck.question_type,
      );
    }
    return [];
  }
  @computed get hasStudentSubmittedQuiz() {
    return this.quizResponse && this.quizResponse.length > 0;
  }
  @computed get hasStudentSubmittedKC() {
    return (
      this.knowledgeCheckResponse && this.knowledgeCheckResponse.length > 0
    );
  }
}

export default StudentAssignmentCardStore;
