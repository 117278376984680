/**
 * @file Provide Search Text functionality
 * @author Prem Pachouri <ppachour@redhat.com>
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
 import { Button } from 'react-bootstrap';
 import { PropTypes } from 'prop-types';
 import SVG from 'react-inlinesvg';
 import SearchCsvIcon from '../../img/icons/search.svg';
 
 const SearchText = (props) => {

    const { t } = useTranslation();
 
   const handleQueryChange = (event) => {
      props.onSearch(event.target.value);
   };
 
   return ( 
        <div className="input-group pt-1 pb-1">
            <input className="form-control rounded" 
                type="search"
                placeholder={t(props.placeHolder)}
                disabled={props.disabled}
                onChange={handleQueryChange}
                onKeyPress={handleQueryChange}
                value={props.query} />
            {props.isSearchIcon && 
              <span className="input-group-append">
                <Button>
                    <SVG src={SearchCsvIcon}></SVG>
                </Button>
              </span>}
        </div>
   );
 };

 SearchText.propTypes = {
    query: PropTypes.string,
    placeHolder: PropTypes.string,
    disabled: PropTypes.bool,
    isSearchIcon: PropTypes.bool,
    onSearch: PropTypes.func,
  };
  
  SearchText.defaultProps = {
    query: '', 
    placeHolder: '',
    disabled: false,
    isSearchIcon: true,
    onSearch:  () => {},
  };
 
 export default SearchText;
 