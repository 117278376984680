import React from 'react';
import { Button } from 'react-bootstrap';
import ViewLoader from '../../Loading/View';
import { PropTypes } from 'prop-types';

const ButtonWithLoader = (props) => {
  const LoadingIndicator = () => {
    return <ViewLoader color="white" />;
  };
  const { loading, buttonProps } = props;
  return (
    <React.Fragment>
      <Button {...buttonProps}>{loading ? LoadingIndicator() : props.children}</Button>
    </React.Fragment>
  );
};

ButtonWithLoader.propTypes = {
  buttonProps: PropTypes.object,
  loading: PropTypes.bool,
 
};

ButtonWithLoader.defaultProps = {
  buttonProps: {
    variant: 'primary',
    OnClick: () => {},
    type: '',
    className: '',
  },
  loading: false,
  
};

export default ButtonWithLoader;
