import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AngleRightIcon, AngleLeftIcon } from '@patternfly/react-icons';
import { Link } from 'react-mobx-router5';
import { storesContext } from '../../../stores';


const Navigation = (props) => {
  const { t } = useTranslation();
  const { routerStore, courseStore } = useContext(storesContext);

  const { route } = routerStore;

  const { next, previous, scroll } = props;

  const Button = (direction, link, isDisabled) => (
    <Link
      href
      routeName={courseStore.coursePageRouteName}
      routeParams={{
        course: route.params.course,
        page: link,
        ...(courseStore.coursePageRouteParamsByRole),
      }}
      {...(scroll && {
        onClick: () =>
          window.scrollTo({
            top: 0,
          }),
      })}
      className={`pf-v5-c-button ${
        isDisabled ? ' pf-m-disabled' : ' pf-m-primary'
      }`}
    >
      {direction === 'next' ? (
        <span className="right-page-navigation">
          {t('Next')} <AngleRightIcon />
        </span>
      ) : (
        <span className="left-page-navigation">
          <AngleLeftIcon />
          {t('Previous')}
        </span>
      )}
    </Link>
  );

  return (
    <div className="pf-v5-l-flex pf-m-justify-content-space-between">
      {previous 
        ? Button('previous', previous)
        : Button('previous', route.params.course, true)}
      {next 
        ? Button('next', next)
        : Button('next', route.params.course, true)}
    </div>
  );
};

Navigation.propTypes = {
  next: PropTypes.string,
  previous: PropTypes.string,
  scroll: PropTypes.bool,
};

Navigation.defaultProps = {
  next: null,
  previous: null,
  scroll: false,
};

export default observer(Navigation);
