import { action, observable, computed } from 'mobx';
import { getAcademyDetails, getAcademies } from '../services/AcademyService';

export default class AcademyStore {
  @observable academyDetails = {};
  @observable academyLoaded = false;
  @observable academyLoading = false;
  @observable academies = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action getAcademyDetails = async (force = false, academyid = null) => {
    if (this.academyLoaded && !force) {
      return this.academyDetails;
    }
    if (this.academyLoading === true) return;
    this.academyLoading = true;

    try {
      const details = await getAcademyDetails(academyid);
      if (details) {
        this.academyDetails = details[0] || details;
      }
    } catch (error) {
      this.academyDetails = {};
    } finally {
      this.academyLoading = false;
      this.academyLoaded = true;
    }
    return this.academyDetails;
  };

  @computed get academyId() {
    return this.academyDetails?.doc_id || '';
  }
  @computed get academyName() {
    return this.academyDetails?.institution_name || '';
  }

  @computed get academyAdminName() {
    const firstName = this.academyDetails?.first_name;
    const lastName = this.academyDetails?.last_name;
    const adminName = firstName || lastName ? `${firstName} ${lastName}` : undefined;
    return adminName;
  }

  @action getAcademies = async (regions = []) => {
    try {
      if (this.academies.length === 0) {
        const academies = await getAcademies();

        this.academies =
          academies.map((academy) => {
            return {
              label: academy.institution_name,
              value: academy.doc_id,
              academy_region: academy.academy_region,
            };
          }) || [];
      }
    } catch (error) {
      this.academies = [];
    } finally {
      return regions.length > 0
        ? this.academies.filter((academy) =>
            regions.includes(academy.academy_region),
          )
        : [];
    }
  };
}
