import { post, get, patch } from 'axios';
import {
  CLASS_ASSIGNMENT_URL,
  CLASS_ASSIGNMENT_DETAIL_URL,
  QUESTION_BANK_URL,
  CLASS_AS_STUDENT_ASSIGNMENT_URL,
  CLASS_ASSIGNMENT_ACADEMY_URL,
} from '../config/constants';

export const postAssignment = async (data) => {
  try {
    const response = await post(`${CLASS_ASSIGNMENT_URL}`, data);
    return response?.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const patchAssignment = async (assignmentId, data) => {
  try {
    const response = await patch(
      `${CLASS_ASSIGNMENT_DETAIL_URL.replace('<assignment-id>', assignmentId)}`,
      data,
    );
    return response?.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const getAssignment = async (assignmentId) => {
  try {
    const response = await get(
      `${CLASS_ASSIGNMENT_DETAIL_URL.replace('<assignment-id>', assignmentId)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getQuestionsFromBank = async (courseSlug) => {
  try {
    const response = await get(
      `${QUESTION_BANK_URL.replace('<course-slug>', courseSlug)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
// This api works only for user who has student role.
export const getAssignmentByClassId = async (classId) => {
  try {
    const response = await get(
      `${CLASS_AS_STUDENT_ASSIGNMENT_URL.replace('<class-id>', classId)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAssignmentByAcademyId = async (academyId) => {
  try {
    const response = await get(
      `${CLASS_ASSIGNMENT_ACADEMY_URL.replace('<academy-id>', academyId)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postAssignmentAnswers = async (classId,data) => {
  try {
    const response = await post(`${CLASS_AS_STUDENT_ASSIGNMENT_URL.replace('<class-id>', classId)}`, data);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export default { postAssignment, getAssignment, getQuestionsFromBank};
