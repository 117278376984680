import { action, observable, computed } from 'mobx';

import { getCourseAndCerts } from '../../services/CoursesAndCertsService';
class CoursesAndCertificationStore {
  @observable coursesAndCerts = [];
  @observable loaded = false;

  @computed get courseKeys() {
    return Object.keys(this.coursesAndCerts).filter(
      (k) => k !== 'doc_type' && k !== '@timestamp' && k !== 'doc_id',
    );
  }
  @action getCourseAndCerts = async () => {
    try {
      if (this.coursesAndCerts && this.coursesAndCerts.length > 0) {
        return this.coursesAndCerts;
      }
      let details = await getCourseAndCerts();

      if (details) {
        details =
          details.items && details.items?.length > 0 ? details.items[0] : [];
        this.coursesAndCerts = details;
      }
      
    } catch (error) {
      this.coursesAndCerts = [];
    } finally {
      this.loaded = true;
    }
  };
}

export default CoursesAndCertificationStore;
