import { action, observable, computed } from 'mobx';
import {
  getCommonAcademyResourcesFiles,
  getLabImages,
  getCloudFrontSignedUrl,
} from '../../services/AcademyService';
import { RESOURCES } from '../../config/constants';

class ResourcesStore {
  folderToSection = {
    // Matching the folder name in S3 and the section route
    Banners: 'banners',
    PressRelease: 'press-release',
    Brochures: 'brochures',
    Student_Resources: 'student-resources',
    Openshift: 'openshift',
    InstructorGuides: 'guides',
    RHA_Logos: 'image-gallery',
    InfoGraphics: 'image-gallery',
    Download_Lab_Images: 'download-lab-images',
    Dual_Boot: 'dual-boot',
    Installation_and_Troubleshooting_Guides:
      'installation-and-troubleshooting-guides',
  };

  @observable files;
  @observable section;
  @observable ready = false;
  @observable loading = false;
  @observable shouldVideoShow = false;
  @observable labImagesByFolders = [];
  @observable labImagesLoading = false;
  @observable courseSlug = '';

  @action async getFiles(loadLabImages) {
    try {
      if (!this.ready && !this.loading) {
        this.loading = true;
        this.files = await getCommonAcademyResourcesFiles();
        if (loadLabImages) {
          await this.getLabImages();
          this.mergeLabFolders();
        }

        this.loading = false;
      }
      this.ready = true;
      return this.files;
    } catch (error) {
      this.files = [];
      this.labImagesByFolders = {};
      console.error(error);
      this.loading = false;
    }
  }
  mergeLabFolders() {
    // Merge lab images  with other files
    this.files = this.labImagesByFolders?.concat(this.files);
  }
  @action async getLabImages(force = false) {
    if (Object.keys(this.labImagesByFolders)?.length > 0 && !force) {
      this.labImagesLoading = false;
      return this.labImagesByFolders;
    }
    try {
      if (!this.labImagesLoading) {
        this.labImagesLoading = true;
        const labImagesByFolders = await getLabImages();
        this.labImagesByFolders = labImagesByFolders
          ?.filter((l) => {
            return this.isFile(l);
          })
          ?.filter((lb) => lb !== undefined);
        this.labImagesLoading = false;
      }

      return this.labImagesByFolders;
    } catch (error) {
      this.files = {};
      this.labImagesByFolders = {};
      console.error(error);
      this.labImagesLoading = false;
    }
  }

  @action togglePromoVideo() {
    this.shouldVideoShow = !this.shouldVideoShow;
  }

  @computed get isSectionForLabImages() {
    return (
      this.section === 'download-lab-images' ||
      this.section === 'dual-boot' ||
      this.section === 'installation-and-troubleshooting-guides'
    );
  }

  @computed get arrangedFiles() {
    let arranged = {};
    for (let folder of Object.keys(this.folderToSection)) {
      arranged[this.folderToSection[folder]] =
        RESOURCES[folder]?.length > 0 ? RESOURCES[folder] : [];
    }
    for (const file of this.files) {
      if (this.isFile(file)) {
        const folder = this.getFolderName(file);
        const section = this.folderToSection[folder];
        if (section) {
          if (!this.isSectionForLabImages) {
            arranged[section].push(this.getFileObject(file));
          } else {
            arranged[section].push(this.getFileObjectForLabImages(file));
          }
        }
      }
    }
    return arranged;
  }

  @computed get selectedFiles() {
    if (this.files) return this.arrangedFiles[this.section];

    return [];
  }

  @action getCloudFrontSignedUrl = async (data) => {
    try {
      const response = await getCloudFrontSignedUrl(data);
      return response
    } catch (error) {
      console.error(error);
    }
  };

  getFolderName(file) {
    return file.split('/')[0];
  }

  getFileName(file) {
    return file.split('/')[1];
  }

  isFile(filename) {
    // Checks is not a folder
    return filename.includes('.');
  }

  getFileObject(file) {
    const title = this.getFileName(file);
    const url = this.getFileUrl(file);
    return { title, url };
  }

  getFileObjectForLabImages(url) {
    const title = this.getFileName(url);
    return { title, url };
  }

  getFileUrl(fileName) {
    return `?filename=${fileName}`;
  }
}

export default ResourcesStore;
