import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';

import ViewLoader from '../../../components/Loading/View';
import { useTranslation } from 'react-i18next';

import StudentAssignmentListStore from './store';
import { storesContext } from '../../../stores';
import { when } from 'mobx';
import moment from 'moment';
import './styles.scss';
import { formatDate } from '../../../services/UtilityService';

const StudentQuizList = React.lazy(() =>
  import('../../../components/StudentQuizList'),
);

const StudentKnowledgeCheckList = React.lazy(() =>
  import('../../../components/StudentKnowledgeCheckList'),
);

const StudentAssignmentListView = () => {
  const { userStore, routerStore, classStore, uiStore } = useContext(
    storesContext,
  );
  const { params } = routerStore.route;
  const { t } = useTranslation();
  const store = useLocalStore(() => new StudentAssignmentListStore(classStore));
  const navigateToCourseSummary = () => {
    routerStore.navigate('summary');
  };
  const renderAssignmentDetails = (details) => {
    const decorColor = 'red';
    const cardDecor = {
      borderTop: '1px solid #cccccc',
      borderRight: '1px solid #cccccc',
      borderBottom: '1px solid #cccccc',
      borderLeft: `4px solid ${decorColor}`,
    };

    return (
      <React.Fragment>
        <Row className="assignment-details-title">
          <Col>
            <h5>{t('Assignment Details')}</h5>
          </Col>
        </Row>
        <div className="detail-cards-wrapper">
          <Row className="detail-cards-wrapper-row">
            <div style={cardDecor} className="detail-card">
              {details?.title}
            </div>

            <div style={cardDecor} className="detail-card">
              {formatDate(moment(details?.start_date), uiStore.currentLanguage)}{' '}
              - {formatDate(moment(details?.end_date), uiStore.currentLanguage)}
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    // Populate assigment
    when(
      () => !store.loaded && userStore.isLoggedIn,
      async () => {
        await store.getClassDetailbyId(params.classid);
        await store.setAssignment(params.classid);
      },
    );
  }, [userStore, store, params]);
  return (
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col className="p-0">
            <h3 className="view-page-title">{`${t('Classes')} / ${
              store.classDetail?.class_name || '---'
            }`}</h3>
          </Col>
        </Row>

        {store.loaded &&
          store.assignmentDetails !== null &&
          renderAssignmentDetails(store.assignmentDetails)}
        {!store.loaded && (
          <div>
            <ViewLoader />
          </div>
        )}
        {store.loaded &&
          store.assignmentDetails !== null &&
          routerStore.route?.name === 'student:quiz' && (
            <React.Fragment>
              <Row>
                <Col>
                  <h5>{t('Quiz')}</h5>
                </Col>
                {/* <Col className="quiz-note">
                  <i> {t('Please submit answers for  each question ')}</i>
                </Col> */}
              </Row>

              <Row>
                <Col>
                  <StudentQuizList
                    classId={store.classId}
                    questions={store.quizQuestions}
                    assignmentId={store.assignmentId}
                    onSubmitAnswers={store.setAssignment}
                    onCancelSubmission={navigateToCourseSummary}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
        {store.loaded &&
          store.assignmentDetails !== null &&
          routerStore.route?.name === 'student:kc' && (
            <React.Fragment>
              <Row>
                <Col>
                  <h5>{t('Knowledge Check')}</h5>
                </Col>
                {/* <Col className="quiz-note">
                  <i> {t('Please submit answers for each question ')}</i>
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <StudentKnowledgeCheckList
                    classId={store.classId}
                    challenges={store.kcQuestions}
                    hasSubmittedKC={store.hasStudentSubmittedKC}
                    assignmentId={store.assignmentId}
                    onSubmitAnswers={store.setAssignment}
                    onCancelSubmission={navigateToCourseSummary}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
      </React.Suspense>
    </Container>
  );
};

export default observer(StudentAssignmentListView);
