import React from 'react';

import IconCardContainer from '../../../components/Cards/IconCardContainer';

const LabImageCard = ({ title, firstLine, secondLine, cardColor, onClick }) => {
  

  

  return (
    <IconCardContainer
      title={title}
      firstLineElement={firstLine}
      secondLineElement={secondLine}
      cardColor={cardColor}
      iconName="file"
      onCardClick={onClick}
    />
  );
};

export default LabImageCard;
