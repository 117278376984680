import { get } from "axios";

import { 
  COURSE_COLLATERAL_API,
  COURSE_INFO_API,
  COURSE_STRUCTURE_API,
  COURSE_PAGE,
  ACADEMY_STUDENT_SELF_PACED_VIDEO_ACCESS_API,
} from "../config/constants";


/**
 * Returns course collateral for a specific course code, version and language
 * @param {string} code - Course code
 * @param {string} version - Course version
 * @param {string} lang - Language
 * @return {Object} An object containing course info data
 */
export const getCourseCollateral = async (code, version, lang = "en-US") => {
  if (!code || !version) {
    throw new Error("Course code and version are required");
  }

  /*
   * tempoary sting. force version and lang.  course collateral will
   * be for the code, not the versionsed slug, I think.
   * language is a server bug which will be resolved
   * --bowe
   */
  version = "1";

  try {
    const collateral = await get(
      `${COURSE_COLLATERAL_API}${code}@@${version}@@${lang}`
    );
    return collateral.data;
  } catch (error) {
    throw new Error(error);
  }
};



export const getCourseInfo = (slug, classId = null) => {
  let url = COURSE_INFO_API.replace('<course-slug>', slug);
  if(classId) {
    url = url + '?class_id=' + classId;
  }
  return new Promise((resolve, reject) => {
    get(url)
      .then(result => {
        if (result.data?.status !== 0) reject();
        resolve(result.data?.result);
      })
      .catch((e) => reject(e));
  });
};

/**
 * Returns course structure (toc)
 * @param {string} courseSlug - Course slug
 * @param {string} lang - Language
 * @return {Object} An object containing course structure (toc) data
 */
export const getCourseStructure = (courseSlug, lang = 'en-US') =>
  new Promise((resolve, reject) => {
    get(
      `${COURSE_STRUCTURE_API.replace(
        '<course-slug>',
        courseSlug,
      )}?lang=${lang}`,
    )
      .then(res => {
        resolve(res.data?.data?.attributes);
      })
      .catch(error => reject(error));
  });

/**
 * Returns course page
 * @param {string} courseSlug - Course slug
 * @param {string} lang - Language
 * @return {Object} An object containing course page data
 */
export const getCoursePage = (courseSlug, pageSlug, lang = 'en-US') => {
  const api = COURSE_PAGE.replace('<course-slug>', courseSlug).replace(
    '<page>',
    pageSlug,
  );

  return new Promise((resolve, reject) => {
    get(`${api}?lang=${lang}`)
      .then(result => {
        const { attributes } = result.data?.data || {};
        resolve(attributes);
      })
      .catch(error => reject(error));
  });
};

/**
 * Returns self paced video access status for student
 * @return {boolean} A boolean flag containing access status
 */
export const getStudentSelfPacedVideoAccessStatus = async (academyId, courseSlug) => {
  if (!academyId) {
    throw new Error('Academy Id is required');
  }
  if (!courseSlug) {
    throw new Error('Course Slug is required');
  }
  try {
    const url = ACADEMY_STUDENT_SELF_PACED_VIDEO_ACCESS_API.replace('<academy-id>', academyId).replace('<offering-slug>', courseSlug);
    const accessStatus = await get(url);
    return accessStatus?.data;
  } catch (error) {
    throw new Error(error);
  }
};
