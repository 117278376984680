import { observable } from "mobx";

export default class PollingStore {
  @observable intervalRef;
  
  start = (action, interval) => {
    this.intervalRef = setInterval(action, interval);
  };

  stop = () => {
    this.intervalRef && clearInterval(this.intervalRef);
  };
}
