import { action, observable } from 'mobx';
import { getAssignment, getAssignmentByAcademyId } from '../services/ClassAssignmentService';

class ClassAssignmentStore {
  @observable assignments = [];
  @observable ready = false;
  @observable loading = false;
  @observable error = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action fetchAssignments = async (force = true, academyId= null) => {
    try {
      if (this.assignments && this.assignments.length && !force) {
        return this.assignments;
      }
      this.loading = true;
      const assignments = academyId ? await getAssignmentByAcademyId(academyId) : await getAssignment('');
      if (assignments) {
        this.assignments = assignments;
      }
      this.ready = true;      
    } catch (error) {
      this.assignments = [];
      this.error = error;
    } finally {
      this.loading = false;
      return this.assignments;
    }
  };

  @action getAssignmentByClassId = (classId) => {
    const assignmentDetails = this.assignments.filter(a => a.class_id === classId);

    return assignmentDetails && assignmentDetails.length > 0
    ? assignmentDetails[0]
    : null;
  }
  @action getAssignmentById = (assignmentId) => {
    
    const assignmentDetails = this.assignments.filter(a => a.doc_id === assignmentId);

    return assignmentDetails && assignmentDetails.length > 0
    ? assignmentDetails[0]
    : null;
  }
}

export default ClassAssignmentStore;
