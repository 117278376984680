import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ViewLoader from '../../components/Loading/View';
import { observer, useLocalStore } from 'mobx-react';
import { when } from 'mobx';
import { storesContext } from '../../stores';
import SupportCaseStore, {SupportCaseContext} from './store';
import './styles.scss';
import Form from '../JSONSchemaForm';
import ResponseModal from '../ResponseModal';
import { getCourseTitleByLanguage, updateJsonSchemaFormSelectInput } from '../../services/UtilityService';

const SupportCase = () => {
  const { t, i18n } = useTranslation();
  const title = t('Open a Support Case');
  const rootStore = useContext(storesContext);
  
  const impactFields = {
    Medium: 3,
    High: 2,
  };

  const schemaForm = {
    type: 'object',
    required: ['title', 'description', 'subcategory', 'impact'],
    properties: {
      title: {
        type: 'string',
        title: t('Issue Title'),
      },
      description: {
        type: 'string',
        title: t('Details'),
      },
      courseCode: {
        type: 'string',
        title: t('Please select the impacted offering'),
        enum: ['Loading catalog'],
        enumNames: [t('Loading catalog...')],
      },
      classId: {
        type: 'string',
        title: t('Please select the impacted class'),
        enum: ['Loading class'],
        enumNames: [t('Loading class...')],
      },
      subcategory: {
        type: 'string',
        title: t('Support Category'),
        enum: [
          'Account or Enrollment Issues',
          'Learning Platform',
          'Lab Environment',
          'Course Content',
        ],
        enumNames: [
          t('Account or Enrollment Issues'),
          t('Learning Platform'),
          t('Lab Environment'),
          t('Course Content'),
        ],
      },
      impact: {
        type: 'string',
        title: t('Impact Level'),
        enum: Object.keys(impactFields),
        enumNames: [t('Medium'), t('High')],
        default: t('Medium'),
      },
      collected: {
        type: 'object',
        title: '',
        properties: {
          username: {
            type: 'string',
            title: t('Username'),
            default: rootStore.userStore.username,
          },
          name: {
            type: 'string',
            title: t('Name'),
            default: rootStore.userStore.name || t('Not set'),
          },
          email: {
            type: 'string',
            title: t('Email'),
            default: rootStore.userStore.email,
          },
          academy_name: {
            type: 'string',
            title: t('Academy'),
            default: rootStore.academyStore.academyName,
          },
        },
      },
    },
  };

  const uiSchema = {
    description: {
      'ui:widget': 'textarea',
    },
    courseCode: {
      'ui:placeholder': t('Select from catalog...'),
      ...(rootStore.userStore.isStudent && {'ui:widget': 'hidden'}),
    },
    classId: {
      'ui:placeholder': t('Select from class...'),
    },
    subcategory: {
      'ui:placeholder': t('Select category...'),
    },
    collected: {
      username: {
        'ui:readonly': true,
      },
      name: {
        'ui:readonly': true,
      },
      email: {
        'ui:readonly': true,
      },
      country: {
        'ui:readonly': true,
      },
      academy_name: {
        'ui:readonly': true,
      },
    },
  };

  const store = useLocalStore(
    () => new SupportCaseStore(rootStore, schemaForm, uiSchema, impactFields),
  );

  useEffect(() => {

    when(
      () => rootStore.catalogStore.catalogReady &&
      rootStore.userStore.isLoggedIn &&
      !rootStore.userStore.isStudent,
      async () => {        
        if (rootStore.catalogStore.subscriptionCatalog) {
          rootStore.catalogStore.filterEntries();
        }        
        let selectCourseOptions = {
          enum: [],
          enumNames: [],
        };
        rootStore.catalogStore.filters.course.forEach(
          (entry) => {
            const entry_title = getCourseTitleByLanguage(entry.translations, i18n.language) || entry.title;           
            selectCourseOptions.enum.push(entry.slug);
            selectCourseOptions.enumNames.push(`${entry.slug?.toUpperCase()}: ${entry_title}`);
          }
        );
        selectCourseOptions.enum.push('Not Applicable');
        selectCourseOptions.enumNames.push(t('Not Applicable'));
        store.schemaForm = updateJsonSchemaFormSelectInput(store.schemaForm, 'courseCode', selectCourseOptions);        
      },
    );

    when(
      () => rootStore.classStore.ready &&
      rootStore.userStore.isLoggedIn,
      async () => {
        store.classes = rootStore.classStore.filteredClasses;
        let selectClassOptions = {
          enum: [],
          enumNames: [],
        };    
        store.classes.forEach(
          (c) => {
            const displayStartDate = rootStore.classStore.formatDate(
              rootStore.classStore.parseDate(c.start_date),
            );
            const displayEndDate = rootStore.classStore.formatDate(
              rootStore.classStore.parseDate(c.end_date),
            );
            selectClassOptions.enum.push(`${c.doc_id}`);
            selectClassOptions.enumNames.push(`${c.course_title} (${displayStartDate} - ${displayEndDate})`);
          }
        );    
        selectClassOptions.enum.push('Not Applicable');
        selectClassOptions.enumNames.push(t('Not Applicable'));
        store.schemaForm = updateJsonSchemaFormSelectInput(store.schemaForm, 'classId', selectClassOptions);
      },
    );

  }, [store, rootStore, t, i18n]);

  return (
    <React.Suspense fallback={<ViewLoader />}>
      <h3 className="view-page-title">{t(`${title}`)}</h3>
      <div className="supportcase-form-wrapper">
        <div className="supportcase-intro">
          <p>
            {t(
              'Fill out this form and submit to create a ticket with our support team. After submitting, you will receive an email with a Service Request Number, and assistance will continue through email.',
            )}
          </p>
        </div>
        <div className="supportcase-form-container">
          <Form
            schema={store.schemaForm}
            formData={store.formData}
            uiSchema={store.uiSchema}
            onChange={form => {
              store.formData = form.formData;
            }}
            onSubmit={() => {
              store.submitSupportCase();
            }}
            submitting={store.submitting}
            showCancelBtn={true}
            onCancel={() => {
              store.cancelSupportCaseForm();
            }}
            disableActionBtns={!store.hasAnyInput}
          />
        </div>                
      </div> 
      {(store.submitSuccess || store.submitFailure) && (
        <SupportCaseContext.Provider value={store}>
          <ResponseModal
            title={`${store.submitSuccess ? t('Success!') : t('Error!')}`}
            message={`${
              store.submitSuccess
                ? t('Your support case has been submitted successfully.')
                : t('Your case has not been submitted, please try again later.')
            }`}
            onHideModal={store.dismissAlert}
            closeBtnText={t('Ok')}
          />
        </SupportCaseContext.Provider>
      )}
    </React.Suspense>
  );
};

export default observer(SupportCase);
