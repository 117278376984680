/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';
import { KalturaWidget } from '@ole-ui/ole-ui-components';
import { storesContext } from '../../../stores';
import { KALTURA_CONFIGS } from '../../../config/constants';
import useLocalStorageState from '../../../hooks/useLocalStorageState';
import { createKalturaSession } from '../../../services/KalturaService';
import { generateUTCTimestamp } from '../../../services/UtilityService';
import CollapseIcon from '../../../img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from '../../../img/icons/web-icon-caret-thin-down.svg';
import ViewLoader from '../../Loading/View';
import './styles.scss';

const VideoWidget = ({ entryId, playerId, isEmbeddedVideo, isPlaylist }) => {
  const [
    IsVideoContainerExpanded,
    setVideoContainerExpanded,
  ] = useLocalStorageState('videoWidgetCollapseState', true);
  const [isVideoPlayerLaunched, setVideoPlayerLaunched] = useState(true);
  const [isPlayerPictureInPicture, setPlayerPictureInPicture] = useState(false);

  const { userStore } = useContext(storesContext);
  const [player, setPlayer] = useState({});
  const [kalturaSession, setKalturaSession] = useLocalStorageState('kaltura_session', localStorage.getItem('kaltura_session'));
  const [kalturaSessionExpiry, setKalturaSessionExpiry] = useLocalStorageState('kaltura_session_expiry', localStorage.getItem('kaltura_session_expiry'));
  const [sessionCreationInProgress, setSessionCreationInProgress] = useState(false);
  const [skipKalturaSession, setSkipKalturaSession] = useState(false);

  const attachVideoEvents = videoPlayer => {
    videoPlayer.addEventListener('enterpictureinpicture', () => {
      setPlayerPictureInPicture(true);
    });

    videoPlayer.addEventListener('leavepictureinpicture', () => {
      setPlayerPictureInPicture(false);
    });
  };

  const removeVideoEvents = React.useCallback(() => {
    if (player && player.removeEventListener) {
      player.removeEventListener('enterpictureinpicture');

      player.removeEventListener('leavepictureinpicture');
    }
  }, [player]);

  const onVideoPlayerLaunch = videoPlayer => {
    attachVideoEvents(videoPlayer);
    setPlayer(videoPlayer);
    setVideoPlayerLaunched(true);
  };

  const { t } = useTranslation();

  const onVideoHandlerClick = () => {
    if (!isVideoPlayerLaunched) return;
    setVideoContainerExpanded(!IsVideoContainerExpanded);
    if (player && player.pause) {
      player.pause();
    }
  };

  useEffect(() => {
    when(
      () => entryId && userStore.isLoggedIn && !sessionCreationInProgress,
      async () => {
        try {
          setSessionCreationInProgress(true);
          var current_time = generateUTCTimestamp(new Date());
          if(!kalturaSession || (
            kalturaSession && 
            kalturaSessionExpiry && 
            current_time > kalturaSessionExpiry)){            
              const ks = await createKalturaSession();            
              if (ks) {
                setKalturaSession(
                  kaltura_session => ks,
                );
                setKalturaSessionExpiry(
                  kaltura_session_expiry => generateUTCTimestamp(new Date(new Date()
                  .setHours(new Date()
                  .getHours() + 23))),
                );
                setSkipKalturaSession(false);
                setSessionCreationInProgress(false);
              }
          }
        } catch (error) {
          setKalturaSession(
            kaltura_session => '',
          ); 
          setKalturaSessionExpiry(
            kaltura_session_expiry => '',
          );       
          setSkipKalturaSession(true);
          setSessionCreationInProgress(false);
        }
      },
    );
    // when leaving this video
    return () => {
      setPlayerPictureInPicture(false);
      removeVideoEvents();
    };
  }, [entryId, userStore, removeVideoEvents, sessionCreationInProgress, kalturaSession, setKalturaSession, kalturaSessionExpiry, setKalturaSessionExpiry]);

  return (
    <React.Fragment>
      <div className="video-container">
        <div
          className={isEmbeddedVideo ? 'd-none' : 'video-handler'}
          onClick={onVideoHandlerClick}
          role="button"
          tabIndex={0}
        >
          <div>{isVideoPlayerLaunched ? t('VIDEO CLASSROOM') : '...'}</div>
          <div className="icon-container">
            <SVG src={IsVideoContainerExpanded ? CollapseIcon : ExpandIcon}>
              {IsVideoContainerExpanded ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </div>        
        <div
          className={
            IsVideoContainerExpanded
              ? (isPlayerPictureInPicture && 'picture-in-picture-video') ||
                'expand-video'
              : 'collapse-video'
          }
        >
          {(kalturaSession || skipKalturaSession) ? (
            <KalturaWidget
              partnerId={KALTURA_CONFIGS.partnerId}
              entryId={entryId}
              playerId={playerId}
              onPlayerLaunched={onVideoPlayerLaunch}
              session={kalturaSession}
              isPlaylist={isPlaylist}
            />
          ) : (
            <div className='video-widget-loader'>
              <ViewLoader color={isEmbeddedVideo ? '#ffffff' : '#2a2a2a'} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

VideoWidget.propTypes = {
  entryId: PropTypes.string.isRequired,
  playerId: PropTypes.number,
  isEmbeddedVideo: PropTypes.bool,
  isPlaylist: PropTypes.bool,
};

VideoWidget.defaultProps = {
  playerId: undefined,
  isEmbeddedVideo: false,
  isPlaylist: false,
};

export default VideoWidget;