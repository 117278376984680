import { get } from 'axios';
import { SERVER_CONFIG_API } from '../config/constants';

export const getServerConfig = async () => {
	try {
		const config = await get(SERVER_CONFIG_API);
		return config?.data?.config;
	} catch (error) {
		throw new Error(error);
	}
};

export default getServerConfig;
