import { action, observable, computed } from 'mobx';
import { getAssignmentByClassId } from '../../../services/ClassAssignmentService';
import { getClassDetailbyId } from '../../../services/ClassService';

import { ASSIGNMENT_TYPES } from '../../../config/constants';

class StudentAssignmentListStore {
  @observable loaded = false;

  @observable classDetail = null;
  @observable assignmentDetails = null;

  constructor(classStore) {
    this.classStore = classStore;
  }

  @computed get classId() {
    return this.classDetail?.doc_id;
  }
  @computed get assignmentId() {
    return this.assignmentDetails?.doc_id;
  }
  @computed get quizQuestions() {
    // return questions that are not submitted by students yet
//const uuidQuizResponse = this.quizResponses?.map((r) => r?.question_id);
    return this.assignmentDetails?.quiz && this.assignmentDetails?.quiz.length
      ? this.assignmentDetails.quiz?.map(q => {
          let quizResponses = this.quizResponses?.filter(qr =>qr.question_id === q.uuid);
          q.quiz_response = quizResponses && quizResponses?.length ? quizResponses[0].quiz_response:[];
          return q;
        
      })
      : [];
  }
  @computed get quizResponses() {
    return this.assignmentDetails?.grades?.student_response?.filter(
      (r) => r.question_type === ASSIGNMENT_TYPES.quiz.question_type,
    );
  }

  @computed get hasSubmittedAllQuiz() {
    
    return (
      this.assignmentDetails?.quiz?.length > 0 &&
      this.quizResponses?.length > 0 &&
      this.quizResponses?.length >= this.assignmentDetails?.quiz?.length
    );
  }
  @computed get hasStudentSubmittedQuiz() {
   
   const quizResponses = this.quizResponses; 
    return quizResponses ? quizResponses?.length > 0 : false;
  }



  @computed get hasStudentSubmittedKC() {
    const kcResponses = this.kcResponses; 
    return kcResponses ? kcResponses?.length > 0 : false;
  }

  @computed get kcResponses() {
    return this.assignmentDetails?.grades?.student_response?.filter(
      (r) => r.question_type === ASSIGNMENT_TYPES.knowledgeCheck.question_type,
    );
  }
  @computed get kcQuestions() {
    // return challenges that are not submitted by students yet
   // const uuidQuizResponse = this.kcResponses?.map((r) => r?.question_id);
    return this.assignmentDetails?.code_challenge && this.assignmentDetails?.code_challenge.length
      ? this.assignmentDetails?.code_challenge.map(c => {
        let codeResponse = this.kcResponses?.filter(cr =>cr.question_id === c.uuid);
        c.code_response = codeResponse && codeResponse?.length ? codeResponse[0].code_response:'';
        return c;
      
    })
      : [];
  }
  @action getClassDetailbyId = async (classId) => {
    try {
      const classDetail = this.classStore.getClassDetailbyId(classId);
      if (classDetail) {
        this.classDetail = classDetail;
      } else {
        this.classDetail = await getClassDetailbyId(classId);
      }
    } catch {
        this.classDetail =null;
    } finally {
      this.hasClassData = true;
      return this.classDetail;
    }
  };

  @action setAssignment = async (classId) => {
    try {
      
      if (classId !== undefined) {
        this.assignmentDetails = await getAssignmentByClassId(classId);
      }
    } catch (e) {
      this.assignmentDetails = null;
    } finally {
      this.loaded = true;
      return this.assignmentDetails;
    }
  };
}

export default StudentAssignmentListStore;
