import { createContext } from 'react';
import { observable, action } from 'mobx';
import {
  getAcademyDetails,
  referAnAcademy,
} from '../../services/AcademyService';
import { getInstructorDetails } from '../../services/InstructorService';

class HelpStore {
  constructor(classStore) {
    this.classStore = classStore;
  }

  @observable academyDetails = null;

  @observable loaded = false;

  @observable academyLoaded = false;

  @observable studentLoaded = false;

  @observable isTourDisplayed = false;

  @observable showReferModal = false;

  @observable submitting = false;

  @observable submitSuccess = false;

  @observable instructor = null;

  @observable submitFailure = false;

  @observable referDetails = {
    university_name: '',
    contact_person: '',
    email: '',
    phone: '',
    website: 'N/A',
  };

  @observable classId = undefined;

  @action submitRefer = async () => {
    this.submitting = true;
    const id = this.academyDetails.doc_id;
    const data = this.referDetails;
    const result = await referAnAcademy(id, data);

    if (result !== `Couldn't refer academy`) {
      this.submitSuccess = true;
      this.cancelForm();
    } else {
      this.submitFailure = true;
      this.showReferModal = false;
    }

    this.submitting = false;
  };

  @action submitReferSuccess = () => {
    console.error('Success, show a modal ', this.referDetails);
  };

  @action addReferInfo = (e, detail) => {
    const value = e.currentTarget.value;

    switch (detail) {
      case 'university':
        this.referDetails.university_name = value;
        break;
      case 'person':
        this.referDetails.contact_person = value;
        break;
      case 'email':
        this.referDetails.email = value;
        break;
      case 'phone':
        this.referDetails.phone = value;
        break;
      case 'website':
        this.referDetails.website = value;
        break;

      default:
        break;
    }
  };

  @action cancelForm = () => {
    this.referDetails = {
      university_name: '',
      contact_person: '',
      email: '',
      phone: '',
      website: 'N/A',
    };
    this.toggleReferModal();
  };

  @action toggleReferModal = () => {
    this.showReferModal = !this.showReferModal;
  };

  @action toggleTour = () => {
    this.isTourDisplayed = !this.isTourDisplayed;
  };

  @action getAcademyDetails = async () => {
    try {
      const details = await getAcademyDetails();
      if (details) {
        this.academyDetails = details[0];
      }
    } catch (error) {
      console.error('Error fetching academy details:', error);
    } finally {
      this.academyLoaded = true;
    }
  };

  @action getInstructorInfo = async (classId) => {
    try {
      const result = await getInstructorDetails(classId);
      if (result) {
        this.instructor = result;
      }
    } catch (error) {
      console.error('Error fetching instructor details:', error);
      this.loaded = false;
    }
  };

  @action dismissAlert = () => {
    if (this.submitFailure) {
      this.submitFailure = !this.submitFailure;
    } else {
      this.submitSuccess = !this.submitSuccess;
    }
  };
}

export default HelpStore;

export const HelpContext = createContext(null);
