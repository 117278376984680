import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const CircularIcon = ({ iconName, backgroundColor, imageStyle }) => {
  const color = backgroundColor || 'var(--cyan)';
  return (
    <React.Fragment>
      <div className="circle-icon">
        <div className="container" style={{ backgroundColor: color }}>
          <div className={`icon-${iconName} image`} style={imageStyle} />
        </div>
      </div>
    </React.Fragment>
  );
};

CircularIcon.propTypes = {
  backgroundColor: PropTypes.string,
  iconName: PropTypes.string,
  imageStyle: PropTypes.object,
};

export default CircularIcon;
