import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ViewLoader from '../../components/Loading/View';
import Form from 'react-jsonschema-form';

function JSONSchemaForm(props) {
  const { t } = useTranslation();
  return (
    <Form {...props}>      
      <Button bsStyle="info" bsSize="large" type="submit" disabled={props.submitting || props.disableActionBtns}>
        {props.submitting ? (
          <ViewLoader color="white" />
        ) : (
        t('Submit'))}
      </Button>
      {props.showCancelBtn && (
         <Button
          className="btn-white btn-outline-dark ml-2"
          onClick={props.onCancel}
          variant="outline-dark"
          disabled={props.submitting || props.disableActionBtns}
        >
          {t('Cancel')}
        </Button>
      )}
    </Form>
  );
}

JSONSchemaForm.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default JSONSchemaForm;
