import { observable, action, computed } from 'mobx';

import { roundData } from '../../../services/UtilityService';
class ClassStudentDetailsStore {
  @observable classDetail = null;
  @observable pageTitle = null;
  @observable assignmentDetails = null;
  @observable ready = false;
  @observable showGradeInput = false;

  @observable studentDetailsLoading = false;
  @observable studentDetails = null;
  constructor(classStore, classAssignmentStore, classStudentStore) {
    this.classStore = classStore;
    this.classAssignmentStore = classAssignmentStore;
    this.classStudentStore = classStudentStore;
  }
  @action toggleGradeInput = () => {
    this.showGradeInput = !this.showGradeInput;
  };
  @computed get assignmentCodeChallenges() {
    return this.assignmentDetails?.code_challenge &&
      this.assignmentDetails?.code_challenge?.length
      ? this.assignmentDetails?.code_challenge
      : [];
  }

  @computed get quizScore() {
    return this.studentDetails?.grades?.quiz_score
      ? roundData(this.studentDetails?.grades?.quiz_score)
      : 0;
  }
  @computed get knowledgeCheckScore() {
    return this.studentDetails?.grades?.code_challenge_score
      ? roundData(this.studentDetails?.grades?.code_challenge_score)
      : 0;
  }

  @computed get totalScore() {
    return this.studentDetails?.grades?.total_score
      ? roundData(this.studentDetails?.grades?.total_score)
      : 0;
  }

  @computed get studentGrades() {
    
    return this.studentDetails?.grades ? this.studentDetails?.grades : null;
  }

  @action setStudentDetails = async (
    classId,
    studentUsername,
    force = false,
  ) => {
    if (this.studentDetails !== null && !force) {
      return this.studentDetails;
    }
    if (this.studentDetailsLoading === true) return;
    try {
      this.studentDetails = await this.classStudentStore.getStudentDetailsByUserName(
        classId,
        studentUsername,
      );

      this.studentDetailsLoading = false;
    } catch (e) {
      console.error(e);
      this.studentDetails = null;
    } finally {
      this.studentDetailsLoading = false;
      return this.studentDetails;
    }
  };

  @action setAssignment = async (classId) => {
    try {
      if (classId !== undefined) {
        this.assignmentDetails = this.classAssignmentStore.getAssignmentByClassId(
          classId,
        );
      }
    } catch (e) {
      this.assignmentDetails = null;
    } finally {
      return this.assignmentDetails;
    }
  };
}

export default ClassStudentDetailsStore;
