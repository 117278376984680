import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Card, Table } from 'react-bootstrap';
import VMRow from './VMRow';

@withTranslation()
@observer
class RavelloLabComponent extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    labComponent: PropTypes.object.isRequired,
  };

  getRows() {
    const { context } = this.props.labComponent;
    if (!(context && context.vm_summary)) {
      return null;
    }
    const action = this.doCommand;
    const elems = context.vm_summary.map(vm => {
      return <VMRow key={vm.id} action={action} vmSummary={vm} />;
    });
    return elems;
  }

  doCommand = async (command, params) => {
    const result = await this.props.action(command, params);
    if (command === 'get_vnc_url') {
      if (result.data && result.data.results) {
        result.data.results.forEach(res => {
          if (!res.component) {
            return;
          }
          if (!res.component.driver === this.context.driver) {
            return;
          }
          const vncUrl = res.result.url;
          if (vncUrl) {
            window.open(vncUrl);
          }
        });
      }
    }

    return result;
  };

  render() {
    const title = this.props.labComponent.slug;
    return (
      <Card header={title} className="lab-controls-vm-panel">
        <Table striped condensed>
          <tbody>{this.getRows()}</tbody>
        </Table>
      </Card>
    );
  }
}

export default RavelloLabComponent;
