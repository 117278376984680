import React, { useContext } from 'react';
import { storesContext } from '../../../../../../stores';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import UserInfoFormStore from './store';
import ButtonWithLoading from '../../../../../Button/ButtonWithLoading';

const UserInfoForm = ({ onUserInfoUpdate, connectLTI=false }) => {
  const { t } = useTranslation();
  const { userStore } = useContext(storesContext);
  const store = useLocalStore(() => new UserInfoFormStore(userStore, onUserInfoUpdate));

  return (
    <React.Fragment>
      <p>      
        { connectLTI ?
          t('Before you can connect external course, you need to set your personal information up.',)
          : t('Before you can purchase lab hours, you need to set your personal information up.',)
        }
      </p>
      <Form>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>{t('Email address')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('Email address')}
            defaultValue={''}
            value={store.defaultEmail}
            onChange={(e) => (store.defaultEmail = e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupName">
          <Form.Label>{t('First Name')}</Form.Label>
          <Form.Control
            placeholder={t('First Name')}
            value={store.firstName}
            onChange={(e) => (store.firstName = e.target.value)}
          />
          <Form.Label>{t('Last Name')}</Form.Label>
          <Form.Control
            placeholder={t('Last Name')}
            value={store.lastName}
            onChange={(e) => (store.lastName = e.target.value)}
          />
        </Form.Group>
        <ButtonWithLoading
          buttonProps={{
            disabled: store.updating,
            type: 'submit',
            variant: 'secondary',
            onClick: (e) => {
              e.preventDefault();
              store.updateInfo();
            },
          }}
          loading={store.updating}
        >
          {t('Update')}
        </ButtonWithLoading>
        <span className="text-danger">{store.errorMessage}</span>
      </Form>
    </React.Fragment>
  );
};

export default observer(UserInfoForm);
