import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import ViewLoader from '../../Loading/View';
import './styles.scss';
import sortAscendIcon from '../../../img/icons/sort_asc.svg';
import sortDescendIcon from '../../../img/icons/sort_desc.svg';
import sortNeutralIcon from '../../../img/icons/sort_neutral.svg';
import { PropTypes } from 'prop-types';
import SVG from 'react-inlinesvg';
import AcademyStudentStore from './store';
import { Accordion } from 'react-bootstrap';
import AcademyStudentDetail from './AcademyStudentDetail';

const AcademyStudentsList = (props) => {
  const { students, onSortBy } = props;
  const { t } = useTranslation();
  const store = useLocalStore(() => new AcademyStudentStore());

  const renderSortButton = (fieldName) => {
    return (
      <span
        className="action-btn"
        role="button"
        tabIndex="0"
        onClick={() => {
          props.onSortBy(fieldName);
        }}
      >
        <SVG
          src={
            props.sortByField === fieldName
              ? (props.isSortAscending && sortAscendIcon) || sortDescendIcon
              : sortNeutralIcon
          }
        ></SVG>
      </span>
    );
  };

  const noResultMessage = () => {
    const noResultMsg = t(`No student exists for this class.`);
    return t(noResultMsg);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex flex-sm-row flex-column row-header row-student">

        <div
          onClick={() => {
            onSortBy('username');
          }}
          className="mr-auto p-2 column-student header"
        >
          <span>{t('Red Hat Network (RHN) ID')}</span>
          {renderSortButton('username')}
        </div>
        
        <div
          onClick={() => {
            onSortBy('name');
          }}
          className="mr-auto p-2 column-student header"
        >
          <span>{t('Name')}</span>
          {renderSortButton('name')}
        </div>

        <div
          onClick={() => {
            onSortBy('total_class');
          }}
          className="mr-auto p-2 column-student header"
        >
          <span>{t('Total Classes')}</span>
          {renderSortButton('total_class')}
        </div>

        <div
          onClick={() => {
            onSortBy('progress');
          }}
          className="mr-auto p-2 column-student header"
        >
          <span>{t('Progress')}</span>
          {renderSortButton('progress')}
        </div>

        <div
          onClick={() => {
            onSortBy('submissions');
          }}
          className="mr-auto p-2 column-student header"
        >
          <span>{t('Submissions')}</span>
          {renderSortButton('submissions')}
        </div>

        <div
          onClick={() => {
            onSortBy('last_accessed');
          }}
          className="mr-auto p-2 column-student header"
        >
          <span>{t('Last Accessed')}</span>
          {renderSortButton('last_accessed')}
        </div>
      </div>
    );
  };

  const renderStudents = () => {
    return (
      <React.Suspense fallback={<ViewLoader />}>
        <React.Fragment>
          <div className="students-list">
            {renderHeader()}
            
            {students.length > 0 ? (
              <Accordion>
                {students.map((student) =>
                  <AcademyStudentDetail 
                    key={student.id} 
                    student={student}
                    selectedStudentId={store.selectedStudentId} 
                    onRowClick={store.setSelectedStudentId}>
                  </AcademyStudentDetail>
                )}
              </Accordion>
            ) : (
              <div className="no-progress-msg">
                <p>{noResultMessage()}</p>
              </div>
            )}
          </div>
        </React.Fragment>
        {/* <!-- Confirmation Modal/Dialogue for removing a student--> */}
        
      </React.Suspense>
    );
  };
  return renderStudents();
};

AcademyStudentsList.propTypes = {
  onRemoveStudents: PropTypes.func,
  onSortBy: PropTypes.func,
  classId: PropTypes.string,
  students: PropTypes.array,

  sortByField: PropTypes.string,
  isSortAscending: PropTypes.bool,
  onSelectStudents: PropTypes.func,
};

AcademyStudentsList.defaultProps = {
  onRemoveStudents: () => {},
  onSortBy: () => {},
  classId: '',
  students: [],
  sortByField: 'name',
  isSortAscending: false,
  onSelectStudents: () => {},
};

export default observer(AcademyStudentsList);