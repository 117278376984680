import React, { useContext } from 'react';

import Routes from '../../config/routes';
import { storesContext } from '../../stores';
import SideBar from './SideBar';
import Header from '../Header';
import Footer from '../Footer';
import './styles.scss';
import PropTypes from 'prop-types';

import { routeNode, RouteView } from 'react-mobx-router5';
import DisplayAnnouncements from '../Announcement/DisplayAnnouncements';

const routeNodeName = ''; // '' because root node

const Layout = (props) => {
  const { userStore, routerStore } = useContext(storesContext);

  const routes = new Routes({
    userStore: userStore,
    routerStore: routerStore,
  });

  return (
    <React.Fragment>
      <Header />
        <div className="container-fluid">
          <div className="row flex-xl-nowrap">
            <SideBar />
            <main className="main-content" role="main">
              {userStore.isLoggedIn && <DisplayAnnouncements></DisplayAnnouncements>}
              {props.route && (
                <RouteView
                  route={props.route}
                  routes={routes.routes}
                  routeNodeName={routeNodeName}
                />
              )}
            </main>
          </div>
        </div>
      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  route: PropTypes.object, // observable
  routerStore: PropTypes.object,
};

Layout.defaultProps = {
  route: {},
};

export default routeNode(routeNodeName)(Layout);
