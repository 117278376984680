/**
 * @file Help component for displaying help cards
 * @author Attila farkas <afarkas@redhat.com>
 */

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { storesContext } from '../../stores';
import HelpCard from '../Cards/HelpCard';
import HelpStore, { HelpContext } from './store';
import ViewLoader from '../Loading/View';
import { observer, useLocalStore } from 'mobx-react';
import { when } from 'mobx';
import { Button } from 'react-bootstrap';
import ReferModal from './ReferModal';
import ResponseModal from '../ResponseModal';
import VideoWidget from '../../components/Video/Widget';
import { KALTURA_CONFIGS, RHA_FAQ_URL } from '../../config/constants';

import './styles.scss';

const Help = () => {
  const { routerStore, userStore, classStore } = useContext(storesContext);
  const store = useLocalStore(() => new HelpStore(classStore));
  const { t } = useTranslation();
  const {
    academyDetails,
    toggleReferModal,
    toggleTour,
    submitReferSuccess,
    submitFailure,
    submitSuccess,
    dismissAlert,
  } = store;

  useEffect(() => {
    // Populate academy details
    when(
      () => !store.loaded && classStore.ready && userStore.isLoggedIn,
      async () => {
        if (!userStore.isStudent) {
          await store.getAcademyDetails();
        }
        store.loaded = true;
      },
    );
  }, [userStore, classStore, store]);

  useEffect(() => {
    // Populate instructor details
    when(
      () => !store.studentLoaded && userStore.isStudent && classStore.ready,
      async () => {
        const classID = classStore.classes[0].doc_id;
        await store.getInstructorInfo(classID);
        store.studentLoaded = true;
      },
    );
  }, [userStore, classStore, store]);

  return (
    <React.Fragment>
      <h3 className="view-page-title">{t('Get Support')} </h3>

      {store.loaded ? (
        <React.Fragment>
          <div className="help-container">
            
            <HelpCard
              top={t('Red Hat Academy Platform Technical Support')}
              hasButton
              buttonText={t('Open a Support Case')}
              onButtonClick={() => {
                routerStore.navigate('submit-support');
              }}
            />

            {userStore.isInstructor && (
              <React.Fragment>
                <HelpCard
                  top={t('Red Hat Academy Learning Platform Administrator')}
                  middle={`${academyDetails.first_name} ${academyDetails.last_name}`}
                  email={academyDetails.email}
                  bottom={academyDetails.phone}
                />
              </React.Fragment>
            )}

            {/* {(userStore.isInstructor || userStore.isAcademyAdmin) && (
              <React.Fragment>
                <HelpCard
                  top="Business Developer Manager"
                  middle="nein"
                  email="email"
                  bottom="huhu"
                />
              </React.Fragment>
            )} */}
            {/* TODO: Waiting on backend changes*/}

            {userStore.isStudent && (
              <HelpCard
                top={t('Red Hat Academy Learning Platform')}
                middle={t('Feature walkthrough')}
                hasButton
                buttonText={t('Watch tutorial')}
                onButtonClick={() => {
                  toggleTour();
                }}
              />
            )}

            {userStore.isStudent &&
              (store.instructor !== null ? (
                <React.Fragment>
                  <HelpCard
                    top={t('Your instructor')}
                    middle={
                      (store.instructor?.first_name || '') +
                      ' ' +
                      (store.instructor?.last_name || '')
                    }
                    email={
                      store.instructor?.emails?.length > 0
                        ? store.instructor?.emails[0]
                        : ''
                    }
                  />
                </React.Fragment>
              ) : (
                <ViewLoader />
              )
            )}                          

            {userStore.isInstructor && (
              <React.Fragment>
                <HelpCard
                  top={t('Refer an Academy')}
                  middle={t(
                    'Submit a short form to help another institution become a Red Hat Academy.',
                  )}
                  hasButton
                  buttonText={t('Refer')}
                  onButtonClick={() => {
                    toggleReferModal();
                  }}
                />
              </React.Fragment>
            )}
              
            <HelpCard
              top={t('Frequently Asked Questions')}
              middle={t(
                'Explore answers to frequently asked questions.',
              )}
              hasButton
              buttonText={t('View FAQ')}
              onButtonClick={() => {
                window.open(RHA_FAQ_URL, "_blank");
              }}
            />
              
          </div>
        </React.Fragment>
      ) : (
        <ViewLoader />
      )}

      {store.loaded && (userStore.isInstructor ||
        userStore.isAcademyAdmin) && (
          <React.Fragment>
          <h3 className="view-page-title">{t('Get Started with Red Hat Academy')} </h3>
          <div className="help-container">
          <HelpCard
            top={t('Onboarding & Enablement Tutorials')}
            middle={t('Your guide to understanding the Learning Platform, curriculum and more.')}
            hasButton
            buttonText={t('Get Started')}
            onButtonClick={() => {
              toggleTour();
            }}
          />
          </div>
        </React.Fragment>
      )}

      {store.isTourDisplayed && (
        <div className="tour-wrapper">
          <div className="overlay">
            <div className="overlay-content">                
              <VideoWidget
                isEmbeddedVideo={true}
                playerId={KALTURA_CONFIGS.videoTutorial.uiConfId}
                entryId={`${
                  userStore.isStudent ? KALTURA_CONFIGS.videoTutorial.studentTutorialEntryId : KALTURA_CONFIGS.videoTutorial.admininstructorTutorialEntryId
                }`}
                isPlaylist={!userStore.isStudent}
              />
              <div className="text-center">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    store.toggleTour();
                  }}
                >
                  {t('Close video')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {store.showReferModal && (
        <HelpContext.Provider value={store}>
          <ReferModal
            toggleReferModalProp={toggleReferModal}
            onReferSubmit={submitReferSuccess}
          />
        </HelpContext.Provider>
      )}

      {(submitSuccess || submitFailure) && (
        <HelpContext.Provider value={store}>
          <ResponseModal
            title={`${submitSuccess ? 'Success!' : 'Something went wrong'}`}
            message={`${
              submitSuccess
                ? 'Thank you for referring an academy.'
                : 'Oops! There was an issue with referring an academy.'
            }`}
            onHideModal={dismissAlert}
          />
        </HelpContext.Provider>
      )}
    </React.Fragment>
  );
};

export default observer(Help);
