import { action, observable, computed } from 'mobx';
import { getAcademyDetails, getStudents } from '../../services/AcademyService';
import moment from 'moment';
import { INITIAL_YEAR } from '../../config/constants';
class AcademyStudentViewStore {

  @observable loaded = false;
  @observable downloading = false;
  @observable students = [];
  @observable _currentPage = 1;
  @observable studentsPerPage = 10;


  @computed get academyId() {
    return (this.academyDetails && this.academyDetails.doc_id) || '';
  }
  @computed get academyName() {
    return (this.academyDetails && this.academyDetails.doc_id) || '';
  }  

  @action getAcademyDetails = async (academyId = null) => {
    try {
      const details = await getAcademyDetails(academyId);
        this.academyDetails = details[0] || details;
    } catch (error) {
      this.hasRelatedData = true;
    }
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return Math.ceil(this.students.length / this.studentsPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedStudents() {
    const startIndex = (this.currentPage - 1) * this.studentsPerPage;
    return this.students.slice(startIndex, startIndex + this.studentsPerPage);
  }

  @action onSortBy = (sortBy) => {
    this.sortByField = sortBy;
    this.isSortAscending = !this.isSortAscending;

    this.students = this.sortStudents(this.students);
  };

  @action sortStudents = (students) => {
    
    if (!this.isSortAscending) {
      return students
        .slice()
        .sort((a, b) =>
          this.sortByField === 'last_accessed'
            ? moment(b[this.sortByField] ? moment(b[this.sortByField]).toDate() : moment().subtract(INITIAL_YEAR, 'years')).diff(
              moment(a[this.sortByField] ? moment(a[this.sortByField]).toDate() : moment().subtract(INITIAL_YEAR, 'years')
            ))
            : this.sortByField === 'username' || this.sortByField === 'name' || this.sortByField === 'submissions'
            ? b[this.sortByField].localeCompare(a[this.sortByField])
            : b[this.sortByField] - a[this.sortByField],
        );
    }

    return students
      .slice()
      .sort((a, b) =>
          this.sortByField === 'last_accessed'
            ? moment(a[this.sortByField] ? moment(a[this.sortByField]).toDate() : moment().subtract(INITIAL_YEAR, 'years')).diff(
              moment(b[this.sortByField] ? moment(b[this.sortByField]).toDate() : moment().subtract(INITIAL_YEAR, 'years')
            ))
            : this.sortByField === 'username' || this.sortByField === 'name' || this.sortByField === 'submissions'
            ? a[this.sortByField].localeCompare(b[this.sortByField])
            : a[this.sortByField] - b[this.sortByField],
        );
  };
  
  @action getAcademyStudents = async (academyId = null) => {
    try {
      const students = await getStudents(academyId);

      if(students) {
        this.students = Object.values(students).map(student => {
          
          return {
            id: student.summary.userinfo.uuid,
            username: student.summary.userinfo.username,
            name: `${student.summary.userinfo.first_name || ''} ${student.summary.userinfo.last_name || ''}`,
            total_class: student.summary.total_class,
            progress: student.summary.cumulative_progress,
            submissions: `${student.summary.submissions?.attempted_assignments}/${student.summary.submissions?.total_assignments}`,
            last_accessed: student.summary.last_accessed,
            academy_id: student.class_info[0]?.academy_id,
            class_info: student.class_info
          }
        });
      } else {
        this.students = [];
      }
    } catch (error) {
      this.hasRelatedData = true;
    } finally {
      this.loaded = true;
    }
  };
}

export default AcademyStudentViewStore;