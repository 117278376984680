import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import { getExamCollateral, requestPromoCode, getInstructorPromoEligibilityStatus } from '../services/ExamService';
import { EXAM_DEFAULT_VERSION } from '../config/constants';

class ExamStore {
  constructor(catalogStore, academyStore) {
    this.catalogStore = catalogStore;
    this.academyStore = academyStore;
  }

  @observable collateral = {};
  @observable apiMessage = {};
  @observable apiInProgress = false;
  @observable promoCodeRequested = false;
  @observable apiError = false;

  @observable code = '';

  @observable isEligibleForPromoCode = false;

  @computed get offering() {
    const versions = this.catalogStore.groupedCatalogEntries[this.code];
    let offering;
    if (versions && versions[EXAM_DEFAULT_VERSION]) {
      if(versions[EXAM_DEFAULT_VERSION]["exam"] && versions[EXAM_DEFAULT_VERSION]["exam"].length > 0 )
      offering = versions[EXAM_DEFAULT_VERSION]["exam"][0];
    }
    return offering;
  }

  @computed get academyId() {
    return this.academyStore.academyDetails?.doc_id;
  }

  @computed get examSlug() {
    return `${this.code}`;
  }
  @action getExamCollateral = async (code, language) => {
    this.apiPreCallHandler();
    try {
      let collateral = await getExamCollateral(code, language);
      if (!collateral) {
        collateral = await getExamCollateral(code);
      }
      this.collateral = collateral;
      return collateral;
    } catch (e) {
      this.apiErrorHandler(
        e,
        'Sorry, exam details cannot be loaded'
      );
      
    } finally {
      this.apiPostCallHandler();
      return {};
    }
  };
  @action requestPromoCode = async () => {
    this.apiPreCallHandler('Promo code request is in progress');
    try {
      await requestPromoCode(this.examSlug, this.academyId);
    } catch (e) {
      this.apiErrorHandler(
        e,
        'Sorry, requesting a promo code for the exam was not successfull'
      );
    } finally {
      this.promoCodeRequested = true;
      
      this.apiPostCallHandler();
    }
  };

  @action getInstructorPromoEligibilityStatus = async () => {
    try {
      const resp = await getInstructorPromoEligibilityStatus(this.academyId);
      if (resp) {
        this.isEligibleForPromoCode = resp.promo_eligibility_status;
      }
    } catch (error) {
      console.error(error);
      this.isEligibleForPromoCode = false;
    }
  };


  apiPreCallHandler = (apiMessage = '') => {
    this.apiMessage = apiMessage ;
    this.apiError = false;
    this.apiInProgress = true;
  };
  apiPostCallHandler = () => {
    this.apiInProgress = false;
  };
  apiErrorHandler = (e, errorMessage) => {
    this.apiError = true;
    this.apiMessage = errorMessage;
  };
}

export const ExamStoreContext = createContext(null);
export default ExamStore;
