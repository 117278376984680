import { get, post } from 'axios';
import { LAB_DEFINITION_API, RAVELLO_LAB } from '../config/constants';

const startLab = (command, courseSlug, blueprintId, applicationId) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      processData: false,
      dataType: 'json',
    };
    const params = {
      command,
      application_id: applicationId,
      course_slug: courseSlug,
      blueprint_id: blueprintId,
    };
    post(RAVELLO_LAB, params, config)
      .then((result) => resolve(result))
      .catch(() => reject());
  });

const getLabDefinition = async (courseSlug) => {
  const url = `${LAB_DEFINITION_API}${courseSlug}`;
  return get(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export { getLabDefinition, startLab };
export default startLab;
