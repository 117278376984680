import { get } from 'axios';
import { INSTRUCTOR_DETAILS } from '../config/constants';

export const getInstructorDetails = async (classID) => {
  try {
    const details = await get(
      `${INSTRUCTOR_DETAILS.replace('<class_id>', classID)}`,
    );
    return details.data;
  } catch (error) {
    throw new Error(error);
  }
};

export default { getInstructorDetails };
