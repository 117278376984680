import React from 'react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import SVG from 'react-inlinesvg';
import sortAscBtn from '../../img/icons/sort_btn_asc.svg';
import sortDescBtn from '../../img/icons/sort_btn_desc.svg';


const SortOrderButton = (props) => {
    
    const { onSortOrder, isSortAscending } = props;
    
    return (
        <span
          className="sort-order-btn ml-2"
          role="button"
          tabIndex="0"
          onClick={() => {
            onSortOrder();
          }}
        >
          <SVG src={(isSortAscending) ? sortAscBtn : sortDescBtn} ></SVG>
        </span>
    );
}

SortOrderButton.propTypes = {
    onSortOrder: PropTypes.func,
    isSortAscending: PropTypes.bool,
};

SortOrderButton.defaultProps = {
    onSortOrder: () => {},
    isSortAscending: false,
};

export default observer(SortOrderButton);