import { action, observable } from 'mobx';
import {
  getVocabularyList,
  getCountryList,
  getVocabularyByNamespace,
} from '../services/VocabularyService';

class VocabularyStore {
  @observable vocabulary = [];
  @observable countries = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action getVocabularyByNamespace = async (slug, language = 'en-US') => {
    const found = this.vocabulary.find(
      namespace => namespace.slug === slug && namespace.language === language,
    );

    if (found) return found.vocabulary;

    const namespace = await getVocabularyByNamespace(slug);

    if (namespace) this.vocabulary.push(namespace);

    return namespace || {};
  };

  @action getVocabularyList = async (language = 'en-US') => {
    let vocabulary = await getVocabularyList(language);

    if (vocabulary.length === 0) {
      vocabulary = await getVocabularyList('en-US');
    }

    this.vocabulary = vocabulary ? vocabulary : [];

    return this.vocabulary;
  };

  @action getCountryList = async (language = 'en-US') => {
    try {
      const countries = await getCountryList(language);
      
      this.countries = countries && countries.vocabulary ? countries.vocabulary.map(country => {
        return {
          label: country.display_name,
          value: country.token
        }
      })
      : [];
    
    } catch (error) {
      this.countries = [];
    }

    return this.countries;
  };
}

export default VocabularyStore;
