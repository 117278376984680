import { RouterStore } from 'mobx-router5';
import { reaction } from 'mobx';
import ConfigStore from './ConfigStore';
import UserStore from './UserStore';
import UIStore from './UIStore';
import { createContext } from 'react';
import VocabularyStore from './VocabularyStore';
import CatalogStore from './CatalogStore';
import ProgressStore from './ProgressStore';
import ClassStore from './ClassStore';
import CourseStore from './CourseStore';
import AlertStore from './AlertStore';
import ClassAssignmentStore from './ClassAssignmentStore';
import ClassStudentStore from './ClassStudentStore';
import AcademyStore from './AcademyStore';
import PollingStore from './PollingStore';
import RegionStore from './RegionStore';
import ResourcesStore from '../views/Resources/store';

class RootStore {
  constructor() {
    this.routerStore = new RouterStore();
    this.configStore = new ConfigStore();
    this.userStore = new UserStore(this);
    this.uiStore = new UIStore(this);
    this.vocabularyStore = new VocabularyStore(this);
    this.catalogStore = new CatalogStore(this);
    this.progressStore = new ProgressStore(this);
    this.classStore = new ClassStore(this);
    this.courseStore = new CourseStore(this);
    this.alertStore = new AlertStore(this);
    this.classAssignmentStore = new ClassAssignmentStore(this);
    this.classStudentStore = new ClassStudentStore(this);
    this.academyStore = new AcademyStore();
    this.regionStore = new RegionStore();
    this.pollingStore = new PollingStore();
    this.resourcesStore = new ResourcesStore();

    this.configStore.getServerConfig();
    
    reaction(
      () => this.userStore.isLoggedIn && this.userStore.role,
      () => {
        if (this.userStore.isLoggedIn && this.userStore.role) {
          if (
            this.userStore.isInstructor ||
            this.userStore.isAcademyAdmin ||
            this.userStore.isStudent
          ) {
            this.classStore.fetchClasses();
            this.academyStore.getAcademyDetails();
          }

          if (this.userStore.isInstructor || this.userStore.isAcademyAdmin) {
            this.catalogStore.getCatalogEntries();
            this.classAssignmentStore.fetchAssignments();
          }

          if(this.userStore.isGlobalBDM || this.userStore.isRegionalBDM) {
            this.regionStore.getRegions();
          }
          
          this.progressStore.getProgress(this.userStore.isStudent);
          this.alertStore.getAlerts();
          this.configStore.getServerConfig();
        }
      },
    );
  }
}

const rootStore = new RootStore();
const storesContext = createContext(rootStore);

export { storesContext };
export default rootStore;
