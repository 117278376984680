import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ViewLoader from '../../components/Loading/View';
import { observer, useLocalStore } from 'mobx-react';
import { storesContext } from '../../stores';
import AnnouncementStore from './store';
import './styles.scss';
import SVG from 'react-inlinesvg';
import ArrowDown from '../../img/icons/web-icon-caret-thin-down.svg';
import ArrowUp from '../../img/icons/web-icon-caret-thin-up.svg';

import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import { when } from 'mobx';
import { Button, Modal } from 'react-bootstrap';


const AnnouncementList = React.lazy(() =>
  import(
    /* webpackChunkName: 'announcements' */ '../../components/Announcement/announcements'
  ),
);
const AnnouncementForm = React.lazy(() =>
  import('../../components/Announcement/announcements/Form'),
);

const AnnouncementView = () => {

	const title = 'Announcements';
  const { t } = useTranslation();
  const { userStore, vocabularyStore, academyStore } = useContext(storesContext);
  const store = useLocalStore(() => new AnnouncementStore(userStore, vocabularyStore, academyStore));

  useEffect(() => {
    when(
      () => !store.loaded && userStore.isLoggedIn,
      async () => {
        await academyStore.getAcademies();
        await vocabularyStore.getCountryList();
        store.getAnnouncements();
      },
    );
  },[store, userStore, academyStore, vocabularyStore]);

  const renderAddAnnouncementForm = () => {
    return (
      <AnnouncementForm
        OnCancelAnnouncement={store.toggleAddAnnouncementForm}
        onSubmitAnnouncement={store.onSubmitAnnouncement}
        announcement={null}
      />
    );
  };
  
  return (
    <React.Suspense fallback={<ViewLoader />}>
      <h3 className="view-page-title">{t(`${title}`)}</h3>
      
      <div className="announcement-wrapper">

        <div className="gray-header" onClick={store.toggleAddAnnouncementForm}>
          <div className="sub-title">{t('Create New')}</div>
          <SVG className="icon" title={t('Create Announcement')} src={store.isNewAnnoucmenetOpen ? ArrowUp : ArrowDown}></SVG>
        </div>
        <div className={`expanded-area ${store.isNewAnnoucmenetOpen && 'show'}`}>
          {store.loaded && renderAddAnnouncementForm()}
        </div>
      
        <div className="gray-header mt-2">{t('Existing')}</div>
        
        <div className="announcement-list">
          
          <div className="row">
            <div className="col">
              {store.loaded ? (
                <AnnouncementList
                  announcements={store.paginatedAnnouncements}
                  onSubmitAnnouncement={store.onSubmitAnnouncement}
                  onDeleteAnnouncement={store.onDeleteAnnouncement}
                />
            ) : (
              <div>
                <ViewLoader />
              </div>
            )}

             
            </div>
          </div>

          <div className="row">
            <div className="col">
              {store.loaded && store.paginatedAnnouncements.length ? (
              <div className="announcement-list-pagination">
                <UltimatePagination
                  currentPage={store.currentPage}
                  totalPages={store.totalPages}
                  onChange={store.setCurrentPage}
                />
              </div>
              ) : null}
            </div>
          </div>
        </div>
		<Modal
                show={store.showRemoveAnnouncementModal}
                centered={true}
                >
                <Modal.Header>
                  {t('Delete Announcement')} {store.selectedAnnouncement?.content && ` : ${store.selectedAnnouncement?.content}`}
                </Modal.Header>
                <Modal.Body>
                    {t('Are you sure you want to remove this announcement?')}
                </Modal.Body>
                <Modal.Footer>
                    {store.errorMessage !== '' &&
                      <span className="error-message mr-2">
                        {t(store.errorMessage)}
                      </span>
                    }
                    <Button
                      variant="outline-info"
                      disabled={store.apiInProgress}
                      onClick={() => store.toggleRemoveAnnouncementModal()}
                    >
                    {t('Close')}
                    </Button>
                    <Button
                      variant="primary"
                      disabled={store.apiInProgress}
                      onClick={() => {
                        store.onRemoveAnnouncement();
                      }}
                    >
                    {store.apiInProgress ? (
                      <span className="spinner-wrapper">
                        <ViewLoader color="#ffffff" />
                      </span>)
                      :
                      t('Delete')}
                    </Button>
                </Modal.Footer>
              </Modal>
        </div>
    </React.Suspense>
  );
};

export default observer(AnnouncementView);