import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';
import { HelpContext } from '../store';
import ViewLoader from '../../Loading/View';
import './styles.scss';

const ReferModal = (props) => {
  const { t } = useTranslation();
  const helpStore = useContext(HelpContext);
  const { referDetails } = helpStore;

  return (
    <React.Fragment>
      <Modal
        show={helpStore.showReferModal}
        onHide={props.toggleReferModalProp}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Refer an Academy')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="refer-modal-content">
              <Form>
                <Form.Group>
                  <Form.Text>
                    {t('Name of College/University')}
                    <span className="asterisk">*</span>
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={`${t('Name of College/University')}...`}
                    style={
                      helpStore.submitting
                        ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                        : {}
                    }
                    onChange={(e) => helpStore.addReferInfo(e, 'university')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Text>
                    {t('Contact person')}
                    <span className="asterisk">*</span>
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={`${t('Name of the person to contact')}...`}
                    style={
                      helpStore.submitting
                        ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                        : {}
                    }
                    onChange={(e) => helpStore.addReferInfo(e, 'person')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Text>
                    {t('Email')}
                    <span className="asterisk">*</span>
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={`${t('Email address')}...`}
                    style={
                      helpStore.submitting
                        ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                        : {}
                    }
                    onChange={(e) => helpStore.addReferInfo(e, 'email')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Text>
                    {t('Phone')}
                    <span className="asterisk">*</span>
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={`${t('Phone number')}...`}
                    style={
                      helpStore.submitting
                        ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                        : {}
                    }
                    onChange={(e) => helpStore.addReferInfo(e, 'phone')}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Text>{t('Website')}</Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={`${t('Website address')}...`}
                    style={
                      helpStore.submitting
                        ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                        : {}
                    }
                    onChange={(e) => helpStore.addReferInfo(e, 'website')}
                  />
                </Form.Group>
              </Form>
              <div className="btns-wrapper">
                <Button
                  className="btn-white btn-outline-dark cancel-btn"
                  onClick={() => {
                    helpStore.cancelForm();
                  }}
                  variant="outline-dark"
                  disabled={helpStore.submitting}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={() => {
                    helpStore.submitRefer();
                  }}
                  disabled={
                    helpStore.submitting ||
                    referDetails.university_name === '' ||
                    referDetails.contact_person === '' ||
                    referDetails.email === '' ||
                    referDetails.phone === ''
                  }
                >
                  {!helpStore.submitting ? (
                    `${t('Submit')}`
                  ) : (
                    <span className="spinner-wrapper">
                      <ViewLoader color="#ffffff" />
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </React.Fragment>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

ReferModal.propTypes = {
  toggleReferModalProp: PropTypes.func,
  onReferSubmit: PropTypes.func,
};

ReferModal.defaultProps = {
  toggleReferModalProp: () => {},
  onReferSubmit: () => {},
};

export default observer(ReferModal);
