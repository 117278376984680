import { observable, action, computed } from 'mobx';
import { getLabDefinition } from '../services/LabService';
import { getCourseInfo } from '../services/CourseService';

export default class LabStore {
  @observable courseSlug = '';

  @observable labDefinition = {};

  @observable courseInfo = {};

  @observable labDefinitionFetched = false;

  @observable labBlueprintsFetched = false;

  @action getLabDefinition = async () => {
    this.labDefinitionFetched = false;
    try {
      this.labDefinition = await getLabDefinition(this.courseSlug);
    } catch (err) {
      console.error(`couldn't fetch lab definition: ${err}`, err);
    } finally {
      this.labDefinitionFetched = true;
    }
  };

  @action getLabBlueprints = async () => {
    this.labBlueprintsFetched = false;
    let courseInfo;
    try {
      courseInfo = await getCourseInfo(this.courseSlug);
    } catch (err) {
      console.error('failed to get course info:', err);
    }

    this.courseInfo = courseInfo || this.courseInfo;
    this.labBlueprintsFetched = true;
    return this.courseInfo;
  };

  @computed get labBlueprints() {
    const { lab_info: labInfo } = this.courseInfo || {};

    const { blueprint_id: blueprintId, lab_environment: labEnvironment } =
      labInfo || {};

    if (!(labEnvironment?.blueprints.length || blueprintId)) {
      return [];
    }

    return labEnvironment?.blueprints || [{ blueprint_id: blueprintId }];
  }
}
