import React from 'react';


const withPageTitle = ({ component: Component, title }) => {
  

  return (props) => {
    document.title = title || '';
    return <Component {...props} />;
  };
};
export default withPageTitle;
