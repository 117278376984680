import React, { Fragment, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Form, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { toJS } from 'mobx';
import * as merge from 'deepmerge';
import SVG from 'react-inlinesvg';
import CalendarIcon from '../../img/icons/calendar.svg';
import './styles.scss';

function DateRangePicker(props) {
  const numberOfMonths = 2;
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [dateRange, setDateRange ]= useState(props.dateRange);
  const { from, to } = dateRange;
  const [disabledDays, setDisabledDays] = useState({
    before: null,
    after: null
  });

  const modifiers = { start: dateRange.from, end: dateRange.to };

  useEffect(() => {
    setDateRange(props.dateRange);
    if(props.monthLimit && props.dateRange.from && props.dateRange.to) {
      setDisabledDays({
        before: DateUtils.addMonths(props.dateRange.to, -(props.monthLimit)),
        after: DateUtils.addMonths(props.dateRange.from, props.monthLimit)
      });
    }
  }, [props.dateRange, props.monthLimit]);

  const toggleCalendar = () => {
    setShow(!show);
  }

  const handleDayClick = (day, modifiers = {}) => {
    if (props.disabled || modifiers.disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, { from: from, to: to });    
    if(props.monthLimit) {
      const dayRange = range.from && range.to ? range : { from: day, to: day};
        setDisabledDays({
          before: DateUtils.addMonths(dayRange.to, -(props.monthLimit)),
          after: DateUtils.addMonths(dayRange.from, props.monthLimit)
        });
      
    }

    mergeDateRange(range);
    
    if(range.from && range.to) {      
      props.onDateRangeSelected(range);
    }
    
  };

  const mergeDateRange = (range) => {
    setDateRange(merge(toJS(dateRange), range || {}));
  };

  const resetCalendar = () => {
    const range = { from: null, to: null };
    setDateRange(range);
    setDisabledDays({
      before: null,
      after: null
    });
    props.onDateRangeSelected(range);
  };

  return (
      <Fragment>
          <Form.Group controlId="formBasicText">
            <div
              className="date-select-wrapper d-flex"
              onClick={() => toggleCalendar()}
            >
              <Form.Control
                type="text"
                value={`${
                  from ? moment(from).format('MMM DD, YYYY') : t('Start date')
                } - ${
                  to ? moment(to).format('MMM DD, YYYY') : t('End date')
                }`}
                placeholder="Select date range"
                className="date-field"
                disabled
              />
              <Button className="date-btn">
                <SVG src={CalendarIcon} />
              </Button>
            </div>
          </Form.Group>

          <Modal
            show={show}
            onHide={() => toggleCalendar()}
            dialogClassName="calendar-modal-dialog container"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="calendar-modal-title">
                From: &nbsp;{' '}
                {`${
                  from ? moment(from).format('MMM DD, YYYY') : t('start date')
                }`}{' '}
                &nbsp; To: &nbsp;
                {`${to ? moment(to).format('MMM DD, YYYY') : t('end date')}`}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="date-picker-wrapper">
                <Form.Group className="calendar-form">
                  <div className="month-section">
                    <DayPicker
                      className="Selectable"
                      numberOfMonths={numberOfMonths}
                      selectedDays={[from, {from, to}]}
                      modifiers={modifiers}
                      onDayClick={handleDayClick}
                      disabledDays={[disabledDays]}
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Button
                        disabled={props.disabled}
                        className="clear-btn btn-outline-dark"
                        variant="outline-dark"
                        onClick={() => resetCalendar()}
                      >
                        {t('Reset')}
                      </Button>
                      <Button
                        className="close-btn"
                        onClick={() => toggleCalendar()}
                      >
                        {t('Done')}
                      </Button>
                    </div>
                    <div className="col-sm float-right m-3">
                      {props.monthLimit ? <div className="note">{`${t("Date range can be upto")} `}{props.monthLimit / 12 }{` ${t("years.")}`}</div> : ''}
                    </div>
                  </div>
                </Form.Group>
              </div>
              
            </Modal.Body>
          </Modal>

      </Fragment>
  )
}

export default DateRangePicker;