import React, { useEffect, useRef } from 'react';
import { PostScribe } from 'react-postscribe';
import PropTypes from 'prop-types';
import { JWPLAYER_CLOUD_URL } from '../../../../config/constants';

const JwPlayer = props => {
  const player = useRef(null);
  let playerId = null;

  useEffect(() => {
    return () => {
      // FIXME: waiting is needed to make `remove()` work
      setTimeout(() => {
        window.jwplayer(playerId).remove();
      }, 1000);
    };
  });

  return (
    <PostScribe
      ref={player}
      html={`<script src="${JWPLAYER_CLOUD_URL}${props.id}.js"></script>`}
      done={() => {
        playerId = player.current?.el?.children[1]?.id;
      }}
    />
  );
};

JwPlayer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default JwPlayer;
