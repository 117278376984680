import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-mobx-router5';
import Store from './store';
import './styles.scss';

@inject('routerStore', 'courseStore')
@withTranslation()
@observer
class ProgressMap extends Component {
  static propTypes = {
    structure: PropTypes.array,
    pageSlug: PropTypes.string,
    routerStore: MobXPropTypes.observableObject.isRequired,
    courseStore: MobXPropTypes.observableObject.isRequired,
    progressStructure: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    structure: [],
    pageSlug: '',
  };

  constructor(props) {
    super(props);

    this.store = new Store();
  }

  componentDidMount() {
    reaction(
      () => this.props.progressStructure,
      () => {
        if (!this.props.progressStructure.length) return;

        this.store.progressStructure = this.props.progressStructure;
      },
    );
  }

  addProgressClass = (list) => {
    const { pageSlug } = this.props;

    let progressClass;
    let activeClass;

    if (pageSlug === list.page_tag) {
      activeClass = 'is-active';
    }

    switch (list.type) {
      case 'section':
        progressClass = 'section';
        break;
      case 'chapter':
        progressClass = 'chapter';
        activeClass = '';
        break;
      case 'preface':
        progressClass = 'preface';
        break;
      case 'appendix':
        progressClass = 'appendix';
        break;
      default:
        progressClass = '';
    }

    return `${progressClass} ${activeClass}`;
  };

  addProgressText = (list, chapterCount) => {
    let text = '';

    switch (list.type) {
      case 'chapter':
        text = chapterCount;
        break;
      case 'preface':
        text = 'P';
        break;
      case 'appendix':
        text = 'A';
        break;
      default:
        text = '';
    }
    return text;
  };

  incrementChapterCount = () => {
    return this.store.incrementChapterCount();
  };

  addClassNames = (content) => {
    let classNames = `progress-map-bar ${this.addProgressClass(content)}`;

    if (content.status === 'inactive') {
      classNames += ' disabled';
    }

    const progress =
      this.store.progressStructure.find((item) => item.uuid === content.uuid)
        ?.progress || 0;

    if (progress === 1) {
      classNames += ' visited';
    }

    return classNames;
  };

  render() {
    const { structure, routerStore, courseStore } = this.props;
    const { route } = routerStore;
    this.store.chapterCount = 0;

    return (
      <div className="progress-map">
        {structure &&
          structure.map((content) => {
            const { id_tag: id } = content;

            return (
              <React.Fragment key={id}>
                {content.status === 'inactive' ? (
                  <span
                    className={this.addClassNames(content)}
                    title={content.title}
                  >
                    {this.addProgressText(
                      content,
                      content.type === 'chapter'
                        ? this.incrementChapterCount()
                        : '',
                    )}
                  </span>
                ) : (
                  <Link
                    href
                    className={this.addClassNames(content)}
                    title={content.title}
                    routeName={courseStore.coursePageRouteName}
                    routeParams={{
                      course: route.params.course,
                      page: content.page_tag,
                      ...(courseStore.coursePageRouteParamsByRole),
                    }}
                  >
                    {this.addProgressText(
                      content,
                      content.type === 'chapter'
                        ? this.incrementChapterCount()
                        : '',
                    )}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
      </div>
    );
  }
}

export default ProgressMap;
