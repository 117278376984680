import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Form, Button, Row, Col, Accordion, Card } from 'react-bootstrap';

import ViewLoader from '../Loading/View';
import { asyncParseCSVFile } from '../../services/UtilityService';

import SVG from 'react-inlinesvg';
import Dropzone from 'react-dropzone';

import checkCircle from '../../img/icons/check-circle.svg';
import crossCircle from '../../img/icons/x-circle.svg';

import BulkUploadStore from './store';

import 'react-day-picker/lib/style.css';
import 'react-multi-email/style.css';
import './styles.scss';

import infoIcon from '../../img/icons/info_2.svg';
import downArrow from '../../img/icons/down_arrow.svg';
import rightArrow from '../../img/icons/right_arrow.svg';
import sampleCsvTemplate from '../../static/sample_student_rhn_ids.csv';

const BulkUpload = (props) => {
  const { t } = useTranslation();

  const store = useLocalStore(() => new BulkUploadStore());

  const setCSVData = () => {
    store.dataFromCsv = props.dataFromCsv;
  };
  useEffect(setCSVData, [props.dataFromCsv]);

  const parseCSVFile = async (acceptedFile) => {
    store.parsingFile = true;
    store.invalidCsvData = false;
    const parsedContents = await Promise.all(
      acceptedFile.map(asyncParseCSVFile),
    );
    let arr = [];
    // this validation should be improved
    parsedContents.forEach((list) => {
      list.map((content) => {
        return props.isCSVContentValid(content)
          ? arr.push(props.getValidObjectFromCSV(content))
          : (store.invalidCsvData = true);
      });
    });
    arr = props.onParsedCSV(arr);
    store.dataFromCsv = arr;
    store.parsingFile = false;
  };

  return (
    <React.Fragment>
      <div className="bulk-upload-info mb-4">
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              onClick={store.toggleBulkUploadInfoPanel}
            >
              <Row>
                <Col className="col-10 pl-2">{t(props.title)}</Col>
                <Col className="col-2 align-self-center text-right">
                  <SVG
                    src={store.isInfoPanelOpen ? downArrow : rightArrow}
                  ></SVG>
                </Col>
              </Row>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="bulk-upload-info-body">
                  <div className="important-info mb-2">
                    <div className="important-info-header">
                      <h6>
                        <SVG src={infoIcon}></SVG>
                        <span className="ml-2">{t(props.infoHeader)}</span>
                      </h6>
                    </div>
                    <div className="important-info-text">
                      <p dangerouslySetInnerHTML={{ __html: props.infoDescriptionMarkup }}></p>
                    </div>
                  </div>
                  <div className="other-info-list">
                    <ul>
                      {props.instructions?.map((instruction) => {
                        return <li dangerouslySetInnerHTML={{ __html: instruction }}></li>;
                      })}
                    </ul>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      <div className="sample-csv-dwnld-btn-wrapper mb-4">
        <a
          className="btn btn-primary csv-dwnld-btn"
          href={sampleCsvTemplate}
          download="csv_template.csv"
        >
          {t('Download CSV template')}
        </a>
      </div>
      <Form.Text>{t('Bulk upload')}</Form.Text>
      <Form.Group className="drag-wrapper">
        {!store.parsingFile ? (
          !store.dataFromCsv.length ? (
            <React.Fragment>
              <Dropzone
                accept=".csv,text/csv"
                onDrop={parseCSVFile}
                disabled={props.disabled}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <div className="drag-overlay">
                      <input {...getInputProps()} />
                      {store.invalidCsvData && (
                        <p className="text-danger">
                          <SVG src={crossCircle} className="mr-2"></SVG>
                          <strong>{t('Upload failed!')}</strong>{' '}
                          {t('This file contains invalid data.')} <br />
                          {t(
                            'Please follow the instructions on this form and ensure that the csv file has valid data.',
                          )}
                        </p>
                      )}

                      <p>
                        {t('Drop CSV file here or')}{' '}
                        <Button
                          disabled={props.disabled}
                          className="select-file-btn link-button"
                        >
                          {t('Browse file')}
                        </Button>
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="drag-overlay">
                <div>
                  <p className="success-message">
                    <SVG src={checkCircle} className="mr-2"></SVG>
                    {t('Bulk upload was successfull!')}
                  </p>
                  <Button
                    disabled={props.disabled}
                    className="clear-btn btn-outline-dark"
                    variant="outline-dark"
                    onClick={() => {
                      store.resetCSVData();
                      props.onReset();
                    }}
                  >
                    {t('Undo bulk upload')}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )
        ) : (
          <div className="parseLoader">
            <ViewLoader />
          </div>
        )}
      </Form.Group>
    </React.Fragment>
  );
};

BulkUpload.propTypes = {
  title: PropTypes.string,
  infoHeader: PropTypes.string,
  infoDescriptionMarkup: PropTypes.string,
  instructions: PropTypes.array,
  disabled: PropTypes.bool,
  dataFromCsv: PropTypes.array,
  isCSVContentValid: PropTypes.func,
  getValidObjectFromCSV: PropTypes.func,
  onParsedCSV: PropTypes.func,
  onReset: PropTypes.func,
};

BulkUpload.defaultProps = {
  title: 'How to upload data in bulk',
  infoHeader: 'Important Prerequisite',
  infoDescriptionMarkup: 'Sample Text',
  instructions: ['Sample instruction 1', 'Sample instruction 2'],
  disabled: false,
  dataFromCsv: [],
  isCSVContentValid: () => {},
  getValidObjectFromCSV: () => {},
  onParsedCSV: () => {},
  onReset: () => {},
};

export default observer(BulkUpload);
