import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const InactiveInstructorModal = ({ store }) => {
  const { t } = useTranslation();
  const renderAcademyDetails = () => {
    return (
      <div className="academy-details">
        <p>
          {t('Admin')}: {store.academy.first_name} {store.academy.last_name}
        </p>
        <p>
          {t('Email')}: {store.academy.email}
        </p>
      </div>
    );
  };

  const onCloseModal = () => {
    store.showBlockingModal = false;
  };

  return store.showBlockingModal ? (
    <React.Fragment>
      <Modal
        show={store.showBlockingModal}
        size="lg"
        backdrop="static"
        centered
        backdropClassName="blocking-modal-backdrop"
        onHide={() => onCloseModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Inactive Account')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`${t('Contact your Academy Admin for more details')}:`}
          <br />
          <br />
          {renderAcademyDetails()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => onCloseModal()}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  ) : null;
};

InactiveInstructorModal.propTypes = {
  store: PropTypes.shape({
    academy: PropTypes.shape({
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    showBlockingModal: PropTypes.bool,
  }),
};

export default InactiveInstructorModal;
