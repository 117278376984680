import { post } from 'axios';
import { SUPPORT_API_URL } from '../config/constants';

export const submitSupportCase = async (payload) => {
  try {
    const response = await post(`${SUPPORT_API_URL}`, payload);
    return response?.data;
  } catch (err) {
    throw new Error(err);
  }
};
