import { post } from "axios";

import { 
  KALTURA_SESSION_API,
} from "../config/constants";


/**
 * Returns Kaltura session id as string
 * @return {string} A String containing Kaltura Session ID
 */
export const createKalturaSession = async () => {
  try {
    const session = await post(KALTURA_SESSION_API);
    return session?.data;
  } catch (error) {
    throw new Error(error);
  }
};
