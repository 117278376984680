import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const GenericAlert = props => {
  const { title, priority, description } = props;
  const { t } = useTranslation();

  return (
    <Alert
      variant={priority}
      {...props.className && { className: props.className }}
    >
      <h4>
        <strong>{t(title)}</strong>
      </h4>
      <p>{t(description)}</p>
    </Alert>
  );
};

GenericAlert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  priority: PropTypes.oneOf(['success', 'warning', 'danger', 'info'])
    .isRequired,
  className: PropTypes.string,
};

GenericAlert.defaultProps = {
  title: 'Important!',
  className: '',
};

export default GenericAlert;
