import { action, observable, computed } from 'mobx';
import { getServerConfig } from '../services/ConfigService';
import { LAB_STORE_IMAGES_API_URL } from '../config/constants';

class ConfigStore {
	@observable config = {};

	@action getServerConfig = async (force) => {
		if (Object.keys(this.config).length && !force) {
			return this.config;
		}

		const config = await getServerConfig();

		if (config) {
			this.config = config;
		}

		return this.config;
	};
	@computed get LabImagesEndpoint() {
		// return a value from configuration, or returns the default lab store endpoint.
		return this.config.REZODE_AWS_CLOUDFRONT_LAB_URL
		  ? this.config.REZODE_AWS_CLOUDFRONT_LAB_URL
		  : LAB_STORE_IMAGES_API_URL;
	  }
}

export default ConfigStore;
