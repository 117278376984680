import React, { Component } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-mobx-router5';


@inject('routerStore', 'userStore','courseStore')
@withTranslation()
@observer
class SectionLinks extends Component {
  static propTypes = {
    data: PropTypes.string,
    routerStore: MobXPropTypes.observableObject.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    courseStore: MobXPropTypes.observableObject.isRequired,
  };

  static defaultProps = {
    data: '',
  };

  render() {
    const { data, routerStore, courseStore } = this.props;
    const { route } = routerStore;
    const htmlParser = new DOMParser();
    const node = htmlParser.parseFromString(data, 'text/html');
    const html = node.body.innerHTML;
    const parserOption = {
      replace: ({ attribs, children }) => {
        if (!attribs?.href) return null;

        const href = attribs.href.replace(/.html.*$/, '');

        return (
          <Link
            href
            routeName={courseStore.coursePageRouteName}
            routeParams={{
              course: route.params.course,
              page: href,
              ...(courseStore.coursePageRouteParamsByRole),
            }}
          >
            {domToReact(children)}
          </Link>
        );
      },
    };

    return <dl>{Parser(html, parserOption)}</dl>;
  }
}

export default SectionLinks;
