import React from 'react';
import { observer } from 'mobx-react';

const AsyncReport = React.lazy(() =>
  import('../../components/AsyncReport'),
);
const AsyncReportView = () => {

	return (
		<AsyncReport />
	 );
};

export default observer(AsyncReportView);
