import React, { useContext } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';

import './styles.scss';
import { labEnvironmentContext } from '../store';
import ComponentError from '../../Error/ComponentError';
import { handleError } from '../../../services/ErrorService';
import SVG from 'react-inlinesvg';
import InfoIcon from '../../../img/icons/info_2.svg';

const NovelloLabInfo = () => {
  const { t } = useTranslation();
  const store = useContext(labEnvironmentContext);

  /* TODO: uncomment/update this when floating IP will be exposed through API
  const classroomFQDN = store.classroomVM.externalFqdn;

  // Expose the External FQDN if the property exists and there's SSH running on the 'classroom' vm
  const suppliedServices = store.classroomVM.suppliedServices || [];

  const hasSSH = Boolean(
    suppliedServices.find(service => service.name === 'ssh'),
  );

  const exposeClassroomFQDN = classroomFQDN && hasSSH;
  */

  const { userLab } = store;

  const {
    components,
    state,
    billing_region: region,
    lab_definition_id: labDefinitionId,
  } = userLab;

  const fc =
    components && components.length
      ? components.find(c => c.driver === 'openstack')
      : {};

  // eslint-disable-next-line camelcase
  const projectName = fc?.context?.project_name
    ? fc.context.project_name
    : null;
  // eslint-disable-next-line camelcase
  const projectId = fc?.context?.project_id ? fc.context.project_id : null;

  const regionDetails = () => {
    return components && components.length
      ? components.map((component, i) => {
          return (
            <p key={i}>
              <strong>
                {component.slug} {t('region')}:
              </strong>{' '}
              {component.region}
            </p>
          );
        })
      : null;
  };

  const labInfo = (
    <Popover
      id="labinfo"
      title={t('Lab information')}
      className="lab-controls-info-panel"
    >
      <p key={'info-1'}>
        <strong>{t('Project id')}:</strong> {projectId || t('Not available')}
      </p>
      <p key={'info-2'}>
        <strong>{t('Project name')}:</strong>{' '}
        {projectName || t('Not available')}
      </p>
      <p key={'info-3'}>
        <strong>{t('Project state')}:</strong> {state}
      </p>
      <p key={'info-4'}>
        <strong>{t('Lab definition id')}:</strong> {labDefinitionId}
      </p>
      {/*
      TODO: uncomment this when floating IP work is available
      {exposeClassroomFQDN ? (
        <p>
          <strong>{t('External FQDN')}:</strong> {classroomFQDN}
        </p>
      ) : null}
      */}
      <p key={'info-5'}>
        <strong>{t('Published region')}:</strong> {region}
      </p>
      {regionDetails()}
    </Popover>
  );

  return (
    <div key='div-info' className="lab-controls-info">
      <OverlayTrigger trigger="click" placement="right" key='div-info-lay' overlay={labInfo}>
        <Button variant="link" className="lab-controls-info-btn">
        <SVG  src={InfoIcon}></SVG> 
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default withErrorBoundary(
  observer(NovelloLabInfo),
  ComponentError,
  handleError,
);
