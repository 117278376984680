import { observable, action } from 'mobx';

class BulkUploadStore {

  @observable parsingFile = false;
  @observable isInfoPanelOpen = true;
  @observable dataFromCsv = [];
  @observable invalidCsvData = false;

  @action toggleBulkUploadInfoPanel = () => {
    this.isInfoPanelOpen = !this.isInfoPanelOpen;
  };

  @action resetCSVData = () => {
    this.dataFromCsv = [];
  };
}

export default BulkUploadStore;
