import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import ViewLoader from '../../components/Loading/View';
import { useTranslation } from 'react-i18next';
import { storesContext } from '../../stores';
import { when } from 'mobx';
import DetailCards from '../../components/ClassDetails/DetailCards';
import EditClassModal from '../../components/ClassDetails/EditClassModal';
import ClassDetailsStore, { ClassDetailsContext } from './store';
import { getAttachedFiles } from '../../services/ClassService';
import moment from 'moment';
import './styles.scss';
import AccessComponent from '../../components/AccessComponent';

const StudentList = React.lazy(() =>
  import('../../components/Classes/Students/'),
);

const ClassAssignmentCard = React.lazy(() =>
  import('../../components/Cards/ClassAssignmentCard'),
);

const StudentPromoCodeModal = React.lazy(() =>
  import('../../components/StudentPromoCodeModal'),
);

const ClassDetailsView = () => {
  const {
    userStore,
    classStore,
    routerStore,
    classAssignmentStore,
    classStudentStore,
    uiStore,
    academyStore,
  } = useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(
    () =>
      new ClassDetailsStore(
        classStore,
        classAssignmentStore,
        classStudentStore,
        userStore,
      ),
  );
  const navigateToCreateAssignment = () => {
    routerStore.navigate('classes:create:assignment', {
      classid: routerStore.route.params.classid,
    });
  };
  const navigateToAssignmentDetail = () => {
    
    if (userStore.isInstructor) {
      routerStore.navigate('classes:edit:assignment', {
        classid: routerStore.route.params.classid,
        assignmentid: store.assignmentId,
      });
    }
    navigateToViewAssignmentDetail();
  };

  const navigateToViewAssignmentDetail = () => {
    if (userStore.isAcademyAdmin) {
      routerStore.navigate('classes:view:assignment', {
        classid: routerStore.route.params.classid,
        assignmentid: store.assignmentId,
      });
    }
  };

  useEffect(() => {

    when(
      () => 
      !classAssignmentStore.ready &&
      !classAssignmentStore.loading && 
      routerStore.route.params.academyid,
      async () => {
        await classAssignmentStore.fetchAssignments(true, routerStore.route.params.academyid);
      }
    );

    when(
      () =>
        classStore.ready &&
        classAssignmentStore.ready &&
        !store.loaded &&
        userStore.isLoggedIn,
      async () => {
        await store.getClassDetailbyId(routerStore.route.params.classid);
        store.populateStudents();
        store.setAssignment(routerStore.route.params.classid);
      },
    );

  }, [userStore, store, classStore, routerStore.route.params, classAssignmentStore]);

  useEffect(() => {
    // Populate student details
    when(
      () => !store.hasAcademyData && userStore.isLoggedIn && academyStore.academyLoaded,
      async () => {
        await store.setAcademyDetails(academyStore.academyDetails);
      },
    );
  }, [store, userStore, routerStore.route.params, academyStore]);

  useEffect(() => {
    when(
      () => store.classDetail,
      async () => {
        if (store.classDetail.custom_class) {
          const existingFileNames = await getAttachedFiles(
            store.classDetail.doc_id,
          );
          store.existingFile = existingFileNames[0];
          store.gotFileName = true;
        }
      },
    );
  }, [store, store.classDetail]);

  return (
    <Container fluid className="class-details-wrapper">
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col className="p-0">
            <h3 className="view-page-title">{`${t('Classes')} / ${
              store.classDetail?.class_name || '---'
            }`}</h3>
          </Col>
        </Row>

        {store.loaded && store.classDetail !== null && (
          <DetailCards details={store.classDetail} />
        )}

        {store.loaded &&
        store.classDetail !== null &&
        store.classDetail.status !== 'initiated' ? (
          <AccessComponent componentName="EditClass">
            <Row>
              <Col className="button-col-wrap">
                <Button onClick={!moment(store.classDetail?.end_date).isBefore() && store.toggleClassEditModal} className={moment(store.classDetail?.end_date).isBefore() && 'disabled' }>
                  {store.classDetail.custom_class && !store.gotFileName ? (
                    <ViewLoader color="white" />
                  ) : (
                    t('Edit Class')
                  )}
                </Button>
              </Col>
            </Row>
          </AccessComponent>
        ) : null}

        <Row>
          <Col>
            <h5>{t('Students')}</h5>
          </Col>
        </Row>

        <Row>
          <Col>
            {store.loaded ? (
              <ClassDetailsContext.Provider value={store}>
                <StudentList
                  classId={store.classId}
                  students={store.paginatedEntries}
                  onSortBy={store.onSortBy}
                  sortByField={store.sortByField}
                  isSortAscending={store.isSortAscending}
                  onRemoveStudents={ () => {store.reloadData(store.classId)}}
                  onSelectStudents={store.onSelectStudents}
                />
              </ClassDetailsContext.Provider>
            ) : (
              <div>
                <ViewLoader />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {/* --- Pagination : Instructor list */}
            {store.loaded && store.paginatedEntries.length ? (
              <div className="students-list-pagination">
                <UltimatePagination
                  currentPage={store.currentPage}
                  totalPages={store.totalPages}
                  onChange={store.setCurrentPage}
                />
              </div>
            ) : null}
          </Col>
        </Row>

        {store.loaded &&
        store.hasAcademyData &&
        store.isInstructorWithPartner &&
        !store.isCustomClass ? (
          <Row>
            <Col className="promo-btn-col-wrap">
              <ClassDetailsContext.Provider value={store}>
                <StudentPromoCodeModal />
              </ClassDetailsContext.Provider>
            </Col>
          </Row>
        ) : null}

        {store.showEditClassModal && (
          <Row>
            <Col>
              <ClassDetailsContext.Provider value={store}>
                <EditClassModal
                  existingDetails={store.classDetail}
                  existingFile={store.existingFile}
                  toggleEditClassModalProp={store.toggleClassEditModal}
                  onEditClassSubmit={() => {
                    store.reloadData(routerStore.route.params.classid);
                  }}
                />
              </ClassDetailsContext.Provider>
            </Col>
          </Row>
        )}

        <Row >
          <Col>
            <h5>{t('Assignment')}</h5>
          </Col>
        </Row>

        {store.loaded &&
          store.assignmentDetails === null &&
          !userStore.isInstructor ? (
            <Row >
              <Col>{t('No assignment exists for this class')}</Col>
            </Row>)
          : null}
        {store.loaded &&
        store.assignmentDetails === null &&
        userStore.isInstructor ? (
          <Row>
            <Col className="assignment-list-wrapper">
              <Button onClick={() => navigateToCreateAssignment()}>
                {t('Create Assignment')}
              </Button>
            </Col>
          </Row>
        ) : null}
        {store.loaded && store.assignmentDetails !== null ? (
          <Row>
            <Col className="assignment-list-wrapper">
              <ClassAssignmentCard
                onClick={() => navigateToAssignmentDetail()}
                assignment={store.assignmentDetails}
                isStudentView={false}
                language={uiStore.currentLanguage}
              />
            </Col>
          </Row>
        ) : null}
      </React.Suspense>
    </Container>
  );
};

export default observer(ClassDetailsView);
