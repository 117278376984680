import { post, get } from 'axios';
import queryString from 'query-string';
import { API_LOGIN, API_LOGOUT } from '../config/constants';

const login = async (username, password) => {
	try {
		const response = await post(
			API_LOGIN,
			queryString.stringify({
				username,
				password,
			}),
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				withCredentials: true,
			}
		);

		const user = response.data;

		return user;
	} catch (error) {
		throw error;
	}
};

const logout = async (redirect = true) => {
	try {
	  const response = await get(API_LOGOUT);

	  const { logout_url: logoutUrl } = response?.data || {};

	  if (logoutUrl && redirect) {
		window.location.href = logoutUrl;
	  }
	} catch (error) {
	  throw new Error(error);
	}
  };

export {
	login,
	logout
};
