import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { sortBy } from 'lodash';
import { getAlerts, partiallyUpdateAlert } from '../services/AlertService';

class AlertStore {
  @observable alerts = [];

  @observable loading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action getAlerts = async () => {
    this.loading = true;
    try {
      const response = await getAlerts();
      this.alerts = response;
    } catch (error) {
      console.error(`Couldn't get alerts:`, error);
    } finally {
      this.loading = false;
      return this.alerts;
    }
  };

  @computed get chronologicalAlerts() {
    return sortBy(this.alerts, '@timestamp').reverse();
  }

  @action partiallyUpdateAlert = async (id, data) => {
    try {
      const updatedAlert = partiallyUpdateAlert(id, data);
      return updatedAlert;
    } catch (error) {
      throw error;
    }
  };

  @computed get currentAlerts() {
    
    const now = moment();

    const current = this.alerts.filter(alert => {
      const startTime = moment(alert.start_time);
      const endTime = moment(alert.end_time);
      // an alert is considered current if:
      // 1. it's enabled
      // 2. today falls between alerts start and end date
      // 3. if it is supposed to be shown for user's subscription
      return (
        alert.enabled &&
        now.isBetween(startTime, endTime) 
      );
    });

    return current;
  }
}

export default AlertStore;
