import { action, computed, observable } from "mobx";

import { getCatalogEntries } from "../services/CatalogService";


class CatalogStore {
  @observable loaded = false;
  @observable entriesLoading = false;
  @observable catalogReady = false;
  @observable subscriptionCatalog = [];

  @observable _latestCourseCurrentPage = 1;
  @observable _examCurrentPage = 1;

  @observable filters = {
    latest_courses: [],
    exam: [],
    course: [],
  };

 

  @computed get flatEntries() {
    // Construct a dict of unique catalog entries with VC, course and exams only, preferring the latest version
    const groupedEntries = this.subscriptionCatalog;
    let uniqueEntries = {};
    // Filter entries by flatten the values for dictonary by list of offerings
    // (i.e dropping the version array for each course code)
    Object.keys(groupedEntries).forEach((k) => {
      let courses = []
      Object.values(Object.values(groupedEntries[k])).forEach((a) => {
        // filter courses 
         const filteredCourses = Object.keys(a)
          .map((allowedModality) => {
            return Object.values(a[allowedModality]).filter(
              (entry) => entry.visibility !== 'hidden',
            );
          }).flat();
          if(filteredCourses.length > 0){
            courses.push(filteredCourses);
          }
      });
      courses = courses.flat();
      if (courses.length > 0) {

        uniqueEntries[k] = courses;
      }
    });
    return uniqueEntries;
  }
  @action filterEntries = () => {
   

    const filteredExams = Object.entries(this.flatEntries)
    .map((code) => { 
      return code[1].filter(a => 
       a.modality ==="exam")
      .sort((a,b) => parseInt(b.version) - parseInt(a.version))[0]})
      .filter(a => a !== undefined)

    
    const filteredCourses = Object.entries(this.flatEntries)
    .map((code) => { 
      return code[1].filter(a => 
      a.visibility !== "hidden" && a.modality ==="course")
      .sort((a,b) => parseInt(b.version) - parseInt(a.version))})
      .filter(a => a !== undefined);

    this.filters.latest_courses = filteredCourses.map(c => {return c[0]}).filter(c => c !== undefined);
    this.filters.exam = filteredExams;
    this.filters.course = filteredCourses.flat();
  };

  @action getCatalogEntries = async (force) => {
    if (this.subscriptionCatalog.length && !force) {
      return this.subscriptionCatalog;
    }
    
    if(this.entriesLoading === true) return ;

    this.entriesLoading = true;

    const subscriptionEntries = await getCatalogEntries();
    
    if (subscriptionEntries) {
      this.subscriptionCatalog =  subscriptionEntries;
    }

    this.entriesLoading = false;
    this.catalogReady = true;

    return this.subscriptionCatalog;
  };

  @action clearCatalog = () => {
    this.subscriptionCatalog = [];
  };

  @action getCourseTitleBySlug = (slug, language) => {
    const course = slug?.substring(0,slug.indexOf("-")).replace("vc","");
    const entryBySlug = this.flatEntries[course];
    

    if (entryBySlug) {
      const  translations  = entryBySlug?.find(e =>  e.translations !== undefined)?.translations;
      const t =
        typeof translations === "object"
          ? Object.values(translations)
          : translations;

      return (
        t?.find((tr) => tr.language === language)?.title ||
        entryBySlug[0]?.title
      );
    }
    return "";
  };

  @computed get groupedCatalogEntries() {
    return this.subscriptionCatalog;
  }

  // Paginated Entries - latest Courses
  @computed get latestCourseEntriesPerPage() {
    return this.filters?.latest_courses?.length;
  }
  
  @computed get latestCourseTotalPages() {
    return (
      Math.ceil(
        this.filters.latest_courses.length / this.latestCourseEntriesPerPage,
        10,
      ) || 1
    );
  }

  @computed get latestCourseCurrentPage() {
    if (this._latestCourseCurrentPage > this.latestCourseTotalPages) {
      return 1;
    }

    return this._latestCourseCurrentPage;
  }

  @computed get latestCoursePaginatedEntries() {
    const startIndex = (this.latestCourseCurrentPage - 1) * this.latestCourseEntriesPerPage;
    return this.filters.latest_courses.slice(
      startIndex,
      startIndex + this.latestCourseEntriesPerPage,
    );
  }

  // Paginated Entries - Exam
  @computed get examEntriesPerPage() {
    return this.filters?.exam?.length;
  }
  @computed get examTotalPages() {
    return (
      Math.ceil(this.filters.exam.length / this.examEntriesPerPage, 10) || 1
    );
  }

  @computed get examCurrentPage() {
    if (this._examCurrentPage > this.examTotalPages) {
      return 1;
    }

    return this._examCurrentPage;
  }

  @computed get exmaPaginatedEntries() {
    const startIndex = (this.examCurrentPage - 1) * this.examEntriesPerPage;
    return this.filters.exam.slice(
      startIndex,
      startIndex + this.examEntriesPerPage,
    );
  }
}

export default CatalogStore;
