import { action, observable } from 'mobx';
import { getRegions } from '../services/ConsumptionService';

class RegionStore {
  @observable regionsResponse = null;

  @action getRegions = async () => {
    try {
      this.regionsResponse = await getRegions();
    } catch (err) {
      this.regionsResponse = null;
    } finally {
      return this.regionsResponse;
    }
  }

  @action getCountriesByRegion = (region) => {
    return this.regionsResponse?.region_map?.[region] || [];
  }
}

export default RegionStore;
