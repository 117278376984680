import Login from '../views/Login';
import { ROLES } from './constants';
import withPageTitle from '../components/PageTitle';
import EnablementView from '../views/Enablement';
import DefaultRedirectView from '../views/DefaultRedirectView';
import AcademyDetailsView from '../views/AcademyDetails';
import HelpView from '../views/Help';
import FeedbackView from '../views/Feedback';
import CourseSummary from '../views/CourseSummary';
import StudentAssignmentListView from '../views/CourseSummary/StudentAssignmentList';
import ClassesView from '../views/Classes';
import ConsumptionSummaryView from '../views/ConsumptionSummary';
import AnnouncementView from '../views/Announcement';
import ResourcesView from '../views/Resources';
import StudentResourcesView from '../views/StudentResources';
import CoursesAndCertificationView from '../views/CoursesAndCertification';
import CourseView from '../views/Course';
import ExamView from '../views/Exam';
import ClassDetailsView from '../views/ClassDetails';
import ClassStudentDetailsView from '../views/ClassDetails/ClassStudentDetails';
import CreateEditAssignmentView from '../views/ClassDetails/CreateEditAssignment';
import ViewAssignmentView from '../views/ClassDetails/ViewAssignment';
import { getServerConfig } from '../services/ConfigService';
import Lab from '../views/Lab';
import AsyncReport from '../views/AsyncReport';
import AcademyStudents from '../views/AcademyStudents';
import SupportCaseView from '../views/SupportCase';
import TalentNetworkView from '../views/TalentNetwork';

const AcademyDetailsPageView = withPageTitle({
  component: AcademyDetailsView,
  title: 'Academy Details',
});

const HelpPageView = withPageTitle({
  component: HelpView,
  title: 'Help',
});

const FeedbackPageView = withPageTitle({
  component: FeedbackView,
  title: 'Feedback',
});

const EnablementPageView = withPageTitle({
  component: EnablementView,
  title: 'Get Trained and Certified',
});

const CourseSummaryPageView = withPageTitle({
  component: CourseSummary,
  title: 'Course Summary',
});

const StudentAssignmentListPageView = withPageTitle({
  component: StudentAssignmentListView,
  title: 'Quiz',
});

const ClassesPageView = withPageTitle({
  component: ClassesView,
  title: 'Manage Classes',
});

const ConsumptionSummaryPageView = withPageTitle({
  component: ConsumptionSummaryView,
  title: 'Academy Summary',
});

const AcademyStudentsPageView = withPageTitle({
  component: AcademyStudents,
  title: 'Consumption Summary',
});

const AnnouncementPageView = withPageTitle({
  component: AnnouncementView,
  title: 'Announcements',
});

const AsyncReportPageView = withPageTitle({
  component: AsyncReport,
  title: 'Reports',
});

const ResourcesPageView = withPageTitle({
  component: ResourcesView,
  title: 'Resources',
});

const StudentResourcesPageView = withPageTitle({
  component: StudentResourcesView,
  title: 'Student Resources',
});

const CoursesAndCertificationPageView = withPageTitle({
  component: CoursesAndCertificationView,
  title: 'Courses and Certifications',
});

const CoursePageView = withPageTitle({
  component: CourseView,
  title: 'Course',
});

const ExamPageView = withPageTitle({
  component: ExamView,
  title: 'Exam',
});

const ClassDetailsPageView = withPageTitle({
  component: ClassDetailsView,
  title: 'Class Details',
});

const ClassStudentDetailsPageView = withPageTitle({
  component: ClassStudentDetailsView,
  title: 'Class Student Details',
});

const CreateEditAssignmentPageView = withPageTitle({
  component: CreateEditAssignmentView,
  title: 'Assignment',
});

const ViewAssignmentPageView = withPageTitle({
  component: ViewAssignmentView,
  title: 'Assignment',
});

const SupportCasePageView = withPageTitle({
  component: SupportCaseView,
  title: 'Open a Support Case',
});

const TalentNetworkPageView = withPageTitle({
  component: TalentNetworkView,
  title: 'Red Hat Academy Talent Network',
});

class Routes {
  constructor(stores) {
    this.stores = stores;
  }

  invalidateAndRedirect = async (shouldRedirect, toState) => {
    const { routerStore, userStore } = this.stores;

    userStore.invalidateUser();

    let scope = 'local';

    try {
      const { OLE_ENABLE_SSO_AUTH: ssoEnabled } = await getServerConfig();

      if (ssoEnabled) {
        scope = 'sso';
      }
    } finally {
      const navOptions = { scope };
      if (shouldRedirect) {
        navOptions.redirect = toState;
      }

      return Promise.reject(
        routerStore.navigate('login', navOptions, { replace: true }),
      );
    }
  };

  loginRequired = () => (toState) => {
    const { userStore } = this.stores;
    if (userStore.isLoggedIn) return true;
    // if the user is unauthorized, send them to the login page
    // and redirect back to where they tried to access
    return userStore
      .init()
      .then(() => true)
      .catch(() => this.invalidateAndRedirect(true, toState));
  };

  routes = [
    {
      name: 'default-redirect',
      path: '/',
      canActivate: this.loginRequired,
      component: DefaultRedirectView,
      isForRoles: [],
    },
    {
      name: 'classes',
      path: '/classes',
      component: ClassesPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Manage Classes',
      menuIcon: 'ClassesIcon',
      isForRoles: [ROLES.instructor.name, ROLES.academy_admin.name],
    },
    {
      name: 'classes:view',
      path: '/classes/:classid',
      canActivate: this.loginRequired,
      component: ClassDetailsPageView,
      isForRoles: [ROLES.instructor.name, ROLES.academy_admin.name],
    },
    {
      name: 'classes:view:student',
      path: '/classes/:classid/:studentusername',
      canActivate: this.loginRequired,
      component: ClassStudentDetailsPageView,
      isForRoles: [ROLES.instructor.name, ROLES.academy_admin.name],
    },
    {
      name: 'classes:create:assignment',
      path: '/classes/:classid/assignment',
      canActivate: this.loginRequired,
      component: CreateEditAssignmentPageView,
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'classes:edit:assignment',
      path: '/classes/:classid/assignment/:assignmentid',
      canActivate: this.loginRequired,
      component: CreateEditAssignmentPageView,
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'classes:view:assignment',
      path: '/classes/:classid/assignment/view/:assignmentid',
      canActivate: this.loginRequired,
      component: ViewAssignmentPageView,
      isForRoles: [ROLES.academy_admin.name],
    },
    {
      name: 'academy',
      path: '/academy',
      component: AcademyDetailsPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Manage Academy Instructors',
      isForRoles: [ROLES.academy_admin.name],
    },
    {
      name: 'summary',
      path: '/summary?:q',
      defaultParams: { title: 'summary' },
      canActivate: this.loginRequired,
      component: CourseSummaryPageView,
      menuTitle: 'Access Courses and Assignments',
      isForRoles: [ROLES.student.name],
    },
    {
      name: 'student:quiz',
      path: '/quiz/:classid',
      defaultParams: { title: 'quiz' },
      canActivate: this.loginRequired,
      component: StudentAssignmentListPageView,
      menuTitle: 'Quiz',
      isForRoles: [],
    },
    {
      name: 'student:kc',
      path: '/kc/:classid',
      defaultParams: { title: 'quiz' },
      canActivate: this.loginRequired,
      component: StudentAssignmentListPageView,
      menuTitle: 'Quiz',
      isForRoles: [],
    },
    {
      name: 'academysummary',
      path: '/academysummary',
      component: ConsumptionSummaryPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Academy Summary',
      menuIcon: 'ConsumptionSummaryIcon',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'academysummary:classes',
      path: '/academysummary/classes/:academyid',
      component: ClassesPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Manage Academy Classes',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'academysummary:class:details',
      path: '/academysummary/classes/:academyid/:classid',
      component: ClassDetailsPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Class Details',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'academysummary:instructors',
      path: '/academysummary/instructors/:academyid',
      component: AcademyDetailsPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Manage Academy Instructors',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'academysummary:students',
      path: '/academysummary/students/:academyid',
      component: AcademyStudentsPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Manage Academy Instructors',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'announcements',
      path: '/announcements',
      component: AnnouncementPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Announcements',
      menuIcon: 'AnnouncementsIcon',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'resources',
      path: '/resources',
      component: ResourcesPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Access Resources',
      isForRoles: [ROLES.instructor.name, ROLES.academy_admin.name],
    },

    {
      name: 'resources:link-list',
      path: '/resources/:section',
      component: ResourcesPageView,
      canActivate: this.loginRequired,
      isForRoles: [],
    },

    {
      name: 'studentresources',
      path: '/student/resources',
      component: StudentResourcesPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Access Student Resources',
      isForRoles: [ROLES.student.name],
    },

    {
      name: 'studentresources:link-list',
      path: '/student/resources/:section',
      component: StudentResourcesPageView,
      canActivate: this.loginRequired,
      isForRoles: [],
    },

    {
      name: 'coursesandcertification',
      path: '/coursesandcertification',
      component: CoursesAndCertificationPageView,
      canActivate: this.loginRequired,
      menuTitle: 'View Courses and Certifications',
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'enablement',
      path: '/enablement',
      defaultParams: { title: 'Get Trained and Certified' },
      canActivate: this.loginRequired,
      component: EnablementPageView,
      menuTitle: 'Get Trained and Certified',
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'courses:lab',
      path: '/courses/:course/lab',
      canActivate: this.loginRequired,
      component: Lab,
      isForRoles: [],
      menuTitle: 'Lab',
    },
    {
      name: 'courses:view',
      path: '/courses/:course',
      canActivate: this.loginRequired,
      component: CoursePageView,
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'courses:view.student',
      path: '/:classid',
      canActivate: this.loginRequired,
      component: CoursePageView,
      isForRoles: [ROLES.student.name],
    },
    {
      name: 'courses:page',
      path: '/courses/:course/pages/:page',
      defaultParams: { role: 'instructor' },
      canActivate: this.loginRequired,
      component: CoursePageView,
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'courses:page.student',
      path: '/:classid',
      defaultParams: { role: 'student' },
      canActivate: this.loginRequired,
      component: CoursePageView,
      isForRoles: [ROLES.student.name],
    },
    {
      name: 'exams:view',
      path: '/exam/:slug',
      defaultParams: { title: 'Exam' },
      canActivate: this.loginRequired,
      component: ExamPageView,
      isForRoles: [ROLES.instructor.name],
    },
    {
      name: 'feedback',
      path: '/feedback',
      defaultParams: { title: 'feedback' },
      canActivate: this.loginRequired,
      component: FeedbackPageView,
      menuTitle: 'Give Feedback',
      isForRoles: [
        ROLES.student.name,
        ROLES.instructor.name,
        ROLES.academy_admin.name,
      ],
    },
    {
      name: 'help',
      path: '/help',
      defaultParams: { title: 'help' },
      canActivate: this.loginRequired,
      component: HelpPageView,
      menuTitle: 'Get Support',
      isForRoles: [
        ROLES.student.name,
        ROLES.instructor.name,
        ROLES.academy_admin.name,
      ],
    },
    {
      name: 'submit-support',
      path: '/submit-support',
      defaultParams: { title: 'submit-support' },
      canActivate: this.loginRequired,
      component: SupportCasePageView,
      menuTitle: 'Open a Support Case',
      isForRoles: [
        ROLES.student.name,
        ROLES.instructor.name,
        ROLES.academy_admin.name,
      ],
    },
    {
      name: 'talent-network',
      path: '/talent-network',
      component: TalentNetworkPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Talent Network Portal',
      isForRoles: [ROLES.student.name],
    },
    {
      name: 'reports',
      path: '/reports',
      component: AsyncReportPageView,
      canActivate: this.loginRequired,
      menuTitle: 'Reports',
      menuIcon: 'reportsIcon',
      isForRoles: [ROLES.global.name, ROLES.bdm.name],
    },
    {
      name: 'login',
      path: '/login',
      defaultParams: { scope: 'sso' },
      component: Login,
      children: [
        {
          name: 'sso',
          path: '/sso',
          defaultParams: { scope: 'sso' },
          component: Login,
        },
        {
          name: 'local',
          path: '/local',
          defaultParams: { scope: 'local' },
          component: Login,
        },
      ],
      canActivate: true,
      menuTitle: 'Login',
      isForRoles: [],
    },
  ];
}
export default Routes;
