import React from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import InfoIcon from '../../img/icons/info_2.svg';

import './styles.scss';

const InfoTextButton = (props) => {

  const { infoText } = props;
  const { t } = useTranslation();
  const info = (
    <Popover
      id="info"
      title={t('Information')}
      className="info-panel"
    >
      <p>
        {t(infoText)}
      </p>
    </Popover>
  );

  return (
    <div key='div-info' className="info">
      <OverlayTrigger trigger={['hover', 'focus']} placement="right" key='div-info-lay' overlay={info}>
        <Button variant="link" className="info-btn">
          <SVG  src={InfoIcon}></SVG> 
        </Button>
      </OverlayTrigger>
    </div>
  );
};

InfoTextButton.propTypes = {
  infoText: PropTypes.string,
};

InfoTextButton.defaultProps = {
  infoText: '',
};

export default observer(InfoTextButton);
