import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { 
  Container, 
  Button, 
  Card,
  Accordion,
} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import PlayIcon from '../../../../img/icons/playt.svg';
import infoIcon from '../../../../img/icons/info_2.svg';
import CollapseIcon from '../../../../img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from '../../../../img/icons/web-icon-caret-thin-down.svg';
import LabInstructionsStore from './store';
import VideoWidget from '../../../../components/Video/Widget';
import { KALTURA_CONFIGS } from '../../../../config/constants';


import './styles.scss';

const LabInstructions = props => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new LabInstructionsStore());

  const { lab } = props;
  store.labState = _.get(lab, 'labState');

  const isExpanded = id => store.expandedPanels?.has(id);
  const { 
    classroomWebapp, 
    webApplications 
  } = lab
  
  const classroomWebappUrl = _.get(classroomWebapp, 'url');

  const redirectClassroomWebapp = url => {
    window.open(url, '_blank');
  };

  return (
    <React.Fragment>
      {store.isTourDisplayed && (
        <div className="tour-wrapper">
          <div className="overlay">
            <div className="overlay-content">
              <VideoWidget
                isEmbeddedVideo={true}
                playerId={KALTURA_CONFIGS.videoTutorial.uiConfId}
                entryId={KALTURA_CONFIGS.videoTutorial.labsTutorialEntryId}
              />
              <div className="text-center">
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    store.toggleTour();
                  }}
                >
                  {t('Close video')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}  
      {webApplications.length === 0 && classroomWebappUrl && (
        <Accordion defaultActiveKey="classroom-webapp-info">
        <Card
          eventKey="classroom-webapp-info"
          className="lab-info-panel"
          onToggle={expanded => {
            store.togglePanels('classroom-webapp-info', expanded);
          }}
        >
          <Accordion.Toggle componentClass="div" as={Card.Header} 
            className="filter-panel-toggle" key="classroom-webapp-info" eventKey="classroom-webapp-info">
            <div
              
              className="filter-panel-heading"
              data-analytics-id="lab-ctrl-acc-vt-dash-lp"
            >
              <div className="filter-panel-heading-title">
                <h4>
                  <SVG  src={infoIcon}></SVG> {t('Classroom Webapp')}
                </h4>
                <div className="filter-panel-heading-icon">
                  <SVG
                    src={
                      isExpanded('classroom-webapp-info')
                        ? CollapseIcon
                        : ExpandIcon
                    }
                  >
                    {isExpanded('classroom-webapp-info')
                      ? t('Collapse')
                      : t('Expand')}
                  </SVG>
                </div>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="classroom-webapp-info">
          <Card.Body collapsible className="filter-panel-body">
            <div className="margin-bottom">
              <p>
                {t(
                  'Classroom web application consoles are more appropriate for a command line experience, including cutting and pasting command text, uploading files into the environments, and related activities.',
                )}
              </p>
              <p>
                {t(
                  'The standard VNC console approach is more appropriate for graphical applications and remote desktop environments.',
                )}
              </p>
            </div>
          </Card.Body>
          </Accordion.Collapse>
          <Card.Footer className="filter-panel-footer">
            <div className="footer-btn-wrapper">
              <React.Fragment>
                <Button
                  bsStyle="primary"
                  type="submit"
                  on
                  onClick={() => redirectClassroomWebapp(classroomWebappUrl)}
                >
                  {t('Access Classroom Webapp')}
                </Button>
              </React.Fragment>
            </div>
          </Card.Footer>
          
        </Card>
        </Accordion>
      )}
      <Container fluid className='instruction-wrapper' >
        <h4>
          <SVG className="play-icon" src={PlayIcon}></SVG> {t('Lab Controls')}
        </h4>
        <p>
          {`${t('Click')} `}
          <b>{t('CREATE')}</b>{' '}
          {t(
            'to build all of the virtual machines needed for the classroom lab environment. This may take several minutes to complete. Once created the environment can then be stopped and restarted to pause your experience.',
          )}
        </p>
        <p>
          {`${t('If you')} `}
          <b>{t('DELETE')}</b>{' '}
          {t(
            'your lab, you will remove all of the virtual machines in your classroom and lose all of your progress.',
          )}
        </p>
        <div className="lab-video-btn-wrapper">
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              store.toggleTour();
            }}
          >
            {t('Watch tutorial')}
          </Button>
        </div>
      </Container>
    </React.Fragment>
  );
};

LabInstructions.propTypes = {
  lab: PropTypes.object,
};

LabInstructions.defaultProps = {
  lab: {},
};

export default observer(LabInstructions);
