/**
 * @file Provides dynamic links, text and title in three responsive containers
 * @author Attila farkas <afarkas@redhat.com>
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Button } from 'react-bootstrap';
import './styles.scss';

const LinkContainer = (props) => {
  const {
    title,
    description,
    actionText,
    actionUrl,
    openActionInNewTab,
    onClick,
  } = props;

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="link-container-wrapper col-sm">
        <h4>{t(title)}</h4>
        <div className="text-wrapper">{parse(description)}</div>
        {onClick ? (
          <Button variant="link" onClick={onClick}>
            {t(actionText)}
          </Button>
        ) : (
          <a
            href={actionUrl}
            {...(openActionInNewTab && {
              target: '_blank',
              rel: 'noopener noreferrer',
            })}
          >
            {t(actionText)}
          </a>
        )}
      </div>
    </React.Fragment>
  );
};

LinkContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  actionText: PropTypes.string.isRequired,
  actionUrl: PropTypes.string.isRequired,
  openActionInNewTab: PropTypes.bool,
  onClick: PropTypes.func,
};

LinkContainer.defaultProps = {
  description: '',
  openActionInNewTab: false,
};

export default LinkContainer;
