const get = (key) => {
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;
}

const set = (key, value) => {
    value && sessionStorage.setItem(key, JSON.stringify(value));
}

const remove = (key) => {
    sessionStorage.removeItem(key);
}

export { 
    get,
    set,
    remove,
};