import { observable, action, computed } from 'mobx';
import { getLTI1P3ProviderInfo } from '../../services/LtiService';
class ExternalResourcesStore { 
    @observable loaded = false;
    @observable providers = [];

    constructor(userStore) {
        this.userStore = userStore;
    }
    
    @action async getLTI1P3ProviderInfo() {
        try {
            // Fetch provider details
            var provider_details = await getLTI1P3ProviderInfo();
            
            provider_details.forEach((provider) => {
                //  Arrange records for each provider
                var provider_info = {};
                provider_info.section_header = provider.section_header;
                provider_info.lti1p3_provider_name = provider.lti1p3_provider_name;
                provider_info.courses = []; 
                
                // Prepare course info for each provider
                const redirect_url = provider.platform_login_url;
                let course_info = provider.course_info;
                course_info?.length &&
                course_info.forEach((l) => {
                    let info = {};
                    info.title = l?.course_title;
                    info.firstLine = l?.desc_first_line;
                    info.url = `${redirect_url}?resource_link_id=${l?.resource_id}&lti_provider=${provider.lti1p3_provider_name}` || null;
                    provider_info.courses.push(info);
                });

                // Set the provider info
                this.providers.push(provider_info);
            });
          
        } catch (error) {
            this.loaded = false;
            console.error('Error getLTI1P3ProviderInfo', error);
        } finally {
            this.loaded = true;
            return this.providers;
        }
    }

    @computed get canConnectLTI1P3() {
        return this.userStore.isStudent && this.userStore.hasPersonalInfo
            ? true
            : false;
    }
}

export default ExternalResourcesStore;
