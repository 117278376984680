import { delete as del, get, post, patch } from 'axios';
import { ANNOUNCEMENT_GET_API, ANNOUNCEMENT, ANNOUNCEMENT_DELETE_API , ANNOUNCEMENT_UPDATE_API, COUNTRIES} from '../config/constants';

export const getAnnouncements = async () => {
  try {
    const response = await get(ANNOUNCEMENT_GET_API);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
  
export const createAnnouncement = async (announcement) => {
  try {
    const response = await post(ANNOUNCEMENT, announcement);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateAnnouncement = async (announcement) => {
  try {
    const response = await patch(ANNOUNCEMENT_UPDATE_API.replace('<doc_id>', announcement.doc_id), announcement);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteAnnouncement = async (doc_id) => {
  try {
    const response = await del(ANNOUNCEMENT_DELETE_API.replace('<doc_id>', doc_id));
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export const displayAnnouncements = async () => {
      try {
        const response = await get(ANNOUNCEMENT);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCountries = async () => {
  try {
    const response = await get(COUNTRIES);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};



export default getAnnouncements;