import React from 'react';
import Classes from '../../components/Classes';

const ClassesView = () => {
  return (
    <Classes />
  );
};

export default ClassesView;
