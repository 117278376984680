import React from 'react';
import { observer } from 'mobx-react';

const Feedback = React.lazy(() =>
  import(/* webpackChunkName: 'examdetails-component' */ '../../components/Feedback'),
);

const FeedbackView = () => {

  return (
   < Feedback />
  );
};

export default observer(FeedbackView);
