import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Row, Col, Button } from 'react-bootstrap';
import VersionProgress from '../VersionProgress';
import CircularIcon from '../CircularIcon';
import ExpandToggle from '../ExpandToggle';
import './styles.scss';
import CertificateOfAttendanceButton from '../CertificateOfAttendance/Button';
import moment from 'moment';

const ExpandableCourseSummary = ({
  course,
  routerStore,
  progress,
  courseStore,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDatesForCourse = (course) => {
    const separationSymbol = '-';
    let render = ' ';
    if (course.code !== '') render += `${separationSymbol} `;
    render += `${course.displayStartDate} - ${course.displayEndDate}`;
    return render;
  };

  const courseControls = (progress) => {
    if (!course.customClass) {
      const completionPercentage = course.completionPercentage;
      const dateToTimestamp = (date) => {
        const dateObj = date ? moment(date) : moment();
        return dateObj.toISOString();
      };
      return (
        <React.Fragment>
          {progress >= completionPercentage && (
            <CertificateOfAttendanceButton
              courseSlug={course.slug}
              classId={course?.class_id}
              timestamp={dateToTimestamp(new Date())}
            />
          )}
          <Button
            hidden={!course.active}
            className="launch-button ml-2"
            variant="link"
            onClick={() => {
              routerStore.navigate(courseStore.courseRouteName, {
                course: course.slug,
                slug: course.slug,
                classid: course?.class_id,
              });
            }}
          >
            {t('Launch')}
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {course.fileUrl ? (
            <a href={course.fileUrl}>{t('Download Class')}</a>
          ) : (
            t('Class has not any attached file')
          )}
        </React.Fragment>
      );
    }
  };
  return (
    <React.Fragment>
      <Accordion>
        <Card className="expandable-course-summary">
          <Card.Header>
            <Row>
              {course ? (
                <React.Fragment>
                  <Col className="circle-container">
                    <CircularIcon
                      backgroundColor={course.active ? '' : '#6A6E73'}
                      iconName="modality-course"
                      imageStyle={{
                        width: '135%',
                        height: '135%',
                        marginTop: '-12px',
                        marginLeft: '-6px',
                      }}
                    />
                  </Col>
                  <Col
                    xs={8}
                    className={
                      course.active ? 'course-info' : 'course-info disabled'
                    }
                  >
                    <p>{course.title}</p>
                    <p>
                      {course.code.toUpperCase()} {renderDatesForCourse(course)}
                    </p>
                  </Col>
                  <Col xs={3} className="course-controls-closed">
                    {!isExpanded && courseControls(progress)}
                    {course && !course.customClass && (
                      <ExpandToggle isExpanded={isExpanded} toggle={toggle} />
                    )}
                  </Col>
                </React.Fragment>
              ) : (
                <p className="m-3">
                  {t('You are not enrolled in a class at the moment.')}
                </p>
              )}
            </Row>
            {course && !course.customClass && (
              <VersionProgress
                progress={progress}
                active={course.active}
                displayVersionDropdown={false}
                versions={[]}
              />
            )}
          </Card.Header>
          {course && !course.customClass && (
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {course.collateral?.outline && (
                  <React.Fragment>
                    <h5>{`${t('Outline')}:`}</h5>
                    <div
                      className="course-outline"
                      dangerouslySetInnerHTML={{
                        __html: course.collateral.outline.body,
                      }}
                    />
                  </React.Fragment>
                )}

                {course.collateral?.audience && (
                  <React.Fragment>
                    <h5>{`${t('Audience')}:`}</h5>
                    <div
                      className="course-audience"
                      dangerouslySetInnerHTML={{
                        __html: course.collateral.audience.body,
                      }}
                    />
                  </React.Fragment>
                )}

                {course.collateral?.prerequisites && (
                  <React.Fragment>
                    <h5>{`${t('Prerequisites')}:`}</h5>
                    <div
                      className="course-prerequisites"
                      dangerouslySetInnerHTML={{
                        __html: course.collateral.prerequisites.body,
                      }}
                    />
                  </React.Fragment>
                )}

                <h5>{`${t('Instructor')}:`}</h5>
                <div className="course-instructor">
                  {course.instructors.map((instructor, index) => (
                    <p key={index}>{instructor}</p>
                  ))}
                </div>
                <div className="text-right">{courseControls(progress)}</div>
              </Card.Body>
            </Accordion.Collapse>
          )}
        </Card>
      </Accordion>
    </React.Fragment>
  );
};

ExpandableCourseSummary.propTypes = {
  course: PropTypes.shape({
    code: PropTypes.string,
    collateral: PropTypes.shape({
      audience: PropTypes.shape({
        body: PropTypes.string,
      }),
      outline: PropTypes.shape({
        body: PropTypes.string,
      }),
      prerequisites: PropTypes.shape({
        body: PropTypes.string,
      }),
    }),
    completionPercentage: PropTypes.any,
    customClass: PropTypes.any,
    fileUrl: PropTypes.any,
    instructors: PropTypes.array,
    progress: PropTypes.number,
    slug: PropTypes.any,
    title: PropTypes.string,
  }),
  progress: PropTypes.number,
  routerStore: PropTypes.shape({
    navigate: PropTypes.func,
  }),
};

export default ExpandableCourseSummary;
