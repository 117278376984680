import { action, computed, observable } from 'mobx';
import {
  getLabLaunchButtonHTML,
  getLabProviderDetails,
} from '../../services/LtiService';

class LabMaterialsStore {
  @observable coursesWithLabs = {};
  @observable ready = false;
  @observable labProviders = {};

  @observable providersInfo = [];
  @observable ready = false;

  constructor(classStore, userStore) {
    this.classStore = classStore;
    this.userStore = userStore;
  }

  @action async getMaterials(course) {
    try {
      if (this.isCourseAllowingLabs(course) && this.canBuyLabs) {
        await this.getLabProvidersForCourse(course);
      }
    } catch (error) {
      console.error('Error getMaterials', error);
      throw new Error(error);
    } finally {
      this.ready = true;
    }
  }

  @action async refreshLabProviders(course) {
    try {
      if (this.isCourseAllowingLabs(course) && this.canBuyLabs) {
        await this.getLabProvidersForCourse(course);
      }
    } catch (error) {
      console.error('Error refreshLabProviders', error);
      throw new Error(error);
    }
  }

  @action isCourseAllowingLabs(course) {
    const allowedCourse =
      course.code.toLowerCase() !== 'do101'
        ? course.code.toLowerCase() in this.coursesWithLabs
        : course.slug.toLowerCase() === 'do101-4.2.2';
    return allowedCourse;
  }

  @action getCourseTitle(course) {
    return course.code.toLowerCase() === 'do101'
      ? course.slug.toLowerCase() === 'do101-4.2.2'
        ? this.labProviders[this.coursesWithLabs[course.code.toLowerCase()]]
            ?.title
        : ''
      : this.labProviders[this.coursesWithLabs[course.code.toLowerCase()]]
          ?.title || 'Additional Lab';
  }

  @action getCourseDescriptionPart2(course) {
    return course.code.toLowerCase() !== 'do101'
      ? 'Our lab partners provide affordable cloud based labs for Red Hat System Administrator I, II and III, which can be accessed globally. The labs are available for purchase in multiple options to choose from through our vendors, Infosec Learning and NDG.'
      : '';
  }

  @action getLabLaunchButton = async (course, labProvider) => {
    try {
      const htmlButton = await getLabLaunchButtonHTML(course, labProvider);
      return htmlButton;
    } catch (error) {
      console.error('Error getLabLaunchButton', error);
    }
  };

  @action async getLabProvidersForCourse(course) {
    let providers = [];
    try {
      const providersIds = this.coursesWithLabs[course.code];
      const providersIdSlug = this.coursesWithLabs[course.slug];
      if (providersIdSlug && !providersIds.includes(providersIdSlug[0])) {
        providersIds.push(providersIdSlug[0]);
      }
      for (let id of providersIds) {
        let info = this.labProviders[id];
        const buttonHtml = await this.getLabLaunchButton(course, id);
        info.providerTitle = info.name;
        info.id = id;
        info.buttonHtml = buttonHtml;

        if (
          this.coursesWithLabs[course.code + '_solution']?.find((x) => x === id)
        ) {
          course.code = course.code + '_solution';
          const withSolutionButtonHtml = await this.getLabLaunchButton(
            course,
            id,
          );
          info.withSolutionButtonHtml = withSolutionButtonHtml;
        }
        providers.push(info);
      }

      this.providersInfo = providers?.sort((a, b) => {
        return a['providerTitle'].localeCompare(b['providerTitle']);
      });
    } catch (error) {
      this.providersInfo = [];
      console.error('Error getLabProvidersForCourse', error);
    } finally {
      return this.providersInfo;
    }
  }

  @action async getLabProviderDetails() {
    let labProviders_info = {};
    try {
      const labProviderDetails = await getLabProviderDetails();
      labProviderDetails?.length &&
        labProviderDetails.forEach((l) => {
          let info = {};
          info.course = l?.course;
          info.name = l.lti_provider_name;
          info.url = l?.url || null;
          info.title = l?.title || null;
          this.labProviders[info.name] = info;
          l.course?.length &&
            l.course.forEach((c) => {
              if (!this.coursesWithLabs[c]?.includes(l.lti_provider_name)) {
                if (this.coursesWithLabs[c]) {
                  this.coursesWithLabs[c].push(l.lti_provider_name);
                } else {
                  this.coursesWithLabs[c] = [l.lti_provider_name];
                }
              }
            });
        });
    } catch (error) {
      console.error('Error getLabProviderDetails', error);
    } finally {
      return labProviders_info;
    }
  }

  @computed get canBuyLabs() {
    return this.userStore.isStudent && this.userStore.hasPersonalInfo
      ? true
      : false;
  }
}

export default LabMaterialsStore;
