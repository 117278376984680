import { observable, action } from 'mobx';
import { displayAnnouncements } from '../../../services/AnnouncementService';
class DisplayAnnouncementsStore {
  
  @observable ready = false;

  @observable announcement = {
    value: 0,
    slides: [],
  };

  @action getAnnouncements = async () => {
    try {
      this.announcement.slides =  await displayAnnouncements();

      this.ready = true;
    } catch (e) {
      console.error(e);
      this.ready = false;
      this.announcement.slides = [];
    }
    
  }
  
  @action dismissAnnouncement = () => {
    console.log('dismissed');
  }

}

export default DisplayAnnouncementsStore;
