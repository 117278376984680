import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Col, Jumbotron,  Row, Container } from 'react-bootstrap';
import ViewLoader from '../../Loading/View';
import { storesContext } from '../../../stores';

const GenericViewError = () => {
  const { t } = useTranslation();
  const { routerStore, uiStore } = useContext(storesContext);

  uiStore.title = routerStore?.route?.params?.title;

  const { title } = uiStore;
  document.title = t(title);

  return (
    <Jumbotron fluid>
  <Container>
    <h4>{t(title)}</h4>
    <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col sm={12}>
            {t(
              "We're having trouble displaying this page. The issue has been reported to our developers, and they'll fix it shortly.",
            )}
          </Col>
        </Row>
      </React.Suspense>
  </Container>
</Jumbotron>
    
  );
};

export default observer(GenericViewError);
