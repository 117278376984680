import { action, observable } from 'mobx';

import { getStudentDetailsByClassId } from '../services/ClassStudentService';

class ClassStudentStore {
  @observable students = [];
  @observable classId = null;

  @observable error;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

 
  // list of students in a class
  @action getStudentDetailsByClassId = async (classId) => {
    this.classId = classId;
    try {
      this.students = await getStudentDetailsByClassId(classId);
    } catch (e) {
      console.error(e);
      this.students = [];
    } finally {
      return this.students;
    }
  };

  // list of students in a class
  @action getStudentDetailsByUserName = async (classId,studentUserName) => {
      let studentDetail = null;
    try {
        if(this.classId !== classId){
            await this.getStudentDetailsByClassId(classId);
        }
        studentDetail = this.students && this.students[studentUserName];
    } catch (e) {
      console.error(e);
      studentDetail  = null;
    } finally {
      return studentDetail;
    }
  };
}

export default ClassStudentStore;
