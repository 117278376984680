import { get, post, put, patch } from 'axios';
import { ALERTS_API_URL } from '../config/constants';

export const getAlerts = async () => {
  try {
    const response = await get(ALERTS_API_URL);
    return response.data?.items;
  } catch (err) {
    throw new Error(err.response?.data?.error || `Couldn't retrieve alerts`);
  }
};

export const updateAlert = async (uuid, data) => {
  try {
    const response = await put(`${ALERTS_API_URL}${uuid}`, data);
    return response.data;
  } catch (err) {
    throw new Error(err.response?.data?.error || `Couldn't update alert`);
  }
};

export const partiallyUpdateAlert = async (uuid, data) => {
  try {
    const response = await patch(`${ALERTS_API_URL}${uuid}`, data);
    return response.data;
  } catch (err) {
    throw new Error(
      err.response?.data?.error || `Couldn't partially update alert`,
    );
  }
};

export const createAlert = async data => {
  try {
    const response = await post(ALERTS_API_URL, data);
    return response.data;
  } catch (err) {
    throw new Error(err.response?.data?.error || `Couldn't create alert`);
  }
};
