import React from 'react';
import PropTypes from 'prop-types';
import WithCardContainer from '../CardContainer';
import './styles.scss';
import {  Card } from 'react-bootstrap';
const IconCardContainer = ({
  iconName, // iconName is used to display an icon specified in the file _icons.scss
  title,
  firstLineElement,
  secondLineElement,
  cardColor, // cardColor is passed down to `WithCardContainer`
  onCardClick,
  showPendingStatus
}) => {
  return (
    
    <Card.Body onClick={(e) => onCardClick(e)} className="icon-card-container">
      <div className={`icon-${iconName} icon`}></div>
      <div className="card-text">
        <p className="font-weight-bold">{title}</p>
        <p>{firstLineElement}</p>
        <p>{secondLineElement}</p>
      </div>
      {showPendingStatus && <div className="pending-status-icon"></div>}
    </Card.Body>
    
  );
};

IconCardContainer.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  firstLineElement: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  secondLineElement: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cardColor: PropTypes.string,
  onCardClick:PropTypes.func,
  showPendingStatus: PropTypes.bool
};

IconCardContainer.defaultProps = {
  onCardClick: () => {},
  showPendingStatus:false
};

export default WithCardContainer(IconCardContainer);
