import PropTypes from "prop-types";
import React from 'react';
import VersionProgress from '../../VersionProgress';
import { Card } from 'react-bootstrap';
import CircularIcon from '../../CircularIcon';
import './styles.scss';

const LearningPathElement = ({ offering, progress }) => {
  return (
    <React.Fragment>
      <Card className={`learning-path-element ${offering.modality}`}>
        <Card.Body>
          <div className="icon-card-container">
            <div className="icon-container">
              {offering.modality === 'course' ? (
                <CircularIcon
                  iconName={`modality-${offering.modality}`}
                  imageStyle={{
                    width: '135%',
                    height: '135%',
                    marginTop: '-35%',
                    marginLeft: '-18%',
                  }}
                />
              ) : (
                <CircularIcon
                  iconName={`modality-${offering.modality}`}
                  backgroundColor="var(--secondary)"
                />
              )}
            </div>
            <div className="card-text ml-3">
              <p className="font-weight-bold">{offering.title}</p>
              <p>{offering.code.toUpperCase()}</p>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <VersionProgress progress={progress} displayVersionDropdown={false} />
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
};

LearningPathElement.propTypes = {
  offering: PropTypes.shape({
    code: PropTypes.string,
    modality: PropTypes.string,
    title: PropTypes.string
  }),
  progress: PropTypes.number
}

export default LearningPathElement;
