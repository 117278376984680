import { observable, action, computed } from 'mobx';
import sortBy from 'lodash/sortBy';
import { getProgress, setCourseProgress } from '../services/ProgressService';

class ProgressStore {
  @observable progressDict = {};
  @observable progressLoaded = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action async getProgress(isStudent = false) {
    try {
      let progress = await getProgress(isStudent);

      this.progressDict = progress;
    } finally {
      this.progressLoaded = true;
    }
  }

  @action async setCourseProgress(
    slug,
    pageUUID,
    entityKeyToTrackProgressWith,
    entityValueToTrackProgressWith,
  ) {
    try {
      const progress = await setCourseProgress(
        slug,
        pageUUID,
        entityKeyToTrackProgressWith,
        entityValueToTrackProgressWith,
      );
      if (progress.rv) {
        if (
          entityKeyToTrackProgressWith === 'class_id' ||
          entityValueToTrackProgressWith in this.progressDict
        ) {
          this.progressDict[entityValueToTrackProgressWith] = progress.rv;
        } else {
          this.progressDict[slug] = progress.rv;
        }
      }

      return progress;
    } catch (error) {
      console.error('Error sendProgress:', error);
    }
  }

  @computed get progressByCourse() {
    const progressDictByCourseCode = Object.entries(this.progressDict).reduce(
      (dict, [slug, progress]) => {
        // eslint-disable-next-line
        let [courseCode] = slug.split('-');
        if (courseCode.slice(-2) === 'vc') {
          courseCode = courseCode.slice(0, -2); // remove vc from course code;
        }
        if (
          !dict[courseCode] ||
          progress['@timestamp'] > dict[courseCode]['@timestamp']
        ) {
          dict[courseCode] = progress;
        }
        return dict;
      },
      {},
    );

    const progressArray = Object.values(progressDictByCourseCode);

    return sortBy(progressArray, '@timestamp').reverse();
  }

  @action getProgressForEntityId(entityValue, entityKey) {
    if (
      entityValue in this.progressDict &&
      this.progressDict[entityValue][entityKey] === entityValue
    ) {
      return this.progressDict[entityValue];
    }
  }

  @action getHighestProgressForCode(code) {
    let selectedElement;
    for (let slug of Object.keys(this.progressDict)) {
      if (slug.startsWith(code)) {
        const progress = this.progressDict[slug];
        if (
          selectedElement === undefined ||
          progress.total_progress > selectedElement.total_progress
        )
          selectedElement = progress;
      }
    }

    return selectedElement;
  }

  @action clearProgress() {
    this.progressDict = {};
  }
}

export default ProgressStore;
