import { get } from 'axios';
import { LEARNING_PATH_API } from '../config/constants';

const getLearningPaths = async () => {
  try {
    const response = await get(`${LEARNING_PATH_API}`);
    const learningPaths = response.data;

    return learningPaths;
  } catch (error) {
    throw new Error(error);
  }
};

export { getLearningPaths };
