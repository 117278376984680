import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import { submitSupportCase } from '../../services/SupportService';

class SupportCaseStore {

  @observable submitting = false;

  @observable submitSuccess = false;

  @observable submitFailure = false;

  @observable formData = {};

  @observable classes = [];

  @observable schemaForm = {};

  uiSchema = {};

  impactFields = {};

  constructor(rootStore, schemaForm, uiSchema, impactFields) {
    this.rootStore = rootStore;
    this.schemaForm = schemaForm;
    this.uiSchema = uiSchema;
    this.impactFields = impactFields;
  }

  @action prepareData() {
    const { title, description, classId, impact, subcategory } = this.formData;
    const selectedClassObj = classId ? this.classes.filter(
      (val) => val.doc_id === classId
    ) : {};
    const courseCode = classId ? selectedClassObj[0]?.course_slug : this.formData.courseCode;
    const data = {
      ...this.formData.collected,
      title,
      description,
      impact: this.impactFields[impact],
      subcategory:
        subcategory === 'Learning Platform' ? 'ROL Portal' : subcategory,
      courseCode,
      country: this.rootStore.academyStore.academyDetails?.country,
      academy_region: this.rootStore.academyStore.academyDetails?.academy_region,
      academy_admin_username: this.rootStore.academyStore.academyDetails?.username,
      academy_admin_name: this.rootStore.academyStore.academyAdminName,
      academy_admin_email: this.rootStore.academyStore.academyDetails?.email,
      ...(classId && selectedClassObj[0] && {
        class_name: selectedClassObj[0].class_name,
        course_title: selectedClassObj[0].course_title,
        custom_class: selectedClassObj[0].custom_class,
        start_date: selectedClassObj[0].start_date,
        end_date: selectedClassObj[0].end_date,
        coa_percentage: selectedClassObj[0].coa_percentage,
        class_instructors: selectedClassObj[0].instructors?.toString(),
      }),
    };
    return data;
  }

  @computed get hasAnyInput() {     
    return Object.keys(this.formData).length > 2 ? true : false ;
  }

  @action async submitSupportCase() {
    try {
      this.submitting = true;
      await submitSupportCase(this.prepareData());
      this.submitSuccess = true;
    } catch (error) {
      console.error('submit error:', error);
      this.submitFailure = true;
    } finally {
      this.submitting = false;
    }
  }

  @action cancelSupportCaseForm = () => {
    this.formData = {};
    this.rootStore.routerStore.navigate('help');
  };

  @action dismissAlert = () => {
    if (this.submitFailure) {
      this.submitFailure = !this.submitFailure;
    } else {
      this.submitSuccess = !this.submitSuccess;
      this.cancelSupportCaseForm();
    }
  };
}

export default SupportCaseStore;
export const SupportCaseContext = createContext(null);