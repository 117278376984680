import { observable, action } from 'mobx';

class LabInstructionsStore {
  @observable isTourDisplayed = false;

  @action toggleTour = () => {
    this.isTourDisplayed = !this.isTourDisplayed;
  };


  @observable expandedPanels = new Set();
  @action togglePanels = (id, expanded) => {
    return expanded
      ? this.expandedPanels.add(id)
      : this.expandedPanels.delete(id);
  };
  
}

export default LabInstructionsStore;
