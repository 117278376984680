import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { storesContext } from '../../stores';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';
import BackGroundImage from '../../img/rha-banner.jpg';
import RHALogo from '../../img/rh-academy-logo.png';
import LinkContainer from '../../components/LinkContainer';
import shortid from 'shortid';
import links from '../../mocks/links.mock';

import { RHA_SUPPORT_LINK } from '../../config/constants';
import { Alert } from 'react-bootstrap';
const DefaultRedirectView = () => {
  const { t } = useTranslation();
  const { routerStore, userStore } = useContext(storesContext);
  const UnAuthorizedAccess = () => (
    <React.Fragment>
      <div className="login-unauthorized">
        <div className="login-banner-image">
          <span className="logo-wrapper d-flex justify-content-center">
            <img src={RHALogo} alt="Red Hat Academy Portal Logo" />
          </span>
          <img
            className="background"
            src={BackGroundImage}
            alt="Red Hat Academy Portal Login"
          />
        </div>
        <Col>
          <div className="login-form-wrapper">
            <h1 className="login-title">
              {t('Unauthorized Red Hat Academy account')}
            </h1>
            <Alert variant="danger">
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`The username used to login is not associated with any Red Hat Academy. Please make sure to use RHN-Id to login into the platform. <br/>If you are already a member of a registered Red Hat Academy but unable to access the Platform, please <a  target="_blank"
                  rel="noopener noreferrer" href="${RHA_SUPPORT_LINK}">contact our support team </a> for further assistance.`),
                }}
              ></p>
            </Alert>
          </div>
        </Col>
        <div className="login-links">
          <div className="container link-row-wrapper">
            <Row className="link-row">
              {links.map((link) => {
                return <LinkContainer key={shortid.generate()} {...link} />;
              })}
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  if (userStore.role) {
    return routerStore.navigate(userStore.role.defaultRoute);
  } else {
    return <UnAuthorizedAccess />;
  }
};

export default observer(DefaultRedirectView);
