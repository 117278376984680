import { observable, computed, action } from 'mobx';
import {
  getQuotas,
  getCurrentUserRecord,
  getUserGroups,
  setPreferredRole,
  patchUserRecord,
} from '../services/UserService';

import { ROLES } from '../config/constants';

export default class UserStore {
  @observable _user = {};
  @observable _isLoggedIn = false;
  @observable groups = [];
  @observable operationInCourse = false;
  @observable error;
  @observable quotas = {};
  

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  async init() {
    const user = await this.getCurrentUser();
    await this.getQuotas();
    return user;
  }
  @computed get user() {
    return this._user;
  }
  @computed get isLoggedIn() {
    return this._isLoggedIn;
  }

  @computed get isAdmin() {
    return this.groups?.includes('Admin');
  }

  @computed get role() {
    let _role = null;

    if (this._user?.role) {
      let chosenRole;
      if (this._user.role.length === 1) {
        chosenRole = this._user.role[0];
      } else if (this._user.role.length > 1) {
        chosenRole = this._user.preferred_role || this._user.role[0];
      }

      _role = this.getRoleDefinition(chosenRole);
    }

    return _role;
  }

  @computed get allRoles() {
    return this._user?.role;
  }

  @computed get isInstructor() {
    return this.role === ROLES.instructor;
  }
  @computed get isAcademyAdmin() {
    return this.role === ROLES.academy_admin;
  }

  @computed get isStudent() {
    return this.role === ROLES.student;
  }

  @computed get isRegionalBDM() {
    return this.role === ROLES.bdm;
  }

  @computed get isGlobalBDM() {
    return this.role === ROLES.global;
  }

  @computed get roleRegion() {
    return this.user?.role_region;
  }

  @computed get username() {
    return this.user?.username;
  }

  @computed get uuid() {
    return this.user?.uuid;
  }

  @computed get firstName() {
    return this.user?.first_name;
  }

  @computed get lastName() {
    return this.user?.last_name;
  }

  @computed get name() {
    const firstName = this.user?.first_name;
    const lastName = this.user?.last_name;
    const name = firstName && lastName ? `${firstName} ${lastName}` : undefined;
    return this.preferredName || name;
  }

  @computed get preferredName() {
    return this.user?.preferred_name;
  }

  @computed get email() {
    return this.user?.default_email;
  }

  @computed get hasPersonalInfo() {
    return this.firstName !== undefined && this.lastName !== undefined && this.email !== undefined;
  }

  @computed get hasAgreedQuestionBankDisclaimer() {
    return this._user?.acceptedQBDisclaimer
      ? this._user?.acceptedQBDisclaimer
      : false;
  }

  @computed get academyStore() {
    return this.rootStore?.academyStore;
  }

  @computed get hasLabHours() {
    if (this.quotas) {
      const hours = this.quotas?.lab_quotas;

      console.warn(
        'hasLabHours',
        hours?.lab_hours_allowed > hours?.lab_hours_used,
      );
      return hours?.lab_hours_allowed > hours?.lab_hours_used;
    }
    return true;
  }

  @computed get labHoursUsed() {
    if (this.quotas) {
      const hours = this.quotas?.lab_quotas;

      return hours !== undefined && hours.lab_hours_used !== undefined
        ? hours.lab_hours_used
        : '';
    }
    return '';
  }

  @computed get labHoursAllowed() {
    if (this.quotas) {
      const hours = this.quotas?.lab_quotas;

      return hours !== undefined && hours.lab_hours_allowed !== undefined
        ? hours.lab_hours_allowed
        : '';
    }
    return '';
  }

  set isLoggedIn(bool) {
    this._isLoggedIn = bool;
  }

  set user(newUser) {
    this._user = newUser;
  }

  set preferredRole(roleName) {
    this._user.preferred_role = roleName;
  }

  @action async changePreferredRole(roleName) {
    try {
      await setPreferredRole(roleName);
      this.preferredRole = roleName;
    } catch (error) {
      this.error = error;
    }
  }

  @action getRoleDefinition(roleName) {
    let roleDef;
    Object.keys(ROLES).forEach((r) => {
      if (ROLES[r].name === roleName?.toLowerCase()) {
        roleDef = ROLES[r];
      }
    });

    return roleDef;
  }

  @action getCurrentUser = async (force) => {
    if (this.user?.uuid && !force) {
      return this.user;
    }

    const user = await getCurrentUserRecord();

    this.isLoggedIn = true;

    this.user = user || this.user;
    return this.user;
  };

  @action async invalidateUser() {
    this.isLoggedIn = false;
    this.user = {};
  }
  @action getCurrentUserGroups() {
    return getUserGroups(this.user.uuid).then((groups) => {
      this.groups = groups;
      return this.groups;
    });
  }

  @action submitQuestionBankDisclaimer(e) {
    const isChecked = e.target.checked;
    this._user.hasDisclaimer = isChecked;
    this.partiallyUpdateUserRecord({ acceptedQBDisclaimer: isChecked });
  }

  @action async partiallyUpdateUserRecord(data) {
    return patchUserRecord(this.user.uuid, data).then((record) => {
      this.user = record;
      return this.user;
    });
  }

  @action async getQuotas(classId = '') {
    let quotaParams = {};

    if (this.isStudent && classId !== '') {
      quotaParams.class_id = classId;
    }

    return getQuotas(quotaParams)
      .then((json) => {
        this.quotas = json;

        return this.quotas;
      })
      .catch(() => {
        this.quotas = {};
      });
  }
}
