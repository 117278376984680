const loginProblems = `<p>If you are unable to login, please <a href="https://sso.redhat.com/" target="_blank"> verify your user credentials here</a>. If you are already a member of a registered Red Hat Academy but unable to authenticate here, please contact our support team for further assistance.</p>`;
const notAcademyMember = `<p>Whether you're offering, teaching, or taking our curriculum, you'll enjoy the unique advantages provided by our program.</p>`;
const moreStuff = `<p>Every Red Hat Online Training course comes with access to the Red Hat Customer Portal, including its Knowledge Base, Discussion Communities, and Tools.</p>`;

export default [
  {
    title: `Problems logging in?`,
    description: loginProblems,
    actionText: 'Contact Support',
    actionUrl: 'mailto:tecsupport-rha@redhat.com',
    openActionInNewTab: true,
  },
  {
    title: `Not a Red Hat Academy member?`,
    description: notAcademyMember,
    actionText: 'About Red Hat Academy',
    actionUrl: 'https://www.redhat.com/en/services/training/red-hat-academy',
    openActionInNewTab: true,
  },
  {
    title: `Looking for more stuff to explore?`,
    description: moreStuff,
    actionText: 'Red Hat Customer Portal',
    actionUrl: 'https://access.redhat.com/',
    openActionInNewTab: true,
  },
];
