/**
 * @file Creates classes/batches with selectable dynamic course list, date range, emails, and csv upload
 * @author Attila farkas <afarkas@redhat.com>
 */

import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { PropTypes } from 'prop-types';
import {
  Form,
  Button,
  Popover,
  OverlayTrigger,
  Modal,
  Row,
  Col,
} from 'react-bootstrap';

import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewLoader from '../../../components/Loading/View';
import {
  MAIL_FORMAT,
  PERCENTAGES,
  MAX_FILE_SIZE_BYTES,
} from '../../../config/constants';

import SVG from 'react-inlinesvg';
import Dropzone from 'react-dropzone';

import DeleteIcon from '../../../img/icons/trash.svg';
import arrowDown from '../../../img/icons/arrow-down.svg';
import checkMark from '../../../img/icons/check-mark.svg';

import { storesContext } from '../../../stores';
import CreateClassStore, { CreateClassContext } from './store';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import 'react-day-picker/lib/style.css';
import 'react-multi-email/style.css';
import './styles.scss';
import DateRangePicker from '../../DateRangePicker';

import BulkUpload from '../../BulkUpload';

const ClassStudentInput = React.lazy(() => import('../ClassStudentInput'));
const ClassStudentDeleteModal = React.lazy(() =>
  import('../ClassStudentDeleteModal'),
);

const CreateClass = (props) => {
  const { i18n, t } = useTranslation();
  const { catalogStore, userStore, academyStore } = useContext(storesContext);
  const store = useLocalStore(
    () => new CreateClassStore(catalogStore, userStore, academyStore),
  );

  const {
    dateRange,
    submitClass,
    editClass,
    confirm,
    cancelForm,
    resetDropFile,
    submitFailure,
    dismissAlert,
    charCounter,
    addBYOName,
  } = store;

  const { isInstructor } = userStore;

  const getSelectedCourse = (e) => {
    const selectedCourseSlug =
      e.target.options[e.target.selectedIndex]?.getAttribute('slug');
    if (selectedCourseSlug) {
      store.selectedCourse.courseName = e.target.value;
      store.selectedCourse.courseCode = e.target.value.substr(
        0,
        e.target.value.indexOf(' '),
      );

      store.selectedCourse.courseSlug = selectedCourseSlug;
    }
  };

  const setPercentage = (e) => {
    const value = parseFloat(e.currentTarget.value);
    store.classToCreate.coa_percentage = value;
  };

  const isCSVStudentValid = (student) => {
    return (
      student.RHNID &&
      student.EMAIL &&
      student.EMAIL.match(MAIL_FORMAT) &&
      student.RHNID !== '' &&
      student.RHNID?.toLowerCase()?.trim() !==
        userStore.username?.toLowerCase().trim()
    );
  };

  const getValidStudentFromCSV = (student) => {
    return {
      username: student.RHNID?.toLowerCase().trim(),
      default_email: student.EMAIL,
    };
  };

  const onParsedCSV = (arr) => {
    arr = arr.reduce(
      (unique, item) => [
        ...unique.filter(
          (obj) => obj.username.toLowerCase() !== item.username.toLowerCase(),
        ),
        item,
      ],
      [],
    );

    const studentUserNames = store.students?.map((s) =>
      s.username?.toLowerCase(),
    );
    //Filter items if they already added.
    arr = arr.filter(
      (a) => !studentUserNames.includes(a.username.toLowerCase()),
    );

    store.studentsFromCSV = arr;
    store.students = [...store.students, ...arr];
    return arr;
  };

  const onFileDrop = async (acceptedFile) => {
    store.fileForBYO.file = acceptedFile[0];
  };

  const popoverPDF = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {t('Upload a file for the custom class')}
      </Popover.Title>
      <Popover.Content>
        {`${t('File formats')}:  `}
        <strong>{t(`PDF, PPT, PPTX or MP4`)}</strong>
      </Popover.Content>
    </Popover>
  );

  useEffect(() => {
    if (!store.submitting && store.submitSuccess) {
      props.submitClassProp();
      if (props.existingClass !== null) {
        props.toggleEditClassModalProp();
        props.onEditClassSubmit();
      }
      store.submitSuccess = !store.submitSuccess;
    }
  }, [store.submitSuccess, props, store.submitting]);

  useEffect(() => {
    store.allClasses = props.getClassesLength;
  }, [store.allClasses, props]);

  useEffect(() => {
    when(
      () => !catalogStore.loaded && userStore.isLoggedIn,
      async () => {
        const entries = await catalogStore.getCatalogEntries();
        if (entries) {
          catalogStore.filterEntries();
        }

        catalogStore.loaded = true;
      },
    );
  }, [userStore, catalogStore]);

  const getTranslation = (translations) => {
    const t =
      typeof translations === 'object'
        ? Object.values(translations)
        : translations;

    return t;
  };
  const courseTitle = (entry, title) => {
    let titleToReturn =
      getTranslation(entry?.translations)?.find(
        (tr) => tr.language === i18n.language,
      )?.title || title;
    titleToReturn += ' ' + entry.version;
    return titleToReturn;
  };
  useEffect(() => {
    if (props.existingClass !== null) {
      store.syncClassDetails(props.existingClass);
    }
  }, [store, props]);

  const renderStudentList = () => {
    return (
      <React.Fragment>
        <Row className="mb-4">
          <Col>{t('Students added to this class')}</Col>
        </Row>
        {store.studentPaginatedEntries &&
        store.studentPaginatedEntries.length ? (
          <Row
            className={`row-student-header mb-2 ${
              props.existingClass !== null
                ? `edit-class-row-student-header`
                : ``
            }`}
          >
            <Col>{t('Red Hat Netwrok ID')}</Col>
            <Col>{t('Email address')}</Col>
            <Col className="text-right">{t('Delete')}</Col>
          </Row>
        ) : null}
        {store.studentPaginatedEntries &&
        store.studentPaginatedEntries.length &&
        store.studentPaginatedEntries.length > 0 ? (
          store.studentPaginatedEntries.map((s, i) => {
            return renderStudent(s, i);
          })
        ) : (
          <p className="font-italic">
            {t('No students are added to this class.')}
          </p>
        )}

        <Row className="students-list-pagination-wrapper">
          <Col>
            {/* --- Pagination : student list */}
            {store.studentPaginatedEntries &&
            store.studentPaginatedEntries.length ? (
              <div className="students-list-pagination">
                <UltimatePagination
                  currentPage={store.currentPage}
                  totalPages={store.totalPages}
                  onChange={store.setCurrentPage}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  const renderStudent = (s, index) => {
    return (
      <Row
        key={index}
        className={`row-student ${
          props.existingClass !== null ? `edit-class-row-student` : ``
        }`}
      >
        <Col>
          <span className="row-sm-header">
            {t('Red Hat Network ID')} {' : '}
          </span>
          <span>{s?.username}</span>
        </Col>
        <Col>
          <span className="row-sm-header">
            {t('Email address')} {' : '}
          </span>
          <span>{s?.default_email}</span>
        </Col>

        <Col className="action-colum text-right">
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              store.toggleDeleteStudentModal(s);
            }}
            role="button"
            className="delete-btn"
          >
            <SVG src={DeleteIcon}></SVG>
          </span>
        </Col>
      </Row>
    );
  };

  const onDateRangeSelected = (dateRange) => {
    store.dateRange = dateRange;
  };

  const infoDescriptionMarkup = `${t(
    'The form below accepts only valid Red Hat Network IDs and email addresses. Red Hat Network IDs can be created by visiting',
  )} <a href="https://sso.redhat.com" target="_blank" rel="noopener noreferrer">sso.redhat.com</a>. ${t(
    'If a student forgot the password of their Red Hat login, ask them to go to the',
  )} <a href="https://sso.redhat.com" target="_blank" rel="noopener noreferrer">Red Hat Login page</a> ${t(
    'and click “Forgot your password?” to reset it',
  )}.`;

  return (
    <React.Fragment>
      <div className="create-class-wrapper">
        <Form>
          {props.existingClass === null && (
            <Form.Row>
              <Form.Group controlId="formBYOCheckbox" className="col">
                <Form.Text>{t('Is this a custom class?')}</Form.Text>
                <Form.Check
                  type="checkbox"
                  label={t(`This is a custom class`)}
                  defaultChecked={
                    props.existingClass !== null
                      ? props.existingClass.custom_class
                      : store.byoConfirmed
                  }
                  onChange={(e) => {
                    confirm(e, 'BYO');
                  }}
                  disabled={store.submitting}
                />
              </Form.Group>
            </Form.Row>
          )}
          
          <Form.Row>
            <Form.Group controlId="formBasicCheckbox" className="col">
              <Form.Text>
                <span className="asterisk">* </span>
                {t('Confirm class type')}
              </Form.Text>
              <Form.Check
                type="checkbox"
                label={t('Class type is Instructor Led Training')}
                checked={store.iltConfirmed}
                onChange={(e) => {
                  confirm(e, 'ILT');
                }}
                disabled={store.submitting}
              />
            </Form.Group>

            <Form.Group controlId="formLOCKCheckbox" className="col">
              <Form.Text>{t('Show/Hide Lab Solution')}</Form.Text>                            
              <Form.Check
                type="checkbox"
                label={t('Lock Solutions')}
                checked={store.lockConfirmed}
                onChange={(e) => {
                  confirm(e, 'LOCK');
                }}
                disabled={store.submitting}
              />
              {store.lockConfirmed && (
                <span className="warning-message">{t('Please note solutions are available in the video content.')}</span>
              )}
            </Form.Group>            
          </Form.Row>

          <Form.Row>
            <Form.Group className="col">
              {store.byoConfirmed ? (
                <React.Fragment>
                  <Form.Text>
                    <span className="asterisk">* </span>
                    {t('Custom name for class')}
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={`${t('Name your class')}...`}
                    defaultValue={
                      props.existingClass !== null
                        ? props.existingClass.course_title
                        : ''
                    }
                    style={
                      store.submitting
                        ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                        : {}
                    }
                    onChange={(e) => addBYOName(e)}
                    maxLength="21"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Form.Text>
                    <span className="asterisk">* </span>
                    {t('Select course')}
                  </Form.Text>
                  {catalogStore.loaded ? (
                    catalogStore.filters.course.length ? (
                      <Form.Control
                        as="select"
                        className="course-selector-dropdown"
                        onChange={(e) => {
                          getSelectedCourse(e);
                        }}
                        disabled={store.submitting}
                        defaultValue={
                          props.existingClass !== null
                            ? props.existingClass.course_title
                            : ''
                        }
                      >
                        <option value="">Select Course</option>
                        {catalogStore.filters.course.map((entry) => {
                          const { doc_id: docId } = entry;
                          return (
                            <option slug={entry.slug} key={docId}>
                              {t(
                                `${entry.code.toUpperCase()} - ${courseTitle(
                                  entry,
                                  entry.title,
                                )}`,
                              )}
                            </option>
                          );
                        })}
                      </Form.Control>
                    ) : (
                      <p>{t('No courses found')}</p>
                    )
                  ) : (
                    <div className="view-loader-wrapper">
                      <ViewLoader />
                    </div>
                  )}
                </React.Fragment>
              )}
            </Form.Group>

            <Form.Group controlId="formBasicText" className="col">
              <Form.Text>
                <span className="asterisk">* </span>
                {t('Select date range')}
              </Form.Text>
              <DateRangePicker
                dateRange={store.dateRange}
                onDateRangeSelected={onDateRangeSelected}
              ></DateRangePicker>
            </Form.Group>
          </Form.Row>

          {store.byoConfirmed && (
            <React.Fragment>
              <Form.Group className="drag-wrapper">
                {!store.fileForBYO.file ? (
                  <React.Fragment>
                    <Dropzone
                      accept="video/mp4, .mp4, application/pdf, .pdf, application/vnd.ms-powerpoint, .ppt, application/vnd.openxmlformats-officedocument.presentationml.presentation, .pptx"
                      onDrop={onFileDrop}
                      disabled={store.submitting}
                      minSize={0}
                      maxSize={MAX_FILE_SIZE_BYTES}
                    >
                      {({ getRootProps, getInputProps, rejectedFiles }) => {
                        const isFileTooLarge =
                          rejectedFiles.length > 0 &&
                          rejectedFiles[0].size > MAX_FILE_SIZE_BYTES;
                        return (
                          <div {...getRootProps()}>
                            <div className="drag-overlay">
                              <input {...getInputProps()} />
                              {isFileTooLarge && (
                                <div className="text-danger mt-2">
                                  File is too large.
                                </div>
                              )}
                              <img
                                src={arrowDown}
                                className="drop-icon"
                                alt="arrow down"
                              />
                              {props.existingClass !== null ? (
                                <React.Fragment>
                                  <p>
                                    {t(
                                      `Drop PDF, PPT, PPTX or MP4 file here (max. size: 100MB)`,
                                    )}
                                  </p>
                                  <p>
                                    {t(
                                      `Your exsiting file: ${props.existingFile} will be replaced`,
                                    )}
                                  </p>
                                </React.Fragment>
                              ) : (
                                <p>
                                  {t(
                                    'Drop PDF, PPT, PPTX or MP4 file here (max. size: 100MB)',
                                  )}
                                </p>
                              )}

                              <Button
                                disabled={store.submitting}
                                className="select-file-btn"
                              >
                                {t('Or select file')}
                              </Button>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      overlay={popoverPDF}
                    >
                      <Button className="btn-char" disabled={store.submitting}>
                        {'?'}
                      </Button>
                    </OverlayTrigger>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="drag-overlay">
                      <div>
                        <img
                          src={checkMark}
                          className="drop-icon"
                          alt="arrow down"
                        />
                        <p>{t('File successfully added!')}</p>
                        <Button
                          disabled={store.submitting}
                          className="clear-btn btn-outline-dark"
                          variant="outline-dark"
                          onClick={resetDropFile}
                        >
                          {t('Remove file')}
                        </Button>
                      </div>
                    </div>
                    <Button
                      className="btn-char"
                      disabled={store.submitting}
                      onClick={resetDropFile}
                    >
                      {'X'}
                    </Button>
                  </React.Fragment>
                )}
              </Form.Group>

              <Form.Group
                controlId="formBYOCheckbox"
                style={{ backgroundColor: '#e9ecef', padding: '15px' }}
              >
                <Form.Text>
                  <span className="asterisk">* </span>
                  {t('Please confirm')}
                </Form.Text>
                <Form.Check
                  type="checkbox"
                  label={t(
                    `I confirm that the content I am uploading herein is my own content and it has not been reviewed, approved or authorized by Red Hat.  I will not display the Red Hat name, logo, trademark symbol, or otherwise state or imply within the materials (whether in a title, subject line, any text, notice, footnote, legend or anywhere within the uploaded materials) that any of the content is Red Hat proprietary or authorized by Red Hat. Red Hat has the right in its sole discretion to edit, refuse to post, or remove any content uploaded herein including anything that Red Hat deems as violation of laws and regulations. I am solely responsible for any content I upload, post, transmit, or otherwise make available to the students. I acknowledge and agree that Red Hat does not have any liability for any action or inaction with respect to any conduct, communication, or posting I make or content I share with the students. I agree to indemnify and hold harmless Red Hat, its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys’ fees and costs, due to or arising out of content that I upload herein and/or share with students, or any violation or infringement of any third party rights.`,
                  )}
                  checked={store.ownConfirmed}
                  onChange={(e) => {
                    confirm(e, 'Own');
                  }}
                  disabled={store.submitting}
                />
              </Form.Group>
            </React.Fragment>
          )}

          {isInstructor && (
            <React.Fragment>
              <BulkUpload
                title={t('How to upload students in bulk')}
                infoHeader={t('Important Prerequisite')}
                infoDescriptionMarkup={infoDescriptionMarkup}
                instructions={[
                  t('Download the CSV template and fill the Red Hat Netwrok IDs (RHNID) on column A and the email addresses of the students on column B.'),
                  `${t('Alternatively, in your favorite spreadsheet processor open a new file and define the header as')} <strong>${t('RHNID')}</strong> ${t('on column A')}, <strong>${t('EMAIL')}</strong> ${t('on column B')}; ${t('fill the details and save the file in .CSV format')}.`,
                  t('Drag and drop the file here, or browse your hard drive to select the file.'),
                  t('To add the students one by one, use the “Upload students individually” feature below.'),
                ]}
                disabled={store.submitting}
                dataFromCsv={store.studentsFromCSV}
                isCSVContentValid={isCSVStudentValid}
                getValidObjectFromCSV={getValidStudentFromCSV}
                onParsedCSV={onParsedCSV}
                onReset={store.resetDropDown}
              />

              <Form.Group>
                <CreateClassContext.Provider value={store}>
                  <ClassStudentInput />
                </CreateClassContext.Provider>
              </Form.Group>
              <Form.Group
                className={`student-list-wrapper container-fluid ${
                  props.existingClass !== null ? 'edit-class' : ''
                }`}
              >
                {renderStudentList()}

                <CreateClassContext.Provider value={store}>
                  <ClassStudentDeleteModal />
                </CreateClassContext.Provider>
              </Form.Group>
            </React.Fragment>
          )}

          <Form.Row>
            <Form.Group className="col">
              <Form.Text>{t('Custom label')}</Form.Text>
              <Form.Control
                type="text"
                style={
                  store.submitting
                    ? { pointerEvents: 'none', backgroundColor: '#e9ecef' }
                    : {}
                }
                onChange={(e) => charCounter(e)}
                defaultValue={
                  props.existingClass !== null
                    ? props.existingClass.label
                    : store.classToCreate.label
                }
                // value={store.classToCreate.label}
                maxLength="36"
              />
              <p className="char-counter">
                {`${
                  props.existingClass !== null
                    ? props.existingClass.label.length
                    : store.classToCreate.label.length
                }/36`}
              </p>
            </Form.Group>

            {!store.byoConfirmed && (
              <Form.Group className="col">
                <Form.Text>
                  <span className="asterisk">* </span>
                  {`${t(
                    'Enable Certificate of Attendance button when student progress reaches',
                  )}`}
                </Form.Text>
                <Form.Control
                  as="select"
                  className="course-selector-dropdown"
                  onChange={(e) => {
                    setPercentage(e);
                  }}
                  disabled={store.submitting}
                >
                  {props.existingClass !== null ? (
                    <option
                      value={props.existingClass.coa_percentage}
                      label={t(
                        `Original: ${
                          props.existingClass.coa_percentage * 100
                        }%`,
                      )}
                    ></option>
                  ) : (
                    <option value={-1}>{t('Select Percentage')}</option>
                  )}
                  {PERCENTAGES.map((percentage) => {
                    return (
                      <option
                        key={percentage.value}
                        value={percentage.value}
                        label={t(`${percentage.label}`)}
                      ></option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            )}
          </Form.Row>

          {store.students?.length > 0 && props.existingClass === null && (
            <React.Fragment>
              <div className="total-emails-wrapper mb-2">
                <div className="total-emails">
                  <span className="">
                    <span>{` ${t('Total number of Students')}: `}</span>
                    <span className="bold-span">{store.students?.length}</span>
                  </span>
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
        <div className="create-btn-wrapper">
          <Button
            className="btn-white btn-outline-dark"
            onClick={() => {
              if (props.existingClass !== null) {
                props.toggleEditClassModalProp();
                cancelForm();
              } else {
                cancelForm();
              }
            }}
            variant="outline-dark"
            disabled={store.submitting}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={props.existingClass !== null ? editClass : submitClass}
            disabled={
              (!store.selectedCourse.courseName &&
                store.selectedCourse.courseName !== 'Select course') ||
              !dateRange.to ||
              !store.iltConfirmed ||
              store.submitting ||
              (!store.byoConfirmed &&
                store.classToCreate.coa_percentage <= 0) ||
              (store.byoConfirmed && !store.ownConfirmed)
            }
          >
            {!store.submitting ? (
              props.existingClass !== null ? (
                `${t('Update')}`
              ) : (
                `${t('Create')}`
              )
            ) : (
              <span className="spinner-wrapper">
                <ViewLoader color="#ffffff" />
              </span>
            )}
          </Button>
        </div>
      </div>

      {submitFailure && (
        <Modal
          show={submitFailure}
          onHide={() => dismissAlert()}
          dialogClassName="calendar-modal-dialog container"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="calendar-modal-title">
              {t('Something went wrong')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {store.classSaveMessage !== ''
                ? t(store.classSaveMessage)
                : t('Oops! There was an issue with creating the class.')}
            </p>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

CreateClass.propTypes = {
  submitClassProp: PropTypes.func,
  getClassesLength: PropTypes.number,
  existingClass: PropTypes.object,
  existingFile: PropTypes.string,
  toggleEditClassModalProp: PropTypes.func,
  onEditClassSubmit: PropTypes.func,
};

CreateClass.defaultProps = {
  submitClassProp: () => {},
  getClassesLength: 0,
  existingClass: null,
  existingFile: '',
  toggleEditClassModalProp: () => {},
  onEditClassSubmit: () => {},
};

export default withErrorBoundary(
  observer(CreateClass),
  GenericViewError,
  handleError,
);
