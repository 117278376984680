import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import ViewLoader from '../../../components/Loading/View';
import { useTranslation } from 'react-i18next';
import { storesContext } from '../../../stores';
import { when } from 'mobx';
import DetailCards from '../../../components/ClassDetails/DetailCards';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../../components/Error/GenericViewError';
import ViewAssignmentStore from './store';

const AssignmentDetailsCard = React.lazy(() =>
  import('../../../components/Classes/Assignments/DetailsCard'),
);
const QuestionList = React.lazy(() =>
  import('../../../components/Classes/Assignments/QuestionsList'),
);
const CodeChallengeList = React.lazy(() =>
  import('../../../components/Classes/Assignments/CodeChallenge/List'),
);

const ViewAssignmentView = () => {
  const {
    userStore,
    classStore,
    routerStore,
    classAssignmentStore,
  } = useContext(storesContext);
  const { params } = routerStore.route;
  const { t } = useTranslation();

  const store = useLocalStore(
    () => new ViewAssignmentStore(classStore, classAssignmentStore),
  );

  useEffect(() => {
    // Populate assignment details
    when(
      () => classStore.ready && userStore.isLoggedIn,
      () => {
        store.classDetail = store.getClassDetailbyId(params.classid);
        store.classLoaded = true;
      },
    );
    when(
      () => classAssignmentStore.ready && userStore.isLoggedIn,
      () => {
        store.setAssignment(params.classid);
        store.assignmentLoaded = true;
      },
    );
  }, [userStore, store, classAssignmentStore, classStore, params]);

  return (
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col className="p-0">
            <h3 className="view-page-title">{`${t('Classes')} / ${
              store.classDetail?.class_name || '---'
            }`}</h3>
          </Col>
        </Row>

        {store.classLoaded && store.classDetail !== null && (
          <DetailCards details={store.classDetail} />
        )}

        {store.assignmentLoaded && store.assignmentDetails !== null && (
          <AssignmentDetailsCard details={store.assignmentDetails} />
        )}

        <Row>
          <Col>
            <h5>{t('Quiz')}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {store.assignmentLoaded ? (
              <QuestionList
                noQuestionMessage={t('No quiz exists for this assignment.')}
                questions={store.allQuestions}
                allowRemoveQuestion={false}
              />
            ) : (
              <div className="view-loader-wrapper">
                <ViewLoader />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>{t('Code Challenge')}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {store.assignmentLoaded && (
              <CodeChallengeList
                noChallengeMessage={t('No code challenge exists.')}
                challenges={store.allChallenges}
                allowRemoveCodeChallenge={false}
              />
            )}
          </Col>
        </Row>
      </React.Suspense>
    </Container>
  );
};

export default withErrorBoundary(
  observer(ViewAssignmentView),
  GenericViewError,
  handleError,
);
