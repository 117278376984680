import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { storesContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import ExpandToggle from '../ExpandToggle';
import CircularIcon from '../../components/CircularIcon';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import LearningPathElement from './LearningPathElement';
import ViewLoader from '../Loading/View';
import 'react-vertical-timeline-component/style.min.css';
import './styles.scss';

const LearningPath = ({ course, store }) => {
  const { t } = useTranslation();

  const { progressStore } = useContext(storesContext);
  const toggle = () => {};

  React.useEffect(() => {
    const getLearningPaths = async () => {
      await store.getLearningPaths();
      store.selectLearningPaths(course.code);
    };

    getLearningPaths();
  }, [course, store]);
  return (
    <React.Fragment>
      {!store.ready || store.loading ? (
        <div className="view-loader-wrapper">
          <ViewLoader />
        </div>
      ) : store.selectedLearningPaths.length > 0 ? (
        store.selectedLearningPaths.map((learningPath, index) => (
          <Accordion key={index}>
            <Card className="learning-path">
              <Card.Header>
                <Row>
                  <Col className="circle-container">
                    <CircularIcon
                      iconName="learning-path"
                      backgroundColor="#5F526D"
                    />
                  </Col>
                  <Col xs={11} className="learning-path-info">
                    <p>{learningPath.title}</p>
                    <p>{learningPath.code.toUpperCase()}</p>
                    <ExpandToggle toggle={toggle} />
                  </Col>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row>
                    <Col md={{ span: 11, offset: 1 }}>
                      <div className="learning-path-description">
                        {learningPath.description && (
                          <React.Fragment>
                            <h5>{`${t('Introduction')}:`}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: learningPath.description.body,
                              }}
                            />
                          </React.Fragment>
                        )}
                        {learningPath.prerequisites && (
                          <React.Fragment>
                            <h5>{`${t('Prerequisites')}:`}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: learningPath.prerequisites.body,
                              }}
                            />
                          </React.Fragment>
                        )}
                      </div>
                      <VerticalTimeline layout="1-column">
                        {store
                          .getLearningPathElements(learningPath)
                          // eslint-disable-next-line array-callback-return
                          .map((el, index) => {
                            // Selecting the first offering of the list as Learning path elements don't care about different versions
                            const offering = el.offerings[0];
                            if (offering) {
                              const progress = progressStore.getHighestProgressForCode(
                                offering.code,
                              );
                              const totalProgress = progress
                                ? Math.round(progress.total_progress * 100)
                                : 0;
                              return (
                                <VerticalTimelineElement
                                  className="vertical-timeline-element--work"
                                  key={`${index}`}
                                >
                                  <LearningPathElement
                                    offering={offering}
                                    progress={totalProgress}
                                  />
                                </VerticalTimelineElement>
                              );
                            }
                          })}
                      </VerticalTimeline>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ))
      ) : (
        <p>{t('No Learning Path Found for this course')}</p>
      )}
    </React.Fragment>
  );
};

LearningPath.propTypes = {
  course: PropTypes.shape({
    code: PropTypes.string,
  }),
};

export default observer(LearningPath);
