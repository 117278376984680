import React from 'react';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../Error/GenericViewError';

import { useTranslation } from 'react-i18next';


import './styles.scss';

const ResponseModal = (props) => {
  const { t } = useTranslation();
  const { title, message, onHideModal, closeBtnText } = props;

  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={() => onHideModal()}
        dialogClassName="container"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="response-modal-title">
            {t(`${title}`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t(`${message}`)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={() => onHideModal()}>
            {t(closeBtnText)}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

ResponseModal.propTypes = {
  show: PropTypes.bool,
  success: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onHideModal: PropTypes.func,
  closeBtnText: PropTypes.string,
};

ResponseModal.defaultProps = {
  show: true,
  success: true,
  title: '',
  message: '',
  onHideModal: () => {},
  closeBtnText: 'Close',
};

export default withErrorBoundary(
  observer(ResponseModal),
  GenericViewError,
  handleError,
);
