import { action, observable } from 'mobx';
import downloadFile from '../../services/DownloadService';

class DownloadFileStore {

    @observable downloading = false;
    
    @action download = async (endpoint) => {

        try {
            this.downloading = true;
            const response = await downloadFile(endpoint);
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const headers = response.headers["content-disposition"].split(';')
            const fileName = headers?.filter(header => header.includes('filename'))[0].split('=')[1];
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            
        } catch (error) {
            this.errorMessage = "Failed to download consumption summary report.";
            console.error('failed to get consumption summary data:', error);
        } finally {
            this.downloading = false;
        }
    };
}

export default DownloadFileStore;