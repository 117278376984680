import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { when, reaction } from 'mobx';
import SideNavigationStore from './store';
import { observer, useLocalStore } from 'mobx-react';
import SideMenuItemIcon from '../../../img/icons/icon.svg';
import ClassesIcon from '../../../img/icons/classes.svg';
import AnnouncementsIcon from '../../../img/icons/announcements.svg';
import ConsumptionSummaryIcon from '../../../img/icons/consumption-summary.svg';
import ProgramsIcon from '../../../img/icons/programs.svg';
import ResourcesIcon from '../../../img/icons/resources.svg';
import CoursesAndCertsIcon from '../../../img/icons/courses-and-certs.svg';
import EnablementIcon from '../../../img/icons/enablement.svg';
import CommunityIcon from '../../../img/icons/rhlc.svg';
import SideMenuOfferingItemIcon from '../../../img/icons/corner-down-right.svg';
import HelpIcon from '../../../img/icons/help.svg';
import FeedbackIcon from '../../../img/icons/feedback.svg';
import CourseSummaryIcon from '../../../img/icons/course-summary.svg';
import AmbassadorIcon from '../../../img/icons/ambassador.svg';
import AcademyDetailsIcon from '../../../img/icons/academy-details.svg';
import ReportsIcon from '../../../img/icons/reports.svg';
import TalentNetworkIcon from '../../../img/icons/rha_talent_network_icon.svg';
import RHALogo from '../../../img/rh-academy-logo.png';
import RHANavBarLogo from '../../../img/rh-academy-navbar-logo.png';
import { VERSION } from '../../../config/constants';

import rootStore, { storesContext } from '../../../stores';
import { Nav, Navbar, Button } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

import Routes from '../../../config/routes';
const SideBar = (props) => {
  const {
    routerStore,
    userStore,
    catalogStore,
    classStore,
    uiStore,
    progressStore,
  } = useContext(storesContext);
  const { i18n, t } = useTranslation();
  const routes = new Routes({
    userStore: userStore,
    routerStore: routerStore,
  }).routes;

  useEffect(() => {
    when(
      () => !catalogStore.entriesLoading,
      () => setNestedRouteTitle(),
    );

    when(
      () => classStore.ready,
      () => setNestedRouteTitle(),
    );

    reaction(
      () => routerStore.route?.name,
      () => resetNestedRouteTitle(),
    );

    when(
      () => routerStore.route?.name,
      async () => {
        if (routerStore.route?.name === 'enablement') {
          await progressStore.getProgress();
        }
      },
    );

    reaction(
      () => uiStore.currentLanguage,
      () => resetNestedRouteTitle(),
    );
  });

  const isSelectedMenuItem = (currentRoute) => {
    return (
      (routerStore.route?.name === currentRoute && 'selected') ||
      (routerStore.route?.name === '/' &&
        currentRoute === 'help' &&
        'selected') ||
      ''
    );
  };

  const getMenuIcon = (menuItem) => {
    switch (menuItem) {
      case 'classes':
        return ClassesIcon;
      case 'announcements':
        return AnnouncementsIcon;
      case 'resources':
        return ResourcesIcon;
      case 'studentresources':
        return ResourcesIcon;
      case 'coursesandcertification':
        return CoursesAndCertsIcon;
      case 'enablement':
        return EnablementIcon;
      case 'rhlc':
        return CommunityIcon;
      case 'help':
        return HelpIcon;
      case 'feedback':
        return FeedbackIcon;
      case 'summary':
        return CourseSummaryIcon;
      case 'academysummary':
        return ConsumptionSummaryIcon;
      case 'reports':
        return ReportsIcon;
      case 'programs':
        return ProgramsIcon;
      case 'studentambassador':
        return AmbassadorIcon;
      case 'academy':
        return AcademyDetailsIcon;
      case 'talent-network':
        return TalentNetworkIcon;
      default:
        return SideMenuItemIcon;
    }
  };

  const store = useLocalStore(() => new SideNavigationStore(rootStore));
  const hamBurgerRef = useRef(null);

  const resetNestedRouteTitle = () => {
    store.resetNestedRouteTitle();
  };

  const setNestedRouteTitle = () => {
    if (
      store.nestedRouteTitle === '' &&
      store.isNestedRoute(routerStore.route?.name)
    ) {
      store.setNestedRouteTitle(i18n.language);
    }
  };
  const renderNestedRoutes = (index, route) => {
    return (
      <Nav.Item key={index}>
        <Button
          variant="link"
          key={index}
          className={`button-nav-items ${isSelectedMenuItem(route.name)}`}
        >
          <SVG src={SideMenuOfferingItemIcon}></SVG>
          {store.sidebarClassName !== 'small' && (
            <span>{store.nestedRouteTitle}</span>
          )}
        </Button>
      </Nav.Item>
    );
  };
  const handleMenu = () => {
    if (
      window.getComputedStyle &&
      window.getComputedStyle(hamBurgerRef.current).display &&
      window.getComputedStyle(hamBurgerRef.current).display === 'block'
    ) {
      hamBurgerRef.current.click();
    }
  };
  const renderMainRoutes = (index, route) => {
    return (
      <Nav.Item key={index}>
        <Button
          variant="link"
          key={index}
          className={`button-nav-items ${isSelectedMenuItem(route.name)}`}
          onClick={() => {
            handleMenu();
            routerStore.navigate(route.name);
          }}
        >
          <SVG src={getMenuIcon(route.name)}></SVG>
          {store.sidebarClassName !== 'small' && (
            <span>{t(route.menuTitle)}</span>
          )}
        </Button>
      </Nav.Item>
    );
  };

  return (
    (userStore.isLoggedIn && userStore.role && store.shouldRender && (
      <div
        className={`col-md-3 col-xl-2 sidebar-menu pl-0 pr-0 ${store.sidebarClassName}`}
      >
        <Navbar collapseOnSelect expand="md" variant="dark" className="w-100">
          <Navbar.Brand className="mobile-menu-title">
            <img
              alt={t('Red Hat Academy')}
              src={RHANavBarLogo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {t('Red Hat Academy')}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="menu-button-toggle"
            ref={hamBurgerRef}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div
              className="flex-column"
              style={{
                flex: '1 1 auto',
              }}
            >
              <Nav className="flex-column side-header">
                <Nav.Item>
                  {store.sidebarClassName !== 'small-sidebar-menu' && (
                    <span className="menu-title">{t('Red Hat Academy')}</span>
                  )}
                  <Button
                    variant="link"
                    className="button-nav-header float-right"
                    onClick={() => store.toggleSiderBar()}
                  >
                    <SVG src={store.closeButton} alt="Collapse menu" />
                  </Button>
                </Nav.Item>
              </Nav>
              <Nav className="flex-column side-items">
                {routes.map(
                  (route, index) =>
                    route.isForRoles.includes(userStore.role?.name) &&
                    (!store.isNestedRoute(route.name)
                      ? renderMainRoutes(index, route)
                      : store.nestedRouteTitle !== '' &&
                        store.nestedRouteName === route.name &&
                        renderNestedRoutes(index, route)),
                )}
              </Nav>
              {store.sidebarClassName !== 'small-sidebar-menu' && (
                <React.Fragment>
                  <span className="menu-logo-wrapper d-flex justify-content-center">
                    <img
                      src={RHALogo}
                      alt="Red Hat Academy Portal Logo"
                      className="rha-logo"
                    />
                  </span>
                  <span className=" d-flex justify-content-center">
                    <span className="version">{VERSION}</span>
                  </span>
                </React.Fragment>
              )}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )) ||
    null
  );
};

export default observer(SideBar);
