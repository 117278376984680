import { get, post } from "axios";
import { CONSUMPTION_SUMMARY, REGION_MAP  } from "../config/constants";

export const getRegions = async () => {
  try {
    const response = await get(`${REGION_MAP.replace('<config_name>', 'region_map')}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSubRegions = async () => {
  try {
    const response = await get(`${REGION_MAP.replace('<config_name>', 'sub_region_map')}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postConsumptionSummary = async (data) => {
  try {
    return await post(`${CONSUMPTION_SUMMARY}`, data);
  } catch (error) {
    throw new Error(error);
  }
};