import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Moment from 'react-moment';

@observer
class TowerLabComponent extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    labComponent: PropTypes.object.isRequired,
  };

  doNull() {
    console.warn(`action ${this.props.action}`);
  }

  renderLabPanel() {
    const context = this.props.labComponent?.context;
    if (!(context && context.guid)) {
      return null;
    }

    const url = `workstation.${context.guid}.do417.prod.nextcle.com`;

    /* new style api: context.stat contains populated display_table */
    /* eslint-disable camelcase */
    const displayTable = context.stat?.display_table;
    if (displayTable && displayTable.length) {
      const tableRows = displayTable.map(x => {
        return (
          <tr key={x.key}>
            <th>{x.title}</th>
            <td>{x.value}</td>
          </tr>
        );
      });

      return (
        <div className="card-white" style={{ margin: '20pt' }}>
          <div className="card-title">Provisioned Lab Details</div>
          <div className="card-body" style={{ margin: '20pt' }}>
            <table className="table">
              <tbody>{tableRows}</tbody>
            </table>
          </div>
        </div>
      );
    }

    /* legacy support for do417 - can be remove once caught up */
    const classroomStatus = context.stat?.probe_status?.status || 'inactive';

    return (
      <div className="card-white" style={{ margin: '20pt' }}>
        <div className="card-title">Provisioned Lab Details</div>
        <div className="card-body" style={{ margin: '20pt' }}>
          <table className="table">
            <tbody>
              <tr key="guid">
                <th>GUID</th>
                <td>{context.guid}</td>
              </tr>
              <tr key="workstation_hostname">
                <th>Workstation Hostname</th>
                <td>{url}</td>
              </tr>
              <tr key="workstation_password">
                <th>Workstation Password</th>
                <td>{context.workstation_password}</td>
              </tr>
              <tr key="state">
                <th>State</th>
                <td>
                  <td>{context.state}</td>
                </td>
              </tr>
              <tr key="classroomStatus">
                <th>Classroom Status</th>
                <td>
                  <td>{classroomStatus}</td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderInstallationProgress() {
    const context = this.props.labComponent?.context;
    if (!(context && context.guid)) {
      return null;
    }

    const isInstalling =
      context.state === 'installing' || context.state === 'unavailable';
    if (!isInstalling) {
      return null;
    }

    const installEvents = context.stat?.events;
    if (!installEvents) {
      return null;
    }

    const rows = installEvents.map(e => {
      const time = e['@timestamp'];
      return (
        <tr key={e['@timestamp']}>
          <td>
            <Moment>{time}</Moment>
          </td>
          <td>{e.play}</td>
        </tr>
      );
    });

    return (
      <div className="card-white" style={{ margin: '20pt' }}>
        <div className="card-title">Installation Progress</div>
        <div className="card-body" style={{ margin: '20pt' }}>
          <table className="table">
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
  // <pre>{JSON.stringify(this.props.labComponent, null, 2)}</pre>

  render() {
    return (
      <div>
        {this.renderLabPanel()}
        {this.renderInstallationProgress()}
      </div>
    );
  }
}

export default TowerLabComponent;
