import { get } from 'axios';
import {
    CATALOG_ENTRY_API,
  
} from '../config/constants';

export const getCatalogEntries = async () => {
 

  try {
    const catalog = await get(`${CATALOG_ENTRY_API}`);
    const { items } = catalog.data;

    return items;
  } catch (error) {
    throw new Error(error);
  }
};




export default getCatalogEntries;
