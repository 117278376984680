import axios from 'axios';
import { getCookie } from './UtilityService';

const setAxiosRequestHeaders = () => {
  axios.interceptors.request.use(
    config => {
      const conf = config;
      const token = getCookie('csrftoken');

      if (token !== null) {
        conf.headers['x-CSRFToken'] = token;
      }

      return conf;
    },
    err => Promise.reject(err),
  );
};

export { setAxiosRequestHeaders };
export default setAxiosRequestHeaders;
