import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { storesContext } from '../../../stores';

const LanguageDropdown = (props) => {
  const { t } = useTranslation();
  const { uiStore } = useContext(storesContext);

  const { language, translations } = props;

  return (
    <div className="course-language-btn">
      <DropdownButton
        title={t('Translations')}
        id="course-language-dropdown"
        variant="primary-new"
      >
        {translations.map((translation) => {
          const found = uiStore.languages.find(
            (lang) => lang.token === translation,
          );

          if (!found) return false;

          const { display_name: name } = found;

          const text = `${name} [${translation}]`;
          const formattedText =
            translation === language ? <strong>{text}</strong> : text;

          return (
            <Dropdown.Item
              key={translation}
              eventKey={translation}
              style={{ display: 'block' }}
              onClick={() => {
                props.callback(translation);
              }}
            >
              {formattedText}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </div>
  );
};

LanguageDropdown.propTypes = {
  callback: PropTypes.func,
  language: PropTypes.string,
  translations: PropTypes.array.isRequired,
};

LanguageDropdown.defaultProps = {
  callback: () => {},
  language: '',
};

export default observer(LanguageDropdown);
