import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, Table } from 'react-bootstrap';

@observer
class OCPSharedLabComponent extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    labComponent: PropTypes.object.isRequired,
  };

  doNull() {
    console.warn(this.props.action);
  }

  render() {
    const { context } = this.props.labComponent;
    if (!(context && context.idm_username)) {
      return null;
    }

    const apiUrl = context.api_url;
    const consoleUrl = context.console_url;

    return (
      
        
        
        <Card header={"OpenShift Details"} className="lab-controls-vm-panel mt-2">
        <Table className='openstack-vm-table' responsive={true} bordered={false} striped={true} >
            <tbody>
              <tr key="username">
                <td>Username</td>
                <td>RHT_OCP4_DEV_USER</td>
                <td>{context.idm_username}</td>
              </tr>
              <tr key="password">
                <td>Password</td>
                <td>RHT_OCP4_DEV_PASSWORD</td>
                <td>{context.idm_password}</td>
              </tr>
              <tr key="api">
                <td>API Endpoint</td>
                <td>RHT_OCP4_MASTER_API</td>
                <td>
                  <a href={apiUrl} rel="noopener noreferrer" target="_blank">
                    {apiUrl}
                  </a>
                </td>
              </tr>
              <tr key="console">
                <td>Console Web Application</td>
                <td />
                <td>
                  <a
                    href={consoleUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {consoleUrl}
                  </a>
                </td>
              </tr>
              <tr key="cluster_id">
                <td>Cluster Id</td>
                <td />
                <td>{context.cluster_id}</td>
              </tr>
            </tbody>
          </Table>
      </Card>
    );
  }
}

export default OCPSharedLabComponent;
