import React from 'react';
import PropTypes from 'prop-types';



import { Card } from 'react-bootstrap';

import Store from './store';

import { CARD_COLORS } from '../../../config/constants';

export default function WithCardContainer(ContentComponent) {
  class cardContainer extends React.Component {
    constructor(props) {
      super();
      this.store = new Store();
      this.cardColor = props.cardColor;
      this.modality = props.course ? props.course.modality : 'icon';
    }

    render() {
      return (
        <React.Fragment>
          <Card
            style={{ borderLeftColor: this.cardColor }}
            className={`offering-card-container ${this.modality}`}
          >
            <ContentComponent {...this.props} />
          </Card>
        </React.Fragment>
      );
    }
  }
  return cardContainer;
}

WithCardContainer.propTypes = {
  cardColor: PropTypes.string,
};

WithCardContainer.defaultProps = {
  cardColor: CARD_COLORS[0],
};
