import { get, post } from 'axios';
import { EXAM_COLLATERAL_API_URL, PROMOCODE_API, ACADEMY_INSTRUCTOR_PROMO_ELIGIBILITY_API } from '../config/constants';

export const getExamCollateral = async (code, language = 'en-US') => {
  if (!code) {
    throw new Error('Course code and version are required');
  }

  try {
    const collateral = await get(
      `${EXAM_COLLATERAL_API_URL}${code}@@${language}`
    );
    return collateral.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const requestPromoCode = async (slug, academyId) => {
  if (!slug) {
    throw new Error('Course / Exam slug is required');
  }

  if (!academyId) {
    throw new Error('Academy Id is required');
  }

  try {
    const response = await post(PROMOCODE_API, {course_slug: slug, academy: academyId});
    return response.data;
  } catch (err) {
    throw new Error(err.response?.data?.error || `Couldn't request promo code`);
  }
};

export const getInstructorPromoEligibilityStatus = async (academyId) => {
  if (!academyId) {
    throw new Error('Academy Id is required');
  }
  try {
    const url = ACADEMY_INSTRUCTOR_PROMO_ELIGIBILITY_API.replace('<academy-id>', academyId);
    const eligibilityStatus = await get(url);
    return eligibilityStatus?.data;
  } catch (error) {
    throw new Error(error);
  }
};



