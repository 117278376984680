import React, { useEffect, useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Alert, Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { SyncLoader } from 'react-spinners';

import { storesContext } from '../../stores';
import LabStore from '../../stores/LabStore';

import LabEnvironment from '../../components/LabEnvironment';


import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import './styles.scss';

const LabView = () => {
  const { routerStore, userStore } = useContext(storesContext);
  const { course: courseSlug } = routerStore.route.params;
  const store = useLocalStore(() => new LabStore());

  
  const { t } = useTranslation();

  

  useEffect(() => {
    store.courseSlug = courseSlug;
    store.getLabDefinition();
    store.getLabBlueprints().then(() => {});
  }, [store, courseSlug]);

  const {
    labBlueprintsFetched,
    labDefinition,
    labDefinitionFetched,
  } = store;

  // TODO: make page header fancier by adding a title
  return (
    <React.Fragment>
      <h3 className="view-page-title">
        {courseSlug} {t('Lab')}{' '}
      </h3>
      <Container fluid className="labs-view">
        <Row>
          <Col sm={12}>
            <div className="loading">
              <SyncLoader
                loading={!labBlueprintsFetched || !labDefinitionFetched}
                size={10}
              />
            </div>
            {labBlueprintsFetched && labDefinitionFetched && (
              <Tabs id="course-tabs" className="courseTabs">
                {labDefinition.doc_id ? (
                  <Tab eventKey={8} title={t('Lab Environment')}>
                    <LabEnvironment
                      courseSlug={courseSlug}
                      labDefinition={labDefinition}
                      userStore={userStore}
                    />
                  </Tab>
                ) : null}
                {!labDefinition.doc_id && (
                  <Tab eventKey={8} title={t('Lab Environment')}>
                    <Alert className="lab-not-found-msg" variant="danger">
                      {t(
                        'We are sorry, but we cannot locate the lab for this version of your course. Please contact support for assistance.',
                      )}
                      <Button
                        variant="primary"
                        href={'https://access.redhat.com/'}
                        target="_blank"
                      >
                        {t('Contact support')}
                      </Button>
                    </Alert>
                  </Tab>
                )}
              </Tabs>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(LabView),
  GenericViewError,
  handleError,
);
