import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import ViewLoader from '../../components/Loading/View';
import { useTranslation } from 'react-i18next';
import CoursesAndCertificationStore from './store';
import { storesContext } from '../../stores';
import { when } from 'mobx';
import CloudIcon from '../../img/icons/cloud.svg';
import CertificationExamsIcon from '../../img/icons/ribbon.svg';
import MiddlewareIcon from '../../img/icons/multiple_apps.svg';
import CoreSystemAdministrationIcon from '../../img/icons/server_stack.svg';

import SVG from 'react-inlinesvg';
import './styles.scss';

const CoursesAndCertificationView = () => {
  const { userStore } = useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(() => new CoursesAndCertificationStore());
  useEffect(() => {
    // Populate course and certs
    when(
      () => !store.loaded && userStore.isLoggedIn,
      async () => {
        await store.getCourseAndCerts();
      },
    );
  }, [store, userStore.isLoggedIn]);
  const renderSVG = (courseType) => {
    switch (courseType.toLowerCase().trim()) {
      case 'certification_exams':
        return <SVG src={CertificationExamsIcon}></SVG>;
      case 'cloud_computing':
        return <SVG src={CloudIcon}></SVG>;
      case 'core_system_administration':
        return <SVG src={CoreSystemAdministrationIcon}></SVG>;
      case 'middleware_development':
        return <SVG src={MiddlewareIcon}></SVG>;
      default:
        return <SVG src={CloudIcon}></SVG>;
    }
  };
  const renderCourseCertContent = (course, index, course_type) => {
    return course?.title ? (
      <React.Fragment key={index}>
        <div key={index} className="course-content justify-content-center">
          <div className="course-content-svg">{renderSVG(course_type)}</div>
          <div className="course-content-div">
            <ul className="ul-course-content">
              <li>
                <h5>{course?.title}</h5>
              </li>
              <li>
                <p>{course?.description}</p>
              </li>
              <li>
                <ul>
                  {course?.items?.map((i, itemIndex) => {
                    return (
                      <li
                        key={itemIndex}
                        dangerouslySetInnerHTML={{
                          __html: i,
                        }}
                      ></li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    ) : null;
  };
  return (
    <Container fluid className="">
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col className="p-0">
            <h3 className="view-page-title">
              {t('Courses and Certifications')}
            </h3>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-center">
            <h4>{t('Train and validate in-demand skills')}</h4>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <p>
              {t(
                'Browse our course and exam offerings to learn more about the value, you get as part of Red Hat Academy',
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="course-cert-wrapper">
            {store.loaded &&
              store.courseKeys.map((k, index) => {
                const course = store.coursesAndCerts[k];
                return renderCourseCertContent(course, index, k);
              })}
            {!store.loaded && <ViewLoader />}
            {store.loaded && store.courseKeys?.length <= 0 && (
              <div className="d-flex justify-content-center no-course-certifications">
                <h6>{t('No courses and certifications found')}</h6>
              </div>
            )}
          </Col>
        </Row>
      </React.Suspense>
    </Container>
  );
};

export default withErrorBoundary(
  observer(CoursesAndCertificationView),
  GenericViewError,
  handleError,
);
