/**
 * @file Provide Search Text functionality
 * @author Prem Pachouri <ppachour@redhat.com>
 */

import React from 'react';
import { PropTypes } from 'prop-types';
import SVG from 'react-inlinesvg';
import DownloadCSV from '../../img/icons/csv-download.svg';
import DownloadFileStore from './store';
import { useLocalStore } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const DownloadFile = (props) => {
    const { t } = useTranslation();
    const { url } = props;
    const store = useLocalStore(() => new DownloadFileStore());

return ( 
    <div className="page-title-csv-wrapper">
        <SVG className={store.downloading ? 'disabled' : ''} 
        title={t('Download CSV')}
        onClick={(e) => !store.downloading && store.download(url)} src={DownloadCSV}></SVG>
    </div>
);
};

DownloadFile.propTypes = {
    url: PropTypes.string.isRequired,
};

export default DownloadFile;
  