import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { when } from 'mobx';
import { storesContext } from '../../stores';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';
import ExamStore, { ExamStoreContext } from '../../stores/ExamStore';
import ViewLoader from '../../components/Loading/View';



const ExamDetails = React.lazy(() =>
  import(/* webpackChunkName: 'examdetails-component' */ '../../components/Exam/ExamDetails'),
);

const ExamView = () => {
  const {
    catalogStore,
    userStore,
    academyStore,
    routerStore,
  } = useContext(storesContext);

  const { i18n } = useTranslation();
  
  const examStore = useLocalStore(
    () => new ExamStore(catalogStore, academyStore),
  );
  const { route } = routerStore;
  const { collateral } = examStore;

  
  const getExamCollateral = () =>{      
    const [code] = route.params.slug.split('-');
    examStore.code = code;
    examStore.getExamCollateral(code, i18n.language);
  }

  useEffect(getExamCollateral, [i18n.language]);
  
  useEffect(() => {    
    when(
      () =>  userStore.isLoggedIn && academyStore.academyLoaded,
      async () => {
        await examStore.getInstructorPromoEligibilityStatus();
      },
    );
  }, [examStore, userStore, academyStore]);

  

  return (
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
        {examStore.offering && (
          <React.Fragment>
            <Row>
              <Col xs={12}>
                <h2>{collateral?.title || `${examStore.offering?.sku}`}</h2>
              </Col>
            </Row>
            {/* <Row className="exam-alerts">
              {alerts.map(alert => (
                <GenericAlert {...alert} />
              ))}
            </Row> */}
            <ExamStoreContext.Provider value={examStore}>
              <ExamDetails/>
            </ExamStoreContext.Provider>
          </React.Fragment>
        )}
      </React.Suspense>
    </Container>
  );
};

export default withErrorBoundary(
  observer(ExamView),
  GenericViewError,
  handleError,
);
