import React, { Fragment, useContext, useEffect } from 'react';

import { useLocalStore, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import StudentAssignmentCardStore from './store';
import { formatDate } from '../../../services/UtilityService';
import { ASSIGNMENT_TYPES } from '../../../config/constants';
import { useTranslation } from 'react-i18next';
import IconCardContainer from '../IconCardContainer';
import { when } from 'mobx';
import moment from 'moment';
import CardList from '../../CardList';
import './styles.scss';

import { storesContext } from '../../../stores';
import { Accordion, Card } from 'react-bootstrap';

const StudentAssignmentCard = (props) => {
  const { t } = useTranslation();
  const { routerStore, uiStore } = useContext(storesContext);
  const store = useLocalStore(() => new StudentAssignmentCardStore(uiStore));
  const navigateToStudentQuiz = () => {
    routerStore.navigate('student:quiz', {
      classid: props.classId,
    });
  };

  const navigateToKnowledgeCheck = () => {
    routerStore.navigate('student:kc', {
      classid: props.classId,
    });
  };

  useEffect(() => {
    // Populate assignment details
    when(
      () =>
        props.classId !== '' && props.classId !== undefined,
        async () => {
          await store.setAssignment(props.classId);
          store.loaded = true;
        },
    );
  }, [store, props.classId]);

  const renderQuizSubmitted = () => {
    return (
      <Fragment>
        {`${t('Submitted')}:`} <strong>{store.quizLastSubmittedDate}</strong>
      </Fragment>
    );
  };
  const renderKCSubmitted = () => {
    return (
      <Fragment>
        {`${t('Submitted')}:`} <strong>{store.kcLastSubmittedDate}</strong>
      </Fragment>
    );
  };
  const renderDueDate = () => {
    return (
      <Fragment>
        {`${t('Due Date')}: `}
        <strong
          className={
            moment(store.assignment?.end_date).isBefore() ? 'due-date' : ''
          }
        >
          {formatDate(
            moment(store.assignment?.end_date),
            uiStore.currentLanguage,
          )}
        </strong>
      </Fragment>
    );
  };
  const renderPostedDate = () => {
    return (
      <Fragment>
        {`${t('Posted')} `}
        <strong>
          {formatDate(
            moment(store.assignment?.start_date),
            uiStore.currentLanguage,
          )}
        </strong>
      </Fragment>
    );
  };
  const renderQuizFirstLine = () => {
    if (store.hasStudentSubmittedQuiz) {
      return renderQuizSubmitted();
    } else {
      return renderDueDate();
    }
  };
  const renderQuizGrade = () => {
    return (
      <Fragment>
        {`${t('Grade')}:`} <strong>{store.quizGrade}%</strong>
      </Fragment>
    );
  };
  const renderKCGrade = () => {
    return (
      <Fragment>
        {`${t('Grade')}:`} <strong>{store.kcGrade}%</strong>
      </Fragment>
    );
  };

  const renderQuizSecondLine = () => {
    if (store.hasStudentSubmittedQuiz) {
      return renderQuizGrade();
    } else {
      return renderPostedDate();
    }
  };

  const renderKCFirstLine = () => {
    if (store.hasStudentSubmittedKC) {
      return renderKCSubmitted();
    } else {
      return renderDueDate();
    }
  };

  const renderKCSecondLine = () => {
    if (store.hasStudentSubmittedKC) {
      return renderKCGrade();
    } else {
      return renderPostedDate();
    }
  };

  const firstLineQuiz = (
    <Fragment>{renderQuizFirstLine()}</Fragment>
  );

  const secondLineQuiz = (
    <Fragment>{renderQuizSecondLine()}</Fragment>
  );

  const firstLineKC = <Fragment>{renderKCFirstLine()}</Fragment>;

  const secondLineKC = <Fragment>{renderKCSecondLine()}</Fragment>;

  return (
    <Fragment>
      <Accordion>
        <Card className="student-assignment">
          {store.loaded && store.assignment ? (
            <Fragment>
              <Card.Header className="opened">
                <p className="font-weight-bold mb-0">
                  {' '}
                  <strong>{`${t('Assignment')}:`}</strong>
                  {'  '}
                  {store?.assignment.title}
                </p>
              </Card.Header>
              <Card.Body>
                <CardList>
                  {store.hasQuiz && (
                    <IconCardContainer
                      onCardClick={navigateToStudentQuiz}
                      iconName={ASSIGNMENT_TYPES.quiz.icon}
                      title={t('Quiz')}
                      firstLineElement={firstLineQuiz}
                      secondLineElement={secondLineQuiz}
                      cardColor="var(--cyan)"
                    />
                  )}
                  {store.hasKC && (
                    <IconCardContainer
                      onCardClick={navigateToKnowledgeCheck}
                      iconName={ASSIGNMENT_TYPES.knowledgeCheck.icon}
                      title={t('Knowledge Check')}
                      firstLineElement={firstLineKC}
                      secondLineElement={secondLineKC}
                      cardColor="var(--cyan)"
                    />
                  )}
                </CardList>
              </Card.Body>
            </Fragment>
          ) : (
            <Card.Body>
              <p>{t('There is no assignment for this course.')}</p>
            </Card.Body>
          )}
        </Card>
      </Accordion>
    </Fragment>);
};

StudentAssignmentCard.propTypes = {
  classId: PropTypes.string,
};

StudentAssignmentCard.defaultProps = {
  classId: '',
};

export default observer(StudentAssignmentCard);
