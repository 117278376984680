import { action, observable } from 'mobx';
import { getStudentActivities } from '../../../../services/AcademyService';
import moment from 'moment';

class AcademyStudentDetailStore {

    @observable loading = false;
    @observable error;
    @observable classes = [];

    @action getStudentActivities = async (student) => {
        try {
            this.loading = true;
            const activityResponse = await getStudentActivities(student.academy_id, student.username);
            if(activityResponse) {
                this.classes = student.class_info.map((student_class) => {
                    let grade = activityResponse.grades?.find(cp => cp.class_id === student_class.doc_id);
      
                    // Set submission as the timestamp of first(oldest) student_response from the grade of selected class_id
                    const submission =  grade && grade.student_response && grade.student_response.length > 0 ?
                                        grade.student_response.sort((a, b) => 
                                        moment(b.timestamp).toDate() - moment(a.timestamp).toDate())[0].timestamp : '';
      
                    const course_progress = activityResponse.course_progress?.find(cp => cp.class_id === student_class.doc_id);
      
                    return {
                        id: student_class.doc_id,
                        class_name: student_class.class_name,
                        course_title: student_class.course_title,
                        total_progress: course_progress?.total_progress || 0,
                        submission: submission, 
                        quiz_score: grade?.quiz_score,
                        total_score: grade?.total_score,
                        code_challenge_score: grade?.code_challenge_score,
                        last_accessed: course_progress['@timestamp'] || '',
                    }
                  })
            }
            
        } catch {
            this.classes = [];
        } finally {
            this.loading = false;
        }
    }
}

export default AcademyStudentDetailStore;