import React, { useContext } from 'react';
import { Button, Card, Accordion } from 'react-bootstrap';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Store from './store';
import { storesContext } from '../../../../../stores';
import './styles.scss';
import InfoTextButton from '../../../../InfoTextButton';

const htmlParser = new DOMParser();

const ShowSolution = (props) => {
  const { data, parser } = props;
  const { courseStore } = useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(() => new Store());

  const node = htmlParser.parseFromString(data, 'text/html');
  const html = node.body.innerHTML;
  let index = 0;

  const getSolutions = (el) =>
    el.filter((child) => child.attribs.class?.match(/solution/));

  const getSolutionsForProcedure = (el) =>
    el.filter((child) => child.attribs.class?.match(/step/));

  const getNonSolutions = (el) =>
    el.filter((child) => !child.attribs.class?.match(/solution/));

  const infoText = 'Instructor has locked solutions, please contact your instructor if access to solutions is needed.';

  const parserOptions = {
    replace: (el) => {
      index += 1;
      const { attribs, children } = el;
      const className = (courseStore.course_lock_solution_status) ? 'lock-solution' : 'show-solution';
      const id = `${className}-panel-${index}`;

      if (
        attribs &&
        attribs.class === 'procedure' &&
        parser !== 'nonsolution'
      ) {
        return(
          <React.Fragment>
            {getSolutionsForProcedure(children).length !== 0 && (
              <Accordion id={id}>
                <Card className={`${className}-panel`} eventKey={id}>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ol className="procedure" type="2">
                        {Parser(
                          renderToStaticMarkup(domToReact(getNonSolutions(children))),
                          parserOptions,
                        )}
                      </ol>
                    </Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      eventKey="0"
                      onClick={() =>
                        store.toggleSolutions(id, !store.solutionIsExpanded(id))
                      }
                      className={`${className}-btn`}
                      disabled={courseStore.course_lock_solution_status}
                    >
                      {
                        !store.solutionIsExpanded(id)
                          ? (courseStore.course_lock_solution_status)
                              ? t('Locked Solution')
                              : t('Show Solution')
                          : t('Hide Solution')
                      }
                    </Accordion.Toggle>

                    {courseStore.course_lock_solution_status && (                  
                      <InfoTextButton infoText={infoText} />
                    )}

                  </Card.Header>
                </Card>
              </Accordion>
            )} 
          </React.Fragment>
        );
      }
      if (attribs && attribs.class === 'step') {
        return(
          <li className="step">
            {Parser(
              renderToStaticMarkup(domToReact(getNonSolutions(children))),
              parserOptions,
            )}
            {getSolutions(children).length !== 0 && (
              <Accordion id={id}>
                <Card className={`${className}-panel`} eventKey={id}>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>{domToReact(getSolutions(children))}</Card.Body>
                  </Accordion.Collapse>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      eventKey="0"
                      onClick={() =>
                        store.toggleSolutions(id, !store.solutionIsExpanded(id))
                      }
                      className={`${className}-btn`}
                      disabled={courseStore.course_lock_solution_status}
                    >
                      {
                        !store.solutionIsExpanded(id)
                          ? (courseStore.course_lock_solution_status)
                              ? t('Locked Solution')
                              : t('Show Solution')
                          : t('Hide Solution')
                      }
                    </Accordion.Toggle>

                    {courseStore.course_lock_solution_status && (                  
                      <InfoTextButton infoText={infoText} />
                    )}

                  </Card.Header>
                </Card>
              </Accordion>
            )}
          </li>
        );
      }
      return null;
    },
  };

  return (
    <div className="show-solution-interaction">
      {Parser(html, parserOptions)}
    </div>
  );
};

ShowSolution.propTypes = {
  data: PropTypes.string,
  parser: PropTypes.string,
};

ShowSolution.defaultProps = {
  data: '',
  parser: 'nonsolution',
};

export default observer(ShowSolution);
