import Papa from 'papaparse';
import { CARD_COLORS, DATE_RANGE_DEFAULT, MAIL_FORMAT, DOMAIN } from '../config/constants';
import moment from 'moment';
import 'moment/min/locales';
import { toJS } from 'mobx';
import * as merge from 'deepmerge';

/**
 * Returns a Promise that resolves to an array of parsed CSV values
 * @param file
 * @returns Promise
 */
async function asyncParseCSVFile(file) {
  return new Promise((resolve, reject) => {
    const completeCallback = async (result) => {
      if (!result) {
        reject();
      }

      if (result && result.errors.length) {
        reject(result.errors);
      }

      resolve(result && result.data);
    };

    const config = {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      complete: completeCallback,
    };

    Papa.parse(file, config);
  });
};

/**
 * Returns a date object based on a provided ISO-8601 string
 * @param {string} string - date in ISO-8601 format
 * @return {Date} a date object
 */
const parseStringUTC = (string) => {
  let s = string;

  s = s.split(/\D/);
  s[6] = s[6] ? `0.${s[6]}` * 1000 : 0;

  const args = Date.UTC(
    s[0],
    (s[1] -= 1),
    s[2],
    s[3] || 0,
    s[4] || 0,
    s[5] || 0,
    s[6] || 0
  );

  return new Date(args);
};

/**
 * Returns an object based on a provided ISO-8601 string
 * @param {string} [query=window.location.search] - query string to parse
 * @return {Object} an object containing query string keys and values.
 */
const getQueryString = (query) => {
  const q = (query || window.location.search).substr(1).split('&');

  if (q === '') {
    return {};
  }

  const b = {};
  let i = 0;

  while (i < q.length) {
    const p = q[i].split('=', 2);
    if (p.length === 1) {
      b[p[0]] = '';
    } else {
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
    }
    i += 1;
  }
  return b;
};

/**
 * Returns the difference between two days
 * @param {string} start - date in ISO-8601 format
 * @param {string} end - date in ISO-8601 format
 * @return {integer} the difference in days
 */
const getDaysDiff = (start, end) => {
  const diff = ((parseStringUTC(end) - parseStringUTC(start)) / 8.64e7).toFixed(
    2
  );

  return diff > 0 ? diff : 0;
};

/**
 * Returns form data
 * @param {string} $form - jQuery form
 * @return {Object} an object containing form data
 */
const formData = ($form) => {
  // TODO: Look for an ES6 or lodash method to do this
  const o = {};

  $form.serializeArray().forEach((item) => {
    o[item.name] = item.value;
  });

  return o;
};

/**
 * Returns Cookie by name
 * @param {string} name - cookie name
 * @return {Object} an object containing cookie data
 */
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length !== 2) {
    return null;
  }

  return parts.pop().split(';').shift();
};

/**
 * Returns string with first letter in uppercase
 * @param {string} string
 */
const transformToSentenceCase = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Returns whether or not an element has a length property
 * @param {string|Array|Object} element
 * @returns {boolean}
 */
const isEmpty = (element) => {
  if (element === undefined || !element.length) {
    return true;
  }

  return false;
};

/**
 * Returns whether or not an object contains empty values
 * @param {Object} object
 * @returns {boolean}
 */
const containsMissingValues = (object) =>
  Object.values(object).some((x) => x === null || x === '');

/**
 * Sorts an array by given object property
 * @param {Array} array
 * @param {string} sortProp
 */
const sortBy = (array, sortProp) =>
  array.sort((item1, item2) => {
    if (item1[sortProp] < item2[sortProp]) return -1;
    if (item1[sortProp] > item2[sortProp]) return 1;

    return 0;
  });

/**
 * Returns array of objects with unique values of a passed property
 * @param array
 * @param property — the criteria of uniqueness
 * @returns {*[]}
 */
const uniqBy = (array, property) => {
  const cb = typeof property === 'function' ? property : (o) => o[property];

  return [
    ...array
      .reduce((map, item) => {
        const key = cb(item);

        if (!map.has(key)) map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

/**
 * Returns an array without occurences of given element
 * @param array
 * @param item
 * @returns {*}
 */
const pull = (array, item) => array.filter((e) => e !== item);

/**
 * Returns an array of files with a given format.
 */
function validateIncomingFiles(acceptedFiles, rejectedFiles, fileFormat) {
  const validFiles = [...acceptedFiles];
  rejectedFiles.forEach((file) => {
    if (file.name.endsWith(`.${fileFormat}`)) {
      validFiles.push(file);
    }
  });
  return validFiles;
};

const randomCardColor = () => {
  return CARD_COLORS[Math.floor(Math.random() * CARD_COLORS.length)];
};
const validateEmail = (emailValue) => {
  return MAIL_FORMAT.test(String(emailValue).toLowerCase());
}
const roundData = data => {
  return data !== null ? Math.round(data * 100) : 0;
};

const formatDate = ( momentDate,language,dateFormat='MMM DD YYYY')=> {
  return momentDate.locale(language).format(dateFormat);
};

const defaultConsumptionSummaryFilters = ()=> {
  return {
      query: "",
      dateRange:  { from: moment().subtract(DATE_RANGE_DEFAULT, "months").toDate().getTime(),
                    to: moment().toDate().getTime()
                  },
      regions: [],
      subRegions: [],
      countries: [],
      statuses: []
  };
};

const defaultVideoConsumptionFilters = ()=> {
  return {
      dateRange:  { from: moment().subtract(DATE_RANGE_DEFAULT, "months").toDate().getTime(),
                    to: moment().toDate().getTime()
                  },
      kaltura_report_type: "user_top_content",
      kaltura_domain: DOMAIN,
      video_type: "",
      video_id: ""
  };
};

const filteredMultiSelectOptions = (options, filter) => {
  if (!filter) {
    return options;
  }
  const re = new RegExp(filter, "i");
  return options.filter(({ value }) => value && value.match(re));
};

const filteredMultiSelectOptionsByLabel = (options, filter) => {
  if (!filter) {
    return options;
  }
  const re = new RegExp(filter, "i");
  return options.filter(({ label }) => label && label.match(re));
};

const isValidUrl = urlString => {
  const urlRegex = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
  const result = urlString.match(urlRegex);
  return result !== null;
};

const getCourseTitleByLanguage = (translations, language) => {
  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const title =
    getTranslation()?.find(tr => tr.language === language)?.title ||
    getTranslation()?.find(tr => tr.language === 'en-US')?.title;
  return title;
};

const updateJsonSchemaFormSelectInput = (schemaForm, index, selectOptions) => {
  schemaForm.properties[index].enum = [];
  schemaForm.properties[index].enumNames = [];
  const updatedSchema = {
    properties: {
      [index]: {
        enum: toJS(selectOptions.enum),
        enumNames: toJS(selectOptions.enumNames),
      },
    },
  };
  schemaForm = merge(toJS(schemaForm), updatedSchema);
  return schemaForm;
};

const generateUTCTimestamp = (dateVal) => {
  return Math.floor((dateVal.getTime() + dateVal.getTimezoneOffset()*60*1000)/1000);
};

export {
  parseStringUTC,
  getQueryString,
  getDaysDiff,
  formData,
  getCookie,
  transformToSentenceCase,
  isEmpty,
  containsMissingValues,
  sortBy,
  uniqBy,
  pull,
  validateIncomingFiles,
  asyncParseCSVFile,
  randomCardColor,
  validateEmail,
  roundData,
  formatDate,
  defaultConsumptionSummaryFilters,
  defaultVideoConsumptionFilters,
  filteredMultiSelectOptions,
  filteredMultiSelectOptionsByLabel,
  isValidUrl,
  getCourseTitleByLanguage,
  updateJsonSchemaFormSelectInput,
  generateUTCTimestamp,
};
