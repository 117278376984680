import { action, observable } from 'mobx';
import { studentResourceSubSections, studentResourceSections } from '../../config/studentResources';

class StudentResourcesStore {
  subsections = studentResourceSubSections;
  sections = studentResourceSections;

  @observable subsection = undefined;
  @observable shouldVideoShow = false;

  @action toggleVideoModal() {
    this.shouldVideoShow = !this.shouldVideoShow;
  }
}

export default StudentResourcesStore;
