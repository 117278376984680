import { computed, observable } from 'mobx';
import { DEFAULT_LANGUAGE } from '../config/constants';

class UIStore {
  @observable _title = '';

  @observable _currentLanguage = DEFAULT_LANGUAGE;

  defaultLanguage = DEFAULT_LANGUAGE;

  @observable languages = [];

  @observable theme = 'default';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  @computed get currentLanguage() {
    for (let language of this.languages) {
      if (language.token === this._currentLanguage)
        return this._currentLanguage;
    }
    return this.defaultLanguage;
  }

  set currentLanguage(lang) {
    this._currentLanguage = lang;
  }

  onLanguageChange = async (language = 'en-US') => {
    const { vocabularyStore } = this.rootStore;

    this.currentLanguage = language;

    await vocabularyStore.getVocabularyList(language);

    const languages = await vocabularyStore.getVocabularyByNamespace(
      'languages',
      language,
    );

    this.languages = languages || this.languages;
    return this.languages.find((lang) => lang.key === language);
  };
}

export default UIStore;
