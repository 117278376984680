import React from 'react';
import SupportCase from '../../components/SupportCase';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../services/ErrorService';
import GenericViewError from '../../components/Error/GenericViewError';

const SupportCaseView = () => {
	return <SupportCase />
};

export default withErrorBoundary(
  observer(SupportCaseView),
  GenericViewError,
  handleError,
);