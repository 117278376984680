import React from 'react';
import CardList from '../../../components/CardList';
import LinkCard from '../../../components/Cards/LinkCard';
import LoadingView from '../../../components/Loading/View';
import { CARD_COLORS } from '../../../config/constants';

const LinkListView = ({ baseFileURL,files, loading, signedUrl }) => {
  const getColor = (index) => {
    return CARD_COLORS[index % CARD_COLORS.length];
  };

  return (
    <div className="resources-link-list">
      <section className="m-3">
        {loading ? (
          <LoadingView />
        ) : (
          <CardList>
            {files && files.map((file, index) => (
              <LinkCard
                title={file.title}
                url={file.url.indexOf('http') > -1 ? file.url : `${baseFileURL}${file.url}`}
                key={index}
                cardColor={getColor(index)}
                signedUrl={signedUrl || false}
              />
            ))}
          </CardList>
        )}
      </section>
    </div>
  );
};

export default LinkListView;
