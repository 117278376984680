import React from 'react';
import { useTranslation } from 'react-i18next';
import IconCardContainer from '../../../components/Cards/IconCardContainer';

const ImageGalleryCard = ({ cardColor, onClick }) => {
  const { t } = useTranslation();

  const title = t('Image Gallery');
  const firstLine = 'Red Hat Academy';
  const secondLine = t('RHA Logos');

  return (
    <IconCardContainer
      title={title}
      firstLineElement={firstLine}
      secondLineElement={secondLine}
      cardColor={cardColor}
      iconName="image"
      onCardClick={onClick}
    />
  );
};

export default ImageGalleryCard;
