import { get, post, patch } from 'axios';

import {
  CLASS_API_URL,
  CLASS_ATTACHED_FILE_API,
  CLASS_PATCH_API_URL,
  CLASS_DETAIL_BY_ID_API_URL,
  CLASS_STUDENT_PROMOCODE_API,
  ACADEMY_CLASS_API_URL,
  ACADEMY_CLASS_CSV_API_URL,
} from '../config/constants';

export const getClasses = async (academyId = null, report_type = null) => {
  try {
    const url = report_type && academyId ? 
                `${ACADEMY_CLASS_CSV_API_URL.replace('<academy_id>', academyId).replace('<report_type>', report_type)}`
                :
                academyId ? `${ACADEMY_CLASS_API_URL.replace('<academy_id>', academyId)}` : `${CLASS_API_URL}`;
    const response = await get(url);
    return report_type ? response : response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postClasses = async (data) => {
  try {
    const response = await post(`${CLASS_API_URL}`, data);
    return response?.data;
  } catch (err) {
    console.error(err);
    return `Couldn't create class`;
  }
};

export const patchClasses = async (data, classId) => {
  try {
    const response = await patch(
      `${CLASS_PATCH_API_URL.replace('<class_id>', classId)}`,
      data,
    );

    return response?.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const postFile = async (classId, data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const myFile = new FormData();

  myFile.append('file', data);

  try {
    const url = `${CLASS_API_URL}/${classId}/storage`;
    const response = await post(url, myFile, config);
    return response.status === 200;
  } catch (err) {
    return `Couldn't upload file`;
  }
};

export const getAttachedFiles = async (classID) => {
  try {
    const response = await get(
      `${CLASS_ATTACHED_FILE_API.replace('<class_id>', classID)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getClassDetailbyId = async (classID) => {
  try {
    const response = await get(
      `${CLASS_DETAIL_BY_ID_API_URL.replace('<class-id>', classID)}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const requestPromoCodeForStudents = async (
  classId,
  studentsUsernames,
) => {
  if (!classId) {
    throw new Error('ClassId is required');
  }

  if (!studentsUsernames) {
    throw new Error('List of student username is required');
  }

  try {
    await post(CLASS_STUDENT_PROMOCODE_API.replace('<class-id>', classId), {
      students: studentsUsernames,
    });
    return 'Promocode requested sucessfully';
  } catch (err) {
    throw new Error(err.response?.data?.error || `Couldn't request promo code`);
  }
};

export default { getClasses, postClasses, postFile, patchClasses };
