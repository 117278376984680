import { useContext } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { checkPermissions } from '../../config/permission';
import { storesContext } from '../../stores';

const AccessComponent = ({ componentName, children }) => {

    const { userStore } = useContext(storesContext);

    const permitted = checkPermissions(componentName, userStore.role.name);

    return permitted ? children : null;
};

AccessComponent.propTypes = {
    componentName: PropTypes.string,
    children: PropTypes.element,
};

AccessComponent.defaultProps = {
    componentName: '',
    children: null
};

export default observer(AccessComponent);
