import { createContext } from 'react';
import { observable, action } from 'mobx';

class HeaderStore {
  @observable showProfile = false;

  @action toggleProfile = () => {
    return (this.showProfile = !this.showProfile);
  };
}

export default HeaderStore;

export const headerContext = createContext(null);
