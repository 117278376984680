import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { when } from "mobx";
import { observer } from "mobx-react";
import { Col, Container, Row } from "react-bootstrap";

import { withErrorBoundary } from "react-error-boundary";
import { handleError } from "../../services/ErrorService";
import GenericViewError from "../../components/Error/GenericViewError";
import ViewLoader from "../../components/Loading/View";
import { storesContext } from "../../stores";
import "./styles.scss";
import {CARD_BORDER_COLOR} from "../../config/constants";

const OfferingVersionCard = React.lazy(() =>
  import(
    /* webpackChunkName: 'enablement' */ "../../components/Cards/OfferingVersionCard"
  )
);

const EnablementView = () => {
  const { t } = useTranslation();
  const {
    catalogStore,

    userStore,
  } = useContext(storesContext);
  const { latestCoursePaginatedEntries, exmaPaginatedEntries } = catalogStore;

  const course = (entry) => {
    return catalogStore.groupedCatalogEntries[entry.code];
  };

  const extractOfferingVersionCardData = (entry) => {
    const target = {};
    ({ slug: target.courseSlug, code: target.courseCode, version: target.courseVersion } = entry);
    
    
    return target;
  };

  const ProgressOfferingCard = (p) => {
    const { entry, cardBorderColor,offeringType, displayVersionDropdown } = p;

    const courses = course(entry);
    const offeringVersionCardProps = extractOfferingVersionCardData(entry);

    if (courses) {
      return (
        <OfferingVersionCard
          key={entry.slug}          
          offeringType={offeringType}
          displayVersionDropdown={displayVersionDropdown}
          {...offeringVersionCardProps}
          cardColor={cardBorderColor}
        />
      );
    }
  };
  useEffect(() => {
    when(
      () => catalogStore.catalogReady && userStore.isLoggedIn,
      async () => {
        
        if (catalogStore.subscriptionCatalog) {
          catalogStore.filterEntries();
        }

        catalogStore.loaded = true;
      }
    );
  }, [userStore, catalogStore]);

  return (
    <React.Fragment> 
    <h3 className="view-page-title">{t("Get Trained and Certified")} </h3>
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
      
        <h5>{t("Self-paced Courses")}</h5>
        <Row>
          <Col className="enablement-list-container">
            {catalogStore.loaded ? (
              latestCoursePaginatedEntries.length ? (
                <React.Fragment>
                  <div className="custom-columns">
                    {latestCoursePaginatedEntries.map((entry) => {
                      const { doc_id: docId } = entry;
                      return (
                        <ProgressOfferingCard
                          entry={entry}
                          key={docId}
                          cardBorderColor={CARD_BORDER_COLOR}
                          displayVersionDropdown={true}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              ) : (
                <p>{t("No video classes found")}</p>
              )
            ) : (
              <ViewLoader />
            )}
          </Col>
        </Row>
        <h5>{t("Certification Exams")}</h5>
        <Row>
          <Col className="enablement-list-container">
            {catalogStore.loaded ? (
              latestCoursePaginatedEntries.length ? (
                <React.Fragment>
                  <div className="custom-columns">
                    {exmaPaginatedEntries.map((entry) => {
                      const { doc_id: docId } = entry;
                      return (
                        <ProgressOfferingCard
                          entry={entry}
                          offeringType={"exam"}
                          displayVersionDropdown={false}
                          key={docId}
                          cardBorderColor={"#004153"}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              ) : (
                <p>{t("No video classes found")}</p>
              )
            ) : (
              <ViewLoader />
            )}
          </Col>
        </Row>
      </React.Suspense>
    </Container>
    </React.Fragment> 

  );
};

export default withErrorBoundary(
  observer(EnablementView),
  GenericViewError,
  handleError
);
