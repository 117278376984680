import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Accordion } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import externalIcon from '../../../../img/icons/external-link.svg';
import CollapseIcon from '../../../../img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from '../../../../img/icons/web-icon-caret-thin-down.svg';

import LabWebApplicationsStore from './store';
import './styles.scss';

const LabWebApps = (props) => {
  const { t } = useTranslation();
  const store = useLocalStore(() => new LabWebApplicationsStore());
  const isExpanded = (id) => store.expandedPanels?.has(id);
  const { userLab } = props.labStore;

  const LabWebApplications = userLab?.web_applications;

  const redirectClassroomWebapp = (url) => {
    window.open(url, '_blank');
  };

  const applicationCards = (
    <React.Fragment>
      {LabWebApplications?.map((webApplication, index) => {
        return (
          <Accordion defaultActiveKey={`classroom-webapp-info-${index}`}>
            <Card
              eventKey={`${`classroom-webapp-info-${index}`}`}
              className="lab-webapp-panel"
              onToggle={(expanded) => {
                store.togglePanels(index, expanded);
              }}
            >
              <div
                className="filter-panel-heading"
                data-analytics-id="lab-ctrl-acc-vt-dash-lp"
              >
                <Accordion.Toggle
                  componentClass="div"
                  as={Card.Header}
                  key={`${`classroom-webapp-info ${index}`}`}
                  className="filter-panel-toggle"
                  eventKey={`${`classroom-webapp-info ${index}`}`}
                >
                  <div className="filter-panel-heading-title">
                    <div className="filter-panel-heading-icon">
                      <SVG src={isExpanded(index) ? CollapseIcon : ExpandIcon}>
                        {isExpanded(index) ? t('Collapse') : t('Expand')}
                      </SVG>
                      <span>{webApplication.title || t('Classroom Webapp')}</span>
                    </div>
                    <div className="lab-ssh-btn-wrapper">
                      <React.Fragment>
                        <Button
                          bsStyle="primary"
                          type="submit"
                          on
                          onClick={() =>
                            redirectClassroomWebapp(webApplication.url)
                          }
                        >
                          <SVG src={externalIcon}></SVG> {t('Access')}
                        </Button>
                      </React.Fragment>
                    </div>
                  </div>
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse
                eventKey={`${`classroom-webapp-info ${index}`}`}
              >
                <Card.Body collapsible className="filter-panel-body">
                  <div className="margin-bottom">
                    <p>{webApplication.description}</p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        );
      })}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {LabWebApplications && (
        <div className="d-flex flex-column mt-5">
          
            <h3>{t('Web Applications')}</h3>
          
          <div className="lab-webpanel-row">{applicationCards}</div>
        </div>
      )}
    </React.Fragment>
  );
};

LabWebApps.propTypes = {
  labStore: PropTypes.object,
};

LabWebApps.defaultProps = {
  labStore: {},
};

export default observer(LabWebApps);
