import { get, post } from 'axios';
import {  PROGRESS_API_URL, COURSE_PROGRESS } from '../config/constants';

// eslint-disable-next-line
export const getProgress = (isStudent = false) => {
  const url = isStudent ? PROGRESS_API_URL + '?sort=class_id' : PROGRESS_API_URL;
  return get(url).then(res => res.data);
};


export const setCourseProgress = (slug, pageUUID, entityKeyToTrackProgressWith, entityValueToTrackProgressWith) =>
  new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };
    var data = {};
    data = {
      course_slug: slug,
      entry_uuid: pageUUID,
      progress: 1,
      score: 1
    };    
    data[entityKeyToTrackProgressWith] = entityValueToTrackProgressWith;
    post(`${COURSE_PROGRESS}`, data, config)
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((error) => reject(error));
  });
