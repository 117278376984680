import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import SVG from 'react-inlinesvg';
import studentsIcon from '../../../img/icons/users.svg';
import { storesContext } from '../../../stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';

import WithCardContainer from '../../Cards/CardContainer';
import { Alert, Card } from 'react-bootstrap';

import './styles.scss';

const handleError = () => {
  // TODO: send error to backend analytics service
};

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert variant="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const ClassCotentCard = (props) => {
  const { t, i18n } = useTranslation();
  const { content , disabled, cardBackgroundColor } = props;
  const { class_name, course_title, start_date, end_date, students, label } = content;
  const timezone = moment.tz.guess() || undefined;
  const locale = i18n.language;
  const { routerStore } = useContext(storesContext);
  const navigateToClass = () => {
    if (!disabled) {
      if(routerStore.route.name === 'academysummary:classes') {
        routerStore.navigate('academysummary:class:details', {
          academyid: routerStore.route?.params?.academyid,
          classid: props.content.doc_id,
        });
      } else {
        routerStore.navigate('classes:view', {
          classid: props.content.doc_id,
        });
      }
    }
  };
  return (
    <React.Fragment>
      <Card.Body
        className={`class-content-card ${moment(end_date).isBefore() ? 'completed' : ''}`}
        {...(cardBackgroundColor !== '' && {
          style : {backgroundColor: cardBackgroundColor},
        })}
        onClick={() => navigateToClass()}
      >
        <div className="class-info-wrapper">
          <div className="class-name">{class_name}</div>
          <div className="course-title">
            <p>{t(`${course_title}`)}</p>
          </div>
          <div className="class-date">
            <Moment
              locale={locale}
              format="ll"
              {...(timezone && {
                tz: timezone,
              })}
            >
              {start_date}
            </Moment>
            <span>&nbsp;-&nbsp;</span>
            <Moment
              locale={locale}
              format="ll"
              {...(timezone && {
                tz: timezone,
              })}
            >
              {end_date}
            </Moment>
          </div>
          <div className="label">{label}</div>
        </div>
        <div className="class-icons-wrapper">
          <div>
            <div className="student-nr">{students?.length || 0}</div>
            <SVG src={studentsIcon}>{t('Students')}</SVG>
          </div>
          <div>
            {content.ambassador_interests && (
              <React.Fragment>
                <div className="student-nr">
                  {content.ambassador_interests.length}
                </div>
                <SVG src={studentsIcon}>
                  {t('Student Ambassador Interests')}
                </SVG>
              </React.Fragment>
            )}
          </div>
          <div>
            {content.submissions && (
              <React.Fragment>
                <div className="student-nr">{content.submissions.length}</div>
                <SVG src={studentsIcon}>{t('Submissions')}</SVG>
              </React.Fragment>
            )}
          </div>
        </div>
      </Card.Body>
    </React.Fragment>
  );
};

ClassCotentCard.propTypes = {
  content: PropTypes.object,
  disabled: PropTypes.bool,
  cardBackgroundColor: PropTypes.string,
};

ClassCotentCard.defaultProps = {
  content: {},
  disabled: false,
  cardBackgroundColor: '',
};

export default withErrorBoundary(
  observer(WithCardContainer(observer(ClassCotentCard))),
  ErrorMessage,
  handleError,
);
