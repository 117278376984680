import { createRouter } from 'router5';
import browserPlugin from 'router5/plugins/browser';
import { mobxPlugin } from 'mobx-router5';
import { APP_BASE } from './constants';
import Routes from './routes';


const configureRouter = (useLoggerPlugin = false, stores) => {
  const {  routerStore } = stores;

  const routes = new Routes(stores);

  const router = createRouter(routes.routes, {
    allowNotFound: true,
  });

  router.usePlugin(mobxPlugin(routerStore));
  router.usePlugin(browserPlugin({ base: APP_BASE, useHash: false }));

  

  return router;
};

export default configureRouter;
