import React, {useContext, useEffect} from 'react';
import { storesContext } from '../../stores';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CardList from '../../components/CardList';
import { CARD_COLORS } from '../../config/constants';
import IconCardContainer from '../../components/Cards/IconCardContainer';

const TalentNetworkView = () => {
  const { t } = useTranslation();
  const { userStore, routerStore } = useContext(storesContext);
  const title = t('Red Hat Academy Talent Network Portal');
  const sections = {    
      Profile: {
          description: t('Red Hat Academy Talent Network connects students who have completed Red Hat training with employers from Red Hat and our partners around the world, creating a pool of enterprise-ready talent for the jobs of tomorrow.'),
          items: [
              {
                  title: t('Red Hat Academy Talent Network'),
                  url: 'https://rhtapps.redhat.com/academy_talent/student/profile',

                  icon: 'file',
                  firstLine: t('Explore jobs and manage your Red Hat Academy Talent Network profile'),
              },
          ],
      },
  };

  const getColor = (index) => {
    return CARD_COLORS[index % CARD_COLORS.length];
  };

  const renderTalentNetworkItems = (items) => {
    return (
      <React.Fragment>
        {items?.length > 0 && (
          <CardList>
            {items?.map((i, index) => {
              return (
                <React.Fragment>
                  <IconCardContainer
                    title={i.title}
                    firstLineElement={i.firstLine}
                    secondLineElement={i.secondLine}
                    cardColor={getColor(index)}
                    iconName={i.icon || 'file'}
                    onCardClick={() => {
                        window.open(i.url, '_blank').focus();
                    }}
                  />
                </React.Fragment>
              );
            })}
          </CardList>
        )}
      </React.Fragment>
    );
  };

  const renderTalentNetwork = (sections) => {
    return Object.keys(sections)?.map((s) => {
      return (
        <React.Fragment>
          <section className="m-3">
            <p>{t(sections[s].description)}</p>
            {renderTalentNetworkItems(sections[s].items)}
          </section>
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    if(userStore.isLoggedIn && !userStore.isStudent){
      routerStore.navigate('default-redirect');
    }
  }, [userStore, routerStore]);

  return (
    <React.Fragment>
        <div className="talent-network">
            <h3 className="view-page-title">{title}</h3>            
            {sections ? renderTalentNetwork(sections) : null}
        </div>
    </React.Fragment>        
  );
};

export default observer(TalentNetworkView);
