import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import SearchText from '../../SearchText';
import { storesContext } from '../../../stores';
import DatePickerInput from '../../DatePickerInput';
import { formatDate } from 'react-day-picker/moment';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import SortOrderButton from '../../SortOrderButton';
import './styles.scss';

const ClassFilter = (props) => {
  const { classStore } = useContext(storesContext);

  const { t } = useTranslation();

  const sortOptions = [
    { value: 'start_date', label: t('Start Date') },
    { value: 'end_date', label: t('End Date') },
    { value: 'class_name', label: t('Class Name') },
  ];

  const validateDate = (selectedDate) => {
    return selectedDate !== undefined;
  };

  const handleStartDateClick = (selectedDay) => {
    classStore.onDateChange('start_date', selectedDay);
    classStore.end_date = '';
  };

  const handleEndDateClick = (selectedDay) => {
    classStore.onDateChange('end_date', selectedDay);
  };

  const resetClassFilter = () => {
    classStore.resetClassFilter();
  };

  return (
    <div className="class-filter-container">
      <div className="row mb-2">
        <div className="col">
          <SearchText
            placeHolder={t('Search classes by name, label or course code')}
            onSearch={classStore.onSearch}
            query={classStore.query}
          ></SearchText>
        </div>
      </div>
      <div className="row">
        <div className="col-md col-sm">
          <span className="class-filter-label">{t('From')}</span>
          <DatePickerInput
            onDateSelected={handleStartDateClick}
            selectedDate={
              classStore.start_date &&
              formatDate(classStore.start_date, 'YYYY-MM-DD')
            }
            onValidateDate={validateDate}
          />
        </div>
        <div className="col-md col-sm">
          <span className="class-filter-label">{t('Until')}</span>
          <DatePickerInput
            onDateSelected={handleEndDateClick}
            selectedDate={
              classStore.end_date &&
              formatDate(classStore.end_date, 'YYYY-MM-DD')
            }
            onValidateDate={validateDate}
            disabledDate={
              classStore.start_date && new Date(classStore.start_date)
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <span className="class-filter-label">{t('Sort by')}</span>
          <div className="sort-input-area d-flex">
            <Select
              className="select-sort-by"
              classNamePrefix="select_sort_by"
              onChange={classStore.onSortBy}
              options={sortOptions}
              value={
                classStore.sortByField
                  ? sortOptions.find((s) => s.value === classStore.sortByField)
                  : sortOptions[0]
              }
            />
            <SortOrderButton
              onSortOrder={classStore.onSortOrder}
              isSortAscending={classStore.isSortAscending}
            ></SortOrderButton>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col text-right">
          <Button
            className="clear-btn"
            onClick={() => {
              resetClassFilter();
            }}
          >
            {t('CLEAR')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(ClassFilter);
