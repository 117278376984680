import React from 'react';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Card, Button } from 'react-bootstrap';

import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from '../../../services/ErrorService';
import GenericViewError from '../../Error/GenericViewError';

import { useTranslation } from 'react-i18next';

import './styles.scss';

const HelpCard = (props) => {
  const { t } = useTranslation();
  const { top,
  middle,
  email,
  bottom,
  hasButton,
  buttonText,
  onButtonClick,
  } = props;

  return (
    <React.Fragment>
      <Card className="offering-card-container help-card">
          <div className="info-top">{t(`${top}`)}</div>
          <div className="info-middle">{t(`${middle}`)}</div>
          {email && (<div className="info-email"><a href={"mailto:" + email}>{email}</a></div>)}
          <div className="info-bottom">{t(`${bottom}`)}</div>
          {hasButton && (<span className="btn-wrapper"><Button onClick={onButtonClick}>{t(`${buttonText}`)}</Button></span>)}
      </Card>
    </React.Fragment>
  );
}

HelpCard.propTypes = {
  top: PropTypes.string,
  middle: PropTypes.string,
  email: PropTypes.string,
  bottom: PropTypes.string,
  hasButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

HelpCard.defaultProps = {
  top: '',
  middle: '',
  email: '',
  bottom: '',
  hasButton: false,
  buttonText: '',
  onButtonClick: () => {},
};

export default withErrorBoundary(
  observer(HelpCard),
  GenericViewError,
  handleError,
);