import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { DropdownButton, ProgressBar, Dropdown } from 'react-bootstrap';

import './styles.scss';

const VersionProgress = (props) => {
  const {
    modality,
    version,
    progress,
    active,
    displayVersionDropdown,
    onVersionChange,
    versions,
  } = props;

  const orderedVersions = Array.from(versions).sort((a, b) => {
    return parseFloat(b) - parseFloat(a);
  });

  return (
    <div className="progress-version-container">
      {displayVersionDropdown && (
        <DropdownButton
          className="progress-version-selector"
          variant="default"
          title={`v.${version}`}
          onClick={(e) => e.stopPropagation()}
        >
          {orderedVersions.map((v) => (
            <Dropdown.Item
              key={v}
              value={v}
              active={v === version}
              onClick={(e) => {
                e.stopPropagation();
                onVersionChange(v);
              }}
            >
              {`v.${v}`}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
      <div className="progress-container">
        {modality !== 'expert_seminar' && modality !== 'exam' ? (
          <ProgressBar
            className={active ? '' : 'disabled-course'}
            striped
            now={progress}
            label={`${progress}%`}
          />
        ) : (
          <div className="no-progress" />
        )}
      </div>
    </div>
  );
};

VersionProgress.propTypes = {
  modality: PropTypes.string,
  progress: PropTypes.number,
  active: PropTypes.bool,
  displayVersionDropdown: PropTypes.bool,
  onVersionChange: PropTypes.func,
  versions: PropTypes.array,
  version: PropTypes.string,
};

VersionProgress.defaultProps = {
  modality: 'course',
  progress: 0,
  active: true,
  displayVersionDropdown: false,
  onVersionChange: () => {},
  versions: [],
  version: '1.0',
};

export default observer(VersionProgress);
