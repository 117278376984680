import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const CardList = ({ children, className }) => {
  return <div className={`card-list m-3 ${className}`}>{children}</div>;
};

CardList.defaultProps = {
  className: 'list',
};

CardList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};

export default CardList;
