import { action, observable, computed } from 'mobx';
import moment from 'moment';
import { createContext } from 'react';

import {
  getAcademyDetails,
  getInstructors,
  deleteInstructorsInfo,
  patchInstructorsInfo,
  getAdoptAcademyInfo,
  sendInterestAcademyInfo,
} from '../../services/AcademyService';
class AcademyDetailsStore {
  @observable hideDeleteModal = true;
  @observable hideActivateModal = true;
  @observable instructorToEnable = {};
  @observable instructorToDelete = {};

  @observable _currentPage = 1;
  @observable apiInProgress = false;
  @observable hasError = false;
  @observable message = '';
  @observable entriesPerPage = 10;

  @observable academyDetails = {};
  @observable isSortAscending = true;
  @observable sortByField = 'name';
  @observable newInstructor = { open: false };
  @observable hasRelatedData = false;
  @observable loaded = false;
  @observable instructors = [];
  @observable adoptAcademyStatus;

  notRequestedStatusString = 'notRequested';
  requestedStatusString = 'requested';
  approvedStatusString = 'approved';

  getInstructorInfo = (username) => {
    return this.instructorsInfo.find((i) => i.username === username);
  };
  extendInstructor = (instructor) => {
    const instructorInfo = this.getInstructorInfo(instructor.username);
    return (instructor = {
      ...instructor,
      open: false,
      name:
        (instructor?.first_name ? instructor?.first_name : '') +
        (instructor?.last_name ? ' ' + instructor?.last_name : ''),
      joined: instructorInfo?.timestamp,
      disabled: instructorInfo?.disabled,
      notes: instructorInfo?.notes,
      is_partner: instructorInfo?.is_partner || false,
      phone: instructor?.phone,
      email: instructor?.default_email,
    });
  };

  @computed get instructorsInfo() {
    return (this.academyDetails && this.academyDetails?.instructors_info) || [];
  }
  @computed get academyId() {
    return this.academyDetails?.doc_id || '';
  }
  @computed get academyName() {
    return this.academyDetails?.institution_name || '';
  }

  @computed get partnerExist() {
    return (
      this.academyDetails?.partner_company_name &&
      this.academyDetails?.partner_company_name !== ''
    );
  }
  resetNewInstructor = () => {
    this.newInstructor = {
      username: '',
      notes: '',
      open: false,
      showMessage: false,
      first_name:'',
      last_name: '',
      default_email:''
    };
  };

  @action submitNewInstructor = async (academyDetails) => {
    this.resetNewInstructor();
    this.academyDetails = academyDetails;
    await this.getInstructors(true);
  };

  @action updateAcademyDetails = async (academyDetails) => {
    this.academyDetails = academyDetails;
    this.instructors = this.instructors.map((i) => this.extendInstructor(i));
  };
  @action onSortInstructorBy = (sortBy) => {
    this.sortByField = sortBy;
    this.isSortAscending = !this.isSortAscending;

    this.instructors = this.sortInstructors(this.instructors);
  };
  @action sortInstructors = (instructors) => {
    if (!this.isSortAscending) {
      return instructors
        .slice()
        .sort((a, b) =>
          this.sortByField === 'joined' || this.sortByField === 'vc_ordered'
            ? moment(b[this.sortByField], 'YYYY/MM/DD') -
              moment(a[this.sortByField], 'YYYY/MM/DD')
            : this.sortByField === 'username' || this.sortByField === 'name'
            ? b[this.sortByField].localeCompare(a[this.sortByField])
            : b[this.sortByField] - a[this.sortByField],
        );
    }

    return instructors
      .slice()
      .sort((a, b) =>
        this.sortByField === 'joined' || this.sortByField === 'vc_ordered'
          ? moment(a[this.sortByField], 'YYYY/MM/DD') -
            moment(b[this.sortByField], 'YYYY/MM/DD')
          : this.sortByField === 'username' || this.sortByField === 'name'
          ? a[this.sortByField].localeCompare(b[this.sortByField])
          : a[this.sortByField] - b[this.sortByField],
      );
  };

  @action getInstructors = async (force = false) => {
    if (this.instructors && this.instructors?.length > 0 && !force) {
      return;
    }
    try {
      this.loaded = false;
      let instructors = await getInstructors(this.academyId);
      if (instructors) {
        instructors = Object.values(instructors).map((i) =>
          this.extendInstructor(i),
        );
        this.instructors = this.sortInstructors(instructors);

        this.loaded = true;
      }
    } catch (error) {
      this.loaded = true;
      console.error(error);
    }
  };

  @action getAcademyDetails = async (academyId = null) => {
    try {
      const details = await getAcademyDetails(academyId);
      if (details) {
        this.academyDetails = details[0] || details;
        this.hasRelatedData = true;
      }
    } catch (error) {
      this.hasRelatedData = true;
    }
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return Math.ceil(this.instructors.length / this.entriesPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.instructors.slice(startIndex, startIndex + this.entriesPerPage);
  }

  @action showDeleteModal = (instructor) => {
    this.instructorToDelete = instructor;
    this.hideDeleteModal = false;
  };
  @action handleCloseDeleteModal = () => {
    this.hideDeleteModal = true;
  };
  @action deleteInstructor = async () => {
    this.handleInprogress('Deleting an instructor');

    deleteInstructorsInfo(this.academyId, {
      instructors: [this.instructorToDelete.username],
    })
      .then((details) => {
        this.handleSuccess('Instructor deleted successfully', details);
      })
      .catch((e) => {
        this.handleError('Error deleting instructor');
      });
  };

  @action showActivateModal = (instructor) => {
    this.instructorToEnable = instructor;
    this.hideActivateModal = false;
  };
  @action handleActivateCloseModal = () => {
    this.hideActivateModal = true;
  };
  @action enableInstructor = async () => {
    this.handleInprogress('Activating an instructor');

    patchInstructorsInfo(this.academyId, {
      instructors: [
        { username: this.instructorToEnable.username, disabled: false },
      ],
    })
      .then((details) => {
        this.handleSuccess('Instructor activated successfully', details);
      })
      .catch((e) => {
        this.handleError('Error activating instructor');
      });
  };

  @action getAdoptAcademyStatus = async () => {
    try {
      if (this.academyId) {
        const info = await getAdoptAcademyInfo(this.academyId);
        if (info.length > 0) {
          const request = info[0];
          if (request.approved) {
            this.adoptAcademyStatus = this.approvedStatusString;
          } else {
            this.adoptAcademyStatus = this.requestedStatusString;
          }
        } else {
          this.adoptAcademyStatus = this.notRequestedStatusString;
        }
      }

      return this.adoptAcademyStatus;
    } catch (error) {
      this.adoptAcademyStatus = 'error';
      this.handleError('Error getting Adopt Academy info');
    }
  };

  @action requestJoinAdoptAcademy = async () => {
    try {
      this.adoptAcademyStatus = 'loading';
      await sendInterestAcademyInfo(this.academyId);
      this.adoptAcademyStatus = this.requestedStatusString;
    } catch (error) {
      this.adoptAcademyStatus = 'error';
      this.handleError('Error requesting access to Adopt an Academy');
    }
  };

  @action onUpdateInstructorInfo = async (academyDetails) => {
    this.academyDetails = academyDetails;
    await this.getInstructors(true);
  };

  handleError = (message) => {
    this.apiInProgress = false;
    this.hasError = true;
    this.message = message;
  };

  handleInprogress = (message) => {
    this.apiInProgress = true;
    this.hasError = false;
    this.message = message;
  };
  handleSuccess = (message, details) => {
    this.apiInProgress = false;
    this.hasError = false;
    this.message = message;
    this.hideDeleteModal = true;
    this.hideActivateModal = true;
    setTimeout(() => {
      this.academyDetails = details;
      this.message = '';
      this.getInstructors(true);
    }, 1000);
  };
}

export default AcademyDetailsStore;
export const AcademyDetailsContext = createContext(null);
