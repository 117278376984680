import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconCardContainer from '../../IconCardContainer';
import ModalLabOptions from './ModalLabOptions';

const BuyLabCard = ({
  courseAllowsLabs,
  canBuyLabs,
  providers,
  course,
  getLabProvidersForCourse,
}) => {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = React.useState(false);
  const title = t('Use Labs');
  const firstLine = course.code.toLowerCase() !== 'do101' ? t(`Purchase and use Lab hours from external providers`):'Use Lab hours from IBM';
  const iconName = 'lab';

  const onUserInfoUpdate = async () => {
    await getLabProvidersForCourse(course);
  };

  return (
    courseAllowsLabs && (
      <React.Fragment>
        <IconCardContainer
          iconName={iconName}
          title={title}
          firstLineElement={<span>{firstLine}</span>}
          cardColor="var(--warning)"
          onCardClick={() => setShowOptions(true)}
        />
        <ModalLabOptions
          show={showOptions}
          modalTitle={ course.code.toLowerCase() !== 'do101' ? t('Purchase or Access Previously Purchased Labs (Optional)') : t('Launch labs')}
          modalSubTitle={course.code.toLowerCase() !== 'do101' ? `${t('From providers')}:` : ""}
          onHide={() => setShowOptions(false)}
          providers={providers}
          canBuyLabs={canBuyLabs}
          onUserInfoUpdate={onUserInfoUpdate}
        />
      </React.Fragment>
    )
  );
};

BuyLabCard.propTypes = {
  courseAllowsLabs: PropTypes.bool,
  canBuyLabs: PropTypes.bool,
  providers: PropTypes.array,
  course: PropTypes.object,
  getLabProvidersForCourse: PropTypes.func,
};

export default BuyLabCard;
