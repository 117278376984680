import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { storesContext } from '../../../stores';
import CertificateOfAttendanceModal from '../Modal';
import Store from './store';

const CertificateOfAttendanceButton = props => {
  const { courseSlug, timestamp, classId = null,academyId=null } = props;

  const { t } = useTranslation();

  const { userStore } = useContext(storesContext);

  const store = useLocalStore(() => new Store());

  const setCertificateOfAttendanceModalVisibility = visible => {
    store.certificateOfAttendanceModalVisibility = visible;
  };

  return (
    <React.Fragment>
      <Button
        variant="secondary-new"
        onClick={() => {
          setCertificateOfAttendanceModalVisibility(true);
        }}
      >
        {t('Certificate of Attendance')}
      </Button>
      {store.certificateOfAttendanceModalVisibility && (
        <CertificateOfAttendanceModal
          user={userStore.user}
          courseSlug={courseSlug}
          classId={classId}
          academyId={academyId}
          timestamp={timestamp}
          setVisibility={setCertificateOfAttendanceModalVisibility}
        />
      )}
    </React.Fragment>
  );
};

CertificateOfAttendanceButton.propTypes = {
  courseSlug: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  classId: PropTypes.string,
  academyId:PropTypes.string
};

export default observer(CertificateOfAttendanceButton);
