import { createContext } from 'react';
import { action, computed, observable } from 'mobx';
import { login } from '../../services/AuthService';
import { getAcademyDetails } from '../../services/AcademyService';
import { logout } from '../../services/AuthService';
import { SESSION_STORAGE_KEYS } from '../../config/constants';
import { remove } from '../../config/sessionStorage';

class LoginStore {
  @observable offeringId = null;

  @observable course = {};

  @observable user = {};

  @observable username = '';

  @observable password = '';

  @observable loading = false;

  @observable loadingFull = false;

  @observable message = {};

  query = {};

  @observable showBlockingModal = false;

  @observable loadedDetails = false;

  @observable academy = {};

  @action async showModal(isInstructor) {
    try {
      if (isInstructor) {
        await this.getAcademyDetails();
        this.showBlockingModal = this.inactive;
        if (this.inactive) await logout(false);
      } else {
        this.showBlockingModal = false;
      }
    } catch (error) {
      this.showBlockingModal = false;
    }
  }

  @computed get inactive() {
    const username = this.user.username;
    const instructors = this.academy?.instructors_info;
    for (let instructor of instructors) {
      if (username === instructor.username) {
        return instructor.disabled || false;
      }
    }

    return false;
  }

  @action getAcademyDetails = async () => {
    try {
      if (!this.loadedDetails && !this.loading) {
        this.loading = true;
        const response = await getAcademyDetails();
        this.academy = response[0];
        this.loading = false;
        this.loadedDetails = true;
      }
    } catch (error) {
      this.loading = false;
    }
  };

  static generateErrorMsg(t) {
    return {
      type: 'danger',
      text: t('Red Hat login or password incorrect. Please try again.'),
    };
  }

  @computed get courseCode() {
    return this.course?.code;
  }

  @computed get modality() {
    return (this.offering?.modality || '').toLowerCase();
  }

  @computed get instructors() {
    return this.offering?.instructors || [];
  }

  isValidOffering() {
    const { modality } = this;

    return (
      this.offeringId &&
      (modality === 'vt' || modality === 'ilt' || modality === 'os')
    );
  }

  @action async post(routerStore, userStore, t, redirect) {
    this.message = {};
    this.loading = true;
    this.userStore = userStore;
    return (
      login(this.username, this.password)
        .then(() => userStore.init())
        .then((u) => {
          this.user = u;
          this.loading = false;
          remove(SESSION_STORAGE_KEYS.ACADEMY_FILTER);
          return u;
        })
        // Display "incorrect username/password" error if login is incorrect
        .catch(() => {
          this.message = LoginStore.generateErrorMsg(t);
          this.loading = false;
          return null;
          // TODO: Send error message to analytics service
        })
    );
  }
}

export default createContext(new LoginStore());
