/**
 * @file Provides class display for instructors, admins, BDM's and Global's
 * @author Attila farkas <afarkas@redhat.com>
 */

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { when, reaction } from 'mobx';
import { observer } from 'mobx-react';
import shortid from 'shortid';

import ViewLoader from '../../components/Loading/View';
import ClassesContentCard from '../../components/Cards/ClassesContentCard';
import ControlledTabs from '../../components/Tabs';
import { storesContext } from '../../stores';
import './styles.scss';
import AccessComponent from '../AccessComponent';
import DownloadFile from '../DownloadFile';
import { ACADEMY_CLASS_CSV_API_URL } from '../../config/constants';
import moment from 'moment';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import ClassFilter from './ClassFilter';


const Classes = (props) => {
  const { catalogStore, userStore, classStore, routerStore, academyStore } = useContext(storesContext);
  const { t } = useTranslation();

  const { isInstructor } = userStore;

  useEffect(() => {

    when(
      () => routerStore.route.params.academyid
      && routerStore.route.params.academyid !== academyStore.academyId,
      () => {
        academyStore.getAcademyDetails(true, routerStore.route.params.academyid);
        classStore.fetchClasses(routerStore.route.params.academyid);
      },
    );

    reaction(
      () => routerStore.route.name === 'classes',
      () => classStore.resetQuery(),
    );

  }, [classStore, routerStore.route, academyStore]);

  useEffect(() => {

    when(
      () => catalogStore.catalogReady && userStore.isLoggedIn,
      async () => {
        if (catalogStore.subscriptionCatalog) {
          catalogStore.filterEntries();
        }

        catalogStore.loaded = true;
      },
    );
  }, [userStore, catalogStore]);

  return (
      <div className="classes-wrapper">

      <div className="row view-page-title">
        <div className="col-sm-11">
          <h3>{routerStore.route.params.academyid ? `${academyStore.academyName} / Classes` : 'Manage Classes'} </h3>
        </div>
        <AccessComponent componentName="ClassesCsvDownload">
          <div className="col-sm">
            <DownloadFile 
              url={ACADEMY_CLASS_CSV_API_URL.replace('<academy_id>', routerStore.route.params.academyid).replace('<report_type>', 'csv')}>
            </DownloadFile>
          </div>
        </AccessComponent>
        
      </div>

      <div className="split-container">

        <div className="pt-3 pb-3">
          <ClassFilter />
        </div>
        {classStore.ready ? (
          classStore.paginatedClasses?.length ? (
            classStore.paginatedClasses.map((entry) => {
              return (
                <ClassesContentCard
                  course={classStore.course}
                  content={entry}
                  key={shortid.generate()}
                  cardColor={moment(entry?.end_date).isBefore() ? '#a9aaaa' : entry.decor}
                />
              );
            })
          ) : (
            <p>{t('No classes found')}</p>
          )
        ) : (
          <div className="view-loader-wrapper">
            <ViewLoader />
          </div>
        )}
      
        {classStore.ready && classStore.paginatedClasses?.length &&(
          <div className="classes-list-pagination">
            <UltimatePagination
              currentPage={classStore.currentPage}
              totalPages={classStore.totalPages}
              onChange={classStore.setCurrentPage}
            />
          </div>
        )}
      </div>
      {classStore.ready && isInstructor && (
        <ControlledTabs
          addClass={classStore.fetchClasses}
          classesLength={classStore.classes.length}
        />
      )}
      </div>
  );
};

export default observer(Classes);
