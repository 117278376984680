import React, { useEffect, useContext } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { when } from 'mobx';
import ViewLoader from '../../../components/Loading/View';
import { useTranslation } from 'react-i18next';
import { storesContext } from '../../../stores';
import CreateAssignmenStore from './store';

const AssignmentInputForm = React.lazy(() =>
  import('../../../components/Classes/Assignments/Input'),
);

const CreateEditAssignmentView = () => {
  const { classStore, userStore, routerStore, classAssignmentStore } = useContext(storesContext);
  const { t } = useTranslation();

  const store = useLocalStore(() => new CreateAssignmenStore(classAssignmentStore, routerStore));
  const title = t('Classes');
  useEffect(() => {
    // Populate student details
    when(
      () => classStore.ready && classAssignmentStore.ready && userStore.isLoggedIn,
      async () => {
        store.classDetails = classStore.getClassDetailbyId(
          routerStore.route.params.classid,
        );

        await store.setAssignment(
          routerStore.route.params?.assignmentid,
        );

        await store.setPredefinedQuestions();
        store.pageTitle = title + '/ ' + store.classDetails?.class_name;

        store.ready = true;
      },
    );
  }, [userStore, classStore, title, store, routerStore.route,classAssignmentStore]);

  return (
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
        <Row>
          <Col className="p-0">
            <h3 className="view-page-title">{store.pageTitle}</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            {store.ready && (
              <AssignmentInputForm onAssignmentCancel={store.onAssignmentCancel} assignmentDetail={store.assignmentDetails} classDetail={store.classDetails} onAssignmentSubmit={store.onAssignmentSubmit} predefinedQuestions={store.predefinedQuestions} />
            )}
          </Col>
        </Row>
      </React.Suspense>
    </Container>
  );
};

export default observer(CreateEditAssignmentView);
