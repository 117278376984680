import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';

import CreateClass from './CreateClass/index.jsx';
import './styles.scss';

const ControlledTabs = (props) => {
  const { t } = useTranslation();
  const { addClass, classesLength } = props;

  return (
    <React.Fragment>
      <h5 className="view-section-title pl-3">{t('Create class')}</h5>
      <CreateClass
        submitClassProp={addClass}
        getClassesLength={classesLength}
      />
    </React.Fragment>
  );
};

ControlledTabs.propTypes = {
  addClass: PropTypes.func,
  classesLength: PropTypes.number,
};

ControlledTabs.defaultProps = {
  addClass: () => {},
  classesLength: 0,
};

export default observer(ControlledTabs);
