import React from 'react';
import { observer } from 'mobx-react';

const ConsumptionSummary = React.lazy(() =>
  import(/* webpackChunkName: 'examdetails-component' */ '../../components/ConsumptionSummary'),
);
const ConsumptionSummaryView  = () => {

	return (
		<ConsumptionSummary />
	 );
};

export default observer(ConsumptionSummaryView );
