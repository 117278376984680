import { action, observable, computed } from 'mobx';
import LearningPathStore from '../components/LearningPath/store';
import { getClasses, getAttachedFiles } from '../services/ClassService';
import { CLASS_ATTACHED_FILE_API } from '../config/constants';
import moment from 'moment';
import 'moment/min/locales';

class ClassStore {
  @observable classes = [];

  @observable sortedArray = [];

  @observable ready = false;

  @observable loading = false;

  @observable course = undefined;

  @observable error;

  @observable language = '';

  @observable query = '';

  @observable start_date = '';

  @observable end_date = '';

  @observable _currentPage = 1;

  @observable classesPerPage = 6;

  @observable sortByField = 'start_date';

  @observable isSortAscending = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.learningPathStore = new LearningPathStore();
    this.language = this.rootStore.uiStore.currentLanguage;    
  }

  @action fetchClasses = async (academyId = null) => {
    try {
      const classes = await getClasses(academyId);
      if (classes) {
        this.classes = this.sortClasses(classes);
      }
      this.ready = true;
      return this.classes;
    } catch (error) {
      this.error = error;
    }
  };
  
  @computed get filteredClasses() {

    let _classes = this.classes?.filter(c => c.course_title.toUpperCase().includes(this.query.toUpperCase()) || c.class_name.includes(this.query.toUpperCase()) || c.label?.toUpperCase().includes(this.query.toUpperCase()));
    if(this.start_date !== ''){
      _classes = _classes?.filter(c => new Date(c.start_date) >= new Date(this.start_date));  
    }
    if(this.end_date !== ''){
      _classes = _classes?.filter(c => new Date(c.start_date) <= new Date(this.end_date));  
    }
    return this.sortClasses(_classes);
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return (
      Math.ceil(this.filteredClasses.length / this.classesPerPage, 10) || 1
    );
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedClasses() {
    const startIndex = (this.currentPage - 1) * this.classesPerPage;
    return this.filteredClasses.slice(
      startIndex,
      startIndex + this.classesPerPage,
    );
  }

  @action onSearch = (query) => {
    this.query = query;
  }

  @action resetQuery = () => {
    this.query = '';
  }
  
  @action getClassDetailbyId(classId) {
    let classDetail = this.classes.filter((c) => c.doc_id === classId);
    if (classDetail && classDetail.length > 0) {
      return classDetail[0];
    }
    return null;
  }
  @action getClassesbyStudentUserName(username) {
    return this.classes.filter((c) => c?.students?.includes(username));
  }

  @computed get getClassesLength() {
    return this.classes.length;
  }

  @computed get assignedClass() {
    // Retrieves the first class found that is in valid date
    for (let studentClass of this.classes) {
      const startDateStr = studentClass.start_date;
      const endDateStr = studentClass.end_date;
      if (this.isNowBetweenDates(startDateStr, endDateStr)) return studentClass;
    }

    return undefined;
  }

  @computed get classId() {
    return this.assignedClass?.doc_id;
  }

  @action getCourseProgress(classId) {
    const progress = this.rootStore.progressStore.getProgressForEntityId(classId,this.rootStore.courseStore.entityKeyToTrackProgressWith);

    return progress ? Math.round(progress.total_progress * 100) || 0 : 0;
  }

  @computed get region() {
    return this.assignedClass?.academy_region;
  }

  @action getCourseCollateral = async (slug) => {
    try {
      const code = slug.split('-')[0];
      const version = slug.split('-')[1];
      const collateral = await this.rootStore.courseStore.getCourseCollateral(
        code,
        version,
        this.language,
      );
      this.collateral = collateral;
      return collateral;
    } catch (error) {
      this.error = error;
    }
  };

  @action getInfoOfCustomClass = async (customClass) => {
    let file;
    try {
      const attachedFiles = await getAttachedFiles(customClass.doc_id);
      file = attachedFiles.length > 0 ? attachedFiles[0] : undefined;
    } catch (error) {
      this.error = error;
    } finally {
      this.course = {
        title: customClass.course_title,
        slug: customClass.course_slug,
        code: '',
        completionPercentage: 0,
        instructors: customClass.instructors,
        modality: 'course',
        fileUrl: this.getFileUrl(customClass.doc_id, file),
        customClass: true,
        startDate: this.formatDate(this.parseDate(customClass.start_date)),
        endDate: this.formatDate(this.parseDate(customClass.end_date)),
        class_id: customClass.doc_id,
      };
    }
  };

  @action getCourseInfoOfClass = async (requestedClass) => {
    if (requestedClass.custom_class) {
      await this.getInfoOfCustomClass(requestedClass);
    } else {
      const collateral = await this.getCourseCollateral(
        requestedClass.course_slug,
      );

      this.course = {
        title: requestedClass.course_title.replace(/^[^-]+ - /, ''),
        slug: requestedClass.course_slug,
        code: requestedClass.course_slug.split('-')[0],
        completionPercentage: requestedClass.coa_percentage * 100,
        instructors: requestedClass.instructors,
        modality: 'course',
        collateral,
        customClass: false,
        startDate: requestedClass.start_date,
        endDate: requestedClass.end_date,
        class_id: requestedClass.doc_id,
      };
    }

    return this.course;
  };

  @action getFileUrl = (classId, fileName) => {
    if (fileName) {
      return `${CLASS_ATTACHED_FILE_API.replace(
        '<class_id>',
        classId,
      )}?filename=${fileName}`;
    }
  };

  @action getAssignedCourseInfo = async (slug,classId) => {
    try {
      if (!this.ready) await this.fetchClasses();
      const selectedClass = this.classes?.find(c => c.doc_id === classId);
      if (selectedClass) {
        await this.getCourseInfoOfClass(selectedClass);
        return this.course;
      } else {
        this.course = undefined;
      }
    } catch (error) {
      this.error = error;
    }
  };

  parseDate(dateStr) {
    return moment(dateStr, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
  }

  @action formatDate(momentDate) {
    const language = this.rootStore.uiStore.currentLanguage;
    return momentDate.locale(language).format('LL');
  }

  @action isNowBetweenDates(startDateStr, endDateStr) {
    // Receives dates in format 2020-09-30T10:00:00.000Z
    const nowDate = moment();
    return (
      this.parseDate(startDateStr).startOf('day') <= nowDate &&
      nowDate <= this.parseDate(endDateStr).endOf('day')
    );
  }

  @action onDateChange = (fieldName, date) => {
    this[fieldName] = date;
  }

  @action resetClassFilter = () => {
    this.query = '';
    this.start_date = '';
    this.end_date = '';
    this.sortByField = 'start_date';
    this.isSortAscending = false;
    this.classes = this.sortClasses(this.classes);
  }

  @action onSortBy = (sortBy) => {
    this.sortByField = sortBy.value;
    this.classes = this.sortClasses(this.classes);
  };

  @action onSortOrder = () => {
    this.isSortAscending = !this.isSortAscending;
    this.classes = this.sortClasses(this.classes);
  };

  @action sortClasses = (classes) => {
    if(!this.isSortAscending){
      return classes
          .slice()
          .sort((a, b) =>
          this.sortByField === 'start_date' || this.sortByField === 'end_date'
          ? moment(moment(b[this.sortByField]).toDate()).diff(moment(moment(a[this.sortByField]).toDate()))
          : b[this.sortByField].localeCompare(a[this.sortByField]),
          );
    }
    else{
      return classes
          .slice()
          .sort((a, b) =>
          this.sortByField === 'start_date' || this.sortByField === 'end_date'
          ? moment(moment(a[this.sortByField]).toDate()).diff(moment(moment(b[this.sortByField]).toDate()))
          : a[this.sortByField].localeCompare(b[this.sortByField]),
          );
    }
  };

}

export default ClassStore;
