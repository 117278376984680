import { action, observable, computed } from 'mobx';
import moment from 'moment';

class CourseSummaryStore {
  @observable assignment = null;
  @observable ready = false;

  @observable _currentPage = 1;
  @observable coursesPerPage = 6;
  @observable courses = [];

  constructor(classStore) {
    this.classStore = classStore;
  }

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @computed get totalPages() {
    return Math.ceil(this.courses.length / this.coursesPerPage, 10) || 1;
  }

  @computed get currentPage() {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get paginatedCourses() {
    const startIndex = (this.currentPage - 1) * this.coursesPerPage;

    return this.courses.slice(startIndex, startIndex + this.coursesPerPage);
  }

  @action populateCourses = async (force = false) => {
    if (this.courses.length && !force) {
      return this.courses;
    }
    try {
      await this.getCourses();
    } catch (e) {
      console.error('[populateCourses]', e);
    } finally {
      this.ready = true;
    }
  };

  @action getCourses = async () => {
    try {
      if (!this.classStore.ready) await this.classStore.fetchClasses();

      let courses = [];
      await Promise.all(
        this.classStore.classes.map(async (requestedClass) => {
          let course = await this.classStore.getCourseInfoOfClass(
            requestedClass,
          );
          course.active = moment().isSameOrBefore(course.endDate, 'day');
          course.displayStartDate = this.classStore.formatDate(
            this.classStore.parseDate(course.startDate),
          );
          course.displayEndDate = this.classStore.formatDate(
            this.classStore.parseDate(course.endDate),
          );
          courses.push(course);
        }),
      );
      // Order the list by class start date descending
      this.courses = courses.sort((a, b) => {
        return new Date(b.startDate) - new Date(a.startDate);
      });
    } catch (error) {
      this.error = error;
    }
  };
}

export default CourseSummaryStore;
