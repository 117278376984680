import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { observer, useLocalStore } from 'mobx-react';
import { when } from 'mobx';
import shortid from 'shortid';
import { Col, Button, Row } from 'react-bootstrap';
import ViewLoader from '../../../components/Loading/View';
import ClassesContentCard from '../../../components/Cards/ClassesContentCard';

import { storesContext } from '../../../stores';
import ClassStudentDetailsStore from './store';
const ClassAssignmentCard = React.lazy(() =>
  import('../../../components/Cards/ClassAssignmentCard'),
);

const CodeChallengeGradeInput = React.lazy(() =>
  import('../../../components/Classes/Assignments/CodeChallenge/GradeInput'),
);
const ClassStudentDetails = () => {
  const {
    classStore,
    routerStore,
    userStore,
    classAssignmentStore,
    classStudentStore,
    uiStore,
  } = useContext(storesContext);
  const { params } = routerStore.route;
  const { classid } = params;
  const { t } = useTranslation();
  const title = t('Classes');

  const store = useLocalStore(
    () =>
      new ClassStudentDetailsStore(
        classStore,
        classAssignmentStore,
        classStudentStore,
      ),
  );
  const navigateToCreateAssignment = () => {
    routerStore.navigate('classes:create:assignment', {
      classid: params.classid,
    });
  };

  useEffect(() => {
    // Populate student details
    when(
      () =>
        classStore.ready &&
        classAssignmentStore.ready &&
        !store.ready &&
        userStore.isLoggedIn,
      async () => {
        store.classDetails = classStore.getClassDetailbyId(params.classid);
        await store.setStudentDetails(params.classid, params.studentusername);
        await store.setAssignment(params.classid);
        store.pageTitle =
          title +
          '/ ' +
          store.classDetails?.class_name +
          '/ ' +
          params.studentusername;
        store.ready = true;
      },
    );
  }, [userStore, classStore, store, params, title, classAssignmentStore]);

  return (
    <React.Fragment>
      <h3 className="view-page-title">{store.pageTitle} </h3>
      <div className="split-container">
        {!store.showGradeInput && (
          <div>
            <h5>{t('Classes')}</h5>
            <Row>
              <Col>
                {userStore.isLoggedIn && classStore.ready && store.ready ? (
                  classStore.classes.length ? (
                    classStore
                      .getClassesbyStudentUserName(params.studentusername)
                      .map((entry) => {
                        return (
                          <ClassesContentCard
                            {...(entry.doc_id !== classid && {
                              cardBackgroundColor: '#d3d3d3',
                            })}
                            disabled={true}
                            course={classStore.course}
                            content={entry}
                            key={shortid.generate()}
                            cardColor={entry.decor}
                          />
                        );
                      })
                  ) : (
                    <p>{t('No classes found')}</p>
                  )
                ) : (
                  <div className="view-loader-wrapper">
                    <ViewLoader />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}

        {!store.showGradeInput && (
          <div className="student-wrapper">
            <h5>{t('Assignments')}</h5>
            {store.ready &&
            store.assignmentDetails === null &&
            userStore.isInstructor ? (
              <Row>
                <Col>
                  <Button onClick={() => navigateToCreateAssignment()}>
                    {t('Create Assignment')}
                  </Button>
                </Col>
              </Row>
            ) : null}

            {store.ready && store.assignmentDetails !== null ? (
              <Row>
                <Col>
                  <ClassAssignmentCard
                    {...(userStore.isInstructor && {
                      onClick: () => store.toggleGradeInput(),
                    })}
                    assignment={store.assignmentDetails}
                    grades={store.studentGrades}
                    isStudentView={true}
                    language={uiStore.currentLanguage}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>{!userStore.isInstructor && t('No assignment exists for this class')}</Col>
              </Row>
            ) }
            {store.ready && store.assignmentDetails !== null ? (
              <Row>
                <Col>
                  <h5>{t('Grades')}</h5>
                  <Row>
                    <Col md={2}>
                      <span>{`${t('Quiz Score')} :`}</span>
                    </Col>
                    <Col>
                      <span>{store.quizScore}%</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <span>{`${t('Knowledge Check Score')} :`}</span>
                    </Col>
                    <Col>
                      <span>{store.knowledgeCheckScore}%</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <span>
                        <strong>{`${t('Total')} :`}</strong>
                      </span>
                    </Col>
                    <Col>
                      <span>
                        <strong>{store.totalScore}%</strong>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </div>
        )}

        {/* <!-- Grade inpunt--> */}
        {store.showGradeInput && store.ready && (
          <div className="student-wrapper">
            <CodeChallengeGradeInput
              challenges={store.assignmentCodeChallenges}
              grades={store.studentGrades}
              classId={params.classid}
              studentUserName={params.studentusername}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(ClassStudentDetails);
