import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import * as merge from 'deepmerge';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Modal
  
} from 'react-bootstrap';

import Store from './store';
import { TRAINING_CONTACTS } from '../../../config/constants';

@inject('userStore')
@withTranslation()
@observer
class CertificateOfAttendanceModal extends Component {
  static propTypes = {
    courseSlug: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
    user: PropTypes.object,
    setVisibility: PropTypes.func,
    t: PropTypes.func.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
  };

  static defaultProps = {
    user: {
      preferred_name: '',
    },
    setVisibility: () => false,
    timestamp: null,
  };

  constructor(props) {
    super(props);

    this.store = new Store();
    this.trainingContacts = TRAINING_CONTACTS;
  }

  componentDidMount() {
    this.store.user = this.props.user;
    this.verifyUser();
  }

  onConfirmModalSubmit = e => {
    e.preventDefault();
    this.getCertificate();
    this.closeConfirmModal();
  };

  onRedirectModalSubmit = e => {
    e.preventDefault();
    this.getCertificate();
    this.closeRedirectModal();
  };

  onChange = e => {
    
    this.store.user = merge(toJS(this.store.user), {
      [e.target.name]: e.target.value,
    });

    
  };

  getCertificate() {
    const { courseSlug, timestamp, user, userStore, classId = null, academyId = null } = this.props;

    this.store.updateUserProfile(user, userStore);
    this.store.generateCertificate(courseSlug, timestamp, classId,academyId);
  }

  verifyUser = () => {
    const { user } = this.props;
    // Verify required user infomation is available
    if (!this.store.user.preferred_name) {
      if (user.first_name && user.last_name) {
        this.store.user.preferred_name = `${user.first_name} ${
          user.last_name
        }`.trim();
        
      }
      
      this.showConfirmModal();
      return;
    }
    

    this.showRedirectModal();
  };

  showConfirmModal = () => {
    this.store.confirmModal.opened = true;
  };

  closeConfirmModal = () => {
    this.store.confirmModal.opened = false;
    const { setVisibility } = this.props;
    setVisibility(false);
  };

  showRedirectModal = () => {
    this.store.redirectModal.opened = true;
  };

  closeRedirectModal = () => {
    this.store.redirectModal.opened = false;
    const { setVisibility } = this.props;
    setVisibility(false);
  };

  render() {
    const { t } = this.props;
    const { confirmModal, redirectModal, user } = this.store;

    const downloadCertText = t('Download Certificate of Attendance');
    const redirectionText = `<strong> ${t('Please note')}: </strong> ${t(
      "you'll be redirected to the Red Hat Learning Community (RHLC) to download your certificate. You may be prompted to register if you are not already an RHLC member or log in using your Red Hat Login (RHN ID) and password.",
    )}`;
    const proceedText = t('Proceed');

    return (
      <React.Fragment>
        <Modal
          show={confirmModal.opened}
          onHide={this.closeConfirmModal}
          size="lg"
        >
          <Form onSubmit={this.onConfirmModalSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{downloadCertText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {t(`In order to download the Certificate of Attendance for this course, you'll need to provide your name as you'd like it to appear on your certificate (your preferred name).`)}
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: redirectionText,
                }}
              />
              <FormGroup
                validated={
                  user.preferred_name && user.preferred_name.length > 0
                    ? null
                    : 'error'
                }
              >
                <FormControl
                  id="CertificateOfAttendancePreferredName"
                  name="preferred_name"
                  type="text"
                  autoFocus
                  placeholder={t('Preferred name')}
                  defaultValue={user.preferred_name}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button
                block
                variant="primary"
                disabled={!user.preferred_name}
                type="submit"
              >
                {proceedText}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          show={redirectModal.opened}
          onHide={this.closeRedirectModal}
          size="lg"
        >
          <Form onSubmit={this.onRedirectModalSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{downloadCertText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p
                dangerouslySetInnerHTML={{
                  __html: redirectionText,
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button block variant="primary" type="submit">
                {proceedText}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CertificateOfAttendanceModal;
