import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Col, Container, Row, Button, Fade, Modal } from 'react-bootstrap';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-4';
import ViewLoader from '../../components/Loading/View';
import { useTranslation } from 'react-i18next';
import AcademyDetailsStore, { AcademyDetailsContext } from './store';
import { storesContext } from '../../stores';
import { when, reaction } from 'mobx';
import './styles.scss';
import AccessComponent from '../../components/AccessComponent';
import { ACADEMY_INSTRUCTOR_CSV_API } from '../../config/constants';
import DownloadFile from '../../components/DownloadFile';

const InstructorsList = React.lazy(() =>
  import(
    /* webpackChunkName: 'academydetails' */ '../../components/AcademyDetails/Instructor'
  ),
);

const InstructorForm = React.lazy(() =>
  import(
    /* webpackChunkName: 'academydetails' */ '../../components/AcademyDetails/Instructor/Form'
  ),
);

const DetailsForm = React.lazy(() =>
  import(
    /* webpackChunkName: 'academydetails' */ '../../components/AcademyDetails/Details'
  ),
);

const AdoptAcademy = React.lazy(() =>
  import(
    /* webpackChunkName: 'adoptAcademy' */ '../../components/AcademyDetails/AdoptAcademy'
  ),
);
const AcademyDetailsView = () => {
  const { userStore, routerStore } = useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(() => new AcademyDetailsStore());
  const toggleAddInstructorForm = () => {
    store.newInstructor.open = !store.newInstructor.open;
  };
  const renderAddInstructorForm = () => {
    return (
      <InstructorForm
        academyId={store.academyId}
        academyPartnerExists={store.partnerExist}
        OnCancelInstructor={toggleAddInstructorForm}
        onSubmitInstructor={store.submitNewInstructor}
        isNewInstructor={true}
        instructor={store.newInstructor}
        currentInstructors={store.instructorsInfo}
      />
    );
  };

  const renderAcademyDetailsForm = () => {
    return store.hasRelatedData ? (
      <Row>
        <Col>
          <DetailsForm
            academyPartnerExists={store.partnerExist}
            academyDetails={store.academyDetails}
            showDetails={store.hasRelatedData}
            onSubmitDetails={store.updateAcademyDetails}
          />
        </Col>
      </Row>
    ) : (
      <Row>
        <Col>
          <h5>{t('Academy Contact Details')}</h5>
        </Col>
        <Col>
          <div>{`${t('Loading Academy Details')}...`}</div>
        </Col>
      </Row>
    );
  };

  const renderAdoptAcademy = () => {
    return (
      <AccessComponent componentName='AdoptAcademy'>
        <AcademyDetailsContext.Provider value={store}>
          <AdoptAcademy adoptionStatus={store.adoptAcademyStatus} onRequest={store.requestJoinAdoptAcademy} />
        </AcademyDetailsContext.Provider>
      </AccessComponent>
    );
  };

  useEffect(() => {
    // Populate academy details
    when(
      () => !store.loaded && userStore.isLoggedIn,
      async () => {
        store.hasRelatedData = false;
        await store.getAcademyDetails(routerStore.route.params.academyid || null);
      },
    );

    reaction(
      () => store.hasRelatedData && store.academyDetails && store.academyDetails.doc_id,
      async () => {
        await store.getInstructors();
      },
    );
  }, [userStore, store, routerStore.route.params.academyid]);
  return (
    <Container fluid>
      <React.Suspense fallback={<ViewLoader />}>
        <Row className="header-instructor">
          <Col className="p-0">
            <div className="row view-page-title">
              <div className="col-sm-11">
                <h3>{routerStore.route.params.academyid ? `${store.academyName} / Instructors` : 'Academy Details'}</h3>
              </div>
              <AccessComponent componentName="InstructorsCsvDownload">
                <div className="col-sm">
                  <DownloadFile 
                    url={ACADEMY_INSTRUCTOR_CSV_API.replace('<academy_id>', store.academyDetails.doc_id).replace('<report_type>', 'csv')}>
                  </DownloadFile>
                </div>
              </AccessComponent>
            </div>

            <div className="secondary-title">
              <h6>{t('Instructors')}</h6>{' '}
              {userStore.isAcademyAdmin && <Button
                className="btn-add-instructor"
                onClick={toggleAddInstructorForm}
                variant="primary"
                disabled={!store.loaded}
              >
                {t('Add')}
              </Button>}
            </div>
          </Col>
        </Row>
        <Row className="add-instructor-row">
          <Col>
            <Fade in={store.newInstructor.open}>
              <div className="row">
                <div className="col add-instructor-form">
                  {store.newInstructor.open && renderAddInstructorForm()}
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            {store.loaded ? (
              <InstructorsList
                academyPartnerExists={store.partnerExist}
                academyId={store.academyId}
                instructors={store.paginatedEntries}
                onSubmitInstructorNotes={store.onUpdateInstructorInfo}
                onDeleteInstructor={store.showDeleteModal}
                onActivateInstructor={store.showActivateModal}
                onSortInstructorBy={store.onSortInstructorBy}
                sortByField={store.sortByField}
                isSortAscending={store.isSortAscending}
              />
            ) : (
              <div>
                <ViewLoader />
              </div>
            )}
          </Col>
        </Row>
        {/* --- Pagination : Instructor list */}
        {store.loaded && store.paginatedEntries.length ? (
          <div className="instructors-list-pagination">
            <UltimatePagination
              currentPage={store.currentPage}
              totalPages={store.totalPages}
              onChange={store.setCurrentPage}
            />
          </div>
        ) : null}

        <Row className="academy-details">
          <Col>{renderAcademyDetailsForm()}</Col>
        </Row>
        {/* <!-- Confirmation Modal/Dialogue for deleting an instructor--> */}
        <Modal
          show={!store.hideDeleteModal}
          onHide={store.handleCloseDeleteModal}
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Delete an instructor')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('Are you sure you want to delete an instructor?')}
          </Modal.Body>
          <Modal.Footer>
            {store.message !== '' && (
              <span className="instructor-message">{t(store.message)}</span>
            )}
            <Button
              variant="outline-info"
              onClick={store.handleCloseDeleteModal}
            >
              {t('Close')}
            </Button>
            <Button
              variant="primary"
              disabled={store.apiInProgress}
              onClick={() => {
                store.deleteInstructor();
              }}
            >
              {t('Delete')}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <!-- Confirmation Modal/Dialogue for activating an instructor--> */}
        <Modal
          show={!store.hideActivateModal}
          onHide={store.handleActivateCloseModal}
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Activate an instructor')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t('Are you sure you want to activate an instructor?')}
          </Modal.Body>
          <Modal.Footer>
            {store.message !== '' && (
              <span className="instructor-message">{t(store.message)}</span>
            )}
            <Button
              variant="outline-info"
              onClick={store.handleActivateCloseModal}
            >
              {t('Close')}
            </Button>
            <Button
              variant="primary"
              disabled={store.apiInProgress}
              onClick={() => {
                store.enableInstructor();
              }}
            >
              {t('Activate')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="adopt-academy">
          <Col>{renderAdoptAcademy()}</Col>
        </Row>
      </React.Suspense>
    </Container>
  );
};

export default observer(AcademyDetailsView);
