import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import {
  InputGroup,
  FormControl,
  DropdownButton,
  DropdownItem,
  Button,
} from 'react-bootstrap';
import { DotLoader } from 'react-spinners';

@inject('userStore')
@withTranslation()
@observer
class VMRow extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired,
    vmSummary: PropTypes.object.isRequired,
    vmAnnotations: PropTypes.object.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
  };

  constructor(props) {
    super(props);

    this.menuOpen = false;
  }

  handleClick(command) {
    const vm = this.props.vmSummary;
    this.props.action(command, { vm_id: vm.id });
  }

  isInhibit(annotateName, vmName) {
    const { vmAnnotations } = this.props;
    const annotate = vmAnnotations.filter(
      a => a.name === annotateName && a.vm_name === vmName && a.value === true,
    );

    return annotate.length > 0;
  }

  render() {
    const vm = this.props.vmSummary;
    const { isInstructor } = this.props.userStore;
    const isInhibitConsole = this.isInhibit('inhibit_vnc_console', vm.name) && !isInstructor;
    const isInhibitReset = this.isInhibit('inhibit_reset', vm.name);
    const isLoading = vm.vm_state !== 'active' && vm.vm_state !== 'stopped';
    return (
      <tr key={vm.id} id={`vm-${vm.id}`}>
        <td className="lab-controls-vm-panel-name">{vm.name}</td>
        <td className="lab-controls-vm-panel-actions">
          <InputGroup>
            <FormControl
              type="text"
              className="autostop-display"
              value={vm.vm_state}
              readOnly
            />
            
            <InputGroup.Append>
              <DotLoader loading={isLoading} color="grey" size={15} />
            </InputGroup.Append>
            <DropdownButton
              
              id="vm-action-dropdown"
              title="Action"
              className="btn-secondary"
            >
              <DropdownItem
                key="vm-action-start"
                disabled={vm.vm_state !== 'stopped'}
                onClick={() => this.handleClick('vm_start')}
              >
                Start
              </DropdownItem>
              
              <DropdownItem
                key="vm-action-shutdown"
                disabled={vm.vm_state !== 'active'}
                onClick={() => this.handleClick('vm_stop')}
              >
                Shutdown
              </DropdownItem>
              <DropdownItem
                key="vm-action-poweroff"
                disabled={vm.vm_state === 'stopped'}
                onClick={() => this.handleClick('vm_poweroff')}
              >
                Power Off
              </DropdownItem>
              
              <DropdownItem
                key="vm-action-reset"
                disabled={isInhibitReset}
                onClick={e => this.handleClick('vm_reset', e)}
              >
                Reset
              </DropdownItem>
            </DropdownButton>
          </InputGroup>
        </td>
        {!isInhibitConsole && (
        <React.Fragment>
          <td className="lab-controls-vm-panel-console">
            <Button
              variant="success"
              disabled={vm.vm_state !== 'active' || isInhibitConsole}
              onClick={() => this.handleClick('get_vnc_url')}
            >
              Open Console
            </Button>
          </td>
        </React.Fragment>
        )}
      </tr>
    );
  }
}

export default VMRow;
