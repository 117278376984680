import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';

@withTranslation()
@observer
class AutoStopControls extends Component {
  static propTypes = {
    labStore: PropTypes.object.isRequired,
  };

  renderAutoStop() {
    const { userLab } = this.props.labStore;
    /* eslint-disable camelcase */
    const time = userLab?.scheduled_shutdown_time;
    if (!time) {
      return null;
    }

    const now = moment.utc();
    const then = moment.utc(time);
    const delta = moment.duration(then.diff(now)).get('hours') + 1;

    return (
      <tr>
        <td>
          Auto-stop <Moment fromNow>{time}</Moment>.
        </td>
        {delta < 12 ? (
          <td>
            <Button
              className="btn-secondary btn-sm"
              title="Add One Hour"
              onClick={() =>
                this.props.labStore.doCommand('app_autostop_extend')
              }
            >
              <span>+</span>
            </Button>
          </td>
        ) : null}
      </tr>
    );
  }

  renderAutoDestroy() {
    const { userLab } = this.props.labStore;
    const time = userLab?.scheduled_destroy_time;
    if (!time) {
      return null;
    }

    const now = moment.utc();
    const then = moment.utc(time);
    const delta = moment.duration(then.diff(now)).get('days') + 1;

    return (
      <tr>
        <td>
          Auto-destroy <Moment fromNow>{time}</Moment>.
        </td>
        {delta < 14 ? (
          <td>
            <Button
              className="btn-secondary btn-sm"
              title="Add One Day"
              onClick={() =>
                this.props.labStore.doCommand('app_autodelete_extend')
              }
            >
              <span>+</span>
            </Button>
          </td>
        ) : null}
      </tr>
    );
  }

  render() {
    return (
      <table className='mt-2'>
        <tbody>
        {this.renderAutoStop()}
        {this.renderAutoDestroy()}
        </tbody>
      </table>
    );
  }
}

export default AutoStopControls;
