import React, {useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { storesContext } from '../../../stores';
import WithCardContainer from '../CardContainer';
import GenericAlert from '../../Alert/GenericAlert';
import './styles.scss';

const LinkCard = ({
  title,
  cardColor, // cardColor is passed down to `WithCardContainer`
  url,
  signedUrl,
}) => {
  const { t } = useTranslation();
  const { resourcesStore } = useContext(storesContext);
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = async (event) => {
    if (signedUrl) {
      const cfSignedUrl = await resourcesStore.getCloudFrontSignedUrl({ "url": url });
      cfSignedUrl ? window.open(cfSignedUrl, '_blank') : setShowAlert(true);
    }
    else{
      window.open(url, '_blank');
    }
  };

  return (
    <div className="card-link">
      <div rel="noopener noreferrer" onClick={handleClick}>
        <p className="font-weight-bold">{title}</p>
      </div>
      {showAlert && (
            <GenericAlert
            type="danger"
            text={
              t(`There was an error fulfilling the request. Please contact support for assistance.`)
            }
          />
      )}
    </div>
  );
};

LinkCard.propTypes = {
  title: PropTypes.string,
  cardColor: PropTypes.string,
  url: PropTypes.string,
  signedUrl: PropTypes.bool,
};

LinkCard.defaultProps = {
  signedUrl: false,
};

export default WithCardContainer(LinkCard);
