import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Link } from 'react-mobx-router5';
import { storesContext } from '../../../stores';
import './styles.scss';



const TableOfContents = (props) => {
  const { t } = useTranslation();
  const { routerStore, courseStore } = useContext(storesContext);

  const { structure } = props;
  const { route } = routerStore;

  if (!structure.length) {
    return null;
  }

  const table = structure.map((data) => {
    const { id_tag: id } = data;

    return (
      <tr key={id}>
        <td>
          <div className={`toc_entry_depth_${data.depth}`}>
            {data.status !== 'inactive' ? (
              <Link
                href
                routeName={courseStore.coursePageRouteName}
                routeParams={{
                  course: route.params.course,
                  page: data.page_tag,
                  ...(courseStore.coursePageRouteParamsByRole),
                }}
              >
                {t(`${data.title}`)}
              </Link>
            ) : (
              <span title="This content is not available in Free Trial\">
                {t(`${data.title}`)}
              </span>
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className="tabContent">
      {structure && (
        <table className="tocContent">
          <tbody>{table}</tbody>
        </table>
      )}
    </div>
  );
};

TableOfContents.propTypes = {
  structure: PropTypes.array,
};

TableOfContents.defaultProps = {
  structure: {},
};

export default observer(TableOfContents);
