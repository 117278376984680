import React, { useContext, useEffect } from 'react';

import { when } from 'mobx';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import LoginStore from './store';
import { storesContext } from '../../stores';

import querystring from 'query-string';
import { SSO_LOGIN_API } from '../../config/constants';
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  FormControl,
  Form,
} from 'react-bootstrap';

import InactiveInstructorModal from '../../components/InactiveInstructorModal';

import './styles.scss';

import BackGroundImage from '../../img/rha-banner.jpg';
import RHALogo from '../../img/rh-academy-logo.png';
import LinkContainer from '../../components/LinkContainer';
import shortid from 'shortid';
import links from '../../mocks/links.mock';
import GenericAlert from '../../components/Alert/GenericAlert';

import ButtonWithLoader from '../../components/Button/ButtonWithLoading';

const Login = () => {
  const { t } = useTranslation();
  const { routerStore, userStore } = useContext(storesContext);

  const { params } = routerStore.route;
  const { offeringId, redirect, scope } = params;

  const baseHref = (document.querySelector('base') || {}).href;

  const store = useContext(LoginStore);

  const { loading } = store;
  document.title = t('Login');
  useEffect(() => {
    const normalizePath = (path) => (path || '').replace(/^\//, '');

    when(
      () => !offeringId,
      () => {
        if (scope === 'sso') {
          const next = redirect
            ? `${baseHref}${normalizePath(
                redirect?.path,
              )}?${querystring.stringify(redirect.params)}`
            : baseHref;

          // Redirect to the intended location after authenticating via SSO
          window.location.replace(
            `${SSO_LOGIN_API}?next_url=${encodeURIComponent(next)}`,
          );
        }
      },
    );
  }, [scope, store, baseHref, offeringId, redirect]);

  const onChange = (e) => {
    store[e.target.name] = e.target.value;
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const isLoginSucess = await store.post(
        routerStore,
        userStore,
        t,
        redirect,
      );
      if (isLoginSucess && userStore.isLoggedIn) {
        userStore.isLoggedIn = false;
        await store.showModal(userStore.isInstructor);
        if (store.showBlockingModal) {
          userStore.invalidateUser();
          return;
        }
        userStore.isLoggedIn = true;
        if (redirect) {
          return routerStore.navigate(redirect.name, redirect.params);
        }
        routerStore.navigate('default-redirect');
      }
    } catch (error) {
      console.error('Error login:', error);
    }
  };

  const LocalLogin = ({ message }) => (
    <React.Fragment>
      <div className="login-banner-image">
        <span className="logo-wrapper d-flex justify-content-center">
          <img src={RHALogo} alt="Red Hat Academy Portal Logo" />
        </span>
        <img
          className="background"
          src={BackGroundImage}
          alt="Red Hat Academy Portal Login"
        />
      </div>
      <Col>
        <div className="login-form-wrapper">
          <h1 className="login-title">
            {t('Log in to your Red Hat Academy account')}
          </h1>
          {Object.prototype.hasOwnProperty.call(message, 'type') && (
            <GenericAlert type={message.type} text={message.text} />
          )}
          <Form
            id="login_form"
            name="login_form"
            className="login-form"
            onSubmit={onSubmit}
          >
            <div id="errors" className="error" />
            <FormGroup>
              <FormLabel className="sr-only">{t('Red Hat login')}</FormLabel>
              <FormControl
                id="username"
                name="username"
                type="text"
                placeholder={t('Red Hat login')}
                maxLength="120"
                autoCapitalize="off"
                autoCorrect="off"
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel className="sr-only">{t('Password')}</FormLabel>
              <FormControl
                id="password"
                name="password"
                type="password"
                placeholder={t('Password')}
                onChange={onChange}
              />
            </FormGroup>
            <ButtonWithLoader
              buttonProps={{ disabled: loading, type: 'submit' }}
              loading={loading}
            >
              {t('Log In')}
            </ButtonWithLoader>

            <a
              id="lost_password_a"
              className="lostPasswordLink external_link"
              href="https://www.redhat.com/wapps/sso/lostPassword.html"
            >
              {t('Forgot your login or password?')}
            </a>
          </Form>
        </div>
      </Col>
      <div className="login-links">
        <div className="container link-row-wrapper">
          <Row className="link-row">
            {links.map((link) => {
              return <LinkContainer key={shortid.generate()} {...link} />;
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    <div className="login">
      <InactiveInstructorModal
        store={store}
        show={store.showBlockingModal}
        academy={store.academy}
      />
      {scope !== 'sso' ? <LocalLogin message={store.message} /> : null}
    </div>
  );
};

export default observer(Login);
