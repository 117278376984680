import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import UserInfoForm from './UserInfoForm';
import LoadingView from '../../../../Loading/View';
import './styles.scss';

const ModalLabOptions = (props) => {
  const { t } = useTranslation();

  const providerDescriptions = {
    infosec: t(
      'Infosec Learning provides affordable cloud based labs for Red Hat System Administration I and Red Hat System Administration II courses, which can be accessed globally with support from a 24/7 help desk. The Infosec Lab Platform is completely cloud based and offers many upgraded features such as step by step solution instructions, auto scored "capture the flag" challenges, instructor dashboards that track student progress and performance metrics, and LMS Gradebook integration.',
    ),
    ndg: t(
      'Network Development Group provides low-cost cloud based labs for the Red Hat Academy System Administration courses. Access is purchased either in six or three month increments, and learners have unlimited access to the lab environment during this time. NDG lab services do not require any downloads, plugins, or special tools other than a web browser and internet connection.',
    ),
    
  };

  const providerTitles = {
    infosec: 'Infosec',
    ndg: 'NDG',
    ibm: 'IBM',
    
  };

  const renderProviders = () => {
    const flexCols = props.providers?.length > 2 ? 'col-6' : 'col';
    return props.providers.map((provider, index) => (
      <div
        className={`${flexCols} provider${index !== props.providers.length - 1 ? ' mb-4': ''}`}
        key={index}
      >
        <li>{providerTitles[provider?.providerTitle?.toLowerCase()] || (provider?.providerTitle || '')} </li>
        {providerDescriptions[provider.id] && (
          <p className="text-muted">{providerDescriptions[provider.id]}</p>
        )}
        <div className="row"
          dangerouslySetInnerHTML={{
            __html: replaceHTMLButtonLabel(provider.buttonHtml, providerTitles[provider.name] || provider.name, provider.withSolutionButtonHtml || false),
          }}
        >
        </div>
      </div>
    ));
  };

  const replaceHTMLButtonLabel = (htmlString, providerName, htmlStringWithSolution = false) => {
    const labelToReplace = 'Launch the tool';
    const actionVerb = t('Access');
    const labsString = t('Labs');
    const solutionString = `(${t('with solution')})`;
    const completeLabel = `${actionVerb} ${providerName} ${labsString}`;
    const completeLabelWithSolution = `${actionVerb} ${providerName} ${labsString} ${solutionString}`;

    return htmlStringWithSolution ? 
      `<div class="col-6 mt-1">${htmlString.replace(labelToReplace, completeLabel).replace("<div />", "</div>")}</div><div class="col-6 mt-1">${htmlStringWithSolution.replace(labelToReplace, completeLabelWithSolution).replace("<div />", "</div>")}</div>`
      : `${htmlString.replace(labelToReplace, completeLabel)}`;
  };

  const renderUserInfoForm = () => {
    return <UserInfoForm onUserInfoUpdate={props.onUserInfoUpdate} />;
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-labs"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalTitle}
        </Modal.Title>
      </Modal.Header>
      {props.canBuyLabs ? (
        <Modal.Body>
          {props.providers.length > 0 ? (
            <React.Fragment>
              <h5>{props.modalSubTitle}</h5>
              <div className='row'>{renderProviders()}</div>
            </React.Fragment>
          ) : (
            <LoadingView />
          )}
        </Modal.Body>
      ) : (
        <Modal.Body>{renderUserInfoForm()}</Modal.Body>
      )}
      <Modal.Footer>
        <Button onClick={() => props.onHide()}>{t('Close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalLabOptions.propTypes = {
  onHide: PropTypes.func,
  providers: PropTypes.array,
  canBuyLabs: PropTypes.bool,
  show: PropTypes.bool,
  onUserInfoUpdate: PropTypes.func,
  modalTitle:PropTypes.string,
  modalSubTitle:PropTypes.string
};

export default ModalLabOptions;
