import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { headerContext } from '../store';
import { storesContext } from '../../../stores';
import { Button, Modal, Dropdown, NavLink, Form } from 'react-bootstrap';
import LoadingView from '../../Loading/View';
import { logout } from '../../../services/AuthService';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import GlobeIcon from '../../../img/icons/globe.svg';
import './styles.scss';

const Profile = () => {
  const { userStore, uiStore, routerStore } = useContext(storesContext);
  const headerStore = useContext(headerContext);
  const { i18n, t } = useTranslation();
  const { languages } = uiStore;
  const language = languages.find((lang) => lang.token === i18n.language) || {};
  let { display_name: languageName } = language;
  const { user } = userStore;

  const onLogout = async () => {
    await logout(userStore);
    localStorage.removeItem("kaltura_session");
    localStorage.removeItem("kaltura_session_expiry");
  };

  const selectRole = async (event) => {
    try {
      userStore.operationInCourse = true;
      const selectedRole = event.target.value;
      await userStore.changePreferredRole(selectedRole);
      userStore.operationInCourse = false;
      headerStore.showProfile = false;
      routerStore.navigate(userStore.role.defaultRoute);
    } catch (error) {
      userStore.operationInCourse = false;
    }
  };
  const userFullName = () => {
    let fullName = userStore.firstName || '';
    fullName +=  fullName?.length > 0 ? ' ': '' ;
    fullName += userStore.lastName || '';
    return fullName?.length > 0 ? fullName : '---';
  }
  const renderUserPersonalDetails = () => {
    return (
      <React.Fragment>
        {' '}
        <Form.Row>
          <Form.Label>{`${t('Name')}: `}</Form.Label>

          <Form.Label className="ml-3">
            {userFullName()}
          </Form.Label>
        </Form.Row>
        <Form.Row>
          <Form.Label>{`${t('Email')}: `}</Form.Label>

          <Form.Label className="ml-3">
            {userStore.email || '---'}
          </Form.Label>
        </Form.Row>
      </React.Fragment>
    );
  };
  const roleChoser = () => {
    
    if (userStore.role) {
      if (userStore.allRoles?.length > 1) {
        if (userStore.operationInCourse) {
          return <LoadingView />;
        } else {
          return (
            <Form>
              <Form.Group>
                {renderUserPersonalDetails()}
                <Form.Row>
                  <Form.Label>{t('Change Account Role')}</Form.Label>
                  <Form.Control
                    disabled={userStore.operationInCourse}
                    as="select"
                    onChange={selectRole}
                  >
                    {userStore.role && (
                      <option value={userStore.role.name}>
                        {userStore.role.name}
                      </option>
                    )}
                    {userStore.allRoles
                      .filter((role) => role !== userStore.role.name)
                      .map((role, i) => (
                        <option value={role} key={i}>
                          {role}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Row>
              </Form.Group>
            </Form>
          );
        }
      } else {
        return (<Form>{renderUserPersonalDetails()}</Form>);
      }
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <div className="container profile-wrapper">
        {headerStore.showProfile && (
          <Modal
            show={headerStore.showProfile}
            onHide={() => (headerStore.showProfile = false)}
            dialogClassName="profile-modal-dialog container"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-title">
                Welcome, {user.username}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {roleChoser()}
              <Dropdown alignRight className="language-selector">
                <Dropdown.Toggle id="language-switcher" as={NavLink}>
                  <SVG src={GlobeIcon}>{t('Language')}</SVG>
                  <span className="language-name">{languageName}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {languages.map((lang) => {
                    const { display_name: name } = lang;
                    return lang.token ? (
                      <Dropdown.Item
                        onClick={() => i18n.changeLanguage(lang.token)}
                        key={lang.token}
                        eventKey={lang.token}
                      >
                        {t(name)}
                      </Dropdown.Item>
                    ) : null;
                  })}
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="primary" onClick={() => onLogout()}>
                {t('Logout')}
              </Button>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(Profile);
