import { action, observable } from 'mobx';
import { getLearningPaths } from '../../services/LearningPathService';

class LearningPathStore {
  @observable learningPaths = [];
  @observable selectedLearningPaths = [];

  @observable loading = false;

  @observable ready = false;

  @observable error;

  @action getLearningPaths = async () => {
    try {
      if (!this.ready && !this.loading) {
        this.loading = true;
        const response = await getLearningPaths();
        this.learningPaths = response.items;
        this.loading = false;
      }
      this.ready = true;
      return this.learningPaths;
    } catch (error) {
      console.error('Error getLearningPaths:', error);
      this.error = error;
    }
  };

  @action selectLearningPaths = (courseCode, lang = 'en-US') => {
    let selectedLearningPaths = []
    for (let path of this.learningPaths) {
      if (path.language === lang) {
        for (let element of path.lp_elements) {
          if (element.code === courseCode) {
            selectedLearningPaths.push(path);
            break;
          }
        }
      }
    }

    this.selectedLearningPaths = selectedLearningPaths;
  };

  @action getLearningPathElements = (learningPath, lang = 'en-US') => {
    let elements = [];
    for (let element of learningPath.lp_elements) {
      let selectedLanguage = { code: element.code, offerings: [] };
      for (let entry of element.catalog_entries) {
        if (entry.language === lang && entry.modality !== 'video_classroom') {
          selectedLanguage.offerings.push(entry);
        }
      }
      elements.push(selectedLanguage);
    }

    return elements;
  };
}

export default LearningPathStore;
