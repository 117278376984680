import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import Trustarc from '../Trustarc';

import {
  ALL_POLICIES_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
  TRAINING_POLICIES_URL,
} from '../../config/constants';
import './styles.scss';
import RHLogo from '../../img/redhat-logo-black-horizontal.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer-main">
        <Row className="anchor">
          <Col className="col-md-3 col-xl-2 brand redhat">
            <img src={RHLogo} width={126} height={30} alt={t('Red Hat logo')} />
          </Col>
          <Col className="col-md-9 col-xl-10 legal">
            <div className="legal-nav">
              <ul>
                <li>
                  <a
                    href={PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="privacy-policy"
                  >
                    {t('Privacy Policy')}
                  </a>
                </li>
                <li>
                  <a
                    href={TRAINING_POLICIES_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="training-policies"
                  >
                    {t('Red Hat Training Policies')}
                  </a>
                </li>
                <li>
                  <a
                    href={TERMS_OF_USE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="terms-of-use"
                  >
                    {t('Terms of Use')}
                  </a>
                </li>
                <li>
                  <a
                    href={ALL_POLICIES_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="all-policies"
                  >
                    {t('All policies and guidelines')}
                  </a>
                </li>
                <li>
                  <Trustarc />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
    </footer>
  );
};

export default Footer;
