import { get } from 'axios';

export const downloadFile = async (url) => {
  try {
    const response = await get(url);
    return response;    
  } catch (error) {
    throw new Error(error);
  }
};

export default downloadFile;
