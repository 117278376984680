import { get, post, delete as del} from 'axios';
import { CLASS_STUDENTS_API_URL } from '../config/constants';



export const getStudentDetailsByClassId = async (classId) => {
  try {
    
    const response = await get(`${CLASS_STUDENTS_API_URL.replace("<class_id>",classId)}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteStudentFromClass = async (classId,userNames) => {
  try {
    
    const response = await del(`${CLASS_STUDENTS_API_URL.replace("<class_id>",classId)}`,{data:userNames});
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const postGradeStudent = async (classId,data) => {
    try {
      const response = await post(`${CLASS_STUDENTS_API_URL.replace("<class_id>",classId)}`, data);
      return response?.data;
    } catch (err) {
      console.error(err);
      return `Couldn't save grades`;
    }
  };









export default { getStudentDetailsByClassId, deleteStudentFromClass, postGradeStudent};
